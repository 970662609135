import React, {useState} from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useCustomModal } from "../custom-message-modal";
import './remove-entity-modal.scss';
import {useAppDispatch} from "../../../hooks/redux-hook";
import { showSnackbar } from "../../../redux/snackbar";
import {Project} from "../../../models/Project";
import {postApiTasksBulkLinkFilesToTask} from "../../../services/task";
import {StyledCloseIconUploader} from "../../../components/close-button";
import {TaskListSelectMenuItem} from "../../../components/TaskListSelectMenuItem";

export function LinkFilesToTaskModal(project: Project, id: number, files: number[], refreshProject: () => void) {

    const dispatch = useAppDispatch();
    const { register, watch, handleSubmit, formState: { errors }, getValues, setError } = useForm<{ taskId: number }>();
    const { hideModal } = useCustomModal();
    const [selectedTasks, setSelectedTasks] = useState<number[]>([]);


    const onSubmit = () => {
        postApiTasksBulkLinkFilesToTask({taskIds: selectedTasks, files: files})
            .then(() => {
                console.log('successfully linked to a task')
                dispatch(showSnackbar({ message: `Successfully linked files to tasks!`, type: "info" }));
            })
            .catch(() => {
                dispatch(showSnackbar({ message: "Error linking files to tasks!", type: "error" }));
            }).finally(() => {
                hideModal();
                refreshProject()
        });
    }

    const onSelectionChange = (event: any) => {
        const taskAlreadySelected = selectedTasks.some(task => task===parseInt(event.target.value))
        if (taskAlreadySelected) {
            setSelectedTasks(prevState => prevState.filter((el) => el!==parseInt(event.target.value)))
        } else {
            setSelectedTasks([...selectedTasks, parseInt(event.target.value)])
        }
    }

    return <div style={{marginTop: 10, width: 500, maxHeight: "calc(100vh - 204px)"}}>
        <div className="wizard-title">
            <StyledCloseIconUploader onClick={hideModal}/>
            <span>{"Link files to tasks"}</span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} style={{marginBottom: 20}}>
        {project?.taskLists ?
            <div>
                <div style={{marginBottom: 100, marginTop: 90}}>
                    {project.taskLists!.map(taskList => {
                        return <TaskListSelectMenuItem key={taskList.id} taskList={taskList} selectedTasks={selectedTasks} onClick={onSelectionChange} />
                    })}
                </div>
            </div>
                    : <p>No task lists found</p>
                }
                <div style={{textAlign: "center"}}>
                    <Button color="secondary" variant="outlined" onClick={hideModal} style={{marginRight: 40}}>CANCEL</Button>
                    <Button disabled={selectedTasks.length===0} type="submit" color="secondary" variant="contained">
                        SUBMIT
                    </Button>
                </div>
            <br/>
        </form>
    </div>
}
