import { loadProjects } from "../redux/projects";
import { hideProgressLine, showProgressLine } from "../redux/progress-line";
import { getApiTemplates, getApiTemplatesById } from "./template";
import { loadTemplate, loadTemplates } from "../redux/projectTemplates";
import { showSnackbar } from "../redux/snackbar";
import { getApiProjects, getApiProjectsWithoutFilesById } from "./project";
import {getApiPatternSets, getApiPatternsBySetId} from "./patternSets";
import { getApiUsers } from "./user";
import { loadUsers } from "../redux/users";
import { getApiCustomFields } from "./custom.fields";
import { loadCustomFields } from "../redux/custom-fields";
import { AppDispatch } from "../redux/store";
import { Project } from "../models/Project";
import { getApiTasksWorklogSummaryById } from "./task";
import { clearTaskTimeTracking, updateTaskTimeTracking } from "../redux/tasksTimeTracking";
import { TaskTimeTrackSummary } from "../models/TaskTimeTrackSummary";
import patternSets, {addPatternsToSet, loadPatternSets, setPatternSetsLoaded} from "../redux/patternSets";

export const checkLoading = (dispatch: AppDispatch, totalProjectsIds: number[] | null, initProjects: Project[]) => {
    if (totalProjectsIds !== null && totalProjectsIds.length === initProjects.length) {
        dispatch(loadProjects(initProjects.sort((a, b) => a.id > b.id ? -1 : 1)));
    }
}


export const fetchTemplates = (signal: AbortSignal, dispatch: AppDispatch) => {
    dispatch(showProgressLine());
    getApiTemplates(signal)
        .then(result => {
            result.forEach(t => {
                getApiTemplatesById(t.id)
                    .then(template => dispatch(loadTemplate(template)));
            })
            dispatch(loadTemplates(result))
        })
        .then(() => dispatch(hideProgressLine()))
        .catch(() => {
            dispatch(hideProgressLine());
            dispatch(showSnackbar({ message: "Error loading templates!" , type: "error" }));
        });
}

export const fetchProjects = (signal: AbortSignal, dispatch: AppDispatch,
                              totalProjectsIds: number[] | null, initProjects: Project[]) => {
    dispatch(showProgressLine());
    getApiProjects(signal)
        .then(projectsList => {
            totalProjectsIds = projectsList.map(p => p.id);
            fetchProjectsTasksTimeTrackingSummary(signal, dispatch, totalProjectsIds);
            projectsList.forEach(async project => {
                getApiProjectsWithoutFilesById(+project.id).then(project => {
                    initProjects.push(project);
                    checkLoading(dispatch, totalProjectsIds, initProjects);
                });
            });
        })
        .then(() => dispatch(hideProgressLine()))
        .catch(() => {
            dispatch(hideProgressLine());
            dispatch(showSnackbar({ message: "Unable to load projects!" , type: "error" }));
        });
}

export const fetchPatternSets = (signal: AbortSignal, dispatch: AppDispatch) => {
    dispatch(setPatternSetsLoaded(false))
    dispatch(showProgressLine());
    getApiPatternSets(signal)
        .then((patternSets) => {
            dispatch(loadPatternSets(patternSets));
            dispatch(setPatternSetsLoaded(true))
        })
        .then(() => dispatch(hideProgressLine()))
        .catch((reason) => {
            dispatch(hideProgressLine());
            dispatch(showSnackbar({ message: "Unable to load master patterns!" , type: "error" }));
            console.log(reason.message)
        });
}

export const fetchUsers = (signal: AbortSignal, dispatch: AppDispatch) => {
    dispatch(showProgressLine());
    getApiUsers({ includeDeleted: false }, signal)
        .then(users => dispatch(loadUsers(users)))
        .then(() => dispatch(hideProgressLine()))
        .catch(() => {
            dispatch(hideProgressLine());
            dispatch(showSnackbar({ message: "Unable to load users!", type: "error" }));
        });
}

export const fetchCustomFields = (signal: AbortSignal, dispatch: AppDispatch) => {
    dispatch(showProgressLine());
    getApiCustomFields(signal)
        .then(customFields => dispatch(loadCustomFields(customFields)))
        .then(() => dispatch(hideProgressLine()))
        .catch((reason) => {
            dispatch(hideProgressLine());
            dispatch(showSnackbar({ message: "Unable to load custom fields!", type: "error" }));
            console.log(reason.message)
        });
}

export const fetchProjectsTasksTimeTrackingSummary = (signal: AbortSignal, dispatch: AppDispatch, projectIds: number[]) => {
    dispatch(clearTaskTimeTracking());
    projectIds.forEach(projectId => {
        fetchTasksTimeTrackingSummary(signal, dispatch, projectId);
    })
}

export const fetchTasksTimeTrackingSummary = (signal: AbortSignal, dispatch: AppDispatch, projectId: number) => {
    dispatch(showProgressLine());
    getApiTasksWorklogSummaryById(projectId)
        .then(data => {
            if (data.length > 0) {
                data.forEach((tts: TaskTimeTrackSummary) => {
                    dispatch(updateTaskTimeTracking(tts))
                })
            }
        })
        .then(() => dispatch(hideProgressLine()))
        .catch(() => {
            dispatch(hideProgressLine());
            dispatch(showSnackbar({ message: "Unable to load tasks time track data!", type: "error" }));
        });
}
