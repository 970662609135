import {Button, Menu, MenuItem} from '@mui/material';
import React, {useContext, useState} from 'react';
import LoadTransformsContext from "../../../contexts/load-transforms-context";


const LoadTransformsButton = () => {
    const { loadTransformCallbacks, setLoadTransformCallbacks} = useContext(LoadTransformsContext)
    const loadTransformCallback = loadTransformCallbacks.risk
    const transformFromReportCallback = loadTransformCallbacks.report
    const transformToCategoryCallback = loadTransformCallbacks.category
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button variant="contained" color="secondary"
                className={'transform-button'}
                onClick={handleClick}
            >
                Load
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={'transform-menu'}
                style={{ zIndex: 99999 }}
            >
                <MenuItem onClick={() => {handleClose();loadTransformCallback();}}>RLS Protect Risk</MenuItem>
                <MenuItem onClick={() => {handleClose();transformFromReportCallback();}}>Transforms Report</MenuItem>
                <MenuItem onClick={() => {handleClose();transformToCategoryCallback();}}>Category Names</MenuItem>
            </Menu>
        </div>
    );
};

export default LoadTransformsButton;