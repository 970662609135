import { useState } from "react";
import { Button, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCustomModal } from "../modals/custom-message-modal";
import { ProjectTemplateWizard } from "./wizards/project-template-wizard";
import { Template } from "../../models/Template";
import { ProjectTemplateRenameWizard } from "./wizards/project-template-rename";
import { ProjectTemplateDelete } from "./wizards/project-template-delete";
import { MoreActionsComponent } from "../../components/more-actions-component";
import { useEventHandler } from "../../hooks/handle-events";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { loadTemplate, projectTemplatesSelector } from "../../redux/projectTemplates";
import { getApiTemplatesById } from "../../services/template";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { showSnackbar } from "../../redux/snackbar";

export function ProjectTemplates() {
    const { showModal } = useCustomModal();
    const dispatch = useAppDispatch();
    const templates = useAppSelector((state) => projectTemplatesSelector(state));

    const fetchTemplate = (id: number) => {
        dispatch(showProgressLine());
        getApiTemplatesById(id)
            .then(template => dispatch(loadTemplate(template)))
            .then(() => dispatch(hideProgressLine()))
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error loading template!", type: "error" }));
            })
    }

    return <div style={{ margin: '32px 32px 0 32px', backgroundColor: '#ffffff',
        height: `calc(100vh - 184px)`, overflow: "auto" }}>
        <div style={{ display: "flex", flexDirection: "column", padding: '24px 32px' }}>
            <span style={{ fontSize: 32, marginBottom: 16 }}>Project Templates</span>
            <p style={{ fontSize: 17, margin: 0 }}>
                Here, you can create a reusable project template that will help you quickly
                set up a project based on your project plan or steps for a specific type of submission. Create once, reuse many times.</p>
            <h5 style={{ fontSize: 25, marginBottom: 32, marginTop: 32, color: '#223250' }}>My Templates</h5>
            {templates.map(template =>
                <ProjectTemplateItem key={template.id} template={template} refreshList={fetchTemplate} />)}
            <Button variant="contained" color="secondary" style={{ width: 174 }}
                    onClick={() => showModal(ProjectTemplateWizard, {})}>
                CREATE TEMPLATE
            </Button>
        </div>
    </div>
}

function ProjectTemplateItem(props: { template: Template, refreshList: (id: number) => void }) {

    const { showModal } = useCustomModal();
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();

    const menuItems = [
        <MenuItem
            key={0}
            onMouseEnter={onMouseEventHandler}
            onMouseLeave={onLeaveMouseEventHandler}
            onClick={() => {
                showModal(ProjectTemplateRenameWizard, { template: props.template })
                    .then(() => props.refreshList(props.template.id));
                setAnchorEl(null);
            }}>
            Rename template
        </MenuItem>,

        <MenuItem
            key={1}
            onMouseEnter={onMouseEventHandler}
            onMouseLeave={onLeaveMouseEventHandler}
            onClick={() => {
                showModal(ProjectTemplateDelete, { template: props.template });
                setAnchorEl(null);
            }}>
            <span style={{ color: 'red' }}>Remove template</span>
        </MenuItem>
    ]

    return <div style={{
        border: '1px solid #E6E7E8', height: 56, marginBottom: 16,
        display: "flex", alignItems: 'center', justifyContent: 'space-between'
    }}>
        <span style={{ fontSize: '15px', color: '#223250', paddingLeft: 24, cursor: 'pointer' }}
              onClick={() => navigate(`/app/user/workflow/project-template/${props.template.id}`)}>
            {props.template.name}
        </span>
        <div style={{alignItems: "right"}}>
        <MoreActionsComponent
            id={props.template.id}
            menuItems={menuItems}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            menuHorizontalPosition = "right"
        />
        </div>
    </div>
}
