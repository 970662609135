import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import "../pages.scss";
import {Box, Button} from "@mui/material";
import {CSVLink} from "react-csv";
import {DataGridPro, GridColDef, GridRowsProp} from "@mui/x-data-grid-pro";
import {getFileNoCache} from "../../components/file-management";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useEventHandler} from "../../hooks/handle-events";
import {useAuthService} from "../../contexts/auth-context";
import {getLogFilesByProjectIdAndFilename} from "../../services/files";

export type sortOption = 'asc' | 'desc';

export function LogsReportViewer () {
    const params = useParams();
    const { documentName, projectId } = params;
    const { hasUserRoleAccess } = useAuthService();
    const headerRow: string[] = ['Date', 'Time', 'Document', 'User', 'Roles', 'Action', 'Annotation Type', 'Pages'];
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [sortDirection, setSortDirection] = useState<sortOption>('asc');
    const [tableRows, setTableRows] = useState<string[][]>([])
    const [filteredRows, setFilteredRows] = useState<string[][]>([])
    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()

    useEffect(() => {
        if (!hasUserRoleAccess()) {
            return;
        }
        if (tableRows.length>0) {
            const results = tableRows.filter(log => {
                if (startDate!==undefined && startDate!==null) {
                    let dateString = log[0] + ' ' + log[1]
                    return (new Date(dateString).getTime()) >= startDate.getTime()
                }
                return true
            }).filter(log => {
                if (endDate!==undefined && endDate!==null) {
                    let dateString = log[0] + ' ' + log[1]
                    return (new Date(dateString).getTime()) <= endDate.getTime()
                }
                return true
            }).sort((a, b) => {
                let aDateString = a[0] + ' ' + a[1]
                let bDateString = b[0] + ' ' + b[1]
                if (sortDirection==='desc') {
                    return (new Date(bDateString).getTime()) - (new Date(aDateString).getTime())
                } else {
                    return (new Date(aDateString).getTime()) - (new Date(bDateString).getTime())
                }
            })
            setFilteredRows(results)
            return;
        }
        getLogFilesByProjectIdAndFilename(projectId!, getDocumentLogsFolder(documentName)).then(fileUrls => {
            let rows: string[][] = []
            for (let url of fileUrls!) {
                getFileContent(url!)
                    .then(fileString => {
                        let i = 0
                        for (let row of fileString.split("\n")) {
                            if (i > 0) {//the first line is the header, so we skip it
                                // setCurrentHeaders(row.split(","))
                                let rowList: string[] = []
                                for (let col of row.split(",")) {
                                    rowList.push(col)
                                }
                                rows.push(rowList)
                            }
                            i++;
                        }
                        setTableRows(tableRows.concat(rows))
                        setFilteredRows(filteredRows.concat(rows))
                        setIsLoading(false)
                    })
            }
            if (fileUrls===undefined || fileUrls.length===0) {
                setIsLoading(false)
            }
        })

      },[startDate, endDate, sortDirection]);

    const getDocumentLogsFolder = (documentName: string | undefined) => {//
        // The document log folder is located in s3 in this location
        if (documentName!==undefined) {
            return documentName + '-logs'
        } else {
            return ''
        }
    }

    async function getFileContent(url: string): Promise<string> {
        if (url) {
            const result = await getFileNoCache(url)
            return result.toString()
        } else {
            return "";
        }
    }

    function getRows() {
        let rows: GridRowsProp = [];
        if (filteredRows.length === 0) {
            return rows;
        }
        let i = 0;
        for(let row of filteredRows) {
            rows = rows.concat(constructRowObject(i, row))
            ++i
        }

        return rows;
    }

    function constructRowObject(i: number, row: any) {
        return {
            id: i,
            Date: row[0],
            Time: row[1],
            Document: row[2],
            User: row[3],
            Roles: row[4],
            Action: row[5],
            'Annotation Type': row[6],
            Pages: row[7]
        };
    }

    const columns: GridColDef[] = headerRow.map(header => {
        return {field: header, headerName: header, minWidth: 230, width: 230}
    })

    const CsvFileName = 'logs_'+(new Date().toLocaleString());

    if (!hasUserRoleAccess()) return <div><p>Your user account does not have permission to access this option. Please contact your System Administrator or RLS Customer Support for assistance.</p></div>

    if (isLoading) return <div>Loading</div>

    if (tableRows.length===0) return <div>No Logs Found</div>

    return <div>
        <header style={{ fontSize: 30, marginBottom: 10 }}>Logs</header>
        <Button variant="contained" color="secondary" style={{marginRight: 10}} >
            <CSVLink style={{textDecoration: "inherit", color: "inherit"} } headers={headerRow} data={tableRows} filename={CsvFileName}>EXPORT RAW DATA</CSVLink>
        </Button>
        <p style={{ fontSize: 17, marginBottom: 20 }}>Raw data will be exported without respect to any filtering or sorting selected on the table below.</p>
        <TextField
            id="datetime-local"
            label="Start Date"
            type="datetime-local"
            InputLabelProps={{
                shrink: true,
            }}
            onChange={(event) => {
                if (event.target.value==='') {//this fixes a bug
                    setStartDate(undefined)
                } else {
                    setStartDate(new Date(event.target.value))
                }
            }}
            style={{ marginBottom: 20, marginRight: 10 }}
        />
        <TextField
            id="datetime-local"
            label="End Date"
            type="datetime-local"
            InputLabelProps={{
                shrink: true,
            }}
            onChange={(event) => {
                if (event.target.value==='') {
                    setEndDate(undefined)
                } else {
                    setEndDate(new Date(event.target.value))
                }
            }}
            style={{ marginBottom: 20, marginRight: 10 }}
        />
        <Select
            label='Sorting' placeholder='Sorting'
            value={sortDirection}
            onChange={e => { setSortDirection(e.target.value as sortOption) }}
            sx={{ cursor: "pointer !important" }}
        >
            <MenuItem
                value={"asc"}
                style={{ backgroundColor: "white" }}
                onMouseEnter={onMouseEventHandler}
                onMouseLeave={onLeaveMouseEventHandler}>ASCENDING ORDER</MenuItem>
            <MenuItem
                value={"desc"}
                style={{ backgroundColor: "white" }}
                onMouseEnter={onMouseEventHandler}
                onMouseLeave={onLeaveMouseEventHandler}>DESCENDING ORDER</MenuItem>
        </Select>
        <Box>
            <DataGridPro autoPageSize={false}
                         autoHeight={true}
                         rows={getRows()}
                         columns={columns}
                         keepColumnPositionIfDraggedOutside={true}
            />
        </Box>
    </div>

}
