import React, {useEffect, useState} from 'react';
import { useAuthService } from '../../contexts/auth-context';
import {useLocation} from 'react-router-dom';

import "../pages.scss";
import './doc-viewer.scss';
import WebViewerComponent from '../../components/webviewer';

interface StateProps {
  file?: string
}
const LeftPaneDocViewer = () => {
  const auth = useAuthService();

  if (auth.hasDocsAccess()) {
    return <WebViewerComponent files={['/Anonymize_fixture.pdf']}/>
  } else {
   return <div><p>Your user account does not have permission to access this option. Please contact your System Administrator or RLS Customer Support for assistance.</p></div>
  }
};

export default LeftPaneDocViewer;
