import Button from "@mui/material/Button";
import { useState } from "react";
import { ButtonSpinner } from "../../../components/button-spinner";
import { useAdminService } from "../../../contexts/super-admin-context";
import { InternalUser } from "../../../models/InternalUser";
import baseline_close from "../../../provisional_icons/baseline-close.png";
import { useCustomModal } from "../custom-message-modal";

export function ReactivateUserModal(props: { user: InternalUser, fetchSuperAdmins?: () => void }) {
    const { hideModal } = useCustomModal();
    const [loading, setLoading] = useState(false);
    const { reactivateUser, currentAccount } = useAdminService();

    const onSubmit = async () => {
        setLoading(true);
        await reactivateUser(props.user.id, props.user.accountId!, (props.user.name ?? props.user.email));
        if (props.fetchSuperAdmins) {
            props.fetchSuperAdmins();
        }
        hideModal();
        setLoading(false);
    }

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img alt="close" src={baseline_close} className="remove-entity-close-button" onClick={() => loading ? {} : hideModal()} />
            <span className="remove-entity-title" style={{ color: "#223250" }}>
                {props.user.role !== "SUPER" ? `Reactivate user ${props.user.name ?? props.user.email} in account ${currentAccount?.name}` :
                    `Reactivate user ${props.user.name ?? props.user.email} as super admin`
                }?
            </span>
            <p className="remove-entity-disclaimer" >
                Reactivated user will be able to login into the RLS platform {props.user.role !== "SUPER" ? "in this account" :
                    "as a super admin"}.
            </p>
            <div className="remove-entity-button-list" style={{ marginTop: 20 }} >
                <Button color="secondary" variant="outlined" onClick={hideModal} disabled={loading}>CANCEL</Button>
                <Button disabled={loading} onClick={onSubmit} color="secondary" variant="contained">
                    REACTIVATE {props.user.role !== "SUPER" ? "USER" : "SUPER ADMIN"} {loading && <ButtonSpinner />}
                </Button>
            </div>
        </div>
    </div>
}
