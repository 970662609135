import React from 'react';
import { Choice } from '@pdftron/webviewer-react-toolkit';
import './RedactionSearchResult.scss';
import classNames from 'classnames';
import {redactionTypeMap} from "../../../constants";

// Alternatively wrap this in useCallback and declare inside component
const displayRedactionSearchResult = (props) => {
    const { ambientStr, resultStrStart, resultStrEnd, resultStr, type } = props;
    if (type === redactionTypeMap['TEXT'] || !(type in redactionTypeMap) ) { // //If the type is a user added pattern we also want to show the before and after text
        const searchValue = ambientStr === '' ? resultStr : ambientStr.slice(resultStrStart, resultStrEnd);
        const textBeforeSearchValue = ambientStr.slice(0, resultStrStart);
        const textAfterSearchValue = ambientStr.slice(resultStrEnd);
        return (
            <>
                {textBeforeSearchValue}
                <span className="search-value">{searchValue}</span>
                {textAfterSearchValue}
            </>
        );
    }
    return resultStr;
};

const RedactionSearchResult = (props) => {
    const {
        isChecked,
        onChange,
        onClickResult,
        isActive,
        icon,
        index
    } = props;

    const displayResult = displayRedactionSearchResult(props);
    const searchResultClassname = classNames('redaction-search-result', { active: isActive });

    return (
        <div className={searchResultClassname} role="listitem" onClick={onClickResult} id={`search-result-${index}`}>
            <div style={{ paddingRight: '14px' }}>
                <input type={"checkbox"}
                    checked={isChecked ?? false}
                    onChange={onChange}
                />
            </div>
            {/*<div style={{ paddingRight: '14px' }}>*/}
            {/*    <Icon glyph={icon} />*/}
            {/*</div>*/}
            <div className="redaction-search-result-info">
                {displayResult}
            </div>
        </div >
    );
};

export default React.memo(RedactionSearchResult);
