import { Worklog } from "../../models/Worklog";
import { useCustomModal } from "../../pages/modals/custom-message-modal";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { tasksTimeTrackingSelector, taskTimeTrackingSelector } from "../../redux/tasksTimeTracking";
import { ConditionalWrapper } from "../conditional-wrapper";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { blueIconColor } from "../../constants";
import Menu from "@mui/material/Menu";
import { convertMinutesToHours, getUserInitials } from "../../services/utils";
import Button from "@mui/material/Button";
import { ProjectEditLoggedTime } from "../../pages/user/wizards/project-edit-logged-time";
import { useTheme } from "@mui/material/styles";
import { useAuthService } from "../../contexts/auth-context";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteApiTasksWorklogById } from "../../services/task";
import { GroupByUserWorklog } from "../../pages/user/task-details";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { showSnackbar } from "../../redux/snackbar";
import { useMediaQuery } from "@mui/material";
import { setShowToolbarAfterDeletion } from "../../redux/action-bar";

export function TimeTrackingComponent(props: { isTemplate: boolean, worklogs: GroupByUserWorklog[], myWorklogs: Worklog[],
    refresh: () => void, taskId: number, subtaskIds: number[], isSubtask: boolean }) {

    const auth = useAuthService();
    const { showModal } = useCustomModal();
    const smallWindow = useMediaQuery('(max-width:1750px)');
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const taskTimeTracked = useAppSelector((state) => taskTimeTrackingSelector(state, props.taskId));
    const subtasksTimeTracked = useAppSelector((state) => tasksTimeTrackingSelector(state, props.subtaskIds));
    const dispatch = useAppDispatch();
    const getTotalTrackedTime = () => (taskTimeTracked?.minutes || 0) + subtasksTimeTracked.reduce((prev, tts) => prev + tts.minutes, 0)

    return <>
        <ConditionalWrapper condition={!props.isTemplate && auth.hasUserRoleAccess()} wrapper={(children: JSX.Element) =>
            <Tooltip title="Edit time">{children}</Tooltip>}
        >
            <IconButton disabled={props.isTemplate || !auth.hasUserRoleAccess()} style = {{marginLeft: smallWindow ? 16 : 40}}
                        onClick={(el) => {
                            dispatch(setShowToolbarAfterDeletion(false));
                            setAnchorEl(el.currentTarget);
                        }}>
                <AccessTimeFilledIcon style={{ color: !props.isTemplate && auth.hasUserRoleAccess() ? blueIconColor : "#E6E7E8" }} />
            </IconButton>
        </ConditionalWrapper>
        {open && <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            style={{ padding: 0 }}
            PaperProps={{ sx: { width: 256 } }}
        >
            <div className="time-tracking-component-wrapper">
                <div className="time-tracking-component-header">
                    <span className="time-tracking-component-text">{props.isSubtask ? "SUBTASK" : "TASK"}</span>
                    <span className="time-tracking-component-task-time">{convertMinutesToHours(taskTimeTracked?.minutes || 0)}</span>
                </div>
                {props.isSubtask ? <div style={{ marginBottom: 5 }}></div> : <div className="time-tracking-component-subheader">
                    <span className="time-tracking-component-subtasks">TOTAL WITH SUBTASKS</span>
                    <span className="time-tracking-component-subtask-time">{convertMinutesToHours(getTotalTrackedTime())}</span>
                </div>}
                <div style={{ display: "flex", flexDirection: "column", overflowY: "auto", maxHeight: "calc(100vh - 440px)"}}>
                    {props.worklogs.filter(w => w.usersWorklog.minutes > 0).map((worklog, index) =>
                        <TimeTrackingUserItem key={worklog.usersWorklog.id} worklog={worklog}
                                              refresh={props.refresh} index={index} myWorklogs={props.myWorklogs} />)}
                </div>
                <div className="time-tracking-component-bottom">
                    <Button color="secondary" variant="contained" sx={{ width: 224, margin: "16px 0" }}
                            onClick={() => showModal(ProjectEditLoggedTime, { editedWorklog: undefined,
                                myWorklogs: props.myWorklogs, taskId: props.taskId, manual: true, refresh: props.refresh })}>
                        ADD TIME MANUALLY
                    </Button>
                    <Button color="secondary" variant="outlined" sx={{ width: 110 }} onClick={() => setAnchorEl(null)}>
                        CLOSE
                    </Button>
                </div>
            </div>
        </Menu>}
    </>
}

function TimeTrackingUserItem(props: { index: number, worklog: GroupByUserWorklog, myWorklogs: Worklog[], refresh: () => void }) {

    const dispatch = useAppDispatch();
    const [isHovered, setIsHovered] = useState(false);
    const theme = useTheme();
    const auth = useAuthService();
    const { showModal } = useCustomModal();
    const orderedWorklogs = props.worklog.usersWorklog.worklogs
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    const isMyAccount = auth.loginInfo?.tenant?.user?.id === props.worklog.usersWorklog.userId;
    const [open, setOpen] = useState(false);

    return <>
        <div style={{ borderTop: props.index === 0 ? "1px solid lightgray" : "" }} />
        <MenuItem onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
                  onClick={() => setOpen(!open)}
                  style={{
                      display: "flex", alignItems: "center", justifyContent: "space-between",
                      backgroundColor: isHovered ? theme.palette.lightGrayishBlue3.main : "transparent"
                  }}>
            <div style={{ display: "flex", alignItems: "center", whiteSpace: "break-spaces" }}>
                <Avatar sx={{ bgcolor: props.worklog.usersWorklog.color, width: 24, height: 24, fontSize: 14 }}>
                    {getUserInitials(props.worklog.usersWorklog.userName)}
                </Avatar>
                <span style={{ fontSize: 15, marginLeft: 8 }}>{props.worklog.usersWorklog.userName}</span>
            </div>
            <span style={{ fontSize: 13, letterSpacing: 0.4 }}>{convertMinutesToHours(props.worklog.usersWorklog.minutes)}</span>
        </MenuItem>
        {open && <div style={{ margin: "6px 16px" }}>
            {orderedWorklogs.filter(w => w.minutes > 0).map(w =>
                <div key={w.id} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                            <span style={{ fontSize: 13, letterSpacing: 0.4, display: "inline-block", width: 55 }}>
                                {convertMinutesToHours(w.minutes)}
                            </span>
                        <span style={{ fontSize: 13, letterSpacing: 0.4, color: "#939598", marginLeft: 16 }}>
                                {moment(w.date).format("MMMM DD")}
                            </span>
                    </div>
                    {isMyAccount || auth.hasAdminRoleAccess() ? <div>
                        <ModeEditIcon style={{ cursor: "pointer", color: "#939598" }} onClick={() => {
                            showModal(ProjectEditLoggedTime, { editedWorklog: w, myWorklogs: props.myWorklogs,
                                taskId: w.taskId, refresh: props.refresh });
                        }} />
                        <DeleteIcon onClick={() => {
                            dispatch(showProgressLine());
                            deleteApiTasksWorklogById(w.id)
                                .then(() => {
                                    dispatch(showSnackbar({ message: "Logged time removed successfully.", type: "info" }))
                                    dispatch(hideProgressLine());
                                    props.refresh();
                                })
                                .catch(() => {
                                    dispatch(hideProgressLine());
                                    dispatch(showSnackbar({ message: "Error deleting entry!", type: "error" }));
                                })
                        }} style={{ cursor: "pointer", color: theme.palette.error.main, marginLeft: 16 }} />
                    </div> : <div />}
                </div>
            )}
        </div>
        }
        <div style={{ borderBottom: "1px solid lightgray" }} />
    </>
}

