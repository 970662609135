import { createSlice } from '@reduxjs/toolkit'
import { RootState } from "./store";
import { TaskList } from "../models/TaskList";

const defaultTaskLists: TaskList[] = [];

const taskListsInitialState = {
    taskLists: defaultTaskLists
}

const taskLists = createSlice({
    name: 'taskLists',
    initialState:taskListsInitialState,
    reducers: {
        loadTaskLists: (state, action) => {
            if (state.taskLists.find(tl => tl.projectId === action.payload[0].projectId)) {
                state.taskLists = state.taskLists.filter(tl => tl.projectId !== action.payload[0].projectId).concat(action.payload);
            } else {
                state.taskLists = state.taskLists.concat(action.payload);
            }
        }
    }
})

export const { loadTaskLists } = taskLists.actions
export const taskListsSelector = (state: RootState, projectId: number) =>
    state.taskLists.taskLists.filter(tl => tl.projectId === projectId)

export default taskLists.reducer
