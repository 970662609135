import { FormControl, InputLabel, Select, MenuItem, Checkbox, Button } from "@mui/material";
import { useState } from "react";
import { Row } from "read-excel-file";
import { StyledBackArrow, StyledCloseIcon } from "../../../components/close-button";
import { CustomFieldKeyTypes } from "../../../components/project/task";
import { useEventHandler } from "../../../hooks/handle-events";
import { useAppSelector, useAppDispatch } from "../../../hooks/redux-hook";
import { CustomField } from "../../../models/CustomField";
import { Task } from "../../../models/Task";
import { TaskCustomFieldValues } from "../../../models/TaskCustomFieldValues";
import { customFieldsSelector, loadCustomFieldsValues } from "../../../redux/custom-fields";
import { projectSelector, loadProject } from "../../../redux/projects";
import { showSnackbar } from "../../../redux/snackbar";
import { getApiCustomFieldsValuesTaskListById } from "../../../services/custom.fields";
import { putApiProjectsProjectCustomFieldsById } from "../../../services/project";
import { postApiTasksBulkCreate, postApiTasksBulkCreateCfv } from "../../../services/task";
import { useCustomModal } from "../custom-message-modal";

interface ImportTaskData {
    includeHeader: boolean;
    customFieldId: number;
    columnCustomField: number;
    columnName: number;
}

export function ImportTasksExcel(props: {
    cells: Row[], taskListId: number, taskListName: string, refreshList: () => void, projectId: number,
    isTemplate: boolean, parentTask?: Task
}) {
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const firstRow = props.cells[0];
    const [importExcelData, setImportExcelData] = useState<ImportTaskData>();
    const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("").map(letter => letter.toUpperCase());
    const columns = alphabetArray.filter((letter, index) => firstRow.length > index);
    const nameColumns = columns.filter((c, i) => i !== importExcelData?.columnCustomField);
    const customFieldColumns = columns.filter((c, i) => i !== importExcelData?.columnName);
    const customFields: CustomField[] = useAppSelector((state) => customFieldsSelector(state)).filter(cf =>
        cf.shortName !== "redactionMarks" && cf.shortName !== "identifiers");
    const dispatch = useAppDispatch();
    const project = useAppSelector((state) => projectSelector(state, props.projectId));
    const { hideModal } = useCustomModal();
    const [errorCustomField, setErrorCustomField] = useState(false);
    const [errorTasks, setErrorTasks] = useState(false);

    const importExcelForProjects = async () => {
        if ((importExcelData?.columnCustomField !== undefined && importExcelData.customFieldId === undefined) ||
            (importExcelData?.customFieldId !== undefined && importExcelData.columnCustomField === undefined)) {
            setErrorCustomField(true);
            return;
        }
        if (importExcelData?.columnName === undefined) {
            setErrorTasks(true);
            return;
        }
        const importedTasks: TaskCustomFieldValues[] = [];
        if (!importExcelData?.includeHeader) {
            props.cells.splice(0, 1);
        }
        props.cells.filter(row => row[importExcelData?.columnName!] !== null).forEach(row => {
            importedTasks.push({
                task: {
                    id: -1, taskListId: props.taskListId, name: row[importExcelData?.columnName!].toString(),
                    status: "NOT_STARTED", parentTaskId: props.parentTask?.id ?? null,sortId:0
                }, customFieldValues: row[importExcelData?.columnCustomField!] !== null &&
                    row[importExcelData.columnCustomField] !== undefined ?
                    [{
                        id: -1, taskId: -1, customFieldId: importExcelData?.customFieldId!,
                        valueString: row[importExcelData?.columnCustomField!].toString()
                    }] : []
            });
        });
        const projectCustomFields = project.projectCustomFields || {}
        const shortName = customFields.find(cf => cf.id === importExcelData?.customFieldId)?.shortName! as CustomFieldKeyTypes;
        if (!projectCustomFields[shortName]) {
            const updateProjectCustomFields = {
                ...projectCustomFields,
                [shortName! as CustomFieldKeyTypes]: true
            }
            await putApiProjectsProjectCustomFieldsById(project.id, updateProjectCustomFields)
                .then(() => {
                    dispatch(loadProject({ ...project, projectCustomFields: updateProjectCustomFields }));
                })
        }
        postApiTasksBulkCreateCfv(importedTasks).then(() => props.refreshList()).then(() => {
            getApiCustomFieldsValuesTaskListById(props.taskListId!).
                then(cfv => dispatch(loadCustomFieldsValues({
                    taskListId: props.taskListId,
                    customFieldsValues: cfv
                })))
        }).then(() => dispatch(showSnackbar({
            message: `${importedTasks.length} ${props.parentTask ? "subtasks" : "tasks"} imported successfully in 
                ${props.taskListName}.`,
            type: "info"
        }))).catch(() => dispatch(showSnackbar({
            message: "Excel file couldn't be imported. Please check the file you want to import and try again.",
            type: "info"
        })));
        hideModal();
    }

    const importExcelForTemplates = () => {
        const arr: Task[] = [];
        if (!importExcelData?.includeHeader) {
            props.cells.splice(0, 1);
        }
        props.cells.filter(row => row[importExcelData?.columnName!] !== null).forEach(row => {
            arr.push({
                id: -1,
                taskListId: props.taskListId, name: row[importExcelData?.columnName!].toString(), status: "NOT_STARTED",sortId:0
            });
        });
        postApiTasksBulkCreate(arr).then(() => props.refreshList()).then(() => dispatch(showSnackbar({
            message: `${arr.length} tasks imported successfully in ${props.taskListName}.`,
            type: "info"
        }))).catch(() => dispatch(showSnackbar({
            message: "Excel file couldn't be imported. Please check the file you want to import and try again.",
            type: "info"
        })));
        hideModal();
    }

    return <div style={{
        width: !props.isTemplate ? 420 : 380, height: !props.isTemplate ? 615 : 400, display: "flex",
        flexDirection: "column", justifyContent: "center"
    }}>
        <div style={{ display: "flex", flexDirection: "column", marginRight: 45, marginLeft: 45 }}>
            <StyledBackArrow onClick={() => hideModal()} />
            <StyledCloseIcon onClick={() => hideModal()} />
            <div style={{ marginTop: 15, fontSize: 25, textAlign: "center" }}>
                Select a column to import the values as task names
            </div>
            <label className="wizard-content-label" style={{ fontSize: 17, marginTop: 12 }}>Each row cell in that
                column will translate into a task name.</label>
            <FormControl size="small" fullWidth style={{ marginTop: 2 }}>
                <InputLabel style={{ color: "#223250" }} id="select-column-label">Select Column</InputLabel>
                <Select
                    value={nameColumns[importExcelData?.columnName!]}
                    error={errorTasks}
                    disableUnderline
                    size="small"
                    color="secondary"
                    variant="outlined"
                    labelId="select-column-label"
                    label="Select Column"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    MenuProps={{ style: { height: 250 } }}
                    onChange={(e) => {
                        if (errorTasks) setErrorTasks(false);
                        const index = columns.indexOf(e.target.value as string);
                        setImportExcelData({ ...importExcelData!, columnName: index })
                    }}
                >
                    {nameColumns.map(letter => <MenuItem
                        value={letter}
                        style={{ backgroundColor: "white", }}
                        onMouseEnter={onMouseEventHandler}
                        onMouseLeave={onLeaveMouseEventHandler}>{letter}</MenuItem>)}
                </Select>
            </FormControl>
            {!props.isTemplate && <>
                <span style={{ marginTop: 24, fontSize: 17, letterSpacing: 0.49 }}>
                    Select an additional column to import the values for a custom field associated to the task names
                    that will be imported.
                </span>
                <span style={{ fontSize: 13, letterSpacing: 0.4 }}>This is an optional action.</span>
                <FormControl size="small" fullWidth style={{ marginTop: 18 }}>
                    <InputLabel style={{ color: "#223250" }} id="select-column-label">Select Custom Field</InputLabel>
                    <Select
                        value={importExcelData?.customFieldId}
                        error={errorCustomField}
                        disableUnderline
                        size="small"
                        color="secondary"
                        variant="outlined"
                        labelId="select-column-label"
                        label="Select Custom Field"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={{ style: { height: 250 } }}
                        onChange={(e) => {
                            if (errorCustomField && importExcelData?.columnCustomField !== undefined) setErrorCustomField(false);
                            setImportExcelData({
                                ...importExcelData!, customFieldId:
                                    customFields.find(cf => cf.name === e.target.value)?.id!
                            })
                        }}
                    >
                        {customFields.map(cf => <MenuItem
                            value={cf.name}
                            style={{ backgroundColor: "white", }}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>{cf.name}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl size="small" fullWidth style={{ marginTop: 18 }}>
                    <InputLabel style={{ color: "#223250" }} id="select-column-label">Select Column</InputLabel>
                    <Select
                        value={customFieldColumns[importExcelData?.columnCustomField!]}
                        error={errorCustomField}
                        disableUnderline
                        size="small"
                        color="secondary"
                        variant="outlined"
                        labelId="select-column-label"
                        label="Select Column"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={{ style: { height: 250 } }}
                        onChange={(e) => {
                            if (errorCustomField && importExcelData?.customFieldId !== undefined) setErrorCustomField(false);
                            const index = columns.indexOf(e.target.value as string);
                            setImportExcelData({ ...importExcelData!, columnCustomField: index })
                        }}
                    >
                        {customFieldColumns.map(letter => <MenuItem
                            value={letter}
                            style={{ backgroundColor: "white", }}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>{letter}</MenuItem>)}
                    </Select>
                </FormControl>
            </>}
            <div style={{ marginTop: 20 }}>
                <Checkbox color='secondary'
                    style={{ padding: 0 }}
                    checked={importExcelData?.includeHeader}
                    onChange={() => setImportExcelData({
                        ...importExcelData!,
                        includeHeader: !importExcelData?.includeHeader
                    })}
                />
                <span style={{ fontSize: 14, alignSelf: "center", marginLeft: 10 }}>
                    Include column header (first row of the table)
                </span>
            </div>
            <Button onClick={() => !props.isTemplate ? importExcelForProjects() : importExcelForTemplates()}
                color="secondary" variant="contained" style={{ width: "fit-content", alignSelf: "center", marginTop: 24 }}>
                IMPORT EXCEL FILE
            </Button>
        </div>
    </div>
}
