import Papa from "papaparse";
import readXlsxFile, { Row } from "read-excel-file";

export function useImportExcel() {
    const acceptedExcelExtensions = ".xlsx, .csv"

    function checkExtension(fileName: string, extension: string) {
        return (new RegExp('(' + extension.replace(/\./g, '\\.') + ')$')).test(fileName);
    }

    const importExcel = async (file: File, onImport: (rows: Row[]) => Promise<unknown>, onError = () => {}) => {
        if (checkExtension(file.name, ".csv")) {
            Papa.parse(file, {
                skipEmptyLines: true,
                complete: async (results) => {
                    onImport(results.data as Row[]);
                },
                error(error, file) {
                    console.log(error)
                    onError();
                }
            });
        } else {
            readXlsxFile(file).then(async (rows) => {
                onImport(rows);
            }).catch((e) => {
                console.log(e);
                onError();
            })
        }
    }

    return {
        importExcel,
        acceptedExcelExtensions
    }
}
