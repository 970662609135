import IconButton from "@mui/material/IconButton/IconButton";
import Menu from "@mui/material/Menu/Menu";
import { styled } from "@mui/material/styles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Tooltip } from "@mui/material";
import { useEffect, useRef } from "react";
import { useAppDispatch } from "../hooks/redux-hook";
import { setShowToolbarAfterDeletion } from "../redux/action-bar";

const StyledMoreVertIcon = styled(MoreVertIcon)({
    height: 24,
    width: 24,
    color: '#939598',
    marginRight: 24,
    cursor: 'pointer'
});

export function MoreActionsComponent(p: { id: number | string, menuItems: JSX.Element[], anchorEl: HTMLElement | null,
    setAnchorEl: (el: HTMLElement | null) => void, menuHorizontalPosition: "left" | "center" | "right", width?: number | string,
    icon?: JSX.Element, anchorOriginHorizontal?: "center" | "right", tooltip?: string, open?: boolean, customHeight?: number,
    paperWidth?: number }) {
    const open = Boolean(p.anchorEl);
    const openMenu = (event: React.MouseEvent<HTMLElement>) => p.setAnchorEl(event.currentTarget);
    const maxHeight = 45 * p.menuItems.length;
    const buttonRef = useRef(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!open && p.open && buttonRef.current) {
            (buttonRef.current as HTMLElement).click();
        }
        if (open && !p.open) {
            p.setAnchorEl(null);
        }
    }, [p.open]);

    return <div key={p.id}>
        <Tooltip title={p.tooltip || ""} placement="top">
            <IconButton
                ref={buttonRef}
                // style={{ width: p.width || 24 }}
                disableRipple
                aria-label="more"
                id="long-button"
                aria-controls={open ? `menu-${p.id}` : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(e) => {
                    openMenu(e);
                    dispatch(setShowToolbarAfterDeletion(false));
                }}
            >

                {p.icon ?? <StyledMoreVertIcon />}
            </IconButton>
        </Tooltip>
        <Menu
            id={`menu-${p.id}`}
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            anchorEl={p.anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: p.anchorOriginHorizontal ?? 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: p.menuHorizontalPosition,
            }}
            open={open}
            onClose={() => p.setAnchorEl(null)}
            PaperProps={{
                style: {
                    width: p.paperWidth!,
                    maxHeight: p.customHeight ?? (maxHeight > 240 ? 240 : (maxHeight + (p.menuItems.length === 1 ? 8 : 0))),
                    marginTop: p.icon ? 8 : 0
                },
                elevation: 1
            }}
        >
            {p.menuItems}
        </Menu>
    </div>
}
