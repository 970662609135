import React from 'react';
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';

type Props = {isDone?: boolean,requiredComp?: React.ReactNode}
const ModalIconContent:React.FC<Props> = ({isDone,requiredComp}) => {
    return (
        <div className={'icon'} style={{borderColor: isDone ? '#005da9' : '#e86122'}}>
            {requiredComp && requiredComp}
            {requiredComp === undefined && (isDone ? <DoneOutlineOutlinedIcon style={{fontSize:"50px",color:"#005da9"}}/> :<DeleteOutlineSharpIcon  style={{fontSize:"50px",color:"#e86122"}}/>)}
        </div>
    )
}
export default ModalIconContent;
