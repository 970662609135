import MenuItem from "@mui/material/MenuItem";
import { statusList, StatusType } from "../constants";
import { MoreActionsComponent } from "./more-actions-component";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import { useAppDispatch } from "../hooks/redux-hook";
import { setShowToolbarAfterDeletion } from "../redux/action-bar";

export function StatusMenuComponent(p: {
    status: StatusType, onUpdate: (status: keyof typeof statusList) => void, projectStatus: boolean, small?: boolean,
    icon?: JSX.Element, customAnchorEl?: HTMLElement | null, setCustomAnchorEl?: (al: HTMLElement | null) => void
}) {
    const dispatch = useAppDispatch();
    const [anchorElProjectStatus, setAnchorElProjectStatus] = useState<HTMLElement | null>(null);
    const projectStatusMenuItems = Object.keys(statusList).map(status =>
        <StatusMenuItem
            key={status}
            onClick={() => {
                dispatch(setShowToolbarAfterDeletion(false));
                p.onUpdate(status as StatusType);
                if (p.setCustomAnchorEl) p.setCustomAnchorEl(null)
                else setAnchorElProjectStatus(null);
            }}
            fullColor={!p.projectStatus}
            color={p.projectStatus ? statusList[status as StatusType].leftColor : statusList[status as StatusType].color}
            name={statusList[status as StatusType].name}
        />
    )

    return !p.icon ? <div className={p.projectStatus ? "task-details-status" : "task-list-task-status"}
        style={{
            backgroundColor: statusList[p.status].color,
            marginLeft: p.projectStatus ? 24 : 0, height: p.small ? 24 : 30
        }}>
        <MoreActionsComponent id={0} anchorEl={anchorElProjectStatus} setAnchorEl={setAnchorElProjectStatus}
            menuHorizontalPosition="center"
            menuItems={projectStatusMenuItems}
            anchorOriginHorizontal="center"
            width={p.projectStatus ? 154 : 0}
            icon={<div style={{ display: "flex", alignItems: "center", flexGrow: 1, justifyContent: "space-between" }}>
                <span style={{
                    letterSpacing: 0.1, color: "white",
                    fontSize: p.projectStatus ? 15 : 11, fontFamily: "Titillium Web",
                    whiteSpace: "nowrap", fontWeight: 600
                }}>
                    {statusList[p.status].name}
                </span>
                {p.projectStatus && <ArrowDropDownIcon style={{ color: "white", marginLeft: 4 }} />}
            </div>
            }
        />
    </div> :
        // mandatory custom anchor elements for custom icons
        <MoreActionsComponent id={0} anchorEl={p.customAnchorEl!} setAnchorEl={p.setCustomAnchorEl!}
            menuHorizontalPosition="center"
            menuItems={projectStatusMenuItems}
            anchorOriginHorizontal="center"
            width={p.projectStatus && !p.icon ? 154 : 0}
            icon={p.icon}
        />
}

export function StatusMenuItem(p: { onClick: () => void, color: string, name: string, fullColor?: boolean }) {

    return !p.fullColor ?
        <MenuItem
            sx={{ m: 0, p: 0 }}
            style={{ width: 158 }}
            onClick={p.onClick}>
            <div style={{ display: "flex" }}>
                <div style={{ width: 10, height: 36, backgroundColor: p.color }}></div>
                <span style={{ alignSelf: "center", marginLeft: 16, fontWeight: 600, fontSize: 15 }}>{p.name}</span>
            </div>
        </MenuItem >
        :
        <MenuItem sx={{ m: 0, p: 0 }}
            style={{ width: 86, textAlign: "center", fontSize: 11, color: "#ffffff", backgroundColor: p.color, padding: 4 }}
            onClick={p.onClick}>
            {p.name}
        </MenuItem >
}
