import React, {useEffect, useState, useContext} from 'react';
import '../Panel.scss'
import WebViewerContext from "../../../contexts/webviewer-context";
import RedactionSearchMultiSelect from "./RedactionSearchMultiSelect";
import PageRangeInput from "../../PageRangeInput";
import SearchStatus from "./SearchStatus";
import RedactionSearchResultsContainer from "./RedactionSearchResultsContainer";
import {useCustomModal} from "../../../pages/modals/custom-message-modal";
import ChangeMarginsModal from "../ChangeMarginsModal";

export const SearchPanel = (props: {redactionSearchPatterns: {label: string, type: string, regex: RegExp}[]}) => {
    const { setInstance,instance } = useContext(WebViewerContext);
    const { showModal, hideModal } = useCustomModal();
    const [pageRange, setPageRange] = useState<(number|undefined)[]|undefined>(undefined);
    const [redactionSearchResults, setRedactionSearchResults] = useState<any[]>([]);
    const [isProcessingRedactionResults, setIsProcessingRedactionResults] = useState<boolean>(false);
    const [searchStatus, setSearchStatus] = useState<string>(SearchStatus.SEARCH_NOT_INITIATED);
    const [searchTerms, setSearchTerms] = useState<{ label: any; value: any; type: string; }[]>();

    function pageRangeOnClear() {
        setPageRange(undefined)
    }

    const deleteRedactionSearchResults = (results: any[], resultsToDelete: any[], selectNextSearchResult: boolean) => {
        //This shouldn't happen, but if it does we want to abort or it will delete all the search results.
        if (results.length === 0) {
            return;
        }
        //If this is deleting the active search result, save the index of that for later.
        let index = -1
        if (resultsToDelete.length === 1 && resultsToDelete[0] === instance.Core.documentViewer.getActiveSearchResult()) {
            index = results.indexOf(resultsToDelete[0])
        }

        const remainingSearchResults = results.filter(item => !resultsToDelete.includes(item))
        instance.Core.documentViewer.clearSearchResults()
        instance.Core.documentViewer.displayAdditionalSearchResults(remainingSearchResults)
        setRedactionSearchResults(remainingSearchResults)
        //If we deleted the active search result, then navigate to the next one if that parameter is true.
        //That should be true when you delete a search result, but not when you add it as a mark. In that
        //case the viewer should stay where it is so that you can see the mark you just made.
        if (index !== -1 && selectNextSearchResult) {
            if (index < remainingSearchResults.length) {
                instance.Core.documentViewer.setActiveSearchResult(remainingSearchResults[index])
            } else if (index > 0) {
                instance.Core.documentViewer.setActiveSearchResult(remainingSearchResults[index - 1])
            }
        }

        setSearchStatus(SearchStatus['SEARCH_DONE']);
        setTimeout(() => {
            setIsProcessingRedactionResults(false);
        }, 100);
    };

    const onCancelSearch = (clearAll: boolean) => {
        if (clearAll) {
            setSearchTerms([]);
            setRedactionSearchResults([]);
            instance.Core.documentViewer.clearSearchResults();
            setIsProcessingRedactionResults(false);
        }
    };

    useEffect(() => {
        const onSearchResultsChanged = (newSearchResults: any[]) => {
            setRedactionSearchResults(newSearchResults)
        }

        if (instance) {
            const documentViewer = instance.Core.documentViewer;
            documentViewer.addEventListener('searchResultsChanged', onSearchResultsChanged)
        }
        return () => {
            if (instance) {
                instance.Core.documentViewer.removeEventListener('searchResultsChanged', onSearchResultsChanged)
            }
        }
    }, [instance]);

    return <div className={'panel'} style={{height: '1000px'}}>
        <RedactionSearchMultiSelect searchTerms={searchTerms} setSearchTerms={setSearchTerms} redactionSearchPatterns={props.redactionSearchPatterns} pageRange={pageRange} setSearchStatus={setSearchStatus}/>
        <div className="container">
            <button
                aria-label='add mark'
                className='margin-button'
                onClick={() => {
                    showModal(ChangeMarginsModal, {})
                }}
            >
                Change Margins
            </button>
            <p>Search By Page Range:</p>
            <PageRangeInput pageRangeChangeCallBack={(newPageRange) => setPageRange(newPageRange)} clearPageRangeCallBack={pageRangeOnClear} placeholder={'enter a page range'}/>
        </div>

        <RedactionSearchResultsContainer
            redactionSearchResults={redactionSearchResults}
            deleteSelectedResults={deleteRedactionSearchResults}
            onCancelSearch={onCancelSearch}
            searchStatus={searchStatus}
            isProcessingRedactionResults={isProcessingRedactionResults}
        />
    </div>;
};
