import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import { ContentCopy } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeletionSnackBar } from "../deletion-snackbar";
import React, { useEffect, useRef, useState } from "react";
import { Task } from "../../models/Task";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import { deleteApiTasksBulkDelete, postApiTasksAssign, postApiTasksBulkCreate, postApiTasksBulkCreateCfv, postApiTasksBulkUndoDelete, postApiTasksBulkUpdate } from "../../services/task";
import { showSnackbar } from "../../redux/snackbar";
import { TaskList } from "../../models/TaskList";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import {
    actionBarIsTemplateSelector, actionBarIsVisibleSelector,
    actionBarProjectIdSelector, actionBarProjectNameSelector, actionBarTasksListsSelector,
    selectedSubtasksSelector,
    selectedTasksSelector,
    selectSubtasks,
    selectTasks,
    showToolbarAfterDeletionSelector,
    setShowToolbarAfterDeletion
} from "../../redux/action-bar";
import { getApiProjectsById, putApiProjectsProjectCustomFieldsById } from "../../services/project";
import { getApiTemplatesById } from "../../services/template";
import { loadTemplate } from "../../redux/projectTemplates";
import { loadProject, projectSelector } from "../../redux/projects";
import { deletedTasksSelector, resetDeletedTasks } from "../../redux/deleted-tasks";
import { TaskListSearch } from "./task-list-search";
import { useLocation } from "react-router-dom";
import { InfoTenantUser } from "../../models/InfoTenantUser";
import { UsersApply } from "../users-apply";
import AddUserBlack from "../../provisional_icons/assign-user-black.svg";
import AddUserWhite from "../../provisional_icons/assign-user-white.svg";
import AddUserDisabled from "../../provisional_icons/assign-user-disabled.svg";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { StatusMenuComponent } from "../status-menu-component";
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { CustomField } from "../../models/CustomField";
import { customFieldsSelector, loadCustomFieldsValues } from "../../redux/custom-fields";
import MenuItem from "@mui/material/MenuItem";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { MoreActionsComponent } from "../more-actions-component";
import moment from "moment";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { CustomFieldKeyTypes } from "./task";
import { TaskCustomFieldValues } from "../../models/TaskCustomFieldValues";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { getApiCustomFieldsValuesTaskListById } from "../../services/custom.fields";
import { statusList, StatusType } from "../../constants";
import { StaticCustomDatePicker } from "../static-custom-date-picker";
import { projectTemplateSelector } from "../../redux/projectTemplates";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

export function ActionBar() {

    const theme = useTheme();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const projectId = useAppSelector((state) => actionBarProjectIdSelector(state));
    const projectName = useAppSelector((state) => actionBarProjectNameSelector(state));
    const isTemplate = useAppSelector((state) => actionBarIsTemplateSelector(state));
    const selectedTasks: Task[] = useAppSelector((state) => selectedTasksSelector(state));
    const selectedSubtasks: Task[] = useAppSelector((state) => selectedSubtasksSelector(state));
    const deletedTasksIds = useAppSelector((state) => deletedTasksSelector(state));
    const isVisible = useAppSelector((state) => actionBarIsVisibleSelector(state));
    const customFields: CustomField[] = useAppSelector((state) => customFieldsSelector(state));
    const projectTaskLists = useAppSelector((state) => projectSelector(state, projectId)).taskLists
    const templateTaskLists = useAppSelector((state) => projectTemplateSelector(state, projectId))?.taskLists;
    const showToolbarAfterDeletion = useAppSelector((state) => showToolbarAfterDeletionSelector(state));
    const taskLists = !isTemplate ? projectTaskLists : templateTaskLists;

    const [displayDuplicateTasks, setDisplayDuplicateTasks] = useState(false);

    const [deletedTasks, setDeletedTasks] = useState<Task[]>();
    const [selectedUsers, setSelectedUsers] = useState<InfoTenantUser[]>([]);

    const [anchorElDuplicate, setAnchorElDuplicate] = useState<null | HTMLElement>(null);
    const [anchorElAssign, setAnchorElAssign] = useState<null | HTMLElement>(null);
    const [anchorElStatus, setAnchorStatus] = useState<null | HTMLElement>(null);
    const [anchorElCustomFields, setAnchorElCustomFields] = useState<null | HTMLElement>(null);

    const [hoverStartDate, setHoverStartDate] = useState(false);
    const [hoverDueDate, setHoverDueDate] = useState(false);
    const [statusHover, setStatusHover] = useState(false);
    const [customFieldsHover, setCustomFieldsHover] = useState(false);
    const [assignHover, setAssignHover] = useState(false);

    const [isStartDateOpen, setIsStartDateOpen] = useState(false);
    const [isDueDateOpen, setIsDueDateOpen] = useState(false);
    const [customFieldOpen, setCustomFieldOpen] = useState<CustomField | null>();

    const [startDateValue, setStartDateValue] = useState<Date | null>(null);
    const [dueDateValue, setDueDateValue] = useState<Date | null>(null);
    const project = useAppSelector((state) => projectSelector(state, projectId));

    const bothTaskTypesSelected = (selectedTasks.length && selectedSubtasks.length);

    useEffect(() => {
        if (showToolbarAfterDeletion) {
            dispatch(setShowToolbarAfterDeletion(false));
        }
    }, [location.pathname]);

    const fetchProject = () => {
        isTemplate ?
            getApiTemplatesById(projectId)
                .then(template => dispatch(loadTemplate(template)))
                .then(() => {
                    dispatch(selectSubtasks([]));
                    dispatch(selectTasks([]));
                })
            :
            getApiProjectsById(projectId).then(project => dispatch(loadProject(project)));
    }

    const closeSelectedOnClick = () => {
        dispatch(setShowToolbarAfterDeletion(false));
        dispatch(selectTasks([]));
        dispatch(selectSubtasks([]));
    }

    const copySelectedOnClick = async () => {
        setDisplayDuplicateTasks(!displayDuplicateTasks);
    }

    const deleteSelectedOnClick = () => {
        const ids = selectedTasks.map(task => task.id).concat(selectedSubtasks.map(subtask => subtask.id));
        deleteApiTasksBulkDelete(ids).then(() => {
            setDeletedTasks(selectedTasks.concat(selectedSubtasks));
            dispatch(selectTasks([]));
            dispatch(selectSubtasks([]));
            fetchProject();
        });
        dispatch(setShowToolbarAfterDeletion(true));
    }

    const onClickDismissDeletion = async () => {
        dispatch(setShowToolbarAfterDeletion(false));
        dispatch(resetDeletedTasks());
    }

    const onClickUndoDeletion = async () => {
        const ids = deletedTasks?.map(task => task.id) as Array<number>;
        await postApiTasksBulkUndoDelete((deletedTasksIds.length > 0) ? deletedTasksIds : ids).then(() => fetchProject())
        dispatch(setShowToolbarAfterDeletion(false));
        dispatch(resetDeletedTasks());
    }

    const duplicateItems = async (taskList: TaskList) => {
        const tasksAndSubtasks: Task[] = selectedTasks.concat(selectedSubtasks)
            .map(task => ({ ...task, id: -1, taskListId: taskList.id }))
        await postApiTasksBulkCreate(tasksAndSubtasks)
            .then(() => fetchProject())
            .then(() => dispatch(showSnackbar({
                message: tasksAndSubtasks.length > 1 ? `${tasksAndSubtasks.length} tasks has been successfully duplicated in ${taskList.name}.` :
                    `Task has been successfully duplicated in ${taskList.name}.`,
                type: "info"
            })))
            .catch(() => dispatch(showSnackbar({
                message: "Error duplicating tasks!",
                type: "error"
            })));
        setAnchorElDuplicate(null);
    }

    const editBulk = <T extends Task, K extends keyof T>(taskKey: K, value: T[K], snackbarMessage: string | JSX.Element) => {
        const editedTasks = selectedTasks.map((tas) => {
            return { ...tas, [taskKey]: value }
        })
        const editedSubtasks = selectedSubtasks.map((tas) => {
            return { ...tas, [taskKey]: value }
        })
        postApiTasksBulkUpdate(editedTasks.concat(editedSubtasks)).then(() => {
            fetchProject();
            dispatch(selectTasks(editedTasks));
            dispatch(selectSubtasks(editedSubtasks));
        }).then(() => dispatch(showSnackbar({
            message: snackbarMessage,
            type: "info"
        })))
            .catch(() => dispatch(showSnackbar({
                message: `Error setting ${taskKey.toString().toLocaleLowerCase()}!`,
                type: "error"
            })));
    }

    const onUsersApply = (selectedUsers: InfoTenantUser[], setSelectedUsers: (selectedUsers: InfoTenantUser[]) => void) => {
        const tasksAndSubtasks: Task[] = selectedTasks.concat(selectedSubtasks);
        const newUserIds = selectedUsers.map(su => su.id);
        const unassign = selectedUsers.find(su => su.id === 0 && su.name === "Unassign");
        const promises: any[] = [];
        const st = [...selectedTasks];
        const sbt = [...selectedSubtasks];
        if (newUserIds.length) {
            dispatch(showProgressLine());
            tasksAndSubtasks.forEach(task => {
                const userIds = task.assignedUserId?.split(",").map(aui => parseInt(aui)).concat(newUserIds);
                const assignedUsers = !unassign ? (userIds?.length! > 1 ? userIds?.join(",")! : newUserIds?.toString()!) : null;
                const idxSt = selectedTasks.map(st => st.id).indexOf(task.id);
                const idxSbt = selectedSubtasks.map(sb => sb.id).indexOf(task.id);
                if (idxSt !== -1) {
                    st.splice(idxSt, 1, { ...task, assignedUserId: assignedUsers })
                } else if (idxSbt !== -1) {
                    sbt.splice(idxSbt, 1, { ...task, assignedUserId: assignedUsers })
                }
                promises.push(postApiTasksAssign({
                    userIds: assignedUsers!,
                    taskId: task.id
                }))
            })
            Promise.all(promises).then(() => {
                fetchProject();
                dispatch(selectTasks(st));
                dispatch(selectSubtasks(sbt));
                setSelectedUsers([]);
            }).then(() => dispatch(hideProgressLine())).then(() => dispatch(showSnackbar({
                message: !unassign ? `Assigned to ${newUserIds.length} user${newUserIds.length > 1 ? "s" : ""}.` : `Removed all assignees.`,
                type: "info"
            })))
                .catch(() => dispatch(showSnackbar({
                    message: "Error assigning users!",
                    type: "error"
                })));
        }
    }

    const bulkCustomFields = customFields.filter(cf => cf.shortName === "nrPages" || cf.shortName === "batch" ||
        cf.shortName === "typeOfReport");

    const customFieldsItems = bulkCustomFields.map(cf => <MenuItem
        key={cf.name}
        onClick={async () => {
            setCustomFieldOpen(cf);
        }}>
        {cf.name}
    </MenuItem>);

    function CustomFieldModal(props: { cf: CustomField }) {
        const [value, setValue] = useState("");
        const ref = useRef<HTMLInputElement | null>();

        useEffect(() => ref.current?.focus(), []);

        const saveOnClick = async () => {
            const tasksWithCustomField: TaskCustomFieldValues[] = [];
            dispatch(showProgressLine());
            selectedTasks.concat(selectedSubtasks).forEach(task => {
                tasksWithCustomField.push({
                    customFieldValues: [{ id: -1, customFieldId: props.cf.id, taskId: task.id, valueString: value }], task: task
                })
            });
            setAnchorElCustomFields(null);
            setTimeout(() => {
                setCustomFieldOpen(null);
            }, 500)
            const projectCustomFields = project.projectCustomFields || {}
            if (!projectCustomFields[props.cf.shortName as CustomFieldKeyTypes]) {
                const updateProjectCustomFields = {
                    ...projectCustomFields,
                    [props.cf.shortName! as CustomFieldKeyTypes]: true
                }
                await putApiProjectsProjectCustomFieldsById(project.id, updateProjectCustomFields)
                    .then(() => {
                        dispatch(loadProject({ ...project, projectCustomFields: updateProjectCustomFields }));
                    })
            }
            postApiTasksBulkCreateCfv(tasksWithCustomField).then(() => fetchProject()).then(() => {
                taskLists?.forEach((tl) => {
                    getApiCustomFieldsValuesTaskListById(tl.id)
                        .then(cfv => dispatch(loadCustomFieldsValues({
                            taskListId: tl.id,
                            customFieldsValues: cfv
                        })))
                })
            }).then(() => dispatch(hideProgressLine())).then(() => dispatch(showSnackbar({
                message: `Set ${props.cf.name} to ${value}.`,
                type: "info"
            })))
                .catch(() => dispatch(showSnackbar({
                    message: `Error setting ${props.cf.name}!`,
                    type: "error"
                })));
        }

        return <div key={props.cf.id} style={{ width: 223, margin: "6px 16px" }}>
            <span style={{ fontSize: 11, letterSpacing: 1.5, textTransform: "uppercase" }}>{props.cf.name}</span>
            <TextField placeholder="Enter a text" size="small" style={{ marginTop: 12, width: "100%" }}
                value={value} onChange={(e) => setValue(e.target.value)} inputRef={ref}
            />
            <div className="change-name-buttons-wrapper" style={{ marginTop: 16 }}>
                <Button color="secondary" variant="outlined" onClick={() => {
                    setAnchorElCustomFields(null);
                    setTimeout(() => {
                        setCustomFieldOpen(null);
                    }, 500)
                }} style={{ width: 89, height: 39 }}>
                    CANCEL
                </Button>
                <Button color="secondary" variant="contained" onClick={saveOnClick} style={{ width: 102, height: 39 }}>
                    SAVE
                </Button>
            </div>
        </div>
    }

    return isVisible ?
        <AppBar position="sticky"
            style={{
                marginTop: 1, marginLeft: 32, width: "calc(100vw - 256px - 64px)", height: 60,
                backgroundColor: theme.palette.veryDarkGrayishViolet.main
            }}>
            <Toolbar style={{ minHeight: 56 }}>
                <ContextualActionBarIcon alt="" areBothTaskTypesSelected={false} icon={<CloseIcon fontSize="small" />}
                    onClick={closeSelectedOnClick} />
                <span style={!isTemplate ? { flexGrow: 0.385, marginLeft: 8 } : { flexGrow: 0.45, fontWeight: "600" }}>
                    {selectedTasks.length + selectedSubtasks.length} selected {projectName && ` in project ${projectName}`}
                </span>
                <div style={{
                    display: "flex", alignItems: "center", flexGrow: !isTemplate ? 0.2 : 0.02,
                    justifyContent: "space-around"
                }}>
                    {!isTemplate && <>
                        <UsersApply setSelectedArray={setSelectedUsers} onApply={onUsersApply}
                            type="ASSIGN" selectedArray={selectedUsers} anchorEl={anchorElAssign} setAnchorEl={setAnchorElAssign}
                            icon={
                                <div onMouseEnter={() => setAssignHover(true)} onMouseLeave={() => setAssignHover(false)}>
                                    <ContextualActionBarIcon isFocused={!!anchorElAssign} alt="Assign" areBothTaskTypesSelected={false}
                                        icon={
                                            <img alt="add user" style={{ width: 22, height: 22 }}
                                                src={!!anchorElAssign ? AddUserBlack : !assignHover ? AddUserWhite : AddUserDisabled}
                                            />}
                                        onClick={(e) => {
                                            setAnchorElAssign(e.currentTarget);
                                            setAssignHover(false);
                                        }} />
                                </div>
                            }
                        />
                        <StaticCustomDatePicker value={startDateValue} setValue={setStartDateValue}
                            setCustomOpen={setIsStartDateOpen} onApply={() => editBulk("startDate",
                                moment(startDateValue).format("YYYY-MM-DD 00:00:00"),
                                `Set start date to ${moment(startDateValue).format("MMM Do YYYY")} for 
                            ${selectedTasks.concat(selectedSubtasks).length} tasks.`)} icon={
                                <div onMouseEnter={() => setHoverStartDate(true)} onMouseLeave={() => setHoverStartDate(false)}>
                                    <ContextualActionBarIcon areBothTaskTypesSelected={false} isFocused={isStartDateOpen}
                                        onClick={(e) => { }}
                                        icon={
                                            <CalendarTodayIcon
                                                style={{
                                                    color: isStartDateOpen ? "black" : !hoverStartDate ? "white" : "#939598",
                                                    width: 18, height: 20
                                                }} />}
                                        alt="Set Start Date" />
                                </div>}
                        />
                        <StaticCustomDatePicker value={dueDateValue} setValue={setDueDateValue}
                            setCustomOpen={setIsDueDateOpen} onApply={() => editBulk("dueDate",
                                moment(dueDateValue).format("YYYY-MM-DD 00:00:00"),
                                `Set Due date to ${moment(dueDateValue).format("MMM Do YYYY")} for 
                            ${selectedTasks.concat(selectedSubtasks).length} tasks.`)} icon={
                                <div onMouseEnter={() => setHoverDueDate(true)} onMouseLeave={() => setHoverDueDate(false)}>
                                    <ContextualActionBarIcon areBothTaskTypesSelected={false} isFocused={isDueDateOpen}
                                        onClick={(e) => { }}
                                        icon={
                                            <EventAvailableIcon
                                                style={{
                                                    color: isDueDateOpen ? "black" : !hoverDueDate ? "white" : "#939598",
                                                    width: 18, height: 20
                                                }} />}
                                        alt="Set Due Date" />
                                </div>}
                        />
                        <StatusMenuComponent status="NOT_STARTED" projectStatus customAnchorEl={anchorElStatus}
                            setCustomAnchorEl={setAnchorStatus} icon={
                                <ContextualActionBarIcon areBothTaskTypesSelected={false} isFocused={!!anchorElStatus}
                                    onClick={(e) => { }}
                                    icon={
                                        <div style={{ display: "flex", alignItems: "center" }} onMouseEnter={() => setStatusHover(true)}
                                            onMouseLeave={() => setStatusHover(false)}>
                                            <TimelapseIcon
                                                style={{
                                                    color: !!anchorElStatus ? "black" : !statusHover ? "white" : "#939598", width: 20,
                                                    height: 20
                                                }}
                                            />
                                        </div>}
                                    alt="Set Status"
                                />
                            }
                            onUpdate={(status) => editBulk("status", status, <div style={{ display: "flex", alignItems: "center" }}>
                                Set status to <div style={{
                                    display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 6,
                                    letterSpacing: 0.1, color: "white", fontSize: 11, fontWeight: 600,
                                    backgroundColor: statusList[status as StatusType].color,
                                    width: 86, height: 30, whiteSpace: "nowrap"
                                }}>
                                    {statusList[status as StatusType].name}
                                </div>
                            </div>)}
                        />
                        <MoreActionsComponent
                            id={`action-bar`}
                            menuItems={!customFieldOpen ? customFieldsItems : [<CustomFieldModal cf={customFieldOpen!} />]}
                            anchorEl={anchorElCustomFields}
                            setAnchorEl={setAnchorElCustomFields}
                            menuHorizontalPosition="center"
                            customHeight={161}
                            icon={
                                <ContextualActionBarIcon areBothTaskTypesSelected={false} isFocused={!!anchorElCustomFields}
                                    onClick={(e) => { }}
                                    icon={
                                        <div style={{ display: "flex", alignItems: "center" }} onMouseEnter={() => setCustomFieldsHover(true)}
                                            onMouseLeave={() => setCustomFieldsHover(false)}>
                                            <AddCircleOutlineIcon
                                                style={{
                                                    color: !!anchorElCustomFields ? "black" : !customFieldsHover ? "white" : "#939598",
                                                    width: 20, height: 20
                                                }} />
                                        </div>}
                                    alt="Add custom field" />
                            }
                        />
                    </>}
                    <TaskListSearch anchorEl={anchorElDuplicate} setAnchorEl={setAnchorElDuplicate} type="LIST" taskListData={taskLists!}
                        projectName={projectName!} bothTaskTypesSelected={!!bothTaskTypesSelected}
                        dependencyArray={[selectedTasks, selectedSubtasks, taskLists]}
                        menuItemOnClick={duplicateItems as (taskList: TaskList | Task) => void}
                        icon={
                            <ContextualActionBarIcon
                                alt={!bothTaskTypesSelected ? "Duplicate tasks" : "You cannot copy both subtasks and tasks in the same time"}
                                isFocused={!!anchorElDuplicate}
                                areBothTaskTypesSelected={!!bothTaskTypesSelected}
                                icon={<ContentCopy fontSize="small" />}
                                onClick={!bothTaskTypesSelected ? () => copySelectedOnClick() : () => { }} />}
                    />
                    {isTemplate && <div style={{ width: 24 }} />}
                    <ContextualActionBarIcon areBothTaskTypesSelected={false} alt="Delete"
                        icon={<DeleteIcon fontSize="small" />} onClick={deleteSelectedOnClick} />
                </div>
            </Toolbar>
        </AppBar>
        :
        ((showToolbarAfterDeletion || deletedTasksIds.length > 0) && location.pathname.startsWith("/app/user/workflow/projects")) ?
         <DeletionSnackBar type={selectSubtasks.length > 0 ? 'Subtask' : 'Task'} onClickDismissDeletion={onClickDismissDeletion}
            onClickUndoDeletion={onClickUndoDeletion} deletedTasksCount={deletedTasks?.length} />
            : <></>
}

function ContextualActionBarIcon(p: {
    icon: JSX.Element, onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void, alt: string,
    areBothTaskTypesSelected: boolean, isFocused?: boolean, setAnchorEl?: (anchorEl: HTMLElement | null) => void
}) {
    return <Tooltip title={p.alt}>
        <IconButton
            classes={{ root: !p.isFocused ? 'hover-state-template-icon' : 'focused-state-template-icon' }}
            edge="start"
            style={{ color: "lightgray", cursor: !p.areBothTaskTypesSelected ? "pointer" : "default" }}
            sx={{}}
            onClick={p.onClick}
        >
            {p.icon}
        </IconButton>
    </Tooltip>
}
