import { Button, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEventHandler } from "../hooks/handle-events";
import { useAppSelector } from "../hooks/redux-hook";
import { Project } from "../models/Project";
import { useCustomModal } from "../pages/modals/custom-message-modal";
import { ArchiveProjectModal } from "../pages/modals/modal-content/archive-project-modal";
import { RemoveProjectModal } from "../pages/modals/modal-content/remove-project-modal";
import { loadProject } from "../redux/projects";
import { showSnackbar } from "../redux/snackbar";
import { getApiProjectsById, putApiProjectIsArchived } from "../services/project";

export function RestoreProjectComponent(p: {selectedArchivedProjectIds: number[], isMenuItem?: boolean}) {
    const dispatch = useDispatch();
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();

    const fetchProject = (id: number) => {
        getApiProjectsById(id)
            .then(project => dispatch(loadProject(project)))
    }

    const handleUndoProjectsArchived = () => {
        const controller = new AbortController();
        p.selectedArchivedProjectIds.forEach((projectId: number) => {
            putApiProjectIsArchived(projectId, {isArchived: false}, controller.signal)
                .then(() => {
                    fetchProject(projectId);
                })
                .catch(() => {
                    dispatch(showSnackbar({ message: "Error undoing project archive!", type: "error" }));
                }
            );
        });
        dispatch(showSnackbar({ message: p.selectedArchivedProjectIds.length > 1 ? "Projects restored!" : "Project restored!", type: "info" }));
    }
    
    if (p.isMenuItem) {
        return <MenuItem
        key={4}
        onMouseEnter={onMouseEventHandler}
        onMouseLeave={onLeaveMouseEventHandler}
        onClick={() => {
            handleUndoProjectsArchived();
        }}>
        Restore project
    </MenuItem>
    } 

    return <Button style={{width: 134, marginTop: 2, marginBottom: 2 }}
            variant="contained" color="secondary" 
            onClick={handleUndoProjectsArchived}
            disabled={p.selectedArchivedProjectIds.length === 0}>
                RESTORE
            </Button>
    
}

export function RemoveProjectComponent(p: {selectedArchivedProjectIds: number[], isMenuItem?: boolean})  {
    const {showModal} = useCustomModal();
    const projects = useAppSelector((state) => state.projects.projects);
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();

    const handleRemoveSelectedProjects = () => {
        const projectNames = p.selectedArchivedProjectIds.map((projectId: number) => {
            const project = projects.find((project: Project) => project.id === projectId);
            return project?.name;
        }).join(", ");

        showModal(() => RemoveProjectModal(projectNames, p.selectedArchivedProjectIds), {});
    }

    if (p.isMenuItem) {
        return <MenuItem
        key={4}
        onMouseEnter={onMouseEventHandler}
        onMouseLeave={onLeaveMouseEventHandler}
        onClick={() => {
            handleRemoveSelectedProjects();
        }}>
        Remove project
    </MenuItem>
    }

    return <Button style={{width: 134, marginTop: 2, marginBottom: 2, marginLeft: 5}}
                variant="contained" color="secondary" 
                onClick={handleRemoveSelectedProjects}
                disabled={p.selectedArchivedProjectIds.length === 0}>
                    REMOVE
            </Button>
}

export function ArchiveProjectComponent(p: {selectedProjectIds: number[], isMenuItem?: boolean})  {
    const {showModal} = useCustomModal();
    const projects = useAppSelector((state) => state.projects.projects);
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();

    const handleArchiveSelectedProjects = () => {
        const projectNames = p.selectedProjectIds.map((projectId: number) => {
            const project = projects.find((project: Project) => project.id === projectId);
            return project?.name;
        }).join(", ");

        showModal(() => ArchiveProjectModal(projectNames, p.selectedProjectIds), {});
    }

    if (p.isMenuItem) {
        return <MenuItem
            key={4}
            onMouseEnter={onMouseEventHandler}
            onMouseLeave={onLeaveMouseEventHandler}
            onClick={() => {
                handleArchiveSelectedProjects();
            }}>
            Archive project
        </MenuItem>
    }

    return  <Button style={{width: 134, marginTop: 2, marginBottom: 2, marginLeft: 5}}
                variant="contained" color="secondary" 
                onClick={handleArchiveSelectedProjects}
                disabled={p.selectedProjectIds.length === 0}>
                    ARCHIVE
            </Button>
}

