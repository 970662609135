import {Button, styled} from "@mui/material";
import {Pattern} from "../../../models/Pattern";
import {useCustomModal} from "../../modals/custom-message-modal";
import {useAppDispatch} from "../../../hooks/redux-hook";
import {useState} from "react";
import {deleteApiPatternSet} from "../../../services/patternSets";
import {removePatternSet} from "../../../redux/patternSets";
import {showSnackbar} from "../../../redux/snackbar";
import {StyledCloseIcon} from "../../../components/close-button";
import {ButtonSpinner} from "../../../components/button-spinner";
import {useAuthService} from "../../../contexts/auth-context";

const StyledCancelButton = styled(Button)({
    color: '#217DA2',
    borderColor: '#217DA2'
});

export function PatternSetDelete(props : { id: number, version: number, name: string }) {
    const { hideModal } = useCustomModal();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const auth = useAuthService();
    const deletePatternSet = () => {
        const controller = new AbortController();
        setLoading(true);
        deleteApiPatternSet(props.id, controller.signal)
            .then(() => {
                dispatch(removePatternSet({id: props.id}));
                hideModal();
            })
            .then(() => dispatch(showSnackbar({
                message: `Pattern Set ${props.name} V${props.version} has been removed from the library.`,
                type: "info"
            })))
            .catch(() => {
                setLoading(false);
                dispatch(showSnackbar({
                    message: "Error deleting pattern set!",
                    type: "error"
                }));
            });
    }

    return <div className="wizard-dialog" style={{ padding: 32, display: 'flex', flexDirection: 'column' }}>
        <StyledCloseIcon onClick={() => loading ? {} : hideModal()} />
        <span style={{ color: '#C13826', fontSize: 24, fontWeight: 600, marginBottom: 16 }}>
            Delete {props.name} V{props.version}?
        </span>
        <span style={{ marginBottom: 24 }}>Are you sure you want to permanently delete this version of this pattern set?</span>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledCancelButton disabled={loading} variant="outlined" onClick={hideModal}>CANCEL</StyledCancelButton>
            <Button disabled={loading} variant="contained" color="secondary" onClick={deletePatternSet}>
                DELETE {loading && <ButtonSpinner />}
            </Button>
        </div>
    </div>
}
