import {useState} from "react";
import {
    anyCharType,
    anyLength,
    ellipses, endsWith,
    exactly,
    exactPhrase,
    followedBy, includeInMatch,
    numbersOrLetters,
    punctuation, startsWith
} from "./constants";

export class Selections {
    [key: string]: any; // Index signature that allows accessing properties using a string key

    numColumns = 0
    exactMatchSelections: string[] = []
    quantifierSelections: string[] = []
    startsWithSelections: string[] = []
    followedBySelections: string[] = []
    endsWithSelections: string[] = []
    optionalSelections: boolean[] = []
    beforeAndAfterSelections: string[] = []
    enabledStatuses: boolean[] = []

    constructor(columns: string[], puncColumns: string[]) {
        this.numColumns = columns.length
        this.exactMatchSelections = this.getExactMatchDefaultSelections(columns, puncColumns);
        this.quantifierSelections = Array.from({ length: columns.length }, () => anyLength);
        this.startsWithSelections = Array.from({ length: columns.length }, () => `${startsWith}${ellipses}`);
        this.followedBySelections = Array.from({ length: columns.length }, () => `${followedBy} ${numbersOrLetters}`);
        this.endsWithSelections = Array.from({ length: columns.length }, () => `${endsWith}${ellipses}`);
        this.optionalSelections = Array.from({ length: columns.length }, () => false);
        this.beforeAndAfterSelections = Array.from({ length: columns.length}, () => includeInMatch)
        this.enabledStatuses = this.getDefaultEnabledStatuses()
    }

    resetBeforeAndAfter = () => {
        this.beforeAndAfterSelections = Array.from({ length: this.numColumns}, () => includeInMatch)
        this.enabledStatuses = this.getDefaultEnabledStatuses()
    }

    //Start with just the first and last enabled
    getDefaultEnabledStatuses = () => {
        let statuses = Array.from({ length: this.numColumns}, () => false)
        statuses[0] = this.enableLookAround(0)
        statuses[this.numColumns - 1] = this.enableLookAround(this.numColumns - 1)
        return statuses
    }

    //PDFTron currently does not support regexes where lookbehinds are not fixed length. (I raised a support ticket about this
    //but they kept denying that it was problem until I gave them a clear way to reproduce it and then they never got back to me).
    //That means that we need to disable the lookaround boxes for any chunk that does not have fixed length.
    enableLookAround = (index: number) => {
        if (this.numColumns === 0) {
            return false
        }
        return !this.optionalSelections[index] &&
            (this.exactMatchSelections[index].startsWith(exactPhrase) ||
             this.exactMatchSelections[index].endsWith(punctuation) ||
             (this.exactMatchSelections[index].startsWith(anyCharType) && this.quantifierSelections[index].startsWith(exactly)))
    }

    getExactMatchDefaultSelections = (columns: string[], puncColumns: string[]) => {
        const defaultSelections: string[] = [];

        for (let index = 0; index < columns.length; index++) {
            const chunk = columns[index];
            const isPunctuation = puncColumns.includes(chunk);
            defaultSelections.push(this.getDefaultSelection(chunk, isPunctuation));
        }
        return defaultSelections
    };

    getDefaultSelection = (text: string, isPunctuation: boolean) => {
        return isPunctuation? `${(anyCharType)} ${(punctuation)}` :
            `${exactPhrase} "${text}"`
    }

    clone(): Selections {
        const clonedSelections = new Selections([], []);
        clonedSelections.numColumns = this.numColumns
        clonedSelections.exactMatchSelections = [...this.exactMatchSelections];
        clonedSelections.quantifierSelections = [...this.quantifierSelections];
        clonedSelections.startsWithSelections = [...this.startsWithSelections];
        clonedSelections.followedBySelections = [...this.followedBySelections];
        clonedSelections.endsWithSelections = [...this.endsWithSelections];
        clonedSelections.optionalSelections = [...this.optionalSelections];
        clonedSelections.beforeAndAfterSelections = [...this.beforeAndAfterSelections];
        clonedSelections.enabledStatuses = [...this.enabledStatuses];

        return clonedSelections;
    }
}
