import { InfoTenantUser } from "../../models/InfoTenantUser";
import { Button } from "@mui/material";
import { Close } from "@mui/icons-material";

export function RemoveUsers(p: { projectName: string, users: InfoTenantUser[],
    restrictUsers: () => void, showRemoveUsers: (value: boolean) => void }) {

    const userNames = p.users.map(user => user.name).join(", ");

    return <div style={{ height: "100vh", width: "100vw", position: "absolute", top: 0, left: 0,
        backgroundColor: "#939598", zIndex: 2000,
        display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <div style={{ display: "flex", flexDirection: "column", backgroundColor: "white", width: 420 }}>
            <Close style={{ marginTop: 16, marginLeft: 380, color: '#939598', width: 24, height: 24,
                cursor: 'pointer', position: 'absolute', }}
                   onClick={() => p.showRemoveUsers(false)} />
            <div style={{ color: "#C13826", fontSize: 21, letterSpacing: 0.15, fontWeight: 600,
                margin: "32px 32px 19px 32px" }}>
                User{p.users.length > 1 && "s"} {userNames} will no longer have access to {p.projectName} project.
            </div>
            <div style={{ margin: "0 32px" }}>
                Selected users will no longer have access to the project, but their past activity, actions,
                allocations on tasks and subtasks will not be removed.
            </div>
            <div style={{ margin: "32px 32px 47px 32px", display: "flex", justifyContent: "space-between" }}>
                <Button variant="outlined" color="secondary" onClick={() => p.showRemoveUsers(false)}>
                    CANCEL
                </Button>
                <Button variant="contained" color="secondary" onClick={p.restrictUsers}>
                    RESTRICT ACCESS
                </Button>
            </div>
        </div>
    </div>
}
