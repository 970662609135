import {
    MAX_PAGE_RANGE_DISTANCE, PARTICIPANT_ID_CATEGORY,
    PATIENT_ID_CATEGORY,
    PATIENT_ID_COLUMN,
    STUDY_ID_CATEGORY,
    STUDY_ID_COLUMN
} from "../../constants";

export function applyDefaultRules(annotations: any[]) {
    associateMostRecentTransformRule(annotations, PATIENT_ID_COLUMN, [PATIENT_ID_CATEGORY, PARTICIPANT_ID_CATEGORY])
    associateMostRecentTransformRule(annotations, STUDY_ID_COLUMN, [STUDY_ID_CATEGORY])
}

export function associateMostRecentTransformRule(annotations: any[], groupingVariable: string,
                    groupingMatchCategories: string[]) {
    let arr: any[] = [];
    let currentAssociatedCategoryValue = "";
    let currentAssociatedCategoryValuePageNo = 0;
    for (let i = 0; i < annotations.length; i++) {
        if (annotations[i].type && groupingMatchCategories.some(category => category.toLowerCase()===annotations[i].type.toLowerCase())) {
            currentAssociatedCategoryValue=annotations[i].getCustomData('trn-annot-preview')
            currentAssociatedCategoryValuePageNo=annotations[i].getPageNumber()
        }
        if (annotations[i].getCustomData(groupingVariable) === "" &&
            (annotations[i].getPageNumber()-currentAssociatedCategoryValuePageNo) <= getMaxPageRangeDistance()) {
            annotations[i].setCustomData(groupingVariable, currentAssociatedCategoryValue)
        }
    }
    return arr;
}

export function getMaxPageRangeDistance() {
    const setting = localStorage.getItem('associationPageDistance')
    return setting && !isNaN(parseInt(setting)) ? parseInt(setting) : MAX_PAGE_RANGE_DISTANCE
}
