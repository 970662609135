import baseline_close from "../../../provisional_icons/baseline-close.png";
import Button from "@mui/material/Button";
import { useCustomModal } from "../../modals/custom-message-modal";
import { useEffect, useState } from "react";
import { ButtonSpinner } from "../../../components/button-spinner";
import { InputAdornment, TextField } from "@mui/material";
import { CustomDatePicker } from "../../../components/custom-date-picker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import moment from "moment";
import {
    deleteApiTasksWorklogById,
    postApiTasksWorklogCreate,
    putApiTasksWorklog
} from "../../../services/task";
import { Worklog } from "../../../models/Worklog";
import { useAppDispatch } from "../../../hooks/redux-hook";
import { showSnackbar } from "../../../redux/snackbar";

export function ProjectEditLoggedTime(props: { myWorklogs: Worklog[], editedWorklog: Worklog | undefined,
    manual?: boolean, taskId: number, refresh: () => void }) {

    const dispatch = useAppDispatch();
    const { hideModal } = useCustomModal();
    const [submitted, setSubmitted] = useState(false);
    const [time, setTime] = useState("");
    const [date, setDate] = useState<Date | null>(props.editedWorklog ? new Date(props.editedWorklog.date) : new Date());
    const [timeError, setTimeError] = useState(false);
    const [currentWorklog, setCurrentWorklog] = useState<Worklog | undefined>(props.editedWorklog);
    const taskId = props.taskId;

    useEffect(() => {
        if (date) {
            const cw = props.myWorklogs.find(mw =>
                moment(mw.date).format("MM/DD/YYYY") === moment(date).format("MM/DD/YYYY"))
            setCurrentWorklog(cw)
        }
    }, [date])

    useEffect(() => {
        if (currentWorklog) {
            formatMinutesToTime();
        }
    }, [])

    const formatMinutesToTime = () => {
        const hours = (currentWorklog!.minutes / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        setTime(`${rhours < 10 ? "0" + rhours : rhours}:${rminutes < 10 ? "0" + rminutes : rminutes}`);
    }

    const onSubmit = () => {
        const controller = new AbortController();
        const expression = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        if (expression.test(time)) {
            setSubmitted(true);
            const editedWorklogDate = props.editedWorklog ? moment(props.editedWorklog.date) : null
            let editSameDate = true;
            if (props.editedWorklog && date && editedWorklogDate && date.valueOf() !== editedWorklogDate.valueOf()) {
                editSameDate = false;
                deleteApiTasksWorklogById(props.editedWorklog.id, controller.signal)
                    .catch(() => {});
            }
            const [hours, minutes] = time.split(":")
            const totalMinutes = +hours * 60 + +minutes;
            if (currentWorklog) {
                putApiTasksWorklog({
                    ...currentWorklog,
                    minutes: props.manual || (editedWorklogDate && !editSameDate) ?
                        currentWorklog.minutes + totalMinutes : totalMinutes, action: props.manual ? "ADD" : "UPDATE"
                }, controller.signal)
                    .then(() => {
                        props.refresh();
                        setSubmitted(false);
                        hideModal();
                    })
                    .catch(() => {
                        setSubmitted(false);
                        dispatch(showSnackbar({ message: "Error saving changes!", type: "error" }))
                    });
            } else {
                postApiTasksWorklogCreate({
                    date: moment(date).format("YYYY-MM-DD 00:00:00"), minutes: totalMinutes, taskId: taskId
                }, controller.signal)
                    .then(() => {
                        props.refresh();
                        setSubmitted(false);
                        hideModal();
                    })
                    .catch(() => {
                        setSubmitted(false);
                        dispatch(showSnackbar({ message: "Error saving changes!", type: "error" }))
                    });
            }
        } else {
            setTimeError(true);
        }
    }

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img alt='close' src={baseline_close} className="remove-entity-close-button" onClick={hideModal} />
            <div className="wizard-title">
                <span>{props.manual ? "Add time manually" : "Edit logged time"}</span>
            </div>
            <div style={{ margin: "0 22px" }}>
                <TextField value={time} onChange={e => setTime(e.target.value)}
                           error={timeError}
                           placeholder="HH:mm" style={{ width: "100%", marginTop: 30 }} />
                <TextField style={{ width: "100%", marginTop: 16 }} value={moment(date).format("MM/DD/YYYY")}
                           InputProps={{
                               endAdornment: (
                                   <InputAdornment position="end">
                                       <CustomDatePicker value={date ?? null} name="" shortView
                                                         setValue={(date) => setDate(date)} dontShowValue
                                                         icon={<CalendarTodayIcon />} />
                                   </InputAdornment>
                               )
                           }}
                />
            </div>
            <div className="remove-entity-button-list" style={{ margin: "40px 22px 0px 22px" }} >
                <Button color="secondary" variant="outlined" onClick={hideModal}>CANCEL</Button>
                <Button disabled={submitted} onClick={onSubmit} color="secondary" variant="contained">
                    SAVE CHANGES {submitted && <ButtonSpinner />}
                </Button>
            </div>
        </div>
    </div>
}
