import React from 'react';
import {Box, LinearProgress} from "@mui/material";

interface Props{
    childComp?: React.ReactNode;
    semiTitle?: string
}
const RequestProcessingPreview:React.FC<Props> = ({childComp, semiTitle}) => {
    return (
        <Box>
            <div className="custom">
                {childComp}
                <br/>
            <LinearProgress className="linear-progress" />
                <small>{semiTitle}</small>
            </div>
        </Box>
    )
}

export default RequestProcessingPreview;