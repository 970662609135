import {SplitText} from "./split-text";
import {Selections} from "./selections";
import {after, before, includeInMatch, isLookahead, isLookbehind, notAfter, notBefore} from "./constants";
import {Button} from "@mui/material";

export function BeforeAndAfterTable(props: {splitText: SplitText, selections: Selections, setSelections: (arg: Selections) => void}) {
    const columns = props.splitText.pieces
    const puncColumns = props.splitText.punctuationMarks

    const beforeAndAfterSelections = props.selections.beforeAndAfterSelections
    const enabledStatuses = props.selections.enabledStatuses

    const addBeforeAndAfterSelector = (index: number) => {
        const options = [<option key={index+includeInMatch}>{includeInMatch}</option>]
        //In the first chunk the user can choose before or not before.
        if (index === 0) {
            options.push(<option key={index+before}>{before}</option>)
            options.push(<option key={index+notBefore}>{notBefore}</option>)
        //If the user selected either before or not before in the first chunk, put that option in the second chunk (and in the third if they selected the second etc.)
        } else if (isLookbehind(beforeAndAfterSelections[index - 1]) && index < columns.length - 1) {
            options.push(<option key={`${index}${beforeAndAfterSelections[index - 1]}`}>{beforeAndAfterSelections[index - 1]}</option>)
        }
        //In the last chunk the user can choose after or not after
        if (index === columns.length - 1) {
            options.push(<option key={index+after}>{after}</option>)
            options.push(<option key={index+notAfter}>{notAfter}</option>)
        //If the user selected either after or not after in the last chunk, put that option in the second to last chunk (and then in the third to last etc.)
        } else if (isLookahead(beforeAndAfterSelections[index + 1]) && index > 0) {
            options.push(<option key={`${index}${beforeAndAfterSelections[index + 1]}`}>{beforeAndAfterSelections[index + 1]}</option>)
        }

        const handleSelectorChange = (event: { target: { value: string }; }) => {
            changeSelectors(index, event.target.value)
        };

        return (
            <td key={`ba${index}`}>
                <select value={beforeAndAfterSelections[index]} onChange={handleSelectorChange} disabled={!enabledStatuses[index]}>
                    {options}
                </select>
            </td>
        );
    };

    //Handles the behavior when a user changes the selector at index to newValue.
    const changeSelectors = (index: number, newValue: string) => {
        let newSelections = props.selections.clone()
        const newBeforeAndAfterSelections = [...beforeAndAfterSelections]
        const newEnabledStatuses = [...enabledStatuses]

        newBeforeAndAfterSelections[index] = newValue
        if (isLookbehind(newValue)) {
            //disable the current selector to prevent the user from choosing different options that would make an
            //invalid regex. They can press the reset button to reset everything.
            newEnabledStatuses[index] = false

            //Enable the next text chunk so the user can choose to include that in the lookbehind.
            if (newSelections.enableLookAround(index + 1)) {
                if (index + 1 <= columns.length) {
                    newEnabledStatuses[index + 1] = true
                }
                //If the next text chunk is punctuation, we'll include it in the lookbehind by default and enable
                //the next chunk after that.
                const nextIsPunc = index + 1 <= columns.length
                if (nextIsPunc) {
                    newBeforeAndAfterSelections[index + 1] = newValue
                    //If the next chunk after that is the last one then don't mess with it
                    if (index + 3 <= columns.length && newSelections.enableLookAround(index + 2)) {
                        newEnabledStatuses[index + 2] = true
                    }
                }
            }
        }

        //Same logic as above but in reverse
        if (isLookahead(newValue)) {
            newEnabledStatuses[index] = false
            if (newSelections.enableLookAround(index - 1)) {
                if (index - 1 >= 0) {
                    newEnabledStatuses[index - 1] = true
                }
                const prevIsPunc = index - 1 >= 0 && puncColumns.includes(columns[index - 1])
                if (prevIsPunc) {
                    newBeforeAndAfterSelections[index - 1] = newValue
                    if (index - 3 >= 0 && newSelections.enableLookAround(index - 2)) {
                        newEnabledStatuses[index - 2] = true
                    }
                }
            }
        }

        newSelections.beforeAndAfterSelections = newBeforeAndAfterSelections
        newSelections.enabledStatuses = newEnabledStatuses
        props.setSelections(newSelections)
    }

    const resetTable = () => {
        let newSelections = props.selections.clone()
        newSelections.resetBeforeAndAfter()
        props.setSelections(newSelections)
    }

    return <div className="table-and-reset-button-container">
        <table id="wizard_table">
            <tbody>
                <tr>
                    {columns.map((chunk, index) => (
                        <td key={index}>{chunk}</td>
                    ))}
                </tr>
                <tr>
                    {columns.map((chunk, index) => (
                        addBeforeAndAfterSelector(index)
                    ))}
                </tr>
            </tbody>
        </table>
        <Button variant="contained" color="secondary" id="rg_button_copy" onClick={resetTable}>Reset</Button>
    </div>
}

