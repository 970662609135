import {useCustomModal} from "../custom-message-modal";
import {useState} from "react";
import baseline_close from "../../../provisional_icons/baseline-close.png";
import Button from "@mui/material/Button";
import {ButtonSpinner} from "../../../components/button-spinner";
import {getFileById, getFileVersionUrl} from "../../../services/files";
import {FileState} from "../../../models/FileState";
import {navToDocs} from "../../user/projects/single-project";
import {useNavigate} from "react-router";

export function RestoreFileVersionModal( props: {projectId: string | undefined, fileName: string, versionId: string, fileId: string, lastModifiedDate: string, tag?: string }) {
    const { hideModal } = useCustomModal();
    const [loading, setLoading] = useState(false);
    const { showModal } = useCustomModal();
    const nav = useNavigate();

    // @ts-ignore
    async function openFileVersionInDocs(projectId, filename, versionId, fileId, tag) {
        const file = await getFileById(fileId);
        const url = (await getFileVersionUrl(projectId, decodeURIComponent(filename), versionId)).url;
        file.url = url;
        file.id = fileId;
        let files : FileState[] = [file];
        navToDocs([url], files, showModal, nav, projectId, undefined, undefined, true, tag)
    }

    const onSubmit = async () => {
        hideModal();
        await openFileVersionInDocs(props.projectId, props.fileName, props.versionId, props.fileId, props.tag)
    }

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img alt="close" src={baseline_close} className="remove-entity-close-button" onClick={() => loading ? {} : hideModal()} />
            <span className="remove-entity-title" style={{ color: "#223250" }}>
                Would you like to restore the current file to its version on {props.lastModifiedDate}?
            </span>
            <p className="remove-entity-disclaimer" >
                This file will be opened in DOCS and saved, restoring the LATEST version to its state here on {props.lastModifiedDate}.
            </p>
            <div className="remove-entity-button-list" style={{ marginTop: 20 }} >
                <Button color="secondary" variant="outlined" onClick={hideModal} disabled={loading}>CANCEL</Button>
                <Button disabled={loading} onClick={onSubmit} color="secondary" variant="contained">
                    RESTORE FILE {} {loading && <ButtonSpinner />}
                </Button>
            </div>
        </div>
    </div>
}
