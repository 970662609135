import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hook";
import { Account } from "../models/Account";
import { InternalUser } from "../models/InternalUser";
import { Role } from "../pages/admin/single-account";
import { setAccountList } from "../redux/account-list";
import { hideProgressLine, showProgressLine } from "../redux/progress-line";
import { showSnackbar } from "../redux/snackbar";
import {
    deleteApiRlsAccountsById,
    getApiRlsAccounts,
    getApiRlsAccountsById,
    postApiRlsAccountsCreate,
    putApiRlsAccountsById,
    putApiRlsAccountsDocsAccess
} from "../services/rls.account";
import {
    deleteApiRlsUsersById, postApiRlsUsersAssignAccount,
    postApiRlsUsersInvite,
    postApiRlsManagersInvite,
    postApiRlsUsersReactivateById,
    postApiRlsUsersSearch,
    postApiRlsAddUserRole,
    putApiRlsUsersById, deleteApiRlsUserRoleById
} from "../services/rls.user";
import { accountListSelector } from "../redux/account-list";

type SAdminContext = ReturnType<typeof useCreateSuperAdminContext>
const SuperAdminContext = createContext<SAdminContext>(null!);

type InternalUserByRole = {
    projectManagers: InternalUser[],
    members: InternalUser[],
    viewOnly: InternalUser[],
    redactors: InternalUser[],
    anonymizers: InternalUser[],
    admins: InternalUser[],
    accountManagers: InternalUser[]
}

export function useAdminService() {
    return useContext(SuperAdminContext);
}

function useCreateSuperAdminContext() {
    const [users, setUsers] = useState<InternalUserByRole>();
    const [allAccountUsers, setAllAccountUsers] = useState<InternalUser[]>([]);
    const [allUsers, setAllUsers] = useState<InternalUser[]>([]);
    const [searchedUsers, setSearchedUsers] = useState<InternalUser[]>([]);
    const [currentAccount, setCurrentAccount] = useState<Account>();
    const [usersGroupedByAccount, setUsersGroupedByAccount] = useState<InternalUser[][]>([[]]);
    const nav = useNavigate();
    const dispatch = useAppDispatch();

    const addAccount = async (name: string, color: string) => {
        try {
            await postApiRlsAccountsCreate({ name: name, color: color, enabled: true, securityType: 0 })
            await getAccounts();
        } catch (e) {
            console.log(e);
        }
    }

    const getAccounts = async () => {
        try {
            const accountList = await getApiRlsAccounts();
            dispatch(setAccountList(accountList));
            localStorage.setItem("accountList", JSON.stringify(accountList));
        } catch (e) {
            localStorage.removeItem("accountList");
            console.log(e);
        }
    }

    const getAccountById = async (id: string) => {
        try {
            const account = await getApiRlsAccountsById(id);
            setCurrentAccount(account);
            await getUsersFromSpecificAccount(id!);
        } catch (e) {
            console.log(e)
        }
    }

    const sendManagerInvite = async (accountDetails: {
        accountId?: string, email: string,
        role: "SUPER" | "AM",
        accountRoles?: ("SUPER" | "PM" | "AM" | "ADMIN" | "USER" | "ANONYMIZE" | "REDACTION" | "VIEW")[];
    }) => {
        try {
            const accountList = localStorage.getItem("accountList");
            const accounts: Account[] = JSON.parse(accountList!);
            const account = accounts.find(a => a.id === accountDetails.accountId);
            console.log("details")
            console.log(accountDetails)
            await postApiRlsManagersInvite({ authType: "CUSTOM_AUTH", ...accountDetails });
            dispatch(showSnackbar({
                message: accountDetails.role !== "SUPER" ?
                    `${accountDetails.email} has been added to ${account?.name} account` : `
                Super admin ${accountDetails.email} has been added to the RLS platform.
                `,
                type: "info"
            }));
            if (accountDetails.accountId) {
                await getUsersFromSpecificAccount(accountDetails.accountId);
            }
        } catch (e) {
            dispatch(showSnackbar({
                message: "Error sending invite!",
                type: "error"
            }));
            console.log(e)
        }
    }

    const sendInvite = async (accountDetails: {
        accountId?: string, email: string,
        role?: "SUPER" | "PM" | "AM" | "ADMIN" | "USER" | "ANONYMIZE" | "REDACTION" | "VIEW",
        accountRoles: ("SUPER" | "PM" | "AM" | "ADMIN" | "USER" | "ANONYMIZE" | "REDACTION" | "VIEW")[];
    }) => {
        try {
            const accountList = localStorage.getItem("accountList");
            const accounts: Account[] = JSON.parse(accountList!);
            const account = accounts.find(a => a.id === accountDetails.accountId);
            await postApiRlsUsersInvite({ authType: "CUSTOM_AUTH", ...accountDetails });
            dispatch(showSnackbar({
                message: accountDetails.role !== "SUPER" ?
                    `${accountDetails.email} has been added to ${account?.name} account` : `
                Super admin ${accountDetails.email} has been added to the RLS platform.
                `,
                type: "info"
            }));
            if (accountDetails.accountId) {
                await getUsersFromSpecificAccount(accountDetails.accountId);
            }
        } catch (e) {
            dispatch(showSnackbar({
                message: "Error sending invite!",
                type: "error"
            }));
            console.log(e)
        }
    }

    const getUsersFromSpecificAccount = async (accountId: string) => {
        try {
            const filteredAvailableAccountManagers: InternalUser[] = [];
            const users = await postApiRlsUsersSearch({ accountId, includeDeleted: true });
            const amResponse = await postApiRlsUsersSearch({ role: "AM" });
            const accountManagers = amResponse.filter(ar => ar.accountId !== accountId);
            const projectManagers = users?.filter(user => user.role === "PM" || user.accountRoles?.find(role => role==="PM"));
            const members = users?.filter(user => user.role === "USER" || user.accountRoles?.find(role => role==="USER"));
            const redactors = users?.filter(user => user.role === "REDACTION" || user.accountRoles?.find(role => role==="REDACTION"));
            const anonymizers = users?.filter(user => user.role === "ANONYMIZE"  || user.accountRoles?.find(role => role==="ANONYMIZE"));
            const viewOnly = users.filter(user => user.role === "VIEW"  || user.accountRoles?.find(role => role==="VIEW"));
            const admins = users?.filter(user => user.role === "ADMIN" || user.accountRoles?.find(role => role==="ADMIN"));
            const allUsers = members.concat(redactors).concat(anonymizers).concat(projectManagers).concat(admins).concat(viewOnly);
            const availableAccountManagers = accountManagers?.filter(au => !allUsers.map(am => am.email).includes(au.email));
            availableAccountManagers.forEach(aam => {
                if (!filteredAvailableAccountManagers.map(faam => faam.email).includes(aam.email)) {
                    filteredAvailableAccountManagers.push(aam);
                }
            })
            setAllAccountUsers(users)
            setUsers({ members, projectManagers, admins, redactors, anonymizers, viewOnly, accountManagers: filteredAvailableAccountManagers });
            return users;
        } catch (e) {
            console.log(e)
        }
    }

    const removeAccount = async (id: string) => {
        try {
            await deleteApiRlsAccountsById(id);
            nav("/app/admin/accounts");
        } catch (e) {
            console.log(e);
        }
    }

    const editAccount = async (id: string, oldAccountInfo: Account, newName: string) => {
        try {
            await putApiRlsAccountsById(id, { ...oldAccountInfo, name: newName });
            getAccountById(id);
            getAccounts()
                .then(() => dispatch(showSnackbar({
                    message: `The account has been renamed to ${newName}.`,
                    type: "info"
                })))
                .catch(() => {
                    dispatch(showSnackbar({
                        message: "Error renaming account!",
                        type: "error"
                    }));
                });
        } catch (e) {
            console.log(e)
        }
    }

    const editAccountAccessToDocs = async (accountId: string, newAccessToDocsState: boolean) => {
        try {
            await putApiRlsAccountsDocsAccess( accountId, { accessToDocs: newAccessToDocsState} );
        } catch (e) {
            console.log(e)
        }
    }

    const deleteUserFromAccount = async (id: number, accountId: string) => {
        try {
            await deleteApiRlsUsersById(id, { accountId });
            await getUsersFromSpecificAccount(accountId);
        } catch (e) {
            console.log(e)
        }
    }

    const removeAccountUserRole = async (id: number, role: "SUPER" | "AM" | "ADMIN" | "PM" | "USER" | "ANONYMIZE" | "REDACTION" | "VIEW", accountId: string) => {
        try {
            await deleteApiRlsUserRoleById({ accountId, userId: id, role });
            await getUsersFromSpecificAccount(accountId);
            dispatch(showSnackbar({
                message:
                    `Role removed`,
                type: "info"
            }));
        } catch (e) {
            dispatch(showSnackbar({
                message: "Error removing role",
                type: "error"
            }));
            console.log(e)
        }
    }

    const addAccountUserRole = async (userId: number, role: "SUPER" | "PM" | "AM" | "ADMIN" | "USER" | "ANONYMIZE" | "REDACTION" | "VIEW", accountId: string) => {
        try {
            await postApiRlsAddUserRole({ role, accountId, userId });
            await getUsersFromSpecificAccount(accountId);
            dispatch(showSnackbar({
                message:
                `Role Added`,
                type: "info"
            }));
        } catch (e) {
            dispatch(showSnackbar({
                message: "Error adding role!",
                type: "error"
            }));
            console.log(e)
        }
    }

    const clearAccountData = () => {
        setCurrentAccount(undefined);
        setUsers(undefined);
    }

    const getAllUsers = () => {
        postApiRlsUsersSearch({ includeDeleted: false }).then((res) => {
            const users = res.filter(u => u.role !== "SUPER");
            const groupByAccount = users.reduce((group: InternalUser[][], user) => {
                group[(user.accountId! as unknown) as number] = group[(user.accountId! as unknown) as number] ?? [];
                group[(user.accountId! as unknown) as number].push(user);
                return group;
            }, []);
            setAllUsers(users);
            setSearchedUsers(users);
            setUsersGroupedByAccount(groupByAccount);
        })
    }

    const reactivateUser = async (id: number, accountId: string, userName: string) => {
        dispatch(showProgressLine());
        postApiRlsUsersReactivateById(id, { accountId }).then(() => {
            getUsersFromSpecificAccount(accountId);
            dispatch(hideProgressLine());
            dispatch(showSnackbar({
                message: `${userName} has been reactivated ${accountId ? `for the ${currentAccount?.name} account` :
                    `as super admin`}.`, type: "info"
            }))
        }).catch(e => {
            dispatch(showSnackbar({ message: `error reactivating user!`, type: "error" }))
        })
    }

    return useMemo(() => ({
        addAccount,
        getAccounts,
        getAccountById,
        sendInvite,
        sendManagerInvite,
        getUsersFromSpecificAccount,
        removeAccount,
        deleteUserFromAccount,
        removeAccountUserRole,
        users,
        addAccountUserRole,
        editAccount,
        editAccountAccessToDocs,
        currentAccount,
        clearAccountData,
        getAllUsers,
        searchedUsers,
        allUsers,
        allAccountUsers,
        usersGroupedByAccount,
        reactivateUser
    }), [users, currentAccount, searchedUsers, allUsers, usersGroupedByAccount])
}

export function SuperAdminContextProvider(props: { children: JSX.Element }) {
    const authService = useCreateSuperAdminContext();
    return <SuperAdminContext.Provider value={authService}>
        {props.children}
    </SuperAdminContext.Provider>
}
