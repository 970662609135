
import TextField from "@mui/material/TextField";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useCustomModal } from "../custom-message-modal";
import './remove-entity-modal.scss';
import { StyledBackArrow, StyledCloseIcon } from "../../../components/close-button";
import { ErrorHelper } from "../../../components/error-helper";
import { useEffect, useRef, useState } from "react";
import { Project } from "../../../models/Project";
import { ButtonSpinner } from "../../../components/button-spinner";
import { ProjectDetails } from "../../../models/ProjectDetails";
import { putApiProjectsProjectDetailsById } from "../../../services/project";
import { AddExistingCustomFieldProjectDetails } from "./add-existing-custom-field-project-details";

export function SetCustomFieldModal(props: {
    value: string, project: Project, fetchProject: () => void, field: string, existingCustomFields: string[]

}) {
    const { hideModal, showModal } = useCustomModal();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<{ field: string, value: string }>();
    const [submitted, setSubmitted] = useState(false);
    const valueInputRef = useRef<HTMLInputElement | null>(null);
    const isInEditMode = props.value !== "";

    useEffect(() => {
        setValue("field", props.field);
        setValue("value", props.value);
        if (!isInEditMode) valueInputRef.current?.focus();
    }, [])

    const onSubmit: SubmitHandler<{ field: string, value: string }> = (data) => {
        setSubmitted(true);
        const oldPd = JSON.parse(JSON.stringify(props.project.projectDetails) ?? {});
        const newPd = { ...oldPd, [props.field]: data.value }
        if (props.field !== data.field) {
            Object.defineProperty(newPd, data.field,
                Object.getOwnPropertyDescriptor(newPd, props.field)!);
            delete newPd[props.field];
        }
        putApiProjectsProjectDetailsById(props.project.id, newPd as ProjectDetails).then(() => {
            props.fetchProject();
            hideModal();
        })
            .catch(e => {
                setSubmitted(false);
            })
    }

    return <div style={{
        width: 747, height: 272, display: "flex",
        marginTop: 36,
    }}>
        <div style={{ display: "flex", flexDirection: "column", marginLeft: 45, marginRight: 45, flexGrow: 1 }}>
            <StyledBackArrow onClick={() => {
                if (isInEditMode) {
                    hideModal();
                } else {
                    showModal(AddExistingCustomFieldProjectDetails, {
                        fetchProject: props.fetchProject, project: props.project, oldProjectDetails: props.project.projectDetails!,
                        existingCustomFields: props.existingCustomFields
                    })
                }
            }} />
            <StyledCloseIcon onClick={() => hideModal()} />
            <div style={{ marginTop: 15, fontSize: 25, textAlign: "center" }}>
                {isInEditMode ? "Edit custom field" : "Add value for custom field"}
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: 6 }}>
                <div>
                    <span style={{}} >Field name</span>
                    <TextField {...register("field", { required: true })} error={!!errors.field}
                        placeholder="Enter field name" style={{ width: 328, marginTop: 8 }} />
                </div>
                <div>
                    <TextField {...register("value", { required: true })} error={!!errors.value} inputRef={valueInputRef}
                        placeholder="Enter value" style={{ width: 316, marginTop: 30 }} />
                </div>
            </div>
            {errors.field && errors.field.type === "required" && <ErrorHelper helperText="Please enter a field name." />}
            {errors.value && errors.value.type === "required" && <ErrorHelper helperText="Please enter a value." />}

            <div className="remove-entity-button-list" style={{ marginTop: 26, alignItems: "center" }} >
                <Button color="secondary" variant="outlined" onClick={hideModal} disabled={submitted} style={{
                    width: 89, height: 39
                }}>CANCEL</Button>
                <Button onClick={handleSubmit(onSubmit)} disabled={submitted}
                    color="secondary" variant="contained" style={{ width: "fit-content", alignSelf: "center", padding: "7px 32px" }}>
                    {isInEditMode ? "SAVE CHANGES" : "ADD CUSTOM FIELD"} {submitted && <ButtonSpinner />}
                </Button>
            </div>
        </div>
    </div>
}
