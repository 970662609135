import React, {useEffect, useRef, useState} from 'react';
import {useCustomModal} from "./custom-message-modal";
import ModalIconContent from "./modal-content/modal-icon-content";


//This asks the user to choose a match category for the mark they just added.
const SanitizeModal = (props : { onRemoveAll: any, onRemoveSelected: any }) => {
    const { hideModal } = useCustomModal();
    const modalRef = useRef<HTMLDivElement>(null);


    return <div ref={modalRef} style={{position: "relative",
        width: 350, display: "flex", alignItems: 'center',
        flexDirection: "column", justifyContent: "center", padding: "20px 30px"
    }}>
        <h4 style={{fontWeight:"bold"}}>Sanitize document</h4>



<ModalIconContent/>

        <p style={{marginBottom:"0px",textAlign:"center"}}>

        You can remove all hidden data and metadata from your document so that sensitive information is not passed along when you publish your pdf
        </p>
        <p style={{textAlign:"center"}}>
            You can choose to selectively remove hidden data instead.
        </p>
        <div style={{marginTop:"10px"}}>
            <button onClick={() => hideModal()} className={"sanitize-first-modal"} style={{backgroundColor:"#e60024"}}>Cancel</button>
            <button onClick={props.onRemoveSelected} className={"sanitize-first-modal"} style={{margin:"0px 10px"}}>Selectively remove</button>
            <button  style={{backgroundColor:"#e86122"}}onClick={
                () => {
                    props.onRemoveAll()
                    hideModal()
                }
                } className={"sanitize-first-modal"}>Remove all</button>

        </div>

        
    </div>


};

export default SanitizeModal;
