export class SplitText {
    private punctuationPattern: RegExp = /[\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+/g;
    textChunks: string[] = [];
    punctuationMarks: string[] = [];
    pieces: string[] = [];

    constructor(text: string) {
        this.splitStringIntoWords(text);
    }

    private splitStringIntoWords(s: string) {
        this.textChunks = s.split(this.punctuationPattern);
        const punctuationMarkMatchResults = Array.from(s.matchAll(this.punctuationPattern));
        for (const mark of punctuationMarkMatchResults) {
            this.punctuationMarks.push(mark[0]);
        }

        for (let index = 0; index < this.textChunks.length; index++) {
            const textChunk = this.textChunks[index];
            if (textChunk !== '') {
                this.pieces.push(textChunk);
            }
            if (index < this.punctuationMarks.length) {
                this.pieces.push(this.punctuationMarks[index]);
            }
        }
    }
}
