import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialState = {
    taskLists: [],
    selectedTasks: [],
    selectedSubtasks: [],
    projectId: -1,
    projectName: undefined,
    isTemplate: false,
    showToolbarAfterDeletion: false
}

const actionBar = createSlice({
    name: "actionBar",
    initialState,
    reducers: {
        selectTasks: (state, action) => {
            state.selectedTasks = action.payload;
        },
        selectSubtasks: (state, action) => {
            state.selectedSubtasks = action.payload;
        },
        setProjectId: (state, action) => {
            state.projectId = action.payload;
        },
        setProjectName: (state, action) => {
            state.projectName = action.payload;
        },
        setTaskLists: (state, action) => {
            state.taskLists = action.payload;
        },
        setIsTemplate: (state, action) => {
            state.isTemplate = action.payload;
        },
        setShowToolbarAfterDeletion: (state, action) => {
            state.showToolbarAfterDeletion = action.payload;
        }

    }
});

export const { selectTasks, selectSubtasks, setProjectId, setProjectName, setTaskLists, setIsTemplate, setShowToolbarAfterDeletion } = actionBar.actions

export const selectedTasksSelector = (state: RootState) => state.actionBar.selectedTasks
export const selectedSubtasksSelector = (state: RootState) => state.actionBar.selectedSubtasks
export const actionBarProjectIdSelector = (state: RootState) => state.actionBar.projectId
export const actionBarProjectNameSelector = (state: RootState) => state.actionBar.projectName
export const actionBarTasksListsSelector = (state: RootState) => state.actionBar.taskLists
export const actionBarIsTemplateSelector = (state: RootState) => state.actionBar.isTemplate
export const actionBarIsVisibleSelector = (state: RootState) =>
    state.actionBar.selectedTasks.length > 0 || state.actionBar.selectedSubtasks.length > 0
    export const showToolbarAfterDeletionSelector = (state: RootState) => state.actionBar.showToolbarAfterDeletion

export default actionBar.reducer
