import {markStyles} from "../constants";

export const MarkStyleDropdown = (props: {selectedStyle: number, onChange: (newStyle: number) => void}) => {

    const handleChange = (event: any) => {
        props.onChange(event.target.value);
    }

    return (
        <select onChange={handleChange}
                style={{height: '32px', marginRight: '1rem'}}>
            {markStyles.map((val) => {
                return <option value={val.id} key={val.name} selected={props.selectedStyle !== undefined && props.selectedStyle === val.id}>
                    {val.name}
                </option>
            })
        }
    </select>)
}