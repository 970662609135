import React, { useState } from 'react';
//import Button from 'components/Button';
import './CollapsiblePanelGroup.scss';
import {Button} from "@mui/material";

const CollapsiblePanelGroup = (props) => {
  const {
    header,
    children,
    className,
    role,
    style,
  } = props;

  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className={className} role={role} style={style}>
      <div className="collapsible-page-group-header">
        {header()}
        <Button
          title={isExpanded ? 'collapse' : 'expanded'}
          img={isExpanded ? 'icon-chevron-up' : 'icon-chevron-down'}
          className="expand-arrow"
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </div>
      {isExpanded && children}
    </div>
  );
};

export default CollapsiblePanelGroup;