import {PatternSet} from "../../../models/PatternSet";
import {StyledBackArrow, StyledCloseIcon} from "../../../components/close-button";
import TextField from "@mui/material/TextField";
import {ErrorHelper} from "../../../components/error-helper";
import Button from "@mui/material/Button";
import {AddExistingCustomFieldProjectDetails} from "./add-existing-custom-field-project-details";
import {useCustomModal} from "../custom-message-modal";
import {useForm} from "react-hook-form";
import {putApiProjectsProjectDetailsById, putApiUpdateProject} from "../../../services/project";
import {ProjectDetails} from "../../../models/ProjectDetails";
import {MoreActionsComponent} from "../../../components/more-actions-component";
import MenuItem, {MenuItemProps} from "@mui/material/MenuItem";
import {useEventHandler} from "../../../hooks/handle-events";
import {ProjectTemplateRenameListWizard} from "../../user/wizards/project-template-rename-list";
import {Dispatch, SetStateAction, useState} from "react";
import {statusList} from "../../../constants";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {Project} from "../../../models/Project";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux-hook";
import {loadProject, projectsSelector} from "../../../redux/projects";
import {createCopyOfPatternSetForProject, deleteApiPatternSet} from "../../../services/patternSets";
import {useAuthService} from "../../../contexts/auth-context";
import {hideProgressLine, showProgressLine} from "../../../redux/progress-line";
import {loadPatternSet, removePatternSet} from "../../../redux/patternSets";
import {showSnackbar} from "../../../redux/snackbar";

export function AddPatternsToProjectModal(props: {patternSets: PatternSet[]}) {
    const { hideModal, showModal } = useCustomModal();
    const [ps, setPs] = useState<PatternSet | null>(null)
    const projects = useAppSelector((state) => projectsSelector(state));
    const [project, setProject] = useState<Project | null>(null)
    const auth = useAuthService();
    const dispatch = useAppDispatch();
    const [inProgress, setInProgress] = useState(false)

    const projectAlreadyHasPatterns = project !== null && project.patternSetID !== null

    //Create a copy of the chosen pattern set and assign it to the chosen project.
    const onSubmit = () => {
        dispatch(showProgressLine());
        setInProgress(true)
        handleOverwriting().then(r => {
            createCopyOfPatternSetForProject(ps!, auth.loginInfo?.user?.name??'user', project!.name, project!.id, auth.loginInfo?.tenant?.schema).then(newPS => {
                dispatch(loadPatternSet(newPS))
                const updatedProject = {...project, patternSetID: newPS.id } as Project
                putApiUpdateProject(project!.id, updatedProject).then(() => {
                    dispatch(loadProject(updatedProject))
                    dispatch(hideProgressLine())
                    hideModal()
                }).catch(reason => {
                    dispatch(showSnackbar({
                        message: "Error adding patterns!", type: "error"
                    }))
                    hideModal()
                })
            }).catch(reason => {
                dispatch(showSnackbar({
                    message: "Error adding patterns!", type: "error"
                }))
                hideModal()
            })
        })

    }

    //If this project already had a pattern set assigned to it, delete that before creating a new one.
    function handleOverwriting(): Promise<void> {
        if (projectAlreadyHasPatterns) {
            return deleteApiPatternSet(project?.patternSetID!)
                .then(() => {
                    dispatch(removePatternSet({id: project?.patternSetID}));
                })
                .then(r => console.log("Deleted pattern set from s3"))
                .catch((reason) => {
                    console.log("Failed to delete pattern set from s3")
                    console.log(reason)
                })
        } else {
            return Promise.resolve()
        }
    }

    const handleSelectPS = (ps : PatternSet) => {
        setPs(ps)
    }

    const handleSelectProject = (p : Project) => {
        setProject(p)
    }


    return <div style={{
        width: 747, height: 388, display: "flex",
        flexDirection: "column", marginTop: 36
    }}>
        <div style={{ display: "flex", flexDirection: "column", marginRight: 45, marginLeft: 45 }}>
            <StyledCloseIcon onClick={() => hideModal()} />
            <div style={{ marginTop: 15, fontSize: 25, textAlign: "center" }}>
                Add Patterns to Project
            </div>
            <label className="wizard-content-label" style={{ fontSize: 17, marginTop: 16, marginBottom: 18, letterSpacing: 0.49 }}>
                This gives users access to these patterns when working on documents in this project.</label>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 8 }}>
                    <div>
                        <PatternSetDropDown patternSets={props.patternSets} selected={ps} handleSelect={handleSelectPS}/>
                    </div>
                    <div>
                        <ProjectDropDown projects={projects} selected={project} handleSelect={handleSelectProject}/>
                    </div>
                </div>
            </div>
            <br/>
            <span style={{ color: 'red', display: projectAlreadyHasPatterns ? 'inline' : 'none' }}>
                This project already has a pattern set assigned to it. The old pattern set will be deleted if you continue.
            </span>
            <Button disabled={ps === null || project === null || inProgress} onClick={onSubmit} color="secondary" variant="contained" style={{
                width: "fit-content", alignSelf: "center", marginTop: 36, padding: "7px 32px"
            }}>
                ADD
            </Button>
        </div>
    </div>
}

export function PatternSetDropDown(props: {patternSets: PatternSet[], selected: PatternSet | null, handleSelect: (ps: PatternSet) => void}) {

    const getNameAndVersion = (ps : PatternSet) => {
        return `${ps.name} V${ps.version}`
    }

    return <DropDown items={props.patternSets} selected={props.selected} handleSelect={props.handleSelect} getItemName={getNameAndVersion} defaultMessage={'Select a Pattern Set'} />
}

export function ProjectDropDown(props: {projects: Project[], selected: Project | null, handleSelect: (p: Project) => void}) {

    return <DropDown items={props.projects} selected={props.selected} handleSelect={props.handleSelect} getItemName={(p: Project) => {return p.name}} defaultMessage={'Select a Project'}/>
}

export function DropDown(props: {items: any[], selected: any | null, handleSelect: (o: any) => void, getItemName: (o: any) => string, defaultMessage: string}) {
    const { onMouseEventHandler, onLeaveMouseEventHandler, onMouseDisabledEventHandler } = useEventHandler();
    const [anchorElColumn, setAnchorElColumn] = useState<HTMLElement | null>(null);
    const menuItems = props.items.map((item, index) =>
        <MenuItem
            key={index}
            onMouseEnter={onMouseEventHandler}
            onMouseLeave={onLeaveMouseEventHandler}
            onClick={() => {
                setAnchorElColumn(null)
                props.handleSelect(item)
            }}>
            {props.getItemName(item)}
        </MenuItem>
    )

    return <div className={"task-details-status"} style={{backgroundColor: "lightgrey", height: 30, width: 250}}>
        <MoreActionsComponent id={0} anchorEl={anchorElColumn} setAnchorEl={setAnchorElColumn}
                              menuHorizontalPosition="center"
                              menuItems={menuItems}
                              anchorOriginHorizontal="center"
                              width={250}
                              icon={<div style={{display: "flex", alignItems: "center", flexGrow: 1, justifyContent: "space-between"}}>
                                        <span style={{letterSpacing: 0.1, color: "black", fontSize: 15, fontFamily: "Titillium Web", fontWeight: 600}}>
                                            {props.selected === null ? props.defaultMessage : props.getItemName(props.selected)}
                                        </span>
                                        <ArrowDropDownIcon style={{color: "black", marginLeft: 4}}/>
                                    </div>}
        />
    </div>
}
