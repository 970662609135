import { useCustomModal } from "../custom-message-modal";
import { StyledCloseIcon } from "../../../components/close-button";

export function ChangeRoleLastPmModal() {
    const { hideModal } = useCustomModal();

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <StyledCloseIcon onClick={hideModal} />
            <span style={{ fontSize: 15, fontWeight: "600", marginTop: 10, width: "95%" }} >
                You cannot change the role for the only user assigned as a Project Manager in the account.
            </span>
            <p style={{ fontSize: 15 }}>
                You will be able to change that user’s role after inviting another Project Manager into the account or assigning
                another user the Project Manager role.
            </p>
        </div>
    </div>
}
