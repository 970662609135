import Button from "@mui/material/Button";
import baseline_close from "../../../provisional_icons/baseline-close.png";
import { useCustomModal } from "../custom-message-modal";

export function UserAlreadyExistsModal(props: { title: string, content: string }) {
    const { hideModal } = useCustomModal();

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img alt="close" src={baseline_close} className="remove-entity-close-button" onClick={hideModal} />
            <span className="remove-entity-title" style={{ color: "#223250" }}>
                {props.title}
            </span>
            <p className="remove-entity-disclaimer">
                {props.content}
            </p>
            <div className="remove-entity-button-list" style={{ marginTop: 20, justifyContent: "flex-end" }} >
                <Button color="secondary" variant="outlined" onClick={hideModal}>GO BACK</Button>
            </div>
        </div>
    </div>
}
