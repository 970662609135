import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import './RedactionItem.scss';
import classNames from 'classnames';
import CustomRedactionContext from '../../RedactionPanel/CustomRedactionContext'
import {Button} from "@mui/material";
import {redactionTypeMap} from "../../../../constants";

const RedactionItem = (props) => {
  const {
    annotation,
    type,
    dateFormat,
    language,
    onRedactionItemDelete,
    onRedactionItemSelection,
    isSelected,
  } = props;

  const date = getLatestActivityDate(annotation);
  const formattedDate = date ? dayjs(date).locale(language).format(dateFormat) : '';
  const dateAndType = `${type} - ${formattedDate}`;
  const className = classNames('redaction-item', { 'redaction-item-selected': isSelected });
  const {
    label,
    redactionType,
    markChecked
  } = annotation;

  let redactionPreview;

  if (redactionType === redactionTypeMap['TEXT']) {
    //Here's where it assigns trn-annot-preview if it's not already there to annotations on a loaded document.
    if (!annotation.getCustomData('trn-annot-preview') && annotation.getContents()) {
      annotation.setCustomData('trn-annot-preview', annotation.getContents())
    }
    redactionPreview = (
      <div>
        {annotation.getCustomData('trn-annot-preview')}
      </div>);
  } else if (
    redactionType === redactionTypeMap['FULL_PAGE']
    || redactionType === redactionTypeMap['FULL_VIDEO_FRAME']
    || redactionType === redactionTypeMap['REGION']
    || redactionType === redactionTypeMap['AUDIO_REDACTION']
    || redactionType === redactionTypeMap['FULL_VIDEO_FRAME_AND_AUDIO']
  ) {
    redactionPreview = label;
  } else {
    redactionPreview = annotation.getContents();
  }

  const onKeyUp = (event) => {
    if (event.key === 'Enter') {
      onRedactionItemSelection();
    }
  };
  const onChangedCheckedItems = useContext(CustomRedactionContext)

  return <div className='d-flex' id={`annotation-${annotation.Id}`}>
    <input type='checkbox' checked={markChecked ?? false} onChange={(e) => onChangedCheckedItems(annotation.Id, e.target.checked)} />
    <div role="listitem" className={className} onClick={onRedactionItemSelection} onKeyUp={onKeyUp} tabIndex={0}>
      <div className="redaction-item-info">
        <div className="redaction-item-preview">
          {redactionPreview}
        </div>
        <div className="redaction-item-date-author">
          {dateAndType}
        </div>
      </div>
      <Button
        style={{ marginLeft: 'auto' }}
        img={'icon-close'}
        onClick={onRedactionItemDelete}
        aria-label='Delete'
      />
    </div>
  </div>;
};

export default React.memo(RedactionItem);

const getLatestActivityDate = (annotation) => {
  if (annotation.DateCreated) {
    return annotation.DateCreated;
  }

  return undefined;
};