import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {//because the development environment can be either undefined or equal 'development'
    Sentry.init({
        dsn: "https://5a900effa08b41e18ff9bb30dbe8d266@o4505245120921600.ingest.sentry.io/4505357909884928",
        environment: process.env.NODE_ENV,
        // Performance Monitoring
        tracesSampleRate: 0.3, // 1.0 means Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
