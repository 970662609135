import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import baseline_subdirectory_arrow_right from "../../provisional_icons/baseline-subdirectory-arrow-right.png";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import useTheme from "@mui/material/styles/useTheme";
import { useEffect, useRef, useState } from "react";
import './my-tasks.scss';
import '../pages.scss';
import { FilterInnerButton } from "../../components/filter-inner-button";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { projectsSelector, selectProject } from "../../redux/projects";
import { useAuthService } from "../../contexts/auth-context";
import { compareDueDateRanges, dueDateList, DueDateType, statusList, StatusType } from "../../constants";
import { useNavigate } from "react-router-dom";
import { Task } from "../../models/Task";
import { userSettingsSelector } from "../../redux/userSettings";
import moment from "moment";
import { SubTask } from '../../models/SubTask';
import { myTasksGroupBySelector, updateMyTasksGroupBy } from "../../redux/project-filters";

interface MyTasksItemProps {
    color: string;
    text: string;
    tasks?: TaskDetail[]
}

type TaskDetail = {
    taskListName: string,
    taskListId: number,
    projectName: string,
    projectId: number
    task?: Task,
    subtasks?: SubTask[],
    taskName?: string,
    taskId?: number
}

export function MyTasks() {

    const auth = useAuthService();
    const dispatch = useAppDispatch();
    const [isModalOpen, setIsModalOpen] = useState<boolean>();
    const theme = useTheme();
    // const [groupBySelection, setGroupBySelection] = useState<"Status" | "Due date">("Status");
    const buttonRef = useRef(null)

    const projects = useAppSelector((state) => projectsSelector(state));
    const groupBySelection = useAppSelector((state) => myTasksGroupBySelector(state));

    useEffect(() => {
        dispatch(selectProject(0));
    }, []);


    const userId = auth.loginInfo?.tenant?.user?.id.toString();

    const myTasks: TaskDetail[] = [];
    projects.forEach(project => {
        project.taskLists?.forEach(taskList => {
            taskList.tasks?.forEach(task => {
                const filteredTask: Task = JSON.parse(JSON.stringify(task));
                if (task.assignedUserId && task.assignedUserId.split(",").includes(userId!)) {
                    const subtasksInGroupWithTasks = task.subtasks?.filter(sb => groupBySelection === "Status" ? task.status !== sb.status :
                        !compareDueDateRanges(task.dueDate, sb.dueDate))!
                    subtasksInGroupWithTasks.map(sb => {
                        const idx = filteredTask.subtasks?.map(e => e.id).indexOf(sb.id);
                        filteredTask.subtasks?.splice(idx!, 1);
                    })
                    myTasks.push({
                        taskListName: taskList.name,
                        taskListId: taskList.id,
                        projectName: project.name,
                        projectId: +project.id,
                        task: filteredTask,
                    })
                } else {
                    filteredTask.subtasks?.forEach((sb, idx) => {
                        filteredTask.subtasks?.splice(idx, 1);
                    })
                }
                const filteredSubTasksIds = filteredTask.subtasks?.map(sb => sb.id);
                const allSubtasksIds = task.subtasks?.map(sb => sb.id);
                const singularSubtasks = allSubtasksIds?.filter(id => !filteredSubTasksIds?.includes(id));
                const assignedSingularSubtasks = task.subtasks?.filter(sb => singularSubtasks?.includes(sb.id)).filter(subTask =>
                    subTask.assignedUserId && subTask.assignedUserId.split(",").includes(userId!));
                const assignedSubtasksWithoutTasks = task.subtasks?.filter(sb => !task.assignedUserId &&
                    !task.assignedUserId?.split(",").includes(userId!) && sb.assignedUserId && sb.assignedUserId.split(",").includes(userId!));
                if (assignedSingularSubtasks?.length || assignedSubtasksWithoutTasks?.length) {
                    myTasks.push({
                        taskListName: taskList.name,
                        taskListId: taskList.id,
                        projectName: project.name,
                        projectId: +project.id,
                        subtasks: assignedSingularSubtasks?.length ? assignedSingularSubtasks : assignedSubtasksWithoutTasks,
                        taskName: task.name,
                        taskId: task.id
                    })
                }
            })
        })
    });

    return <div style={{ flexGrow: 1 }} onClick={isModalOpen ? () => setIsModalOpen(!isModalOpen) : () => { }}>
        <div className="page-wrapper">
            <div className="my-tasks-inner-box" >
                <span className="my-tasks-title">My tasks</span>
                <Button style={{
                    width: 164,
                    height: 36,
                    backgroundColor: "#EDF5F8",
                    borderRadius: 16,
                    cursor: "pointer",
                    textTransform: "capitalize",
                    color: theme.palette.secondary.main
                }}
                    ref={buttonRef}
                    onClick={() => setIsModalOpen(!isModalOpen)}>
                    Group by: {groupBySelection}
                </Button>
                {isModalOpen && <Box boxShadow={5} className="my-tasks-group-by-modal"
                    style={{
                        top: (buttonRef.current! as HTMLElement).offsetTop + 44,
                        left: (buttonRef.current! as HTMLElement).offsetLeft + 5
                    }}>
                    <p className="my-tasks-group-by-modal-title">GROUP BY</p>
                    <FilterInnerButton title="Status" groupBy={groupBySelection}
                        onClick={() => dispatch(updateMyTasksGroupBy("Status"))} />
                    <FilterInnerButton title="Due date" groupBy={groupBySelection}
                        onClick={() => dispatch(updateMyTasksGroupBy("Due date"))} />
                </Box>}
            </div>
            <div></div>
            <div style={{ overflowY: "auto", height: `calc(100vh - var(--headerHeight) - var(--footerHeight) - 64px - 78px - 64px)` }}>
                {groupBySelection === "Status" ?
                    Object.keys(statusList).map(status => {
                        const subtasks: TaskDetail[] = [];
                        const statusTaskList = myTasks.filter(myTask => myTask.task?.status === status);
                        myTasks.forEach(myTask => {
                            if (myTask.subtasks?.length) {
                                const subtaskList = myTask.subtasks?.filter(sb => sb.status === status);
                                if (subtaskList.length) {
                                    subtasks.push({ ...myTask, subtasks: subtaskList });
                                }
                            }
                        })
                        const title = statusList[status as StatusType].name.toLowerCase();
                        return <MyTasksItem key={status} tasks={statusTaskList.concat(subtasks)} text={title}
                            color={statusList[status as StatusType].color} />
                    })
                    : Object.keys(dueDateList).map(dd => {
                        const filteredTaskLists: TaskDetail[] = [];
                        myTasks.forEach(myTask => {
                            if (myTask.task?.dueDate !== undefined && dueDateList[dd as DueDateType].condition(myTask.task?.dueDate)) {
                                filteredTaskLists.push(myTask);
                            }
                            if (myTask.subtasks?.length) {
                                const subtasks = myTask.subtasks.filter(sb => sb.dueDate !== undefined &&
                                    dueDateList[dd as DueDateType].condition(sb.dueDate));
                                if (subtasks.length) {
                                    filteredTaskLists.push({ ...myTask, subtasks: subtasks });
                                }
                            }
                        })
                        return <MyTasksItem key={dd} tasks={filteredTaskLists} text={dueDateList[dd as DueDateType].name}
                            color={dueDateList[dd as DueDateType].color} />
                    })
                }
            </div>
        </div >
    </div >
}

function MyTasksItem(p: MyTasksItemProps) {
    const [isExpanded, setIsExpanded] = useState(p.tasks && p.tasks.length > 0);

    useEffect(() => {
        if (!isExpanded && p.tasks && p.tasks.length > 0) {
            setIsExpanded(true);
        }
    }, [p.tasks]);

    return <div style={{ marginBottom: 24 }}>
        <Button style={{ justifyContent: "flex-start", marginBottom: 7, width: "100%" }} onClick={() => setIsExpanded(!isExpanded)} >
            <ArrowRightIcon sx={{ width: 24, color: p.color, transform: isExpanded ? "rotate(90deg)" : "none" }} />
            <span className="my-tasks-item-title" style={{ color: p.color }}>{p.text}</span>
            <span className="my-tasks-item-count">({p.tasks?.length})</span>
        </Button>
        {isExpanded && (p.tasks || []).map(data => <MyTask key={data.task?.id} task={data} />)}
    </div>
}

function MyTask(p: { task: TaskDetail }) {
    const nav = useNavigate();
    const userSettings = useAppSelector((state) => userSettingsSelector(state));

    return <div className="my-tasks-task-wrapper">
        <div className="my-tasks-task-inner-box">
            <span className="my-tasks-task-name" onClick={() => nav(`/app/user/workflow/projects/${p.task.projectId}`)}
                style={{ cursor: "pointer" }}>
                {p.task.projectName}
            </span> &nbsp;
            <span className="my-tasks-task-name-arrow">&#62;</span> &nbsp;
            <span className="my-tasks-task-name" style={{ cursor: "pointer" }}
                onClick={() => nav(`/app/user/workflow/projects/${p.task.projectId}/task-list/${p.task.taskListId}`)}>
                {p.task.taskListName}
            </span> &nbsp;
            {!p.task.subtasks ? <>
                <div className="my-tasks-task-details-wrapper">
                    <Button style={{ justifyContent: "flex-start", flexGrow: 1, textTransform: "none", marginLeft: -21 }} sx={{ paddingLeft: 2.8 }}
                        onClick={() => nav(`/app/user/workflow/task-details/${p.task.task?.id}`)}>
                        <span className="my-tasks-task-filename">
                            {p.task.task?.name}
                            <span className="my-tasks-task-subtask-counter">
                                {p.task.task?.subtasks?.length ? `(${p.task.task?.subtasks.length} subtasks)` : ""}
                            </span>
                        </span>
                        <div style={{ flexGrow: 1 }} />
                        <span style={{ justifyContent: "flex-end" }} className={`my-tasks-task-date ${dueDateList["OVERDUE"].condition(p.task.task?.dueDate) ? "my-tasks-overdue-date" : ""}`}>
                            {p.task.task?.dueDate ?
                                moment(p.task.task?.dueDate).format(userSettings.regional.dateFormat) : "-"}
                        </span>
                    </Button>
                </div>
                {(p.task.task?.subtasks && p.task.task?.subtasks?.length > 0) && p.task.task.subtasks.map((subtask, index) =>
                    <MySubTask key={subtask.id} subtask={subtask} last={index === p.task?.task?.subtasks!.length! - 1} />)}
            </> : <>
                <div className="">
                    <span style={{ cursor: "pointer", fontSize: 13, letterSpacing: 0.4 }}
                        onClick={() => nav(`/app/user/workflow/task-details/${p.task.taskId}`)} >
                        {p.task.taskName}
                    </span>
                </div>
                {p.task.subtasks.map((subtask, index) => <MySubTask key={subtask.id} subtask={subtask}
                    last={index === p.task.subtasks?.length! - 1} />)}
            </>}
        </div>
    </div>
}

function MySubTask(p: { subtask: SubTask, last: boolean }) {

    const auth = useAuthService();
    const nav = useNavigate();
    const userSettings = useAppSelector((state) => userSettingsSelector(state));
    const userId = auth.loginInfo?.tenant?.user?.id.toString();

    return <div>
        <div className="my-tasks-sub-task-wrapper">
            <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
                <img alt='arrow-right' src={baseline_subdirectory_arrow_right} className="my-tasks-sub-task-arrow-right" />
                <Button style={{
                    flex: 1, justifyContent: "flex-start", flexGrow: 1, textTransform: "none",
                    fontWeight: (p.subtask.assignedUserId && p.subtask.assignedUserId.split(",").includes(userId!)) ? 600 : "normal"
                }}
                    onClick={() => nav(`/app/user/workflow/task-details/${p.subtask.parentTaskId}/${p.subtask.id}`)}>
                    {p.subtask.name}
                    <div style={{ flexGrow: 1 }} />
                    <span className={`my-tasks-task-date ${dueDateList["OVERDUE"].condition(p.subtask.dueDate) ? "my-tasks-overdue-date" : ""}`}>
                        {p.subtask.dueDate ?
                            moment(p.subtask.dueDate).format(userSettings.regional.dateFormat) : "-"}
                    </span>
                </Button>
            </div>
        </div>
        {!p.last && <hr className="my-tasks-sub-task-horizontal-line"></hr>}
    </div>
}
