import { Template } from "../models/Template";
import { BaseTemplate } from "../models/BaseTemplate";
import { BaseProject } from "../models/BaseProject";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
export function getApiTemplates(signal?: AbortSignal): Promise<Template[]> {
    const url = new URL(`/api/templates`, baseUrl);
    return fetchAndParse<Template[]>(url.toString(), { method: "GET", headers, signal });
}
export function getApiTemplatesById(id: number, signal?: AbortSignal): Promise<Template> {
    const url = new URL(`/api/templates/${id}`, baseUrl);
    return fetchAndParse<Template>(url.toString(), { method: "GET", headers, signal });
}
export function putApiTemplatesById(id: number, body?: BaseTemplate, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/templates/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function deleteApiTemplatesById(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/templates/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
export function postApiTemplatesCreate(body?: BaseTemplate, signal?: AbortSignal): Promise<Template> {
    const url = new URL(`/api/templates/create`, baseUrl);
    return fetchAndParse<Template>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function putApiProjectsById(id: number, body?: BaseProject, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/projects/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
