import Papa from 'papaparse';
import {AnnotationData} from "../pages/modals/load-from-risk-modal";
import {getOriginalText} from "../services/ReportGenerator";
import {PATIENT_ID_COLUMN} from "../constants";
import {DATE_UNIT_SEPARATOR, parseDate} from "./DateParser";
import {Moment} from "moment";

const INPUT_FILE: string = 'data/input.csv';
const OUTPUT_FILE: string = 'data/output.csv';
const COLUMN_PROPERTIES_FILE: string = "input/definition.xml";
const INPUT_FOLDER = 'input/'

//The string used in Risk to represent redacted data. The default is *. The user can change it, but there's not an
//easy way to read it here, so we just assume the default.
const REDACTION_MARKER = "*";
export const REDACTION_KEYWORD = "%REDACT%";
export const RETAIN_KEYWORD = "%RETAIN%";

const DATE_OFFSET = "RANDOMIZED_DATE_INTERVAL";
const RELATIVE_DATE = "RELATIVE_DATE_OFFSET";

const intervalPattern = new RegExp("([\\[\\]])(\\d+(?:\\.\\d+)?),\\s*(\\d+(?:\\.\\d+)?)([\\[\\]])");
const wordIntervalPattern = new RegExp("(\\d+(?:\\.\\d+)?)\\s*to\s*(\\d+(?:\\.\\d+)?)");
const greaterOrLessThanPattern = new RegExp("([<>]=?)\\s*(\\d+(?:\\.\\d+)?)");

const dateDelimiterFinder = new RegExp("((\\s+)|,|-|\\.|\\/)");

export class DeidData {

    extractedFiles: Map<string, Promise<string>> = new Map();
    columnToIdToOutputMap: Map<string, Map<string, Transformation>> = new Map();
    columnPropertiesMap: Map<string, ColumnProperties> = new Map()
    columnNameMap: Map<string, string[]> = new Map()
    patientIDColumn: string | undefined;
    headers: string[] = []
    patientIDs: string[] = []
    intervalColumns: Set<string> = new Set();

    constructor(patientIDColumn?: string) {
        this.patientIDColumn = patientIDColumn;
    }

    initialize = async (extractedFiles: Map<string, Promise<string>>) => {
        this.extractedFiles = extractedFiles;
        this.columnNameMap = getColumnNameMap();
        //This looks for the patient ID column, among other things.
        await this.parseColumnProperties(this.extractedFiles);
        if (this.patientIDColumn) {
            await this.buildOutputMap()
        }
    }

    buildOutputMap = async (midpointColumns: Set<string> = new Set()) => {
        // @ts-ignore
        if (!this.extractedFiles.has(INPUT_FILE)) {
            throw new Error('DEID is missing input file');
        }

        const input = await this.extractedFiles.get(INPUT_FILE);
        const output = await this.extractedFiles.get(OUTPUT_FILE);
        if (!input || !output) {
            throw new Error('Could not find input.csv and output.csv');
        }

        const inputParsed: any[] = this.getParsedCSV(input!);
        const outputParsed: any[] = this.getParsedCSV(output!);

        if (inputParsed.length !== outputParsed.length) {
            throw new Error('Input and Output have different numbers of rows');
        }

        if (!this.patientIDColumn) {
            throw new Error('need to assign a patient ID column before parsing');
        }

        this.headers = Object.getOwnPropertyNames(inputParsed[0])
        let dateColumns: Set<string> = new Set;
        this.intervalColumns = new Set;

        for (let i = 0; i < inputParsed!.length; i++) {
            if (!inputParsed[i].hasOwnProperty(this.patientIDColumn!)) {
                continue;
            }
            this.patientIDs.push(inputParsed[i][this.patientIDColumn])
            for (const columnName in inputParsed[i]) {
                //Skip dates here, those are handled separately
                if (this.columnPropertiesMap.get(columnName)?.type === 'EVENT_DATE_ATTRIBUTE') {
                    dateColumns.add(columnName);
                    continue;
                }

                const patientID = inputParsed[i][this.patientIDColumn];
                const inputValue: string = inputParsed[i][columnName];
                const outputValue: string = outputParsed[i][columnName];
                if (!this.columnToIdToOutputMap.has(columnName)) {
                    this.columnToIdToOutputMap.set(columnName, new Map);
                }
                //Intervals like age or weight are also handled separately, but we need the simple lookup for the parsing to work.
                if (this.isInterval(outputValue)) {
                    this.intervalColumns.add(columnName);
                }
                if (columnName === this.patientIDColumn) {
                    this.columnToIdToOutputMap.get(columnName)!.set(patientID, new PatientIdTransformation(outputValue));
                } else {
                    this.columnToIdToOutputMap.get(columnName)!.set(patientID, new SimpleLookupTransformation(outputValue));
                }
            }
        }

        if (dateColumns.size !== 0) {
            await this.buildDateOutputMap(dateColumns, this.extractedFiles);
        }
        if (this.intervalColumns.size !== 0) {
            await this.buildIntervalOutputMap(this.extractedFiles, midpointColumns);
        }
    }

    private isInterval = (text: string): boolean => {
        return intervalPattern.test(text.trim()) || greaterOrLessThanPattern.test(text.trim())
    }

    private getHierarchyParsed = async (columnName: string, extractedFiles: Map<string, Promise<string>>): Promise<any[] | undefined> => {
        const hierarchyLocation = INPUT_FOLDER + this.columnPropertiesMap.get(columnName)!.hierarchy;
        const hierarchy = await extractedFiles.get(hierarchyLocation);
        if (!hierarchy) {
            console.log(`Could not find this hierarchy: ${hierarchyLocation}`)
            return undefined;
        }
        return this.getParsedCSV(hierarchy!,  false)
    }

    private buildIntervalOutputMap = async (extractedFiles: Map<string, Promise<string>>, midpointColumns: Set<string>) => {
        for (const intervalColumn of Array.from(this.intervalColumns.values())) {
            if (!this.columnPropertiesMap.has(intervalColumn) || !this.columnToIdToOutputMap.has(intervalColumn)) {
                continue;
            }

            const hierarchyParsed = await this.getHierarchyParsed(intervalColumn, extractedFiles);
            if (!hierarchyParsed) {
                continue;
            }

            const hierarchyColumns: Set<string>[] = Array.from({length: hierarchyParsed!.length}, () => new Set())
            for (let i = 1; i < hierarchyParsed!.length; i++) { //Start at 1 to skip the headers
                const line = hierarchyParsed[i];
                for (let j = 1; j < line.length; j++) {
                    const cell = hierarchyParsed[i][j];
                    if (cell && cell !== REDACTION_MARKER) {
                        hierarchyColumns[j].add(hierarchyParsed[i][j]);
                    }
                }
            }

            this.columnToIdToOutputMap.get(intervalColumn)!.forEach((transformation, patientID) => {
                const rawOutput = transformation.getOutputText("");
                if (!rawOutput || rawOutput === REDACTION_MARKER) {
                    return;
                }
                let column = -1;
                //Figure out which level of the hierarchy this transformation is using, based on the output.
                for (let i = hierarchyParsed.length - 1; i >= 0; i--) {
                    if (hierarchyColumns[i].has(rawOutput)) {
                        column = i;
                    }
                }
                if (column === -1) {
                    return;
                }
                try {
                    this.columnToIdToOutputMap.get(intervalColumn)!.set(patientID, new IntervalTransformation(rawOutput, hierarchyColumns[column], midpointColumns.has(intervalColumn)))
                } catch (e) {
                    console.log('error creating interval')
                    console.log(e);
                }

            })
        }
    }

    private buildDateOutputMap = async (dateColumns: Set<string>, extractedFiles: Map<string, Promise<string>>) => {
        for (const dateColumn of Array.from(dateColumns.values())) {
            if (!this.columnPropertiesMap.has(dateColumn)) {
                continue;
            }
            if (!this.columnToIdToOutputMap.has(dateColumn)) {
                this.columnToIdToOutputMap.set(dateColumn, new Map);
            }
            const hierarchyParsed = await this.getHierarchyParsed(dateColumn, extractedFiles);
            if (!hierarchyParsed) {
                continue;
            }

            let mode = this.columnPropertiesMap.get(dateColumn)!.mode
            if (!mode) {
                mode = this.detectDateTransformMode(hierarchyParsed);
            }
            if (mode === DATE_OFFSET) {
                for (let i = 1; i < hierarchyParsed!.length; i++) { //Start at 1 to skip the headers
                    const line = hierarchyParsed[i];
                    const patientID = line[0];
                    const offset = parseInt(line[1], 10);
                    if (isNaN(offset)) {
                        console.log(`This line the date offset hierarchy is not valid. There needs to be an integer in the second column ${line} (${dateColumn})`);
                        continue;
                    }
                    this.columnToIdToOutputMap.get(dateColumn)!.set(patientID, new DateOffsetTransformation(offset));
                }
            } else if (mode === RELATIVE_DATE) {
                for (let i = 1; i < hierarchyParsed!.length; i++) { //Start at 1 to skip the headers
                    const line = hierarchyParsed[i];
                    const patientID = line[0];
                    const startDate = parseDate(line[1])
                    if (!startDate || !startDate.isValid()) {
                        console.log(`This line in the relative day hierarchy does not have a valid date ${line} (${dateColumn})`)
                    }
                    this.columnToIdToOutputMap.get(dateColumn)!.set(patientID, new RelativeDayTransformation(startDate!))
                }
            }
        }
    }

    private detectDateTransformMode = (hierarchyParsed: any[]): string | undefined => {
        if (hierarchyParsed.length > 1 && hierarchyParsed[1].length > 1) {
            const firstCell = hierarchyParsed[1][1];
            const intPattern: RegExp = /^\d+$/
            if (intPattern.test(firstCell)) {
                return DATE_OFFSET;
            }
            const parsedAsDate = parseDate(firstCell);
            if (parsedAsDate && parsedAsDate.isValid()) {
                return RELATIVE_DATE;
            }
        }
        return undefined;
    }

    private parseColumnProperties = async (extractedFiles: Map<string, Promise<string>>): Promise<void> => {
        const columnPropertiesXML = await extractedFiles.get(COLUMN_PROPERTIES_FILE)
        if (!columnPropertiesXML) {
            throw new Error("This Risk project is missing input/definition.xml")
        }
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(columnPropertiesXML, 'application/xml');
        const columns = xmlDoc.getElementsByTagName('assigment'); //Assignment is spelled wrong in ARX

        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            const columnProperties = this.parseColumn(column);
            if (columnProperties) {
                //Choose the first grouping variable to be the patient ID column.
                this.columnPropertiesMap.set(columnProperties.name, columnProperties)
                if (columnProperties.grouping && !this.patientIDColumn) {
                    this.patientIDColumn = columnProperties.name
                }
            }
        }
    };

    private parseColumn = (columnXML: Element): ColumnProperties|undefined => {
        const name = this.parseProperty(columnXML, 'name');
        const type = this.parseProperty(columnXML, 'type');
        const dataType = this.parseProperty(columnXML, 'datatype');
        const mode = this.parseProperty(columnXML, 'mode');

        if (!name || !type || !dataType) {
            console.log(`Couldn't parse this column from Risk ${columnXML}`);
            return undefined;
        }

        const hierarchy = this.parseProperty(columnXML, 'ref');
        const grouping: boolean = this.parseProperty(columnXML, 'grouping')?.trim().toLowerCase() === 'true';
        return new ColumnProperties(name, type, dataType, mode, hierarchy, grouping)
    }

    private parseProperty = (columnXML: Element, propertyName: string): string|undefined => {
        const element = columnXML.getElementsByTagName(propertyName)[0];
        return element && element.textContent ? element.textContent : undefined
    }

    public normalizeKey(s: string | null): string | null {
        return s ? s.toLowerCase().trim() : null;
    }



    //This returns an array of rows. Each row is an object keyed by the column headers if useHeaders is true. Otherwise, each row is an array.
    private getParsedCSV = (csvString: string, useHeaders = true): any[] => {
        const ignoreQuotes =  localStorage.getItem('ignoreQuotationsRisk') === 'true'
        const { data, errors } = Papa.parse(csvString, {
            delimiter: ',',
            quoteChar: ignoreQuotes ? '' : '"', //setting the quoteChar to the empty string makes it ignore quotes.
            header: useHeaders,
            skipEmptyLines: true,
        });
        if (errors.length !== 0) {
            console.log("Error parsing CSV file")
            console.log(errors)
        }
        return data
    }

    getReplacementText = (column: string | undefined, datasetPatientID: string | undefined, originalText: string): string | undefined => {
        if (!column || !datasetPatientID) {
            return undefined
        }
        return this.columnToIdToOutputMap?.get(column)?.get(datasetPatientID)?.getOutputText(originalText)
    }

    //Takes a patient ID from a document and tries to find a matching patient ID in the dataset. This tries to account
    //for different formats.
    getPatientID = (documentPatientID: string) => {

        const match = this.patientIDs.find(datasetPID => normalizeID(datasetPID) === normalizeID(documentPatientID));
        if (match) {
            return match;
        } else {
            //Check if there are any IDs in the dataset with the same ending as the document ID. This is often the case when
            //the document leaves off the study or site ID, so it may say "123" while the dataset says "STUDY1-SITE1-123".
            //Only return this if there's just one match though, otherwise we want the user to look at it and confirm.
            const possibleMatches = this.patientIDs
                .filter(datasetPatientID => normalizeID(datasetPatientID).endsWith(normalizeID(documentPatientID)) ||
                    normalizeID(documentPatientID).endsWith(normalizeID(datasetPatientID)));
            if (possibleMatches.length === 1) {
                return possibleMatches[0]
            } else {
                return undefined
            }
        }
    }

    //Takes a category used in a document and tries to find a matching column name from the dataset.
    getColumn = (documentCategory: string): string|undefined => {
        const docCategory = this.normalizeKey(documentCategory)
        const possibleColumnNames: string[] = this.columnNameMap.get(docCategory!) ?? []

        for (const columnName of this.headers) {
            const colName = this.normalizeKey(columnName);
            if (docCategory === colName || possibleColumnNames.includes(colName!)) {
                return columnName;
            }

            if (documentCategory === 'date' &&
                this.columnPropertiesMap.get(columnName)?.type === 'EVENT_DATE_ATTRIBUTE' &&
                (colName?.endsWith('dt') || colName?.endsWith('dtc'))) {
                return columnName;
            }
        }
        return undefined;
    }

    buildCategoryToColumnMap = (categories: string[]): Map<string, string | undefined> => {
        const map: Map<string, string | undefined> = new Map;
        categories.forEach(category => {
            map.set(category, this.getColumn(category))
        })
        return map;
    }

    buildAnnotationMap = (annotations: any[], categoryToColumnMap: Map<string, string | undefined>) => {
        let map = new Map<string, AnnotationData>()
        annotations.forEach(annotation => {
            const documentText: string = getOriginalText(annotation);
            const pageNum: number = annotation.getPageNumber();
            const documentPID: string = annotation.getCustomData(PATIENT_ID_COLUMN);
            const datasetPID: string | undefined = this.getPatientID(documentPID);
            const category: string = annotation.getCustomData('trn-redaction-type')
            const column = categoryToColumnMap.get(category)
            const replacementText: string | undefined = this.columnToIdToOutputMap?.get(column ?? category)?.get(datasetPID ?? documentPID)?.getOutputText(documentText);

            map.set(annotation.Id, new AnnotationData(annotation.Id, documentText, pageNum, documentPID, datasetPID, replacementText, category, column));
        })
        return map;
    }

    updateAnnotationMapReplacementText = (map: Map<string, AnnotationData>) => {
        const newMap = new Map;
        Array.from(map.keys()).forEach(id => {
            const annotationData = map.get(id)!;
            annotationData.replacementText = this.columnToIdToOutputMap?.get(annotationData.column ?? annotationData.category)?.get(annotationData.datasetPID ?? annotationData.documentPID)?.getOutputText(annotationData.documentText);
            newMap.set(id, annotationData);
        })
        return newMap;
    }
}

interface Transformation {
    getOutputText : (inputText: string) => string|undefined
}

class SimpleLookupTransformation implements Transformation{
    outputTextExact: string;

    constructor(outputTextExact: string) {
        this.outputTextExact = outputTextExact;
    }

    getOutputText = (inputText: string = '') =>  {
        if (this.outputTextExact == REDACTION_MARKER) {
            return REDACTION_KEYWORD;
        } else if (this.normalize(inputText) === this.normalize(this.outputTextExact)) {
            return RETAIN_KEYWORD;
        } else {
            return this.outputTextExact;
        }
    }

    normalize = (text: string) => {
        return text.trim().toLowerCase()
    }
}

class PatientIdTransformation implements Transformation {
    outputTextExact: string;

    constructor(outputTextExact: string) {
        this.outputTextExact = outputTextExact;
    }

    //When transforming patient IDs we want the replacement text to match the format in the document, which may be different
    //then in the dataset.
    //Example:
    //In the dataset, 15011022 is transformed to 40645707.
    //In the document, that patient is listed as CDISC01-1501-11022.
    //This should transform that to CDISC01-4064-5707.
    getOutputText = (inputText: string) => {
        const inputDigits = normalizeID(inputText);
        const outputDigits = normalizeID(this.outputTextExact);

        let output = '';
        let digitsReplaced = 0;
        for (let i = inputText.length - 1; i >= 0; i--) {

            if (inputText[i].match(/\d/g) && digitsReplaced < inputDigits.length && digitsReplaced < outputDigits.length) {
                //Replace all digits in the original with the digits from the replacement. Starting with the last digit
                //so that leading 0s won't throw it off.
                output = outputDigits[outputDigits.length - 1 - digitsReplaced] + output;
                digitsReplaced++;
            } else {
                //All other characters should be retained.
                output = inputText[i] + output;
            }
        }

        return output;
    }
}

class DateOffsetTransformation implements Transformation {
    offsetDays: number;
    getOutputText = (inputText: string = '') => {
        try {
            const date = parseDate(inputText);
            if (!date) {
                return undefined;
            }
            date.add(this.offsetDays, 'days')
            const format = date.creationData().format?.toString() ?? "YYYY-MM-DD";
            let offsetDateString = date.format(format)
            const delimiterMatch = dateDelimiterFinder.exec(inputText)
            if (delimiterMatch) {
                const delimiter = delimiterMatch[0];
                offsetDateString = offsetDateString.replaceAll(DATE_UNIT_SEPARATOR, delimiter);
            }
            return offsetDateString;
        } catch (e) {
            console.log('error offsetting date')
            console.log(e);
        }
        return undefined;
    }

    constructor(offsetDays: number) {
        this.offsetDays = offsetDays;
    }
}

class RelativeDayTransformation implements Transformation {
    startDate: Moment;
    getOutputText = (inputText: string = '') => {
        try {
            const date = parseDate(inputText);
            const daysBetween = Math.round(date!.diff(this.startDate, 'days'));
            return `Day ${daysBetween}`;
        } catch (e) {
            console.log('error offsetting date')
            console.log(e);
        }
    }

    constructor(startDate: Moment) {
        this.startDate = startDate;
    }
}

//Used for transforming a number to an interval, e.g. 42 years to 40-50 years.
//The default interval is the one used in the dataset. We pass other options because the document may have a different value.
//For example the dataset may have 39 years old (changed to 30-39) but the document may have 40 years old (which should be changed to 40-49).
//If useMidpoint is true it will instead return the midpoint of the interval as the output text. For example 45 instead of 40-49.
class IntervalTransformation implements Transformation {
    useMidpoint: boolean = false;
    defaultInterval: Interval;
    intervalOptions: Set<Interval>;

    constructor(defaultIntervalString: string, intervalOptionStrings: Set<string>, useMidpoint: boolean) {
        this.useMidpoint = useMidpoint;
        this.defaultInterval = new Interval(defaultIntervalString, useMidpoint);
        this.intervalOptions = new Set();
        intervalOptionStrings.forEach((thing) => {
            this.intervalOptions.add(new Interval(thing, useMidpoint));
        })
    }

    getOutputText = (inputText: string = '') => {
        if (this.defaultInterval.isTextInInterval(inputText)) {
            return this.defaultInterval.toString();
        }
        for (const interval of Array.from(this.intervalOptions.values())) {
            if (interval.isTextInInterval(inputText)) {
                return interval.toString();
            }
        }
        console.log(`Couldn't find an interval that fits ${inputText}, so it will be redacted`)
        return REDACTION_KEYWORD;
    }
}

class ColumnProperties {
    name: string;
    type: string;
    dataType: string;
    mode: string | undefined;
    hierarchy: string | undefined;
    grouping: boolean;

    constructor(name: string, type: string, dataType: string, mode: string | undefined = undefined, hierarchy: string | undefined = undefined, grouping: boolean = false) {
        this.name = name;
        this.type = type;
        this.dataType = dataType;
        this.mode = mode;
        this.hierarchy = hierarchy;
        this.grouping = grouping
    }
}

class Interval {
    rawText: string;
    start: number;
    end: number;
    startInclusive: boolean = true;
    endInclusive: boolean = true;
    useMidpoint: boolean = false;

    constructor(interval: string, useMidpoint = false) {
        this.rawText = interval;
        this.useMidpoint = useMidpoint;
        const matcher1 = intervalPattern.exec(interval);
        const matcher2 = wordIntervalPattern.exec(interval);
        const matcher3 = greaterOrLessThanPattern.exec(interval);

        if (matcher1) {
            this.start = parseFloat(matcher1[2]);
            this.end = parseFloat(matcher1[3]);
            const startBracket = matcher1[1];
            const endBracket = matcher1[4];
            this.startInclusive = startBracket === "[";
            this.endInclusive = endBracket === "]";
        } else if (matcher2) {
            this.start = parseFloat(matcher2[1]);
            this.end = parseFloat(matcher2[2]);
        } else if (matcher3) {
            const sign = matcher3[1];
            const num = parseFloat(matcher3[2]);

            if (sign.startsWith("<")) {
                this.start = Number.NEGATIVE_INFINITY;
                this.end = num;
                this.endInclusive = sign.endsWith("=")
            } else {
                this.start = num;
                this.startInclusive = sign.endsWith("=");
                this.end = Number.POSITIVE_INFINITY;
            }
        } else {
            console.log("Couldn't parse interval " + interval);
            throw new Error("Couldn't parse interval");
        }
    }

    isTextInInterval = (text: string)  => {
        const num = parseFloat(text.trim());
        if (num === Number.NaN) {
            console.log(`can't parse this as a number ${text}`)
            return false;
        }

        return (num > this.start || (num === this.start && this.startInclusive)) &&
            (num < this.end || (num === this.end && this.endInclusive));
    }

    toString = (): string => {
        if (this.start === Number.NEGATIVE_INFINITY) {
            return `${this.endInclusive? "≤" : "<"}${this.end}`
        } else if (this.end === Number.POSITIVE_INFINITY) {
            return `${this.startInclusive? "≥" : ">"}${this.start}`
        } else if (this.useMidpoint) {
            return `${Math.round((this.start + this.end) / 2)}`;
        } else {
            return `${this.startInclusive ? this.start : this.start + 1}-${this.endInclusive ? this.end : this.end - 1}`;
        }
    }
}

export const getColumnNameMap = () => {
    const setting = localStorage.getItem('columnNameMap')
    if (!setting) {
        return getDefaultColumnNameMap();
    }
    try {
        const jsonArray = JSON.parse(setting)
        let columnNameMap = new Map();
        for (let i = 0; i < jsonArray.length; i++) {
            const key = jsonArray[i][0]
            if (columnNameMap.has(key)) {
                const value = columnNameMap.get(key)
                value.push(jsonArray[i][1])
                columnNameMap.set(key, value)
            } else {
                columnNameMap.set(key, [jsonArray[i][1]])
            }
        }
        return columnNameMap;
    } catch (e) {
        console.log('error reading custom column name map')
        return getDefaultColumnNameMap();
    }
}

const getDefaultColumnNameMap = () => {
    let columnNameMap = new Map();
    columnNameMap.set('patient_id', ['subjid', 'pt', 'usubjid', 'case']);
    columnNameMap.set('participant_id', ['subjid', 'pt', 'usubjid', 'case']);
    columnNameMap.set('site_id', ['siteid', 'center', 'centre', 'centerid', 'centreid']);
    columnNameMap.set('genders', ['sex']);
    columnNameMap.set('weight', ['weightbl']);
    columnNameMap.set('height', ['heightbl']);
    columnNameMap.set('bmi', ['bmibl']);
    columnNameMap.set('study_id', ['studyid']);
    columnNameMap.set('date', ['event_date', 'start_date'])
    return columnNameMap
}

export const getColumnNameMapAsStrings = () => {
    const map = getColumnNameMap();
    let lines: string[] = [];
    map.forEach(function(value:string[], key:string) {
        lines.push(`${value.join(', ')} => ${key}\n`);
    })
    return lines;
}

const normalizeID = (id: string): string => {
    //remove everything except for digits
    let normalizedID = id.replaceAll(/[^0-9]/g, "");
    //remove leading zeros
    normalizedID = normalizedID.replaceAll(/^0+/g, "");
    return normalizedID;
}

