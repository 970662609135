import React, { useEffect, useState } from "react";
import { Wizard } from "../../../components/wizard";
import { TextField } from "@mui/material";
import { WizardData } from "../../../models/WizardData";
import { ChildContext } from "../../../components/wizard";
import { postApiTaskListsCreate } from "../../../services/task.list";
import { postApiTasksBulkCreate } from "../../../services/task";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hook";
import { showSnackbar } from "../../../redux/snackbar";
import { Task } from "../../../models/Task";
import { projectSelector } from "../../../redux/projects";
import { projectTemplateSelector } from "../../../redux/projectTemplates"
import ErrorIcon from '@mui/icons-material/Error';

export function ProjectTemplateDuplicatedListWizard(p: {
    projectId: number, taskListId: number, refreshList: () => void,
    isTemplate: boolean
}) {
    const dispatch = useAppDispatch();
    const projectTaskLists = useAppSelector((state) => projectSelector(state, p.projectId)).taskLists
    const templateTaskLists = useAppSelector((state) => projectTemplateSelector(state, p.projectId))?.taskLists;
    const taskLists = !p.isTemplate ? projectTaskLists : templateTaskLists;

    const initialProjectTemplateTaskList = {
        name: "",
        projectId: -1,
        onSubmit: () => { }
    }

    const [projectTemplateTaskList, setProjectTemplateTaskList] = useState<WizardData>(initialProjectTemplateTaskList);
    const [error, setError] = useState({
        empty: false,
        alreadyExists: false
    });

    useEffect(() => {
        setProjectTemplateTaskList({ ...projectTemplateTaskList, onSubmit: () => duplicateTaskList() });
    }, [projectTemplateTaskList.name]);

    const emptyError = projectTemplateTaskList.name.trim() === "";
        const alreadyExistsError = taskLists?.some(taskList =>
            taskList.name.toLocaleLowerCase().trim() === projectTemplateTaskList.name.trim().toLocaleLowerCase())!;

    useEffect(() => {
        if (error.alreadyExists || error.empty) {
            setError({alreadyExists: alreadyExistsError, empty: emptyError});
        }
    }, [projectTemplateTaskList.name])

    const duplicateTaskList = () => {
        if (emptyError || alreadyExistsError) {
            setError({ empty: emptyError, alreadyExists: alreadyExistsError });
            return true;
        } else {
            setError({ alreadyExists: false, empty: false });
        }
        const taskList = taskLists?.find(tl => tl.id === p.taskListId);
        if (!taskList || !taskList.tasks) {
            return;
        }
        postApiTaskListsCreate({ projectId: p.projectId, name: projectTemplateTaskList.name }).then(newTaskList => {
            const newTasks: Task[] = taskList.tasks?.map(t => ({
                ...t,
                id: -1,
                taskListId: newTaskList.id,
                subtasks: t.subtasks?.map(st => ({ ...st, taskListId: newTaskList.id }))
            }))!;
            postApiTasksBulkCreate(newTasks).then(() => p.refreshList()).then(() => dispatch(showSnackbar({
                message: "List duplicated successfully.",
                type: "info"
            }))).catch(() => dispatch(showSnackbar({
                message: "Error duplicating list!",
                type: "error"
            })));
        })
    }

    const wizardArray = [<ProjectTemplateDuplicatedList key={0} projectTemplateTaskList={projectTemplateTaskList} error={error}
        setProjectTemplateTaskList={setProjectTemplateTaskList} />];
    return <div className="wizard-dialog" >
        <Wizard childrenData={projectTemplateTaskList}>
            {wizardArray}
        </Wizard>
    </div>
}

export function ProjectTemplateDuplicatedList(props: {
    projectTemplateTaskList: WizardData, error: { empty: boolean, alreadyExists: boolean },
    setProjectTemplateTaskList: (p: WizardData) => void
}) {
    const setValue = React.useContext(ChildContext);

    useEffect(() => {
        setValue({ title: "Rename Duplicated List", canLeaveStep: () => true });
    }, [])

    return <div className="wizard-content">
        <br />
        <label className="wizard-content-label ">Enter a List name</label>
        <TextField
            value={props.projectTemplateTaskList.name}
            error={props.error.empty || props.error.alreadyExists}
            helperText={(props.error.alreadyExists || props.error.empty) && <span style={{ marginLeft: -14 }}>
                <ErrorIcon sx={{ width: 12, height: 12 }} />
                <span style={{ marginLeft: 5, verticalAlign: "text-bottom" }}>{props.error.empty ?
                    "Task list name is required." : "Task list name already exists."}</span>
            </span>}
            onChange={e => props.setProjectTemplateTaskList({ ...props.projectTemplateTaskList, name: e.currentTarget.value })}
            placeholder='List Name'
        />
    </div>
}
