import React, { useState } from "react";
import "./project/project.scss"
import { GridColDef, GridRenderCellParams, GridRowsProp } from "@mui/x-data-grid-pro";
import {Box, Button, Link } from "@mui/material";
import { Project } from "../models/Project";
import { useAppSelector } from "../hooks/redux-hook";
import { userSettingsSelector } from "../redux/userSettings";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { projectFiltersSelector } from "../redux/project-filters";
import { DataGrid } from "@mui/x-data-grid";

export function ArchivedProjectsComponent(p: { projects: Project[], fetchProject: (id: number) => void, setSelectedArchivedProjectIds: (ids: number[]) => void }) {
    const nav = useNavigate();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const filters = useAppSelector((state) => projectFiltersSelector(state, 0));
    const archivedProjects = p.projects.filter((project: Project) => project.isArchived === true);
    const userSettings = useAppSelector((state) => userSettingsSelector(state));
    const dateFormat = userSettings.regional.dateFormat;
    const data = getRows();
    const rowsPerPage = 25;
    const totalPages = Math.ceil(archivedProjects.length / rowsPerPage)

    function getDaysUntilRemoval(dateMoment: moment.Moment) {
        const today = moment();
        const msSinceArchived = today.diff(dateMoment);
        const daysSinceArchived = Math.floor(moment.duration(msSinceArchived, "milliseconds").asDays());
        // subtract 1 because then it will be 0 on the day before it is removed at midnight
        return 365 - daysSinceArchived - 1;
    }

    function getRows() {
        let rows: GridRowsProp = [];
        if (p.projects.length === 0) {
            return rows;
        }

        for(let project of archivedProjects) {
            if (filters.searchTerm === "" || project.name.toLowerCase().includes(filters.searchTerm.toLowerCase())) {
                const dateMoment = moment(project.archivedAt);
        
                rows = rows.concat( {
                    id: project.id,
                    col1: project.name,
                    col2: dateMoment.format(dateFormat),
                    col3: getDaysUntilRemoval(dateMoment),
                
                });
            }
        }

        return rows;
    }

    const columns: GridColDef[] = [
        {
            field: 'col1', headerName: 'Project Name', flex: 10, renderCell: (params: GridRenderCellParams) => {
                return <Link component="button" underline="hover" onClick={() => {
                    nav(`/app/user/workflow/projects/${params.row.id}`)
                }}>
                    {params.row.col1}
                
                </Link>
            }
        },
        {
            field: 'col2', headerName: 'Archived On', flex: 3
        },
        {
            field: 'col3', headerName: 'Days Until Removal', flex: 3
        },
    ];

    return <Box style={{ minHeight: 350, height: 500, maxHeight: 600, width: '100%' }}>
        <DataGrid
            sx={{
                // disable cell selection style
                '.MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                // pointer cursor on ALL rows
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer'
                }
              }}
            initialState={{
                pagination: { pageSize: 25 } ,
            }}
            rowHeight={30}
            style={{ height: "calc(100vh - 348px)" }}
            rows={getRows()}
            hideFooter={true}
            //@ts-ignore
            columns={columns}
            pagination
            page={currentPage-1}
            checkboxSelection
            onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = data.filter((row) =>
                    selectedIDs.has(row.id),
                );
                p.setSelectedArchivedProjectIds(selectedRows.map((row) => row.id));
            }}
            disableRowSelectionOnClick={true}
            {...data}
        />
        <CustomPaginationFooter currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} noData={archivedProjects.length === 0} />
    </Box>
}

export const CustomPaginationFooter = (p: {currentPage: number, totalPages: number, setCurrentPage: (page: number) => void, noData: boolean }) => {

    return (
        <div style={{height: 50}} className={"cell-left-border cell-right-border cell-bottom-border"}>
            <p style={{display: "inline-block"}}></p>
            <Button disabled={p.currentPage===p.totalPages || p.noData} className={"page-navigate-button"}
                    onClick={() => p.setCurrentPage(p.currentPage+1)}>
                <p style={{fontSize: 22}}>{'>'}</p>
            </Button>
            <Button disabled={p.currentPage===1 || p.noData} className={"page-navigate-button"} style={{marginLeft: 30}}
                    onClick={() => p.setCurrentPage(p.currentPage-1)}>
                <p style={{fontSize: 22}}>{'<'}</p>
            </Button>
            <p style={{display: "inline-block", float: "right", fontSize: 15}}> 
                {p.noData ? "page 0 of 0" : `page ${p.currentPage} of ${p.totalPages}`}
            </p>
        </div>
    )
}

