import {useNavigate} from "react-router-dom";

import React, {useEffect, useState} from "react";
import {ArrowBack, Delete, Edit} from "@mui/icons-material";
import {Button} from "@mui/material";
import {useCustomModal} from "../modals/custom-message-modal";
import {PatternDelete} from "./wizards/pattern-delete";
import {PatternSet} from "../../models/PatternSet";
import {useAppDispatch, useAppSelector} from "../../hooks/redux-hook";
import {
    addPatternsToSet,
    loadPatternSet,
    patternSetSelector, patternSetsLoadedSelector, removePattern,
    updateModifiedTimeOnPatternSet
} from "../../redux/patternSets";
import {showSnackbar} from "../../redux/snackbar";
import {Pattern} from "../../models/Pattern";
import {
    createNewVersionOfPatternSet, deleteApiPatternById,
    getApiPatternsBySetId,
    postApiPatternCreate,
    putApiPatternSet, putApiPatternTextFileById
} from "../../services/patternSets";
import {BasePattern} from "../../models/BasePattern";
import {fetchPatternSets} from "../../services/init";
import {hideProgressLine, showProgressLine} from "../../redux/progress-line";
import {useAuthService} from "../../contexts/auth-context";
import {User} from "../../models/User";
import moment from "moment-timezone";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import baseline_close from "../../provisional_icons/baseline-close.png";
import {HeaderRowWithSortButtons, sortRows} from "./master-patterns-library";
import {LoadingMessage} from "./wizards/loading-message";
import {StyledCloseIconUploader} from "../../components/close-button";
import Dropzone from "react-dropzone-uploader";
import {saveAs} from "file-saver";

//There are two different components here because the user can access this page either from the master patterns library
//or from project details.
export function SinglePatternSetFromMaster() {
    return SinglePatternSet({fromProject: false})
}

export function SinglePatternSetFromProject() {
    return SinglePatternSet({fromProject: true})
}

export function SinglePatternSet(props : {fromProject: boolean}) {
    const id = parseInt(window.location.href.split('/').pop()!);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const arePatternSetsLoaded: boolean = useAppSelector((state) => patternSetsLoadedSelector(state))
    const ps : PatternSet | undefined = useAppSelector((state) => patternSetSelector(state, id ));
    const auth = useAuthService();
    const { hideModal } = useCustomModal();
    const newVersionInProgress = ps && ps.name && (sessionStorage.getItem('editing-master-pattern') === ps.name)
    console.log('new version in progress: ' + newVersionInProgress)

    const fetchPatterns = (id: number) => {
        dispatch(showProgressLine());
        getApiPatternsBySetId(id)
            .then(patterns => {
                dispatch(addPatternsToSet(patterns))
            })
            .then(() => {
                dispatch(hideProgressLine());
                setIsLoading(false);
            })
            .catch((reason) => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error loading patterns!", type: "error" }));
                console.log(reason)
            })
    }

    useEffect(() => {
        if (ps && arePatternSetsLoaded) {
            fetchPatterns(ps.id)
        }
    }, [arePatternSetsLoaded])

    console.log(ps?.projectID === null ? "no project ID" : "yes project ID")

    async function handleVersioning(ps : PatternSet) : Promise<PatternSet>  {
        if (!newVersionInProgress && !props.fromProject && ps.projectID === null) {
            sessionStorage.setItem('editing-master-pattern', ps!.name!)
            dispatch(showProgressLine());
            showModal(LoadingMessage, {message: `Creating new version...`})
            return createNewVersionOfPatternSet(ps, auth.loginInfo?.user?.name!, auth.loginInfo?.tenant?.schema)
                .then(newVersion => {
                    dispatch(loadPatternSet(newVersion));
                    dispatch(hideProgressLine())
                    hideModal()
                    return newVersion
                })
        } else {
            return ps!
        }
    }

    const
        navigateToCorrectPatternSet = (id: number) => {
        if (ps?.id !== id) {
            navigate(`/app/user/workflow/master-patterns-library/${id}`)
        }
    }

    const createPattern = () => {
        handleVersioning(ps!)
            .then(newMp => {
                const body = getPatternFormForUpload({name: getIncrementalNewPatternName(newMp.patterns.map(pattern => pattern.name)), category: "", description: "", color: "", raw_pattern_location: "", master_id: newMp.id} as BasePattern)
                postApiPatternCreate(body)
                    .then(result => {
                        const controller = new AbortController();
                        fetchPatternSets(controller.signal, dispatch)
                        fetchPatterns(newMp.id)
                        updateTime(newMp)
                    })
                    .then(result => {
                        navigateToCorrectPatternSet(newMp.id)
                    })
                    .catch(reason => {
                        dispatch(showSnackbar({ message: "Error creating pattern" , type: "error" }))
                        console.log(reason.message)
                    })
            })
    }

    let failedToLoad = false;
    if (!ps) {
        failedToLoad = true
        console.log("Couldn't find MP")
    }
    console.log(ps?.projectID)

    const name = ps? ps.name : "Missing Name"
    let navigate = useNavigate();
    const { showModal } = useCustomModal();

    if (ps && !ps.patterns) {
        console.log("MP has no patterns")
    }
    let patterns : Pattern[] = ps?.patterns? ps.patterns : []

    //Filter the table when the user types something into the search box.
    const [searchTerm, setSearchTerm] = useState("")
    const rowHasSearchTerm = (row: Pattern, term : string) => {
        if (!term) {
            return true;
        }
        return row.name.toLowerCase().includes(searchTerm.toLowerCase()) || row.category.toLowerCase().includes(searchTerm.toLowerCase());
    }
    const filteredRows = patterns.filter(row => rowHasSearchTerm(row, searchTerm))

    const removeRow = (row : Pattern) => {
        handleVersioning(ps!)
            .then(newMp => {
                //If we copied the master pattern to a new version, we need to find the new version of this pattern
                const newRow = newMp.patterns.filter(pattern => pattern.name === row.name)[0]
                showModal(PatternDelete, newRow)
                    .then(() => {
                        fetchPatterns(newMp.id)
                        updateTime(newMp)
                        //compileAllCategoriesIntoOneJson(newMp.id)
                    })
                    .then(() => {
                        navigateToCorrectPatternSet(newMp.id)
                    })
            })
    }

    const updateTime = (mp: PatternSet) => {
        if (mp) {
            dispatch(updateModifiedTimeOnPatternSet(getIdAndUpdatedTime(mp.id, auth.loginInfo?.user)))
            //No need to update the values for the API call because the backend will handle figuring out the current user and time.
            putApiPatternSet(mp)
        }
    }

    const goBack = () => {
        props.fromProject ? navigate(-1) : navigate('/app/user/workflow/master-patterns-library/')
    }

    if (isLoading) return <div>Loading</div>

    if (failedToLoad) {
        return <div>
            <ArrowBack className='arrow-back' onClick={() => goBack()} />
            <h1>Error loading patterns!</h1>
        </div>
    } else if (!auth.hasPMRoleAccess() && !props.fromProject) {
        return <p>Your user account does not have permission to access this path. Please contact your System Administrator or RLS Customer Support for assistance.</p>
    }

    const deleteAllPatterns = () => {
        patterns.forEach(pattern => deletePattern(pattern))
    }

    const ShowUpload = () => {
        const {hideModal} = useCustomModal();
        const [waiting, setWaiting] = useState(false)

        const handleSubmit = async (files: { file: any; }[], allFiles: any[]) => {
            dispatch(showProgressLine())
            setWaiting(true)
            dispatch(showSnackbar({ message: "Uploading files!", type: "info" }));

            for (const file of files) {
                const fileName = file.file.name
                const lastDotIndex = fileName.lastIndexOf('.');
                const extension = fileName.substring(lastDotIndex + 1);
                if (extension !== 'txt') {
                    console.log(`skipping ${fileName} because it's not a txt file`)
                    continue;
                }
                const name = fileName.substring(0, lastDotIndex)

                if (patterns.some(pattern => pattern.name === name)) {
                    console.log(`skipping ${name} because a pattern with that name already exists`);
                    showSnackbar({message: `skipping ${name} because a pattern with that name already exists`, type: 'error'});
                    continue;
                }
                const body: FormData = getPatternFormForUpload({name: name, category: name, description: "", color: "", raw_pattern_location: "", master_id: ps.id} as BasePattern)
                //This adds a row to the database with the metadata and creates a blank text file in s3 as a placeholder.
                const newPattern = await postApiPatternCreate(body)
                //This writes the file to that placeholder file in s3.
                await putApiPatternTextFileById(newPattern.id, file.file)
            }

            fetchPatterns(ps.id)
            hideModal();
            dispatch(hideProgressLine())
        }

        return (
            <div className="upload-file-modal-container">

                <div className="wizard-title">
                    <StyledCloseIconUploader onClick={hideModal} />
                    <span>{"RLS File Uploader"}</span>
                </div>
                <Dropzone
                    validate={file => {
                        return false
                    }}
                    //onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    maxFiles={100}
                    inputContent="Click here or drag and drop"
                    //submitButtonDisabled={false}
                    styles={{ dropzone: { minHeight: 450, minWidth: 400, padding:8 } }}
                />
            </div>
        )
    }

    const deletePattern = (pattern: Pattern) => {
        const controller = new AbortController();
        deleteApiPatternById(pattern.id, controller.signal)
            .then(() => {
                dispatch(removePattern({id: pattern.id, pattern_set_id: pattern.master_id}));
            })
            .then(() => dispatch(showSnackbar({
                message: `Pattern ${pattern.name} has been removed from the library.`,
                type: "info"
            })))
            .catch(() => {
                dispatch(showSnackbar({
                    message: "Error deleting pattern!",
                    type: "error"
                }));
            });
    }

    async function downloadFile(url: string, filename: string): Promise<void> {
        const response = await fetch(url!);
        const blob = await response.blob();
        saveAs(blob, filename);
        //There needs to be a slight delay between downloads or the server stops responding.
        await new Promise(resolve => setTimeout(resolve, 100));
        console.log(`Downloaded file: ${filename}`);
    }

    async function downloadAll(): Promise<void> {
        try {
            const patterns = await getApiPatternsBySetId(ps!.id)
            for (const pattern of patterns) {
                await downloadFile(pattern.raw_pattern_location, pattern.name + '.txt')
            }
            console.log('All files downloaded successfully.');
            dispatch(showSnackbar({message: "All files downloaded successfully.", type: "info"}));
        } catch (error) {
            console.error('An error occurred while downloading files:', error);
            dispatch(showSnackbar({message: "Error Downloading Files!", type: "error"}));
        }
        dispatch(hideProgressLine());
    }

    const title = ps?.projectID === null ? `${name} V${ps.version}` : name

    return <div>
        <div className="page-wrapper">
            <ArrowBack className='arrow-back' onClick={() => goBack()} />
            <h1>{title}</h1>
            <div>
                <Button variant="contained" color="secondary" style={{width: 230}} onClick={createPattern} >Add New Pattern</Button>
                {/*Search bar for filtering rows by name*/}
                <div className="project-filter-bar-search">
                    <TextField
                        id="input-with-icon-textfield"
                        value={searchTerm}
                        onChange={(event) => setSearchTerm(event.target.value)}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="secondary" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                searchTerm && <InputAdornment position="end">
                                    <IconButton onClick={() => setSearchTerm("")}>
                                        <img alt="close" src={baseline_close} style={{ width: 20, height: 20 }} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        placeholder="Search"
                        style={{
                            marginTop: 16, marginBottom: 8, padding: 8, backgroundColor: "white", width: 210,
                            border: "1px solid #E6E7E8", borderRadius: 4
                        }}
                        variant="standard"
                    />
                </div>
            </div>
            <PatternTable rows={filteredRows} removeRow={removeRow} masterId={id} fromProject={props.fromProject}/>
            <Button onClick={() => {deleteAllPatterns()}}>Delete All</Button>
            <Button onClick={() => {showModal(ShowUpload, {})}}>Upload txt Files</Button>
            <Button onClick={() => {downloadAll()}}>Download All</Button>
        </div>
    </div>
}

interface PatternTableProps {
    rows: Pattern[]
    removeRow: (row : Pattern) => void
    masterId: number
    fromProject: boolean
}

export function PatternTable({rows, removeRow, masterId, fromProject} : PatternTableProps) {
    let navigate = useNavigate();
    //For sorting the table
    const [sortColumn, setSortColumn] = useState<number>(0);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const columns = [
        { label: 'Pattern Name ', key: 'name' },
        { label: 'Category (Label)', key: 'category' },
        { label: 'Description', key: 'description' }
    ];

    const sortedRows = sortRows(rows, sortColumn, sortDirection, columns) as Pattern[];

    const handleSort = (columnIndex: number) => {
        if (sortColumn === columnIndex) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(columnIndex);
            setSortDirection('asc');
        }
    };

    function goToPattern(id: number) {
        let path = fromProject? '/app/user/workflow/projects/patterns/' : '/app/user/workflow/master-patterns-library/'
        path = `${path}${masterId}/${id}`
        navigate(path)
    }

    return <table>
        <HeaderRowWithSortButtons columns={columns} sortColumn={sortColumn} sortDirection={sortDirection} handleSort={handleSort} />
        <tbody>
        {[...sortedRows].map((item, i) =>
            <tr key={i}>
                <td style={{width: '20%'}}>{item.name}</td>
                <td style={{width: '20%'}}>{item.category}</td>
                <td style={{width: '60%'}}>
                    {item.description}
                    <Delete className="delete-icon" style={{float: 'right'}} onClick={() => removeRow(item)}/>
                    <Edit className='edit-icon' style={{float: 'right'}}
                          onClick={() => goToPattern(item.id)} />
                </td>
            </tr>
        )}
        </tbody>
    </table>
}

export function getIdAndUpdatedTime(id: number, username: User | undefined): {"pattern_set_id": number, "username": string, "date": string} {
    const currentDateString = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    return {pattern_set_id: id, username: username?.name? username.name : "unknown", date: currentDateString}
}

export function getPatternFormForUpload(metadata: BasePattern) {
    const formData = new FormData();

    // Spread the metadata properties into formData
    for (const key in metadata) {
        if (metadata.hasOwnProperty(key)) {
            formData.append(key, String(metadata[key as keyof BasePattern]));
        }
    }

    return formData;

}

export const newPatternName = "New Pattern";

//Return New Pattern, then New Pattern (2), New Pattern (3), etc.
export const getIncrementalNewPatternName = (usedNames: string[]) => {
    const newPatterns = usedNames.filter(name => name.startsWith(newPatternName));
    if (newPatterns.length === 0) {
        return newPatternName;
    } else {
        let biggestUsedNumber = 1;
        for (let i = 0; i < newPatterns.length; i++) {
            const num: number = +newPatterns[i].slice(13, newPatterns[i].length -1)
            if (num > biggestUsedNumber) {
                biggestUsedNumber = num;
            }
        }
        return newPatternName + ` (${biggestUsedNumber+1})`;
    }
}

