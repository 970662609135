import { createSlice } from "@reduxjs/toolkit";
import { Account } from "../models/Account";
import { RootState } from "./store";

const emptyAccountList: Account[] = []

const initialState = {
    accountList: emptyAccountList,
}

const accountList = createSlice({
    name: 'accountList',
    initialState,
    reducers: {
        setAccountList: (state, action) => {
            state.accountList = action.payload;
        },
        resetAccountList: (state) => {
            state.accountList = [];
        }
    }
});

export const { setAccountList, resetAccountList } = accountList.actions;

export const accountListSelector = (state: RootState) => state.accountList.accountList;

export default accountList.reducer;
