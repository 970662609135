import { styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const StyledCloseIcon = styled(CloseIcon)({
    color: '#939598',
    width: 24,
    height: 24,
    cursor: 'pointer',
    position: 'absolute',
    right: 16,
    top: 16
});

export const StyledCloseIconUploader = styled(CloseIcon)({
    color: '#939598',
    width: 24,
    height: 24,
    cursor: 'pointer',
    position: 'absolute',
    right: 16,
    top: 6
});

export const StyledBackArrow = styled(ArrowBackIcon)({
    color: '#939598',
    width: 24,
    height: 24,
    cursor: 'pointer',
    position: 'absolute',
    left: 16,
    top: 16
});
