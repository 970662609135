import { TaskList } from "../../models/TaskList";
import { Task } from "../../models/Task";
import React, { useEffect, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { MoreActionsComponent } from "../more-actions-component";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { CustomFieldKeyTypes, ProjectTask } from "./task";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { loadProject, projectSelector, updateTask } from "../../redux/projects";
import { Project } from "../../models/Project";
import { putApiProjectsProjectCustomFieldsById } from "../../services/project";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { showSnackbar } from "../../redux/snackbar";
import { CustomField } from "../../models/CustomField";
import { customFieldsSelector } from "../../redux/custom-fields";
import { useAuthService } from "../../contexts/auth-context";
import { projectFiltersSelector } from "../../redux/project-filters";
import { getApiTasksById } from "../../services/task";
import { putApiNotificationsMention } from "../../services/notifications";




const initialTask: Task = {
    name: "",
    status: "NOT_STARTED",
    taskListId: -1,
    id: -1,
    timeTrackedByUserId: null,
    sortId:0 // sort id added to move the task
}


export function ProjectListTable(p: {
    taskList: TaskList, displayedTasks: Task[], projectId: number, projectName: string,
    newTask: boolean, setNewTask: (value: boolean) => void, isTemplate: boolean, refreshList: (key?: string) => void, groupName?: string
}) {


    const auth = useAuthService();
    const dispatch = useAppDispatch();
    const tableRef = useRef(null);
    const posRef = useRef(0);
    const mouseDown = useRef((event: MouseEvent) => {
        if (tableRef.current !== null) {
            const row = (tableRef.current as HTMLElement);
            posRef.current = event.pageX;
            row.addEventListener('mousemove', dragRef.current, false);
            setDragging(true);
        }
    });
    const mouseUp = useRef(() => {
        if (tableRef.current !== null) {
            const row = (tableRef.current as HTMLElement);
            row.removeEventListener('mousemove', dragRef.current, false);
            setDragging(false);
        }
    });
    const dragRef = useRef((event: MouseEvent) => {
        const scroll = event.pageX - posRef.current;
        posRef.current = event.pageX;
        if (tableRef.current !== null) {
            const el = tableRef.current as HTMLElement;
            if (el) {
                el.scrollBy(scroll > 0 ? -5 : 5, 0);
            }
        }
        event.preventDefault();
        event.stopPropagation();
    });
    const [anchorElColumn, setAnchorElColumn] = useState<HTMLElement | null>(null);
    const [tableScroll, setTableScroll] = useState(false);
    const [dragging, setDragging] = useState(false);
    const project: Project = useAppSelector((state) => projectSelector(state, p.projectId));
    const customFields: CustomField[] = useAppSelector((state) => customFieldsSelector(state));
    const filters = useAppSelector((state) => projectFiltersSelector(state, p.projectId));
    const [displayedTasks, setDisplayedTask] = useState<Task[]>([]); //State to manage the task display after drag and drop
    const displayTaskRef = useRef<Task[]>(p.displayedTasks)
    // Function to handle the state of the task drap and drop
    const handleTaskReorder = (newTasks: any) => {
        // Update the state with the newly reordered tasks
        setDisplayedTask(newTasks);
        displayTaskRef.current = newTasks;
    };

    const setProjectCustomFields = (field: string) => {
        const updateProjectCustomFields = {
            ...project.projectCustomFields,
            [field]: true
        }
        dispatch(showProgressLine());
        putApiProjectsProjectCustomFieldsById(project.id, updateProjectCustomFields)
            .then(() => {
                dispatch(loadProject({ ...project, projectCustomFields: updateProjectCustomFields }));
                dispatch(hideProgressLine());
            })
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error adding column!", type: "error" }));
            });
    }

    useEffect(() => {
        setDisplayedTask(p.displayedTasks)
    }, [p.displayedTasks])

    useEffect(() => {
        if (tableRef.current) {
            setTableScroll((tableRef.current as HTMLElement).scrollWidth > (tableRef.current as HTMLElement).clientWidth);
        }
    }, [project.projectCustomFields]);
    const [sortInProgress, setSortInProgress] = useState(false);

    useEffect(() => {
        if (tableScroll && tableRef.current !== null) {
            const table = (tableRef.current as HTMLElement);
            table.addEventListener('mousedown', mouseDown.current, true);
            table.addEventListener('mouseup', mouseUp.current, true);
            table.addEventListener('mouseleave', mouseUp.current, true);
        } else if (tableRef.current !== null) {
            const table = (tableRef.current as HTMLElement);
            table.removeEventListener('mousedown', mouseDown.current, true);
            table.removeEventListener('mouseup', mouseUp.current, true);
            table.removeEventListener('mouseleave', mouseUp.current, true);
        }
        return () => {
            if (tableRef.current !== null) {
                const table = (tableRef.current as HTMLElement);
                table.removeEventListener('mousedown', mouseDown.current, true);
                table.removeEventListener('mouseup', mouseUp.current, true);
                table.removeEventListener('mouseleave', mouseUp.current, true);
            }
        };
    }, [tableScroll]);

    const fetchTask = (isUpdate: boolean = false, taskId:number) => {
        if (taskId === -1) return;

        dispatch(showProgressLine());
        getApiTasksById(taskId)
            .then(res => {
                if (isUpdate && project) {
                    dispatch(updateTask({
                        projectId: project!.id,
                        taskListId: res.taskListId,
                        task: res
                    }));
                }
                // Get the index of the task to be updated in the state array
                const taskIdToUpdate = res.id;
                const indexToUpdate = displayTaskRef.current.findIndex((task: any) => task.id === taskIdToUpdate);

                if (indexToUpdate !== -1) {
                    // Create a new array with the updated task
                    // const updatedTasks = [
                    //     ...displayedTasks.slice(0, indexToUpdate),
                    //     res,
                    //     ...displayedTasks.slice(indexToUpdate + 1)
                    // ];
                    const updatedTasks = [...displayTaskRef.current]
                    updatedTasks.splice(indexToUpdate, 1, res)

                    // Update the state with the new array of tasks
                    setDisplayedTask( updatedTasks );
                    displayTaskRef.current = updatedTasks
                    console.log(res,"res",)

                }
                dispatch(hideProgressLine());
            })
            .catch(() => dispatch(hideProgressLine()))
    }




    const columnItems = customFields.filter(cf => project.projectCustomFields === null ||
        (project.projectCustomFields && cf.shortName && !project.projectCustomFields[cf.shortName as CustomFieldKeyTypes]))
        .map(cf => <MenuItem
            key={cf.name}
            onClick={() => {
                setProjectCustomFields(cf.shortName || "");
                setAnchorElColumn(null);
            }}>
            {cf.name}
        </MenuItem>);


    const tableCanHaveNewTask = () => filters.groupBy === undefined
        || (filters.groupBy === "Status" && p.groupName === "Not started")
        || (filters.groupBy === "Due date" && p.groupName === "No due date")
        || (filters.groupBy === "Batch" && p.groupName === "Undefined")
    return <div ref={tableRef}
        style={{
            overflow: "auto", cursor: dragging ? "grabbing" : "default",
            maxWidth: "calc(100vw - 256px - 128px)"
        }}>
        <table style={{ width: "calc(100vw - 256px - 130px)" }}
            className="single-template-list-table"
        >
            {sortInProgress && <div className="spinner spinner-position-center"></div>}
            <thead>
            <tr className="single-template-list-table-head">
                <td style={{ minWidth: 50 }} className="sticky-column"></td>
                <td style={{ minWidth: 50 }} className="sticky-column"></td>
                <td style={{ minWidth: 400 }} className="sticky-column-2"></td>
                <td style={{ minWidth: 100 }}>ASSIGNEE</td>
                <td style={{ minWidth: 100 }}>START DATE</td>
                <td style={{ minWidth: 100 }}>DUE DATE</td>
                <td style={{ minWidth: 120 }}>STATUS</td>
                <td style={{ minWidth: 120 }}>DATE COMPLETED</td>
                <td style={{ minWidth: 120 }}>TIME TRACKED</td>
                <td style={{ minWidth: 120 }}>Files</td>
                {customFields.filter(cf =>
                    project.projectCustomFields && cf.shortName && project.projectCustomFields[cf.shortName as CustomFieldKeyTypes])
                    .map(cf => <td key={cf.name} style={{ minWidth: 100, textTransform: "uppercase" }}>{cf.name}</td>)}
                <td style={{ minWidth: 30 }}>
                    {!p.isTemplate && columnItems.length > 0 && auth.hasPMRoleAccess() &&
                        <MoreActionsComponent
                            key={1}
                            id={`column-${p.taskList.id}`}
                            menuItems={columnItems}
                            anchorEl={anchorElColumn}
                            setAnchorEl={setAnchorElColumn}
                            menuHorizontalPosition="center"
                            icon={<AddCircleOutlineIcon sx={{ color: "#939598", width: 20, height: 20 }} />}
                            tooltip="Add custom field"
                        />
                    }
                    {p.isTemplate && <AddCircleOutlineIcon sx={{ color: "#E6E7E8", width: 20, height: 20 }} />}
                </td>
            </tr>
            </thead>
            <tbody className="table-body-single-project-template">
                {

                }
                {p.newTask && tableCanHaveNewTask() && <ProjectTask sortProgress={(bool: boolean) => setSortInProgress(bool)} key={-1} taskListId={p.taskList.id} task={initialTask} projectName={p.projectName}
                    projectId={p.projectId} last={displayedTasks.length === 0} newTask={p.newTask} setNewTask={p.setNewTask}
                    refreshList={p.refreshList} taskListName={p.taskList.name} isScroll={tableScroll}
                    isTemplate={p.isTemplate} projectCustomFields={project.projectCustomFields!} tableRef={tableRef.current}
                    index={0} displayedTasks={displayedTasks} onTaskReorder={handleTaskReorder} fetchTask={fetchTask}
                />}
                {displayedTasks.map((task, index) =>
                    task ?
                        (<ProjectTask sortProgress={(bool: boolean) => setSortInProgress(bool)} key={task.id} taskListId={p.taskList.id} task={task} projectName={p.projectName}
                            projectId={p.projectId} last={index === displayedTasks.length! - 1}
                            newTask={false} setNewTask={p.setNewTask} refreshList={p.refreshList}
                            taskListName={p.taskList.name} isScroll={tableScroll} isTemplate={p.isTemplate}
                            projectCustomFields={project.projectCustomFields!} tableRef={tableRef.current}
                            index={index} displayedTasks={displayedTasks} onTaskReorder={handleTaskReorder} fetchTask={fetchTask}
                        />)
                        : null)}
            </tbody >
        </table >
    </div>
}


