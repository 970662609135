import { useState } from "react";
import baseline_close from "../../../provisional_icons/baseline-close.png";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useAdminService } from "../../../contexts/super-admin-context";
import { useCustomModal } from "../custom-message-modal";
import './remove-entity-modal.scss';
import { ButtonSpinner } from "../../../components/button-spinner";
import { useAppDispatch } from "../../../hooks/redux-hook";
import { showSnackbar } from "../../../redux/snackbar";

export function RemoveAccountModal(accountName: string, accountId: string) {
    const { register, handleSubmit, formState: { errors }, getValues, setError } = useForm<{ wordCheck: string }>();
    const { removeAccount } = useAdminService();
    const { hideModal } = useCustomModal();
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const word = "REMOVE";

    const onSubmit = () => {
        if (getValues("wordCheck") !== word) {
            setError("wordCheck", { type: "value" });
        } else {
            setLoading(true);
            removeAccount(accountId)
                .then(hideModal)
                .then(() => dispatch(showSnackbar({
                    message: `${accountName} has been removed from RLS platform.`,
                    type: "info"
                })))
                .catch(() => {
                    setLoading(false);
                    dispatch(showSnackbar({
                        message: "Error removing account!",
                        type: "error"
                    }));
                });
        }
    }

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img alt="close" src={baseline_close} className="remove-entity-close-button" onClick={() => loading ? {} : hideModal()} />
            <span className="remove-entity-title" >
                Remove {accountName} from the platform?
            </span>
            <p className="remove-entity-disclaimer" >
                <b>WARNING:</b> Removing this account from the platform will remove all the projects, tasks and history, along with everything else
                that is linked or had been created so far within the account. This action is permanant and cannot be reverted.
            </p>
            <p className="remove-entity-check" >
                Please type the word “{word}” in the field below in order to confirm the action.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    error={!!errors.wordCheck}
                    variant="outlined"
                    id="outlined-error-helper-text"
                    placeholder="Enter the word"
                    {...register("wordCheck", { required: true })}
                    inputProps={{ style: { fontSize: 17 } }}
                    style={{ width: "100%" }}
                />
                <div className="remove-entity-button-list" >
                    <Button disabled={loading} color="secondary" variant="outlined" onClick={hideModal}>CANCEL</Button>
                    <Button disabled={loading} type="submit" color="secondary" variant="contained">
                        REMOVE ACCOUNT {loading && <ButtonSpinner />}
                    </Button>
                </div>
            </form>
        </div>
    </div>
}
