import { createSlice } from '@reduxjs/toolkit'
import { RootState } from "./store";
import { ProjectFilter } from '../components/project-filters';

const defaultFilter: ProjectFilter = {
    projectId: 0,
    searchTerm: "",
    groupBy: undefined,
    filter: {
        keyword: "",
        status: {
            type: undefined,
            color: undefined
        },
        date: null
    },
    showMyTasks: undefined,
    assignees: [],
    showSubtasks: undefined,
    showArchivedProjects: undefined,
    viewCompletedProjects: undefined,
}

const defaultFilters: ProjectFilter[] = [];

const filtersInitialState = {
    filters: defaultFilters,
    showAllProjects: false,
    myTasksGroupBy: "Status"
}

const projectFilters = createSlice({
    name: 'projectFilters',
    initialState: filtersInitialState,
    reducers: {
        updateFilters: (state, action) => {
            const projectId = state.showAllProjects ? 0 : action.payload.projectId;
            const updatedFilter = {
                ...action.payload,
                projectId
            }
            if (state.filters.find(filter => filter.projectId === projectId)) {
                state.filters = state.filters.map(filter => {
                    if (filter.projectId === projectId) {
                        return updatedFilter;
                    }
                    return filter;
                })
            } else {
                state.filters.push(updatedFilter)
            }
        },
        updateShowAllProjects: (state, action) => {
            state.showAllProjects = action.payload;
        },
        updateMyTasksGroupBy: (state, action) => {
            state.myTasksGroupBy = action.payload;
        }
    }
})

export const { updateFilters, updateShowAllProjects, updateMyTasksGroupBy } = projectFilters.actions
export const projectFiltersSelector = (state: RootState, projectId: number) =>
    state.projectFilters.filters.find(filter =>
        filter.projectId === (state.projectFilters.showAllProjects ? 0 : projectId)) || { ...defaultFilter, projectId }
export const myTasksGroupBySelector = (state: RootState) => state.projectFilters.myTasksGroupBy

export default projectFilters.reducer
