import { TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ChildContext, Wizard } from "../../../../components/wizard";
import { Project } from "../../../../models/Project";
import { putApiProjectsById } from "../../../../services/template";
import { useAppDispatch } from "../../../../hooks/redux-hook";
import { hideProgressLine, showProgressLine } from "../../../../redux/progress-line";
import { showSnackbar } from "../../../../redux/snackbar";

interface ProjectNameAndStudyID {
    name: string,
    studyID: string,
    onSubmit: () => void
}

export function ProjectEditNameAndStudyID(props: { projectNameAndStudyID: ProjectNameAndStudyID, setProjectNameAndStudyID: (p: ProjectNameAndStudyID) => void }) {
    const setValue = useContext(ChildContext);

    useEffect(() => {
        setValue({ title: "Edit Project Name and Study ID", canLeaveStep: () => true });
    }, [])

    useEffect(() => {
        setValue({ title: "Edit Project Name and Study ID", canLeaveStep: () => props.projectNameAndStudyID.name.trim() !== "" })
    }, [props.projectNameAndStudyID.name]);
    
    return <div className="wizard-content">
        <br />
        <label className="wizard-content-label ">Project name</label>
        <TextField
            value={props.projectNameAndStudyID.name}
            onChange={e => props.setProjectNameAndStudyID({ ...props.projectNameAndStudyID, name: e.currentTarget.value })}
            placeholder='Project name'
            required helperText={props.projectNameAndStudyID.name.trim() === "" ? "Project name is required" : ""} 
            error={props.projectNameAndStudyID.name.trim() === ""}
        />
        <br />
        <label className="wizard-content-label ">Study ID</label>
        <TextField
            value={props.projectNameAndStudyID.studyID}
            onChange={e => props.setProjectNameAndStudyID({ ...props.projectNameAndStudyID, studyID: e.currentTarget.value })}
            placeholder='Study ID'
        />
    </div>
}


export function ProjectEditNameAndStudyIDWizard(p: { project: Project, refreshList: () => void }) {

    const initialProjectNameAndStudyID = {
        name: p.project.name,
        studyID: p.project.studyId,
        onSubmit: () => { }
    }

    const dispatch = useAppDispatch();
    const [projectNameAndStudyID, setProjectNameAndStudyID] = useState<ProjectNameAndStudyID>(initialProjectNameAndStudyID);

    useEffect(() => {
        setProjectNameAndStudyID({ ...projectNameAndStudyID, onSubmit: () => editProject() });
    }, [projectNameAndStudyID.name, projectNameAndStudyID.studyID]);

    const editProject = () => {
        dispatch(showProgressLine())
        putApiProjectsById(p.project.id, {
            name: projectNameAndStudyID.name,
            color: p.project.color,
            studyId: projectNameAndStudyID.studyID,
            submissionTypeId: p.project.submissionTypeId,
            organizationShare: p.project.organizationShare,
            users: [],
            markStyleName: p.project.markStyleName,
            isArchived: p.project.isArchived || false,
        })
            .then(() => {
                p.refreshList();
                dispatch(showSnackbar({ message: "Project name and Study ID changed successfully.", type: "info" }));
                dispatch(hideProgressLine());
            })
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error changing project name and study id!", type: "error" }));
            })
    }

    const wizardArray = [<ProjectEditNameAndStudyID key={0} projectNameAndStudyID={projectNameAndStudyID} setProjectNameAndStudyID={setProjectNameAndStudyID} />]
    return <div className="wizard-dialog" >
        <Wizard childrenData={projectNameAndStudyID}>
            {wizardArray}
        </Wizard>
    </div>
}
