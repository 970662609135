import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import "../pages.scss";
import './doc-viewer.scss';
import {Box, Button} from "@mui/material";
import {DataGridPro, GridColDef, GridRowsProp} from "@mui/x-data-grid-pro";
import {utils, writeFile} from 'xlsx'

const INEXACT_METRICS_TITLE = "Metrics with Inexact Matching\n"
    + "(An annotation is a true positive if the final version has an annotation with the same ID or " +
    "an annotation in approximately the same place)\n\n";
const EXACT_METRICS_TITLE = "Metrics with Exact Matching\n"
    + "(An annotation is a true positive if the final version has an annotation with the same ID, " +
    "annotation type, and replacement text)";
const COMPARISON_TITLE = "Annotation Comparison"

export function ComparisonReportViewer () {
  const params = useParams();
  const { documentName } = params;
  const location = useLocation();
  const [inexactMetricsRows, setInexactMetricsRows] = useState<string[][]>([])
  const [inexactMetricsRowsHeaders, setInexactMetricsRowsHeaders] = useState<string[]>([])
  const [exactMetricsRows, setExactMetricsRows] = useState<string[][]>([])
  const [exactMetricsRowsHeaders, setExactMetricsRowsHeaders] = useState<string[]>([])
  const [comparisonReportRows, setComparisonReportRows] = useState<string[][]>([])
  const [comparisonReportHeaders, setComparisonReportHeaders] = useState<string[]>([])

  useEffect(() => {
    const inexactMetricsRowsStorage = JSON.parse(localStorage.getItem(documentName + 'InexactMetricsRows')!);
    const exactMetricsRowsStorage = JSON.parse(localStorage.getItem(documentName + 'ExactMetricsRows')!);
    const comparisonReportStorage = JSON.parse(localStorage.getItem(documentName + 'ComparisonReport')!);
    setInexactMetricsRows(inexactMetricsRowsStorage)
    setInexactMetricsRowsHeaders(Array.from(inexactMetricsRowsStorage[0]))
    setExactMetricsRows(exactMetricsRowsStorage)
    setExactMetricsRowsHeaders(Array.from(exactMetricsRowsStorage[0]))
    setComparisonReportRows(comparisonReportStorage)
    comparisonReportStorage.length>0 && setComparisonReportHeaders(Array.from(comparisonReportStorage[0]))
  }, [location.state]);

  function getRows(contents: string[][], headers: string[]) {
    let rows: GridRowsProp = [];
    if (contents.length === 0) {
      return rows;
    }
    let i = 0;
    for(let row of contents) {
      if (i!==0) {//the first row is the header
        rows = rows.concat(constructRowObject(i, row, headers))
      }
      ++i
    }

    return rows;
  }

  function constructRowObject(i: number, row: string[], headers: string[]) {
    let object = {id: i};
    let j=0;
    headers.map(header => {
      object = {
        ...object,
        [header]: [row[j]]
      }
      ++j
    })
    return object
  }

  if (inexactMetricsRows.length===0) {
    return <p>No Inexact Metrics</p>
  }

  if (exactMetricsRows.length===0) {
    return <p>No Exact Metrics</p>
  }

  const inexactMetricsColumns: GridColDef[] = inexactMetricsRowsHeaders.map((header, i) => {
    return {field: header, headerName: header, minWidth: 230, width: 230}
  })

  const exactMetricsColumns: GridColDef[] = exactMetricsRowsHeaders.map((header, i) => {
    return {field: header, headerName: header, minWidth: 230, width: 230}
  })

  const comparisonReportColumns: GridColDef[] = comparisonReportHeaders.map((header, i) => {
    return {field: header, headerName: header, minWidth: 230, width: 230}
  })

  const exportExcel = () => {
    let wb = utils.book_new()
    let inexactMetricsSheetvalue = inexactMetricsRows
    inexactMetricsSheetvalue.unshift([INEXACT_METRICS_TITLE])
    let metricsSheet = utils.aoa_to_sheet(inexactMetricsSheetvalue)

    let exactMetricsSheetvalue = exactMetricsRows
    exactMetricsSheetvalue.unshift([EXACT_METRICS_TITLE])
    let metricsExactMatchSheet = utils.aoa_to_sheet(exactMetricsSheetvalue)

    let comparisonReportSheetvalue = comparisonReportRows
    comparisonReportSheetvalue.unshift([COMPARISON_TITLE])
    let comparisonReportSheet = utils.aoa_to_sheet(comparisonReportSheetvalue)

    utils.book_append_sheet(wb, metricsSheet, "Metrics")
    utils.book_append_sheet(wb, metricsExactMatchSheet, "Metrics - Exact Match")
    utils.book_append_sheet(wb, comparisonReportSheet, "Comparison Report")
    writeFile(wb, downloadFileName)
  }

  const downloadFileName = documentName+'-comparison-report.xlsx';

  return <div>
    <header style={{ fontSize: 30, marginBottom: 20 }}>Comparison Report</header>
    <p style={{ fontSize: 17, marginBottom: 20 }}>File: {documentName}</p>
    <p style={{ fontSize: 17, marginBottom: 20 }}>The Comparison Report is a multi-tab report comparing two documents and providing specific data used for Metrics.</p>
    <Button variant="contained" color="secondary" style={{marginRight: 10, marginBottom: 0}} onClick={exportExcel} >EXPORT RAW DATA</Button>
    <h2 style={{ fontSize: 20 }}>Metrics</h2>

    <p style={{ fontSize: 17, marginBottom: 20 }}>{INEXACT_METRICS_TITLE}</p>
    <Box>
      <DataGridPro autoPageSize={false}
                   autoHeight={true}
                   rows={getRows(inexactMetricsRows, inexactMetricsRowsHeaders)}
                   columns={inexactMetricsColumns}
                   keepColumnPositionIfDraggedOutside={true}
      />
    </Box>

    <h3 style={{ fontSize: 20 }}>Metrics - Exact Match</h3>
    <p style={{ fontSize: 17, marginBottom: 20 }}>{EXACT_METRICS_TITLE}</p>
    <Box>
      <DataGridPro autoPageSize={false}
                   autoHeight={true}
                   rows={getRows(exactMetricsRows, exactMetricsRowsHeaders)}
                   columns={exactMetricsColumns}
                    keepColumnPositionIfDraggedOutside={true}
      />
    </Box>

    <h4 style={{ fontSize: 20 }}>Comparison Report</h4>
    <p style={{ fontSize: 17, marginBottom: 20 }}>{COMPARISON_TITLE}</p>
    <Box>
      <DataGridPro autoPageSize={false}
                   autoHeight={true}
                   rows={getRows(comparisonReportRows, comparisonReportHeaders)}
                   columns={comparisonReportColumns}
                   keepColumnPositionIfDraggedOutside={true}
      />
    </Box>
  </div>

};


