import { useState } from "react";
import baseline_close from "../../../provisional_icons/baseline-close.png";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useCustomModal } from "../custom-message-modal";
import './remove-entity-modal.scss';
import { ButtonSpinner } from "../../../components/button-spinner";
import { getApiProjectsById, putApiProjectIsArchived } from "../../../services/project";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../../hooks/redux-hook";
import { showSnackbar } from "../../../redux/snackbar";
import { loadArchivedProject } from "../../../redux/projects";

export function ArchiveProjectModal(projectNames: string, projectIds: number[]) {

    const dispatch = useAppDispatch();
    const { register, handleSubmit, formState: { errors }, getValues, setError } = useForm<{ wordCheck: string }>();
    const { hideModal } = useCustomModal();
    const [loading, setLoading] = useState(false);
    const nav = useNavigate();
    const word = "ARCHIVE";

    const fetchProject = (id: number) => {
        getApiProjectsById(id)
            .then(project => dispatch(loadArchivedProject(project)))
    }

    const onSubmit = () => {
        if (getValues("wordCheck") !== word) {
            setError("wordCheck", { type: "value" });
        } else {
            setLoading(true);
            const controller = new AbortController();
            projectIds.forEach(projectId => {
                putApiProjectIsArchived(projectId, {isArchived: true}, controller.signal)
                    .then(() => {
                        fetchProject(projectId);
                    })
                    .catch(() => {
                        setLoading(false);
                        dispatch(showSnackbar({ message: "Error deleting project!", type: "error" }));
                    })
            });
            hideModal();
            nav("/app/user/workflow/projects");
        }
    }

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img alt="close" src={baseline_close} className="remove-entity-close-button" onClick={() => loading ? {} : hideModal()} />
            <span className="remove-entity-title" >
                Archive {projectNames}?
            </span>
            <p className="remove-entity-disclaimer" >
                <b>WARNING:</b> Upon archiving {`${projectIds.length > 1 ? "these projects" : "this project"}`}, you will have 365 days to
                    restore {`${projectIds.length > 1 ? "them" : "it"}`} before all the lists, tasks, subtasks and history, along with everything 
                    else that is linked or had been created so far within the project is permanently deleted.
            </p>
            <p className="remove-entity-check" >
                Please type the word “{word}” in the field below in order to confirm the action.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    error={!!errors.wordCheck}
                    variant="outlined"
                    id="outlined-error-helper-text"
                    placeholder="Enter the word"
                    {...register("wordCheck", { required: true })}
                    inputProps={{ style: { fontSize: 17 } }}
                    style={{ width: "100%" }}
                />
                <div className="remove-entity-button-list" >
                    <Button disabled={loading} color="secondary" variant="outlined" onClick={hideModal}>CANCEL</Button>
                    <Button disabled={loading} type="submit" color="secondary" variant="contained">
                      {`ARCHIVE PROJECT${projectIds.length > 1 ? "S" : ""}`} {loading && <ButtonSpinner />}
                    </Button>
                </div>
            </form>
        </div>
    </div>
}