import React, { useEffect, useState } from "react";
import { Wizard } from "../../../components/wizard";
import { TextField } from "@mui/material";
import { WizardData } from "../../../models/WizardData";
import { ChildContext } from "../../../components/wizard";
import { putApiTaskListsById } from "../../../services/task.list";
import { TaskList } from "../../../models/TaskList";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hook";
import { showSnackbar } from "../../../redux/snackbar";
import { actionBarTasksListsSelector } from "../../../redux/action-bar";
import ErrorIcon from '@mui/icons-material/Error';

export function ProjectTemplateRenameListWizard(p: { taskList: TaskList, refreshList: () => void }) {

    const dispatch = useAppDispatch();

    const initialProjectTemplateTaskList = {
        name: p.taskList.name,
        projectId: p.taskList.projectId,
        onSubmit: () => { }
    }

    const [projectTemplateTaskList, setProjectTemplateTaskList] = useState<WizardData>(initialProjectTemplateTaskList);
    const [error, setError] = useState({
        empty: false,
        alreadyExists: false
    })
    const [submitted, setSubmitted] = useState(false);
    const taskLists: TaskList[] = useAppSelector((state) => actionBarTasksListsSelector(state));

    useEffect(() => {
        setProjectTemplateTaskList({ ...projectTemplateTaskList, onSubmit: () => editTaskList() });
    }, [projectTemplateTaskList.name]);

    const emptyError = projectTemplateTaskList.name.trim() === "";
    const alreadyExistsError = taskLists?.some(taskList =>
        taskList.name.toLocaleLowerCase().trim() === projectTemplateTaskList.name.trim().toLocaleLowerCase())!;

    useEffect(() => {
        if (submitted) {
            setError({ empty: emptyError, alreadyExists: alreadyExistsError });
        }
    }, [projectTemplateTaskList.name])

    const editTaskList = () => {
        setSubmitted(true);
        if (emptyError || alreadyExistsError) {
            setError({ empty: emptyError, alreadyExists: alreadyExistsError });
            return true;
        } else {
            setError({ alreadyExists: false, empty: false });
        }
        putApiTaskListsById(p.taskList.id, { projectId: p.taskList.projectId, name: projectTemplateTaskList.name })
            .then(() => p.refreshList()).then(() => dispatch(showSnackbar({
                message: "List renamed successfully.",
                type: "info"
            }))).catch(e => dispatch(showSnackbar({
                message: "List rename error!",
                type: "error"
            })));
    }
    const wizardArray = [<ProjectTemplateRenameList key={0} projectTemplateTaskList={projectTemplateTaskList} error={error}
        setProjectTemplateTaskList={setProjectTemplateTaskList} />];
    return <div className="wizard-dialog" >
        <Wizard childrenData={projectTemplateTaskList}>
            {wizardArray}
        </Wizard>
    </div>
}

export function ProjectTemplateRenameList(props: {
    projectTemplateTaskList: WizardData, error: { empty: boolean, alreadyExists: boolean }
    setProjectTemplateTaskList: (p: WizardData) => void
}) {
    const setValue = React.useContext(ChildContext);

    useEffect(() => {
        setValue({ title: "Rename List", canLeaveStep: () => true });
    }, [])

    return <div className="wizard-content">
        <br />
        <label className="wizard-content-label ">Enter a new List name</label>
        <TextField
            value={props.projectTemplateTaskList.name}
            error={props.error.empty || props.error.alreadyExists}
            onChange={e => props.setProjectTemplateTaskList({ ...props.projectTemplateTaskList, name: e.currentTarget.value })}
            placeholder='List Name'
            helperText={(props.error.alreadyExists || props.error.empty) && <span style={{ marginLeft: -14 }}>
                <ErrorIcon sx={{ width: 12, height: 12 }} />
                <span style={{ marginLeft: 5, verticalAlign: "text-bottom" }}>{props.error.empty ?
                    "Task list name is required." : "Task list name already exists."}</span>
            </span>}
        />
    </div>
}
