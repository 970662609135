import React from 'react';
import './wizard.scss'
import baseline_arrow_back from '../provisional_icons/baseline-arrow-back.png';
import baseline_close from '../provisional_icons/baseline-close.png';
import Button from '@mui/material/Button';
import { useCustomModal } from '../pages/modals/custom-message-modal';
import { WizardData } from "../models/WizardData";


interface Props {
    title: string;
    canLeaveStep: () => boolean;
}

export const ChildContext = React.createContext<React.Dispatch<React.SetStateAction<Props>>>(() => false);

export function Wizard({ children, childrenData }: { children: JSX.Element[], childrenData: WizardData }) {
    const [position, setPosition] = React.useState(0);
    const [childState, setChildState] = React.useState<Props>({ title: "", canLeaveStep: () => false });
    const { hideModal } = useCustomModal();

    const goBack = () => {
        if (position === 0) {
            hideModal();
        }
        if (position >= 1) {
            setPosition(position - 1);
        }
    }

    const goNext = () => {
        if (childrenData.onErrorNext) {
            const error = childrenData.onErrorNext();
            if (error) return;
        }
        const dataValue = Object.values(childrenData)[position];
        if (dataValue == "" || dataValue.trim() === "") return;
        if (position < children.length - 1) {
            setPosition(position + 1);
        }
    }

    const submit = () => {
        if (position === children.length - 1) {
            const submit = childrenData.onSubmit();
            if (submit !== true) {
                hideModal();
            }
        } else {
            console.error("submit error");
        }
    }

    const closeWiz = () => {
        hideModal();
    }

    return <div className="wizard-container">
        <div className="wizard-out">
            <div onClick={childState.canLeaveStep() ? goBack : () => { }} style={{ cursor: "pointer" }}>
                <img alt='back' src={baseline_arrow_back} />
            </div>
            <div onClick={closeWiz} style={{ cursor: "pointer" }}>
                <img alt='close' src={baseline_close} />
            </div>
        </div>
        <div className="wizard-title">
            <span> {childState.title} </span>
        </div>
        <div style={{ display: "flex", marginLeft: 15, marginRight: 15 }}>
            <ChildContext.Provider value={setChildState}>
                {children[position]}
            </ChildContext.Provider>
        </div>
        <br />
        <Button variant="contained" color="secondary" className="wiz-button" onClick={
            childState.canLeaveStep()
                ? (
                    position === children.length - 1
                        ? submit
                        : goNext
                )
                : () => { }
        }>{
                position === children.length - 1 ? 'Save' : 'Next'
            }
        </Button>
    </div>
}
