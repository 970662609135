
import TextField from "@mui/material/TextField";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useCustomModal } from "../custom-message-modal";
import './remove-entity-modal.scss';
import { StyledBackArrow, StyledCloseIcon } from "../../../components/close-button";
import { ErrorHelper } from "../../../components/error-helper";
import { useEffect, useState } from "react";
import { putApiProjectsById } from "../../../services/template";
import { Project } from "../../../models/Project";
import { ButtonSpinner } from "../../../components/button-spinner";

export function EditStudyIdModal(props: { value: string, project: Project, fetchProject: () => void }) {
    const { hideModal } = useCustomModal();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<{ studyId: string }>();
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        setValue("studyId", props.value);
    }, [])

    const onSubmit: SubmitHandler<{ studyId: string }> = (data) => {
        setSubmitted(true);
        putApiProjectsById(props.project.id, {
            name: props.project.name,
            color: props.project.color,
            studyId: data.studyId,
            submissionTypeId: props.project.submissionTypeId,
            organizationShare: props.project.organizationShare,
            users: [],
            markStyleName: props.project.markStyleName,
            isArchived: props.project.isArchived || false,
        }).then(() => {
            props.fetchProject();
            hideModal();
        })
            .catch(e => {
                setSubmitted(false);
            })
    }

    return <div style={{ width: 420, height: 315, display: "flex", marginTop: 36 }}>
        <div style={{ display: "flex", flexDirection: "column", marginRight: 45, marginLeft: 45 }}>
            <StyledBackArrow onClick={() => hideModal()} />
            <StyledCloseIcon onClick={() => hideModal()} />
            <div style={{ marginTop: 15, fontSize: 25, textAlign: "center" }}>
                Edit Study ID
            </div>
            <span style={{ marginTop: 48 }}>Study ID</span>
            <TextField {...register("studyId", { required: true })} error={!!errors.studyId}
                placeholder="Enter study ID" style={{ width: 328, marginTop: 8 }} />
            {errors.studyId && errors.studyId.type === "required" && <ErrorHelper helperText="Please enter a value." />}
            <Button onClick={handleSubmit(onSubmit)} disabled={submitted}
                color="secondary" variant="contained" style={{ width: "fit-content", alignSelf: "center", marginTop: 36, padding: "7px 32px" }}>
                SAVE CHANGES {submitted && <ButtonSpinner />}
            </Button>
        </div>
    </div>
}
