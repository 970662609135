
//Top, right, bottom, left
import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "./store";

export const defaultPageMargins = {'header': 0, 'right': 0, 'footer': 0, 'left': 0}

const pageMarginsInitialState = {
    pageMargins: defaultPageMargins
}

const pageMargins = createSlice({
    name: 'pageMargins',
    initialState: pageMarginsInitialState,
    reducers: {
        setHeader: (state, action) => {
            state.pageMargins.header = action.payload;
        },
        setRight: (state, action) => {
            state.pageMargins.right = action.payload;
        },
        setFooter: (state, action) => {
            state.pageMargins.footer = action.payload;
        },
        setLeft: (state, action) => {
            state.pageMargins.left = action.payload;
        },
    }
})

export const {setHeader, setRight, setFooter, setLeft} = pageMargins.actions

export const pageMarginsSelector = (state: RootState) => state.pageMargins.pageMargins

export default pageMargins.reducer