import React, {useEffect, useRef, useState} from 'react';
import {useCustomModal} from "./custom-message-modal";
import { statusList } from '../../constants';

//This asks the user to choose a match category for the mark they just added.
const ChangeStatusModal = (props : { initialTaskStatus: string, onSelection: (status: string) => void }) => {
    const { hideModal } = useCustomModal();
    const modalRef = useRef<HTMLDivElement>(null);
    const [checked, setChecked] = useState(props.initialTaskStatus || 'NOT_STARTED')
    const onSubmit = () => {
     props.onSelection(checked)   
     hideModal()
    }


    return <div ref={modalRef} style={{position: "relative",
        width: 350, display: "flex", alignItems: 'center',
        flexDirection: "column", justifyContent: "center", padding: "20px 30px"
    }}>
        <h4>Change Status</h4>
        <hr />
        {(Object.keys(statusList)).map((val, index) => {
            return <div>
            <input key={index} id={val} type="radio" name="status" checked={checked === val} onChange={(e) => setChecked(val)} />
            <label htmlFor={val}>{val}</label>
            </div>
        })}
        <div>
            <button className='change-status-btn' onClick={() => hideModal()}>Cancel</button>
            <button className='change-status-btn success' onClick={() => onSubmit()}>Submit</button>

        </div>

        
    </div>


};

export default ChangeStatusModal;
