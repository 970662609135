import React, { useEffect, useState } from "react";
import './admin-wizard.scss';
import { ChildContext, Wizard } from "../../../components/wizard";
import { TextField } from "@mui/material";
import { useAdminService } from "../../../contexts/super-admin-context";
import { AccountWizard } from "./add-account-wizard";
import { Account } from "../../../models/Account";

export function RenameAccountWizard(oldAccountInfo: Account) {
    const { editAccount } = useAdminService();
    const onSubmit = async () => {
        await editAccount(oldAccountInfo.id, oldAccountInfo, currentAccount.name)
    }

    const [currentAccount, setCurrentAccount] = useState<AccountWizard>({
        name: "",
        onSubmit: () => { }
    })

    useEffect(() => {
        setCurrentAccount({ ...currentAccount, onSubmit: onSubmit })
    }, [currentAccount.name])

    const wizardArray = [<RenameAccount key={0} account={currentAccount} setAccount={setCurrentAccount} />];

    return <div className="wizard-dialog" >
        <Wizard childrenData={currentAccount}>
            {wizardArray}
        </Wizard>
    </div>
}

function RenameAccount(props: { account: AccountWizard, setAccount: (p: AccountWizard) => void }) {
    const setValue = React.useContext(ChildContext);

    useEffect(() => {
        setValue({ title: "Rename Account", canLeaveStep: () => true });
    }, [])

    return <div className="wizard-content">
        <br />
        <label className="wizard-content-label ">Enter a new Account name</label>
        <TextField
            value={props.account.name} onChange={e => props.setAccount({ ...props.account, name: e.currentTarget.value })}
            placeholder='Account name'
        />
    </div>
}
