import { TaskList } from "../../models/TaskList";
import React, { useEffect, useRef, useState } from "react";
import { useCustomModal } from "../../pages/modals/custom-message-modal";
import { useEventHandler } from "../../hooks/handle-events";
import { useTheme } from "@mui/material/styles";
import { useImportExcel } from "../../hooks/import-excel";
import { Row } from "read-excel-file";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { ProjectTemplateRenameListWizard } from "../../pages/user/wizards/project-template-rename-list";
import { ProjectTemplateDuplicatedListWizard } from "../../pages/user/wizards/project-template-duplicated-list";
import { RemoveTemplateListModal } from "../../pages/modals/modal-content/remove-template-list-modal";
import { MoreActionsComponent } from "../more-actions-component";
import Button from "@mui/material/Button/Button";
import item_content_add from "../../provisional_icons/icon-content-add.png";
import icon_content_add_active from "../../provisional_icons/icon-content-add-active.png";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { projectFiltersSelector } from "../../redux/project-filters";
import { filterTasks } from "../../services/filter-service";
import { useAuthService } from "../../contexts/auth-context";
import { dueDateList, DueDateType, statusList, StatusType } from "../../constants";
import { GroupTable } from "./group-table";
import { ProjectListTable } from "./project-list-table";
import {
    cfvByTaskListSelectorAndCustomFieldIdSelector,
    customFieldsSelector, loadCustomFieldsValues,
} from "../../redux/custom-fields";
import { getApiCustomFieldsValuesTaskListById } from "../../services/custom.fields";
import { ImportTasksExcel } from "../../pages/modals/modal-content/import-tasks-excel";
import { ImportCustomFieldsExcel } from "../../pages/modals/modal-content/import-custom-fields-excel";
import {selectedTasksSelector, selectTasks, setShowToolbarAfterDeletion} from "../../redux/action-bar";
import {Checkbox, FormControlLabel, Stack} from "@mui/material";
import taskLists from "../../redux/taskLists";
import {Task} from "../../models/Task";

enum TaskSelection {
    SELECT_PAGINATED_ALL = 'SELECT_PAGINATED_ALL',
    SELECT_OVERALL = 'SELECT_OVERALL'
}

export function TaskLists(p: { projectName: string, projectId: number, taskLists: TaskList[], fullList: TaskList, fetchProject: (key?: string) => void,
    isTemplate: boolean, shortView: boolean }) {
    
    const filters = useAppSelector((state) => projectFiltersSelector(state, p.projectId));
    const auth = useAuthService();
    const displayedTasks = (taskList: TaskList) => (p.isTemplate ? taskList.tasks || [] :
        filterTasks(taskList.tasks || [], filters, auth.loginInfo?.tenant?.user!));
    const remainingLists = p.taskLists.filter(tl => !tl.tasks?.length || !!filterTasks(tl.tasks || [], filters, auth.loginInfo?.tenant?.user!).length);

    return <>
        {
            p.taskLists?.map((taskList) => <div key={taskList.id}>
                    <ProjectList
                        key={taskList.id}
                        taskList={taskList}
                        fullList={p.fullList}
                        refreshList={p.fetchProject}
                        projectName={p.projectName}
                        taskListsCount={p.taskLists?.length!}
                        shortView={p.shortView}
                        isTemplate={p.isTemplate}
                        projectId={p.projectId}
                    />
                    {!!displayedTasks(taskList).length && taskList.id !== remainingLists![remainingLists?.length! - 1].id &&
                        <div className="single-project-template-list-separator" style={{ marginBottom: p.shortView ? 16: ""}} />}
                </div>
            )
        }
    </>
}

function ProjectList(p: { taskList: TaskList, fullList: TaskList, refreshList: (key?: string) => void, projectName: string, projectId: number,
    taskListsCount: number, shortView: boolean, isTemplate: boolean }) {

    const theme = useTheme();
    const auth = useAuthService();
    const dispatch = useAppDispatch();
    const { acceptedExcelExtensions, importExcel } = useImportExcel();
    const { showModal } = useCustomModal();
    const { onMouseEventHandler, onLeaveMouseEventHandler, onMouseDisabledEventHandler } = useEventHandler();
    const filters = useAppSelector((state) => projectFiltersSelector(state, p.projectId));
    const customFields = useAppSelector((state) => customFieldsSelector(state));
    const inputFileForTasks = useRef<HTMLInputElement | null>(null);
    const inputFileForCustomFields = useRef<HTMLInputElement | null>(null);
    const [newTask, setNewTask] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isPaginatedAllSelected, setIsPaginatedAllSelected] = useState<boolean>();
    const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false)
    const selectedTasks: Task[] = useAppSelector((state) => selectedTasksSelector(state));
    const batchCustomField = customFields.find(cf => cf.name === "Batch");
    const batchCustomFieldsValues = useAppSelector((state) =>
        cfvByTaskListSelectorAndCustomFieldIdSelector(state, p.taskList?.id, batchCustomField?.id || 3));
    const batchValues = Array.from(new Set(batchCustomFieldsValues.map(cfv => cfv.valueString))).sort()

    useEffect(() => {
        getApiCustomFieldsValuesTaskListById(p.taskList.id).
            then(cfv => dispatch(loadCustomFieldsValues({
            taskListId: p.taskList.id,
            customFieldsValues: cfv
        })))
    }, []);


    useEffect(() => {
        if (!newTask && p.taskList.tasks?.length === 0) {
            setNewTask(true);
        }
    });


    const  taskSelectionHandler = (selectionType: TaskSelection) => {
        if(selectionType === TaskSelection.SELECT_PAGINATED_ALL){
            setIsPaginatedAllSelected(prev=>!prev);
            setIsSelectedAll(false)
            dispatch(selectTasks(!isPaginatedAllSelected ? p.taskList.tasks : []));
        }else{
            setIsSelectedAll(prev => !prev);
            setIsPaginatedAllSelected(false)
            dispatch(selectTasks(!isSelectedAll ? p.fullList.tasks : []));
        }
    }

    const onImportTasks = async (rows: Row[]) => {
        await showModal(ImportTasksExcel, { cells: rows as Row[], taskListId: p.taskList.id, projectId: p.projectId,
            taskListName: p.taskList.name, isTemplate: p.isTemplate, refreshList: () => p.refreshList()})
    };

    const onImportCustomFields = async (rows: Row[]) => {
        await showModal(ImportCustomFieldsExcel, { cells: rows as Row[], taskListId: p.taskList.id, taskListName: p.taskList.name,
            projectId: p.projectId, type: "TASKS", tasks: p.taskList.tasks!,
            refreshList: () => p.refreshList() })
    };

    const menuItems = auth.hasPMRoleAccess() ? [
        <MenuItem
            key={0}
            onMouseEnter={onMouseEventHandler}
            onMouseLeave={onLeaveMouseEventHandler}
            onClick={() => {
                showModal(ProjectTemplateRenameListWizard,
                    { taskList: p.taskList, refreshList: p.refreshList });
                setAnchorEl(null);
            }}>
            Rename list
        </MenuItem>,
        <MenuItem key={1}
                  onMouseEnter={onMouseEventHandler}
                  onMouseLeave={onLeaveMouseEventHandler}
                  onClick={() => {
                    inputFileForTasks.current?.click();
                  }}>
            <input type="file" id='file' accept={acceptedExcelExtensions}
                   onChange={async (e) => {
                       setAnchorEl(null);
                       await importExcel(e.target.files![0], onImportTasks);
                       inputFileForTasks.current = null;
                   }} ref={inputFileForTasks} style={{ display: "none" }} />
            Import excel for tasks
        </MenuItem>,
        !p.isTemplate ? <MenuItem key={4}
        onMouseEnter={onMouseEventHandler}
        onMouseLeave={onLeaveMouseEventHandler}
        onClick={() => {
            inputFileForCustomFields.current?.click();
        }}>
        <input type="file" id='file' accept={acceptedExcelExtensions}
            onChange={async (e) => {
                setAnchorEl(null);
                await importExcel(e.target.files![0], onImportCustomFields);
                inputFileForCustomFields.current = null;
            }} ref={inputFileForCustomFields} style={{ display: "none" }} />
          Import excel for custom fields
        </MenuItem> : <></>,
        <MenuItem
            key={2}
            onMouseEnter={onMouseEventHandler}
            onMouseLeave={onLeaveMouseEventHandler}
            onClick={() => {
                showModal(ProjectTemplateDuplicatedListWizard,
                    { projectId: p.taskList.projectId, taskListId: p.taskList.id, refreshList: p.refreshList, isTemplate: p.isTemplate });
                setAnchorEl(null);
            }}>
            Duplicate list
        </MenuItem>,
        <MenuItem
            key={3}
            onMouseEnter={(p.taskListsCount > 1) ? onMouseEventHandler : onMouseDisabledEventHandler}
            onMouseLeave={onLeaveMouseEventHandler}
            onClick={() => {
                if (p.taskListsCount > 1) {
                    showModal(RemoveTemplateListModal, { taskList: p.taskList }).then(() => p.refreshList());
                    setAnchorEl(null);
                }
            }}>
            <span style={{ color: (p.taskListsCount > 1) ? 'red' : "gray" }}>Delete list</span>
        </MenuItem>

    ] : []

    const displayedTasks = (p.isTemplate ? p.taskList.tasks || [] :
        filterTasks(p.taskList.tasks || [], filters, auth.loginInfo?.tenant?.user!));

    return ((displayedTasks && displayedTasks.length > 0) || newTask) ? <div key={p.taskList.id} style={{ margin:"0 24px" }}>
        <div className={p.shortView ? "single-template-list-header-short-view" : "single-template-list-header"}>
            <span className="single-template-list-name">
                {p.taskList.name}
            </span>
            <div style={{alignItems: "right"}}>


                {auth.hasPMRoleAccess() && <MoreActionsComponent
                    key={0}
                    id={p.taskList.id}
                    menuItems={menuItems}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    menuHorizontalPosition="right"
                />}
            </div>
        </div>
        <Stack direction="row" alignItems="center" spacing="1em">
        {auth.hasPMRoleAccess() && <Button disabled={newTask} onClick={() => {
            setNewTask(true);
            dispatch(setShowToolbarAfterDeletion(false));
        }}
                style={{ color: p.taskList.tasks?.some(task => task.name === "") ? "gray" : theme.palette.secondary.main }}>
            <img alt="add" src={newTask ? item_content_add : icon_content_add_active} />
            <span style={{ color: newTask ? "gray" : theme.palette.secondary.main }}>ADD TASK</span>
        </Button>}
            <FormControlLabel control={
                <Checkbox checked={selectedTasks.length > 0 && isPaginatedAllSelected} value={isPaginatedAllSelected} onChange={()=>taskSelectionHandler(TaskSelection.SELECT_PAGINATED_ALL)}/>
            } label="Select All Page Tasks" />
            <FormControlLabel
                control={
                <Checkbox checked={selectedTasks.length > 0 && isSelectedAll} value={isSelectedAll}  onChange={()=>taskSelectionHandler(TaskSelection.SELECT_OVERALL)}/>
            } label="Select All Tasks" />
        </Stack>
        {filters.groupBy !== undefined ? <div style={{ marginTop: -16 }}>
                {filters.groupBy === "Status" && Object.keys(statusList).map(status => {
                        const statusTaskList = displayedTasks.filter(task => task.status === status);
                        const title = statusList[status as StatusType].name.toLowerCase();
                        return statusTaskList.length > 0 || (filters.groupBy === "Status" && newTask && status === "NOT_STARTED") ?
                            <GroupTable key={`groupTable-${title}`} title={title[0].toUpperCase() + title.slice(1)}
                                        color={statusList[status as StatusType].color} taskList={p.taskList}
                                        displayedTasks={statusTaskList} newTask={newTask} setNewTask={setNewTask}
                                        refreshList={p.refreshList} isTemplate={p.isTemplate} projectId={p.projectId}
                                        projectName={p.projectName} />
                            :
                            <div key={status}></div>
                    }
                )}
                {filters.groupBy === "Due date" && Object.keys(dueDateList).map(dd => {
                        const dueDateTaskList = displayedTasks.filter(task => dueDateList[dd as DueDateType].condition(task.dueDate));
                        return dueDateTaskList.length > 0 || (filters.groupBy === "Due date" && newTask && dd === "NO_DUE_DATE") ?
                            <GroupTable key={dueDateList[dd as DueDateType].name} title={dueDateList[dd as DueDateType].name}
                                        color={dueDateList[dd as DueDateType].color} taskList={p.taskList}
                                        displayedTasks={dueDateTaskList} newTask={newTask} setNewTask={setNewTask}
                                        refreshList={p.refreshList} isTemplate={p.isTemplate} projectId={p.projectId}
                                        projectName={p.projectName} />
                            :
                            <div key={dueDateList[dd as DueDateType].name}></div>
                    }
                )}
                {filters.groupBy === "Batch" && batchValues && batchValues.map(batch => {
                    //TODO filter by custom field batch value
                        const dueDateTaskList = displayedTasks.filter(task =>
                            batchCustomFieldsValues.find(cfv => cfv.taskId === task.id && cfv.valueString === batch));
                        return dueDateTaskList.length === 0 || !batch ?
                            <div key={batch}></div>
                            :
                            <GroupTable key={batch} title={batch} color="#0277BD" taskList={p.taskList}
                                        displayedTasks={dueDateTaskList} newTask={newTask} setNewTask={setNewTask}
                                        refreshList={p.refreshList} isTemplate={p.isTemplate} projectId={p.projectId}
                                        projectName={p.projectName} />
                    }
                )}
                {filters.groupBy === "Batch" &&
                    (displayedTasks.filter(task => !batchCustomFieldsValues.find(cfv => cfv.taskId === task.id)).length > 0 || newTask) &&
                    <GroupTable title="Undefined" color="#58595B" taskList={p.taskList}
                                                            displayedTasks={displayedTasks.filter(task =>
                                                                !batchCustomFieldsValues.find(cfv => cfv.taskId === task.id))}
                                                            newTask={newTask} setNewTask={setNewTask}
                                                            refreshList={p.refreshList} isTemplate={p.isTemplate} projectId={p.projectId}
                                                            projectName={p.projectName} />}
            </div>
            :
            <ProjectListTable key="tableList-ungrouped" taskList={p.taskList} displayedTasks={displayedTasks} newTask={newTask}
                              setNewTask={setNewTask} refreshList={p.refreshList} isTemplate={p.isTemplate}
                              projectId={p.projectId} projectName={p.projectName} />
        }
    </div> : <></>
}

