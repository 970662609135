import {useCustomModal} from "../custom-message-modal";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import {ButtonSpinner} from "../../../components/button-spinner";
import TextField from "@mui/material/TextField";
import baseline_close from "../../../provisional_icons/baseline-close.png";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";

export function UpdateFileTagModal( props: {projectId?: number, fileName: string, fileId?: string, tag?: string, updateTag: (status: string | undefined) => void}) {
    const [radioValue, setRadioValue] = React.useState('retain');
    const [tagValue, setTagValue] = useState("     ");
    const { hideModal } = useCustomModal();
    const [loading, setLoading] = useState(false);
    const [modifyTagDisabled, setModifyTagDisabled] = React.useState(true);

    let tag = props.tag;
    if(tag === undefined) {
        tag = "";
    }
    else {
        tag = tag.toString();
    }

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setRadioValue(value);
        if(value === "retain") {
            // @ts-ignore
            setTagValue(tag);
            setModifyTagDisabled(true);
        }
        else if(value === "save") {
            setTagValue(" ");
            setModifyTagDisabled(true);
        }
        else if(value === "add") {
            // @ts-ignore
            setTagValue(tag);
            setModifyTagDisabled(false);
        }
    };

    const onSubmit = async () => {
        if(radioValue === "save") {
            props.updateTag(" ");
        }
        else if(radioValue === "retain") {
            props.updateTag(tag);
        }
        else {
            props.updateTag(tagValue);
        }
        hideModal();
    }

    // . : + = @ _ / -
    const pattern = /^[a-zA-Z0-9.:=@_\/\-\s]+$/

    const error = tagValue.match(pattern) === null && !modifyTagDisabled;

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img alt="close" src={baseline_close} className="remove-entity-close-button" onClick={() => loading ? {} : hideModal()} />

            <span className="remove-entity-title" style={{ color: "#223250", textAlign: "center"}}>Add or update a document tag and save
            </span>
            <br></br>
            <form onSubmit={onSubmit}>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="retain"
                    name="radio-buttons-group"
                    value={radioValue}
                    onChange={handleRadioChange}
                >
                    <TextField
                        disabled={modifyTagDisabled}
                        onChange={(e) => setTagValue(e.target.value)}
                        value={tagValue==="     " ? tag: tagValue}
                        defaultValue={tag}
                        variant="outlined"
                        id="outlined-error-helper-text"
                        inputProps={{ style: { fontSize: 17 } }}
                        style={{ width: "100%" }}
                        helperText={
                            error ? "Only alphanumeric and . : + = @ _ / - allowed!" : ""}
                        error={error}
                    />
                    <FormControlLabel value="retain" control={<Radio />} label="Retain Current Tag" />
                    <FormControlLabel value="add" control={<Radio />} label="Add or Update Tag" />
                    <FormControlLabel value="save" control={<Radio />} label="Save Without Tag" />
                </RadioGroup>
            <div className="remove-entity-button-list" style={{ marginTop: 20 }} >
                <Button disabled={loading} color="secondary" variant="outlined" onClick={hideModal}>CANCEL</Button>
                <Button disabled={loading || error || (tagValue === tag && radioValue ==="add") } onClick={onSubmit} color="secondary" variant="contained">
                   Save {loading && <ButtonSpinner />}
                </Button>
            </div>
            </form>
        </div>
    </div>
}
