import Button from "@mui/material/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import baseline_arrow_back from "../../provisional_icons/baseline-arrow-back.png";
import './single-project-template.scss';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import assign_icon from '../../provisional_icons/assign-icon.png';
import './task-details.scss';
import baseline_event_available from '../../provisional_icons/baseline-event_available.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MoreActionsComponent } from "../../components/more-actions-component";
import React, { useEffect, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useEventHandler } from "../../hooks/handle-events";
import { useTheme } from "@mui/material/styles";
import {
    deleteApiTasksById, deleteApiTasksUnlinkFilesToTask,
    getApiTasksById,
    getApiTasksByIdWorklogSummary,
    getApiTasksByTaskIdWorklogs,
    postApiTasksAssign,
    postApiTasksBulkUndoDelete,
    postApiTasksCreate,
    postApiTasksWorklogByIdTimerStart,
    postApiTasksWorklogByIdTimerStop,
    postApiTasksWorklogCreate,
    putApiTasksById,
    putApiTaskTimeTrackedByUserId
} from "../../services/task";
import { Task } from "../../models/Task";
import TextField from "@mui/material/TextField";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { BaseTask } from "../../models/BaseTask";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { SubTask } from "../../models/SubTask";
import { DeletionSnackBar } from "../../components/deletion-snackbar";
import { useCustomModal } from "../modals/custom-message-modal";
import { RemoveSubtaskListModal } from "./wizards/project-remove-subtask-list-modal";
import { projectsSelector, selectProject, updateTask } from "../../redux/projects";
import { projectTemplatesSelector } from "../../redux/projectTemplates";
import { convertMinutesToHours, getProjectByTaskId } from "../../services/utils";
import { StatusMenuComponent } from "../../components/status-menu-component";
import { blueIconColor, statusList, StatusType } from "../../constants";
import { CustomDatePicker } from "../../components/custom-date-picker";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from "moment";
import { UsersSelect } from "../../components/users-select";
import { filteredUsersSelector, usersSelector } from "../../redux/users";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Comments } from "../../components/project/comments";
import { Project } from "../../models/Project";
import { DuplicateSubtaskListModal } from "./wizards/project-duplicate-subtask-list-modal";
import { useImportExcel } from "../../hooks/import-excel";
import { Row } from "read-excel-file";
import { useAuthService } from "../../contexts/auth-context";
import { Worklog } from "../../models/Worklog";
import { CustomFieldComponent } from "../../components/custom-field-component";
import {
    taskTimeTrackingSelector,
    updateTaskTimeTracking
} from "../../redux/tasksTimeTracking";
import { TimeTrackingComponent } from "../../components/project/time-tracking-component";
import { showSnackbar } from "../../redux/snackbar";
import { ButtonSpinner } from "../../components/button-spinner";
import { ImportTasksExcel } from "../modals/modal-content/import-tasks-excel";
import { setDeletedTasks, deletedTasksSelector } from "../../redux/deleted-tasks";
import { Box, useMediaQuery } from "@mui/material";
import { ImportCustomFieldsExcel } from "../modals/modal-content/import-custom-fields-excel";
import { showToolbarAfterDeletionSelector, setShowToolbarAfterDeletion } from "../../redux/action-bar";
import { Link } from "@mui/material";
import { useDispatch } from "react-redux";
import {DataGridPro, GridColDef, GridRowsProp} from "@mui/x-data-grid-pro";
import baseline_delete from "../../provisional_icons/baseline-delete.png";
import {LinkFilesToTaskModalGivenTask} from "../modals/modal-content/link-files-to-task-modal-given-task";
import {getApiFilesWithURLByTaskId} from "../../services/files";
import {FileState} from "../../models/FileState";
import { putApiCustomFieldsSorting } from "../../services/custom.fields";
import { DragIndicator } from "@mui/icons-material";
import {navToDocs} from "./projects/single-project";

export type GroupByUserWorklog = {
    usersWorklog: Worklog & { worklogs: Worklog[] }
}

enum TimerState {
    NOT_INITIALIZED,
    START,
    STOP
}

export function TaskDetails() {

    const theme = useTheme();
    const nav = useNavigate();
    const params = useParams();
    const location = useLocation();
    const smallWindow = useMediaQuery('(max-width:1750px)');
    const dispatch = useAppDispatch();
    const projects = useAppSelector((state) => projectsSelector(state));
    const templates = useAppSelector((state) => projectTemplatesSelector(state));
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [anchorElSubtask, setAnchorElSubtask] = useState<HTMLElement | null>(null);
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const [taskDetails, setTaskDetails] = useState<Task>();
    const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
    const [taskFiles, setTaskFiles] = useState<FileState[]>([]);
    const [subtaskField, setSubtaskField] = useState("");
    const [selectedSubtaskId, setSelectedSubtaskId] = useState<number>();
    const [worklogs, setWorklogs] = useState<GroupByUserWorklog[]>([]);
    const [currentWorklog, setCurrentWorklog] = useState<Worklog>();
    const [timerState, setTimerState] = useState<TimerState>();
    const [timing, setTiming] = useState<string>("00:00");
    const { showModal } = useCustomModal();
    const { acceptedExcelExtensions, importExcel } = useImportExcel();
    const showToolbarAfterDeletion = useAppSelector((state) => showToolbarAfterDeletionSelector(state));
    const auth = useAuthService();
    const inputFile = useRef<HTMLInputElement | null>(null);
    const { taskId, subtaskId } = params;
    const id = subtaskId ? +subtaskId : +taskId!;
    let project = getProjectByTaskId(projects, +taskId!);
    let isTemplate = false;
    if (project === undefined) {
        project = getProjectByTaskId(templates, +taskId!);
        isTemplate = true;
    }
    const users = useAppSelector((state) => isTemplate || (project as Project).organizationShare ? usersSelector(state, true)
        : filteredUsersSelector(state, (project as Project)!.users?.map(u => u.id) || []));
    const taskTimeTrackingSummary = useAppSelector((state) => taskTimeTrackingSelector(state, id));
    const loadedWorklogs = useRef<boolean | null>(null);
    const deletedTasksIds = useAppSelector((state) => deletedTasksSelector(state));
    const inputFileForCustomFields = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        loadedWorklogs.current = null;
        setTaskDetails(undefined);
        setCurrentWorklog(undefined);
        fetchTask();
    }, [taskId, subtaskId])

    useEffect(() => {
        fetchTask();
        if (project) {
            dispatch(selectProject(project.id));
        }
    }, [project]);

    useEffect(() => {
        if (deletedTasksIds.length === 0) {
            fetchTask(true);
        }
    }, [deletedTasksIds])

    useEffect(() => {
        if (loadedWorklogs.current === null && taskDetails && users.length > 0) {
            loadedWorklogs.current = true;
            fetchWorklogs();
        }
    }, [taskDetails, users])

    useEffect(() => {
        const id = setInterval(() => {
            if (timerState === TimerState.STOP && currentWorklog?.timerStart) {
                updateTiming(currentWorklog);
            }
        }, 60000);
        return () => clearInterval(id);
    }, [currentWorklog, timerState])

    const updateTiming = (cw: Worklog) => {
        const ms = moment().diff(moment(cw?.timerStart), 'milliseconds') + 2000;
        setTiming(moment(ms).format("HH:mm"));
    }

    const fetchTask = (isUpdate: boolean = false) => {
        dispatch(showProgressLine());
        getApiTasksById(subtaskId ? +subtaskId : +taskId!)
            .then(res => {
                if (isUpdate && project) {
                    dispatch(updateTask({
                        projectId: project!.id,
                        taskListId: res.taskListId,
                        task: res
                    }));
                }
                setTaskDetails(res);
                setSubtaskField("");
                getApiFilesWithURLByTaskId(res.id).then(files => {
                    setTaskFiles(files)
                })
                setDisplayedTask(res.subtasks as any)
                dispatch(hideProgressLine());
            })
            .catch(() => dispatch(hideProgressLine()))
    }

    const [displayedTasks, setDisplayedTask] = useState<Task[]>([]);
    const handleTaskReorder = (newTasks: any) => {
        // Update the state with the newly reordered tasks
        setDisplayedTask(newTasks);
    };

    const fetchWorklogs = () => {
        getApiTasksByTaskIdWorklogs(id).then(worklogs => {
            dispatch(showProgressLine());
            const currentWorklog = worklogs.filter(w => (w.userId === auth.loginInfo?.tenant?.user?.id) && (
                moment(w.date).format("MM/DD/YYYY") === moment(new Date()).format("MM/DD/YYYY")
            )).pop();
            if (currentWorklog?.timerStart) {
                updateTiming(currentWorklog);
            }
            setCurrentWorklog({ ...currentWorklog! });
            setTimerState(currentWorklog?.timerStart ? TimerState.STOP : TimerState.START);
            const groupedWorklogs: GroupByUserWorklog[] = [];
            users.forEach(u => {
                const userWorklogs = worklogs.filter(w => w.userId === u.id);
                if (userWorklogs.length) {
                    let mins = 0;
                    userWorklogs.forEach(uw => mins = mins + uw.minutes);
                    const obj: GroupByUserWorklog = {
                        usersWorklog: {
                            ...userWorklogs[userWorklogs.length - 1], minutes: mins, worklogs: userWorklogs
                        }
                    };
                    groupedWorklogs.push(obj)
                }
            })
            setWorklogs([...groupedWorklogs]);
            dispatch(hideProgressLine());
        });
        getApiTasksByIdWorklogSummary(id!)
            .then(summary => dispatch(updateTaskTimeTracking(summary)));
    }

    const getMyWorkLogs = () => worklogs.filter(w => w.usersWorklog.userId === auth.loginInfo?.tenant?.user?.id).pop()?.usersWorklog.worklogs ?? [];

    const getTaskList = () => project?.taskLists!.find(taskList => taskList.id === taskDetails?.taskListId)

    const getParentTask = () => getTaskList()!.tasks!.find(t => t.id === taskDetails?.parentTaskId)

    const createSubTask = async () => {
        await postApiTasksCreate({
            name: subtaskField, status: "NOT_STARTED", parentTaskId: taskDetails?.id,
            taskListId: taskDetails?.taskListId!, timeTrackedByUserId: "",
        })
            .then(() => fetchTask(true));
    }

    const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        dispatch(setShowToolbarAfterDeletion(false));
        if (e.key === "Enter" && subtaskField !== "") {
            createSubTask();
        }
    }

    const onClickDismissDeletion = async () => {
        dispatch(setShowToolbarAfterDeletion(false));
    }

    const onClickUndoDeletion = async () => {
            await postApiTasksBulkUndoDelete([selectedSubtaskId!, ...deletedTasksIds]).then(() => {
            dispatch(setShowToolbarAfterDeletion(false));
            fetchTask(true);
        });
    }

    const editTask = <T extends BaseTask, K extends keyof T>(taskKey: K, value: T[K]) => {
        const baseTask = { ...taskDetails } as BaseTask
        putApiTasksById(id, { ...baseTask, [taskKey]: value }).then(() => fetchTask(true));
    }

    const onImport = async (rows: Row[]) => {
        await showModal(ImportTasksExcel, {
            cells: rows as Row[], taskListId: taskDetails?.taskListId!, taskListName: getTaskList()!.name,
            projectId: project?.id!, isTemplate: isTemplate, parentTask: taskDetails, refreshList: () => fetchTask()
        })
    };

    const startTimerOnClick = () => {
        dispatch(setShowToolbarAfterDeletion(false));
        if (timerState === TimerState.NOT_INITIALIZED) {
            return;
        }
        if (!currentWorklog?.timerStart) {
            putApiTaskTimeTrackedByUserId(id!, { userId: auth.loginInfo?.tenant?.user?.id, isTimeTrackedByUserId: true })
                .then(() => {
                    fetchTask(true);
                });
            setTimerState(TimerState.NOT_INITIALIZED);
            if (currentWorklog?.id === undefined) {
                postApiTasksWorklogCreate({
                    date: moment(new Date()).format("YYYY-MM-DD 00:00:00"), minutes: 0, taskId: taskDetails?.id!
                }).then(newWorkLog => {
                    postApiTasksWorklogByIdTimerStart(newWorkLog.id)
                        .then(() => {
                            setTimerState(TimerState.START);
                            fetchWorklogs();
                        })
                        .catch(() => {
                            setTimerState(TimerState.STOP);
                            dispatch(showSnackbar({ message: "Error starting time track!", type: "error" }));
                            fetchWorklogs();
                        });
                }).catch(() => {
                    setTimerState(TimerState.STOP);
                    dispatch(showSnackbar({ message: "Error starting time track!", type: "error" }))
                });
            } else {
                postApiTasksWorklogByIdTimerStart(currentWorklog.id)
                    .then(() => fetchWorklogs())
                    .catch(() => {
                        setTimerState(TimerState.STOP);
                        dispatch(showSnackbar({ message: "Error starting time track!", type: "error" }))
                    });
            }
        } else {
            putApiTaskTimeTrackedByUserId(id!, { userId: auth.loginInfo?.tenant?.user?.id, isTimeTrackedByUserId: false })
                .then(() => {
                    fetchTask(true);
                });
            setTimerState(TimerState.NOT_INITIALIZED);
            postApiTasksWorklogByIdTimerStop(currentWorklog.id)
                .then(() => {
                    setTimerState(TimerState.START);
                    fetchWorklogs();
                    getApiTasksByIdWorklogSummary(taskDetails!.id)
                        .then(summary => dispatch(updateTaskTimeTracking(summary)))
                })
                .catch(() => {
                    setTimerState(TimerState.STOP);
                    dispatch(showSnackbar({ message: "Error stopping time track!", type: "error" }))
                });
        }

    }

    const onImportCustomFields = async (rows: Row[]) => {
        await showModal(ImportCustomFieldsExcel, { cells: rows as Row[], taskListId: getTaskList()?.id!,
            taskListName: getTaskList()?.name!, projectId: project?.id!, type: "SUBTASKS", tasks: taskDetails?.subtasks!,
            refreshList: () => {} })
    };

    const menuItems = [
        <MenuItem
            key={0}
            onMouseEnter={onMouseEventHandler}
            onMouseLeave={onLeaveMouseEventHandler}
            onClick={async () => {
                const controller = new AbortController();
                deleteApiTasksById(id, controller.signal).then(() => {
                    nav(-1);
                    dispatch(setDeletedTasks(id));
                });
            }}>
            <span style={{ color: theme.palette.error.main, fontSize: 14 }}>Delete {!!taskDetails?.parentTaskId ? 'subtask': 'task'}</span>
        </MenuItem>
    ]
    const menuItemsSubTasks = [
        !isTemplate ? <MenuItem key={0}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}
                                onClick={() => {
                                    inputFile.current?.click();
                                }}>
            <input type="file" id='file' accept={acceptedExcelExtensions}
                   onChange={async (e) => {
                       setAnchorElSubtask(null);
                       await importExcel(e.target.files![0], onImport);
                       inputFile.current = null;
                   }} ref={inputFile} style={{ display: "none" }} />
            <span style={{ fontSize: 15 }}>Import excel file</span>
        </MenuItem> : <></>,
        !isTemplate ? <MenuItem key={1}
        onMouseEnter={onMouseEventHandler}
        onMouseLeave={onLeaveMouseEventHandler}
        onClick={() => {
            inputFileForCustomFields.current?.click();
        }}>
        <input type="file" id='file' accept={acceptedExcelExtensions}
            onChange={async (e) => {
                setAnchorEl(null);
                await importExcel(e.target.files![0], onImportCustomFields);
                inputFileForCustomFields.current = null;
            }} ref={inputFileForCustomFields} style={{ display: "none" }} />
          Import excel for custom fields
        </MenuItem> : <></>,
        !isTemplate ? <MenuItem
            key={2}
            onMouseEnter={onMouseEventHandler}
            onMouseLeave={onLeaveMouseEventHandler}
            onClick={async () => {
                if (taskDetails?.subtasks?.length) {
                    showModal(DuplicateSubtaskListModal, {
                        subtaskList: taskDetails.subtasks!, allLists: project?.taskLists!,
                        projectName: project?.name!
                    }).then(() => fetchTask());
                    setAnchorElSubtask(null);
                }
            }}>
            <span style={{ fontSize: 15, color: taskDetails?.subtasks?.length ? theme.palette.primary.main : "gray" }}>
                Duplicate subtasks list
            </span>
        </MenuItem> : <></>,
        <MenuItem
            key={3}
            onMouseEnter={onMouseEventHandler}
            onMouseLeave={onLeaveMouseEventHandler}
            onClick={async () => {
                if (taskDetails?.subtasks?.length) {
                    const arr = taskDetails?.subtasks?.map(subtask => subtask.id);
                    showModal(RemoveSubtaskListModal, { ids: arr! }).then(() => fetchTask());
                    setAnchorElSubtask(null);
                }
            }}>
            <span style={{ fontSize: 15, color: taskDetails?.subtasks?.length ? theme.palette.error.main : "gray" }}>
                Delete subtasks list
            </span>
        </MenuItem>
    ]

    const filteredUsers = () => users.filter(u => taskDetails && taskDetails.assignedUserId &&
        taskDetails.assignedUserId.split(",").includes(u.id.toString()))

    const goToDocs = (publicUrls: any[]) => {
        if(publicUrls) {
            const taskIdNum = taskId ? parseInt(taskId) : undefined
            const status = (taskDetails as Task).status
            navToDocs(publicUrls, taskFiles, showModal, nav, project!.id, taskIdNum, status)
        }
    };

    function getUrlsFromSelectedRows() {
        let urlList : string[] = [];

        taskFiles.forEach(file => {
            if (selectedFiles.includes(file.id)) {
                urlList.push(file.url!);
            }
        })

        return urlList;
    }

    let columns: GridColDef[]
    if (auth.hasPMRoleAccess()) {
        columns = [
            {
                field: 'col1', headerName: 'File Name', flex: 15, cellClassName: 'cell-left-border', headerClassName: 'cell-left-border'
            },
            {
                field: 'col2', headerName: '',flex:3,  renderCell: params => {
                    return  <Button style={{backgroundColor: "inherit", textDecoration: 'underline', color: "blue", border: "none"}} sx={{fontSize: 1}} color="secondary" variant="outlined" onClick={() => {
                        deleteApiTasksUnlinkFilesToTask(taskDetails!.id!, {fileId: params.row.id})
                            .then(() => fetchTask(true))
                            .then(() => {
                                dispatch(showSnackbar({ message: "File removed From task!", type: "info" }));
                                dispatch(hideProgressLine())})
                            .catch(() => {
                                dispatch(hideProgressLine());
                                dispatch(showSnackbar({ message: "Error removing file from task!", type: "error" }));
                            });
                    }
                    } >UNLINK</Button>
                }
            },
        ]
    } else {
        columns = [
            {
                field: 'col1', headerName: 'File Name', flex: 15, cellClassName: 'cell-left-border', headerClassName: 'cell-left-border'
            },
        ]
    }

    const [sortInProgress, setSortInProgress] = useState(false);

    function getRows() {
        let rows: GridRowsProp = [];
        for(let file of taskFiles) {
            rows = rows.concat({
                id: file.id,
                col1: file.name,
            });
        }

        return rows;
    }

    return taskDetails === undefined || project === undefined || currentWorklog === undefined?
        <></> :
        <div style={{ overflow: "hidden" }}>
            {showToolbarAfterDeletion && <DeletionSnackBar type="Subtask" onClickUndoDeletion={onClickUndoDeletion}
                                                           onClickDismissDeletion={onClickDismissDeletion} />}
            <div className="single-project-template-box">
                <div className="single-project-template-header" style={{ fontSize: 15, fontWeight: 600, padding: '10px 0' }}>
                    <img alt="back" src={baseline_arrow_back} onClick={() =>
                        location.state && (location.state as { source: string }).source ?
                            nav((location.state as { source: string }).source,
                                { state: { taskId: taskDetails.id, projectId: project?.id }}) : nav(-1)}
                         className="single-project-temaplate-back-button" />
                    <div style={{ marginLeft: 8 }}>
                            <span onClick={() => nav(`/app/user/workflow/${!isTemplate ? "projects" : "project-template"}/${project?.id}`)}
                                  style={{ cursor: "pointer" }}
                            >
                                {project?.name}
                            </span>
                        <ArrowForwardIosIcon style={{ alignSelf: "center" }}
                                             sx={{ fontSize: 14, color: "gray", margin: "0px 6px -2px 6px" }} />
                        <span onClick={() => !isTemplate ? nav(`/app/user/workflow/projects/${project?.id}/task-list/${taskDetails.taskListId}`) :
                            nav(`/app/user/workflow/project-template/${project?.id}`)}
                              style={{ cursor: "pointer" }}>
                                {getTaskList()!.name}
                            </span>
                        {taskDetails.parentTaskId !== null && <>
                            <ArrowForwardIosIcon style={{ alignSelf: "center" }}
                                                 sx={{ fontSize: 14, color: "gray", margin: "0px 6px -2px 6px" }} />
                            <span onClick={() => nav(`/app/user/workflow/task-details/${taskDetails?.parentTaskId}`)}
                                  style={{ cursor: "pointer" }}
                            >
                                    {getParentTask()!.name}
                                </span>
                        </>}
                    </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="bottom-border right-border-1 top-border" style={{ width: smallWindow ? "40%" : "50%" }}>
                        <div style={{
                            display: "flex", alignItems: "center", justifyContent: "space-between",
                            height: 60, marginLeft: 16
                        }}>
                            {isTemplate ?
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="task-details-status task-details-status-disabled">
                                        <span style={{ letterSpacing: 0.1, color: "#00000061" }}>NOT STARTED</span>
                                        <ArrowDropDownIcon />
                                    </div>
                                    <img className="task-details-icon" alt="assign" src={assign_icon} />
                                </div>
                                :
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {auth.hasPMRoleAccess() ||
                                    (taskDetails.assignedUserId &&
                                        taskDetails.assignedUserId.split(",").includes(auth.loginInfo?.tenant?.user?.id?.toString() || "")) ?
                                        <div style={{ marginLeft: -24, marginRight: 30 }}>
                                            <StatusMenuComponent status={taskDetails.status as StatusType} projectStatus
                                                                 onUpdate={(status) => editTask("status", status)} />
                                        </div>
                                        :
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center",
                                            letterSpacing: 0.1, color: "white", fontSize: 15, fontWeight: 600,
                                            backgroundColor: statusList[taskDetails.status as StatusType].color,
                                            width: 158, height: 40, borderRadius: 4, whiteSpace: "nowrap", marginRight: 30 }}>
                                            {statusList[taskDetails.status as StatusType].name}
                                        </div>
                                    }
                                    <div>
                                        <UsersSelect color="blue" allUsers={users} size={32}
                                                     selectedUsers={filteredUsers()}
                                                     multiple={false} showAvatar={true}
                                                     onChange={userIds => postApiTasksAssign({
                                                        userIds: Array.isArray(userIds) ?
                                                            userIds.join(",") : userIds?.toString()!, taskId: taskDetails.id
                                                    }).then(() => fetchTask(true))}
                                        />
                                    </div>
                                </div>
                            }
                            <div style={{alignItems: "right"}}>
                                {auth.hasPMRoleAccess() && <MoreActionsComponent
                                    id={0}
                                    menuItems={menuItems}
                                    anchorEl={anchorEl}
                                    setAnchorEl={setAnchorEl}
                                    menuHorizontalPosition="right"
                                />}
                            </div>
                        </div>
                    </div>
                    <div className="task-details-second-row-column top-border bottom-border left-border-1">
                        <div className={smallWindow ? "task-details-info-item-small" : "task-details-info-item"}>
                            <span style={{ marginBottom: !isTemplate ? (taskDetails.startDate ? 8 : 5) : 0 }}>START DATE</span>
                            {isTemplate ?
                                <img alt="available" src={baseline_event_available} className="task-details-small-icon" />
                                :
                                <CustomDatePicker value={taskDetails.startDate ? new Date(taskDetails.startDate!) : null}
                                                  setValue={(value) =>
                                                      editTask("startDate",
                                                          value ? moment(value).format("YYYY-MM-DD 00:00:00") : null)
                                                  }
                                                  readonly={!auth.hasPMRoleAccess()}
                                                  shortView name="START DATE"
                                                  icon={<CalendarTodayIcon style={{ color: auth.hasPMRoleAccess() ? blueIconColor : "#E6E7E8" }} />}
                                />
                            }
                        </div>
                        <div className={smallWindow ? "task-details-info-item-small" : "task-details-info-item"}>
                            <span style={{ marginBottom: !isTemplate ? (taskDetails.dueDate ? 8 : 5) : 0 }}>DUE DATE</span>
                            {isTemplate ?
                                <img alt="available" src={baseline_event_available} className="task-details-small-icon" />
                                :
                                <CustomDatePicker value={taskDetails.dueDate ? new Date(taskDetails.dueDate!) : null}
                                                  setValue={(value) =>
                                                      editTask("dueDate",
                                                          value ? moment(value).format("YYYY-MM-DD 00:00:00") : null)}
                                                  readonly={!auth.hasPMRoleAccess()}
                                                  shortView name="DUE DATE"
                                                  icon={<EventAvailableIcon style={{ color: auth.hasPMRoleAccess() ? blueIconColor : "#E6E7E8" }} />} />
                            }
                        </div>
                        <div className={smallWindow ? "task-details-info-item-small" : "task-details-info-item"}>
                            <span style={{ marginBottom: 8 }}>TIME TRACKED</span>
                            <span style={{ fontSize: 13 }} >{timerState === TimerState.START ?
                                convertMinutesToHours(taskTimeTrackingSummary?.minutes || 0) :
                                (!currentWorklog?.timerStart ? '00:00' : timing)}</span>
                        </div>
                        {isTemplate || !auth.hasUserRoleAccess() || timerState === TimerState.NOT_INITIALIZED ?
                            <div className="task-details-status task-details-status-disabled"
                                 style={{ width: 131, height: 31, marginLeft: 40 }}>
                                {isTemplate || !auth.hasPMRoleAccess() ? <span style={{ letterSpacing: 1.25 }}>START TIMER</span> :
                                    !currentWorklog?.timerStart ? <>
                                            <span style={{ letterSpacing: 1.25 }}>START TIMER</span><ButtonSpinner />
                                        </> :
                                        <>
                                            <span style={{ letterSpacing: 1.25 }}>STOP TIMER</span><ButtonSpinner />
                                        </>
                                }
                            </div>
                            :
                            <Button key={timerState} variant="contained"
                                    color={timerState === TimerState.STOP ? "strongRed" : "moderateGreen"}
                                    onClick={startTimerOnClick}
                                    style={{
                                        width: 133, height: 39,
                                        color: timerState === TimerState.STOP ? "white" : "black", margin: "0 4px 0 40px"
                                    }}>
                                {timerState === TimerState.STOP ? "STOP" : "START"} TIMER
                            </Button>
                        }
                        {worklogs && <TimeTrackingComponent isTemplate={isTemplate} myWorklogs={getMyWorkLogs()}
                                                            worklogs={worklogs} refresh={fetchWorklogs} taskId={id}
                                                            isSubtask={!!taskDetails.parentTaskId}
                                                            subtaskIds={taskDetails.subtasks?.map(s => s.id) || []} />}
                    </div>
                    <div className="bottom-border right-border-1 task-details-description"
                         style={{ height: `calc(100vh - 184px - 113px - ${showToolbarAfterDeletion ? "55px" : "0px"})`,
                             width: smallWindow ? "40%" : "50%" }}>
                        <div style={{ margin: "16px 16px 24px 16px" }}>
                            <TaskDetailsEditableField value={taskDetails.name} fieldEdited="NAME" placeholder=""
                                                      checkOnClick={async (taskEditInput) => {
                                                          const baseTask = taskDetails as BaseTask;
                                                          await putApiTasksById(taskDetails.id, { ...baseTask, name: taskEditInput! });
                                                          fetchTask();
                                                      }} />
                            <TaskDetailsEditableField value={taskDetails.description} placeholder="Write a description for this task"
                                                      fieldEdited="DESCRIPTION" checkOnClick={async (taskEditInput) => {
                                const baseTask = taskDetails as BaseTask;
                                await putApiTasksById(taskDetails.id, { ...baseTask, description: taskEditInput! });
                                fetchTask();
                            }} />
                            { (taskDetails && taskFiles.length>0) ? <div > <Button disabled={selectedFiles.length===0} style={{marginBottom: 20}} type="submit" onClick={() => goToDocs(getUrlsFromSelectedRows())} color="secondary" variant="contained">
                                GO TO DOCS
                            </Button>
                            <DataGridPro
                                initialState={{
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}
                                rowHeight={30}
                                style={{ height: "604px", minWidth: "500px" }}
                                rows={getRows()}
                                columns={columns}
                                pagination
                                pageSizeOptions={[25, 50, 100]}
                                checkboxSelection
                                onRowSelectionModelChange={(ids) => {
                                    // @ts-ignore
                                    setSelectedFiles(ids);
                                }}
                            /></div>
                            :
                                <Button style={{marginBottom: 20}} variant="contained" color="secondary" onClick={() => {showModal(() => LinkFilesToTaskModalGivenTask({taskId: taskDetails.id, refreshProject: fetchTask}), {})}} >LINK FILES</Button>
                            }

                            <CustomFieldComponent isTemplate={isTemplate} task={taskDetails} />
                            {taskDetails.parentTaskId === null && <>
                                <div className="subtask-header-wrapper">
                                    <span className="subtask-header-title">
                                        Subtasks
                                        <span style={{ fontSize: 13, fontWeight: "normal" }}>
                                            &nbsp;({taskDetails.subtasks?.length})
                                        </span>
                                    </span>
                                    <div style={{alignItems: "right", marginRight: -20}}>
                                        {auth.hasPMRoleAccess() && <MoreActionsComponent
                                            id={1}
                                            menuItems={menuItemsSubTasks}
                                            anchorEl={anchorElSubtask}
                                            setAnchorEl={setAnchorElSubtask}
                                            menuHorizontalPosition="right"
                                        />}
                                    </div>
                                </div>
                                {sortInProgress && <div className="spinner spinner-position-center"></div>}
                                {
                                    displayedTasks.map(subtask =>
                                        <SubTaskListed
                                        displayedTasks={displayedTasks} onTaskReorder={handleTaskReorder}
                                        sortProgress={(bool: boolean) => setSortInProgress(bool)}

                                        key={subtask.id} subtask={subtask}
                                                       onClick={() => {
                                                           nav(`/app/user/workflow/task-details/${taskDetails.id}/${subtask.id}`)
                                                           setTaskDetails(undefined);
                                                       }}
                                                       onClickMore={() => setSelectedSubtaskId(subtask.id)}
                                                       onClickOption={() => {
                                                           const controller = new AbortController();
                                                           deleteApiTasksById(selectedSubtaskId!, controller.signal)
                                                               .then(() => fetchTask(true));
                                                       }}
                                        />
                                    )
                                }
                                {auth.hasPMRoleAccess() ? <TextField
                                        style={{ width: "100%", borderRadius: 20 }}
                                        sx={{
                                            '& fieldset': {
                                                borderRadius: '0px',
                                            },
                                        }}
                                        placeholder="Add a subtask"
                                        value={subtaskField}
                                        onBlur={() => {
                                            if (subtaskField !== "") {
                                                createSubTask();
                                            }
                                        }}
                                        onChange={(e) => setSubtaskField(e.target.value)}
                                        onKeyPress={onKeyPress}
                                    />
                                    :
                                    taskDetails.subtasks && taskDetails.subtasks.length > 0 ?
                                        <div style={{ borderTop: "1px solid lightgray" }} />
                                        :
                                        <></>
                                }
                            </>}
                        </div>
                    </div>
                    <Comments showToolbarAfterDeletion={showToolbarAfterDeletion} taskLists={project.taskLists!} worklogs = {worklogs}
                              isTemplate={isTemplate} task={taskDetails} projectName={project.name} />
                </div>
            </div >
        </div>
}

function TaskDescription(p: {description: string | null | undefined, placeholder: string, setEditTask: any,
    setTaskEditInput: any, value: string | null | undefined}) {

    const auth = useAuthService();
    const dispatch = useDispatch();
    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

    return (
        <Box
        sx={{
            display: "inline-flex",
            alignItems: 'top',
            borderRadius: '4px',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            width: '99.5%',
            minHeight: '140px',
            marginBottom: "20px",
        }}>
            {p.description !== "" && p.description !== null && p.description !== undefined ?
                <p style={{ marginTop: 10, marginBottom: 10, marginLeft: 12, marginRight: 12, width: "100%"}}>
                    {p.description.split("\n").map(line => {
                        return (
                            <div>
                                {line.split(" ").map(part => {
                                console.log("part", part)
                                return urlRegex.test(part) ?
                                <Link style={{wordBreak: "break-all"}} href={part} target="_blank"> {part} </Link> : part + " "})}
                                <br/>
                            </div>
                        );
                    })}
                </p>
                    :
                <p style={{ marginTop: 10, marginBottom: 10, marginLeft: 12, marginRight: 12,
                    width: "100%", color: "rgba(0, 0, 0, 0.40)"}}>
                        {auth.hasPMRoleAccess() ? p.placeholder : "No description for this task."}
                </p>
            }

            <ModeEditIcon style={{ cursor: "pointer", marginTop: 12, marginRight: 9,
                justifyContent: "right", color: "rgba(0, 0, 0, 0.55)"}}
                 onClick={() => {
                        dispatch(setShowToolbarAfterDeletion(false));
                        p.setEditTask(true);
                        p.setTaskEditInput(p.value ?? "");
                    }} />
        </Box>
    )
}

function TaskDetailsEditableField(p: {
    value: string | null | undefined, fieldEdited: "NAME" | "DESCRIPTION" | "TIME",
    checkOnClick: (taskEditInput: string) => Promise<void>, placeholder: string, isTimeFormat?: boolean
}) {

    const auth = useAuthService();
    const theme = useTheme();
    const [taskEditInput, setTaskEditInput] = useState<string>();
    const [editTask, setEditTask] = useState(false);
    const [error, setError] = useState(false);
    const saveRef = useRef(false);
    const dispatch = useAppDispatch();
    const textFieldSX = {
        input: {
            WebkitTextFillColor: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.primary.main} !important`,
        },
    };

    enum EditableField {
        NAME = "NAME",
        DESCRIPTION = "DESCRIPTION"
    }

    const isDescription = EditableField.DESCRIPTION === p.fieldEdited;
    const timeExpression = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

    if (p.fieldEdited !== "DESCRIPTION" || editTask) {
        return (
            <TextField
            value={p.fieldEdited === "DESCRIPTION" ? (!editTask ? "" : taskEditInput) : (!editTask ? p.value ?? "" : taskEditInput)}
            disabled={!editTask}
            sx={textFieldSX}
            error={error}
            multiline={isDescription}
            placeholder={p.value === "" ? (auth.hasPMRoleAccess() ? p.placeholder : "No description for this task.") : ""}
            fullWidth
            style={{ marginBottom: 16 }}
            onChange={e => { setTaskEditInput(e.target.value) }}
            onBlur={() => setTimeout(() => {
                if (!saveRef.current) {
                    setEditTask(false)
                }
            }, 500)}
            inputRef={input => input && input.focus()}
            inputProps={{ style: isDescription ? { minHeight: 120, fontSize: 15 } : { fontSize: 17, letterSpacing: 0.49 } }}
            InputProps={{
                endAdornment: auth.hasUserRoleAccess() ? (
                    <InputAdornment position="end" style={{ display: "flex", alignSelf: "flex-start", alignItems: "flex-start" }}>
                        {!editTask ?
                            <ModeEditIcon style={{ cursor: "pointer", marginTop: isDescription ? 0 : 15, }} onClick={() => {
                                dispatch(setShowToolbarAfterDeletion(false));
                                setEditTask(true);
                                setTaskEditInput(p.value ?? "");
                            }} /> :
                            <>
                                <CheckCircleIcon color="primary"
                                                style={{ cursor: "pointer", marginTop: isDescription ? 0 : 15 }}
                                                onClick={async () => {
                                                    saveRef.current = true;
                                                    if (p.isTimeFormat && !timeExpression.test(taskEditInput!)) {
                                                        setError(true);
                                                        return;
                                                    }
                                                    await p.checkOnClick(taskEditInput!);
                                                    setEditTask(false);
                                                    setError(false);
                                                    saveRef.current = false;
                                                }} />
                                <CancelIcon color="disabled" style={{ marginLeft: 5, cursor: "pointer", marginTop: isDescription ? 0 : 15 }}
                                            onClick={() => {
                                                setError(false);
                                                setEditTask(false);
                                                setTaskEditInput("");
                                            }} />
                            </>}
                    </InputAdornment>
                ) : <></>
            }}
            />
        )
    } else {
        return (
            <TaskDescription description={p.value} placeholder={p.placeholder} setEditTask={setEditTask}
                setTaskEditInput={setTaskEditInput} value={p.value}/>
        )
    }
}

function SubTaskListed(p: {
    subtask: SubTask, onClick: () => void, onClickMore: () => void, onClickOption: () => void, displayedTasks: any, onTaskReorder: any,
    sortProgress: (value: boolean) => void
}) {

    const auth = useAuthService();
    const theme = useTheme();
    const [anchorElSingleSubtask, setAnchorElSingleSubtask] = useState<HTMLElement | null>(null);
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const dispatch = useAppDispatch();



    const onDragStart = (event: any, sortId: number, id: number) => {
        event.dataTransfer.setData("text/plain", sortId);
        event.dataTransfer.setData("id", id);
    };

    const onDragOver = (event: any) => {
        event.preventDefault();
    };
    const onDrop = async (event: any, targetSortId: number, targetId: number) => {
        event.preventDefault();
        const sourceSortId = event.dataTransfer.getData("text/plain");
        const id = event.dataTransfer.getData("id");
        if (+sourceSortId !== targetSortId) {
            // hit the api
            p.sortProgress(true);

            const sorted = await putApiCustomFieldsSorting(+id, +targetId)
            if(sorted) {
                reorderTasks(sourceSortId, targetSortId);
            }
            p.sortProgress(false);
        }
    };

    const reorderTasks = (sourceId: string, targetId: number) => {
        let newTasks = p.displayedTasks.map((task: any) => ({ ...task }));
        const sourceIndex = newTasks.findIndex((task: any) => task.sortId === +sourceId);

        const targetIndex = newTasks.findIndex((task: any) => task.sortId === targetId);

        if (sourceIndex !== -1 && targetIndex !== -1) {
            const temp = newTasks[sourceIndex].sortId
            newTasks[sourceIndex].sortId = newTasks[targetIndex].sortId
            newTasks[targetIndex].sortId = temp;
            // local sorting
            newTasks = newTasks.sort((a: any, b: any) => { return a.sortId - b.sortId; });
            // Call a function to update the tasks in the parent component
            p.onTaskReorder(newTasks);
        }
    };

    const menuItemSingleSubTask = [
        <MenuItem
            key={1}
            onMouseEnter={onMouseEventHandler}
            onMouseLeave={onLeaveMouseEventHandler}
            onClick={() => {
                p.onClickOption();
                setAnchorElSingleSubtask(null);
                dispatch(setShowToolbarAfterDeletion(true));
            }}>
            <span style={{ fontSize: 14, color: theme.palette.error.main }}>Delete subtask</span>
        </MenuItem>
    ]
    return <div key={p.subtask.id} className="subtask-details-wrapper" onDragStart={(event) => onDragStart(event, p.subtask.sortId, p.subtask.id)}
    onDragOver={onDragOver}
    onDrop={(event) => onDrop(event, p.subtask.sortId, p.subtask.id)}
    draggable>
        <p className="subtask-details-subtask-name" onClick={p.onClick}>
            {p.subtask.name}
        </p>
        {auth.hasPMRoleAccess() && <>
            <DragIndicator
                    style={{ cursor: "grab" }}
                />
        <div onClick={p.onClickMore}>
            <MoreActionsComponent
                id={p.subtask.id}
                menuItems={menuItemSingleSubTask}
                anchorEl={anchorElSingleSubtask}
                setAnchorEl={setAnchorElSingleSubtask}
                menuHorizontalPosition="right"
            />
        </div>
        </>}
    </div>
}

