import { CircularProgress } from "@mui/material";
import {
    ANNOTATION_LOCATION_COLUMN,
    PARTICIPANT_ID_CATEGORY,
    PATIENT_ID_CATEGORY,
    PATIENT_ID_COLUMN
} from "../constants";
import {applyDefaultRules} from "./rules/defaultRules";

export function generatePatientReport(annotations: any[], fileName: string) {
    const groupingVariable = PATIENT_ID_COLUMN
    const groupingMatchCategories = [PATIENT_ID_CATEGORY, PARTICIPANT_ID_CATEGORY]
    const matchCategories = getDistinctMatchCategories(annotations);
    applyDefaultRules(annotations)
    const reportRowsMap = createReportRowsMap(annotations, groupingVariable, groupingMatchCategories, fileName);
    const headerRow = getHeaderRowOrdering(matchCategories, groupingMatchCategories);
    const reportRowsArray: string[][] = []
    reportRowsMap.forEach(function(value, key) {
        const list: string[] = []
        headerRow.forEach((header) => {
            list.push(value.get(header))
        })
        reportRowsArray.push(list)
    })
    return {headerRow, reportRowsArray}
}

function getDistinctMatchCategories(annotations: any[]) {
    return unique(annotations.map(annotation => annotation.type))
}

function unique(list: any[]) {
    let arr: any[] = [];
    for (let i = 0; i < list.length; i++) {
        if (!arr.some(arrayElement => arrayElement && arrayElement.toLowerCase()===list[i].toLowerCase())) {
            arr.push(list[i]);
        }
    }
    return arr;
}

function createReportRowsMap(annotations: any[], groupingVariable: string,
                          groupingMatchCategories: string[], fileName: string) {
    let reportRowMap = new Map();

    for (let i = 0; i < annotations.length; i++) {
        const groupingVariableValue = annotations[i].getCustomData(groupingVariable)
        if (groupingVariableValue !== "") {
            if (!reportRowMap.has(groupingVariableValue)) reportRowMap.set(groupingVariableValue, new Map<string, string[]>())//putIfAbsent
            const row = reportRowMap.get(groupingVariableValue)
            let matchCategory = annotations[i].type
            if (!row!.has(matchCategory)) row!.set(matchCategory, [])//putIfAbsent
            row!.get(matchCategory).push(annotations[i].getCustomData('trn-annot-preview'))
            if (matchCategory && groupingMatchCategories.some(groupingMatchCategory => groupingMatchCategory.toLowerCase() === matchCategory.toLowerCase())) {
                row!.set(ANNOTATION_LOCATION_COLUMN, []);
                row!.get(ANNOTATION_LOCATION_COLUMN).push(fileName)
            }
        }
    }
    return reportRowMap
}

function getHeaderRowOrdering(matchCategories: string[], groupingMatchCategories: string[]) {
    matchCategories.sort((a, b) => {
        if (groupingMatchCategories.some(category => category.toLowerCase()===a.toLowerCase())) return -1
        else if (groupingMatchCategories.some(category => category.toLowerCase()===b.toLowerCase())) return 1
        else return a.localeCompare(b)
    });
    matchCategories.splice(1, 0, ANNOTATION_LOCATION_COLUMN)
    return matchCategories;
}
