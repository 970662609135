import {
    ANNOTATION_ID_COLUMN,
    ANNOTATION_TYPE, APPLIED_RULES,
    CREATION_DATE_COLUMN,
    DESCRIPTION_ROW,
    FILE_NAME_COLUMN,
    MATCH_CATEGORY_COLUMN,
    OCCURRENCE_COLUMN,
    ORDERING_ID_COLUMN,
    ORIGINAL_TEXT_COLUMN,
    PAGE_NUMBER_COLUMN,
    PATIENT_ID_COLUMN,
    QUADS, REPLACED_END_INDEX, REPLACED_START_INDEX, REPLACEMENT_METHOD,
    REPLACEMENT_TEXT_COLUMN,
    STANDARDIZED_DATE_COLUMN,
    START_DATE_COLUMN,
    STATUS_COLUMN, STUDY_ID_COLUMN,
    USERNAME
} from "../constants";
import {getDisplayCategory} from "../components/webviewer";

export function generateSearchResultsReport(searchResults: any[], fileName: string) {
    const outputData: Map<string, string>[] = []
    const headerRow = Array.from(DESCRIPTION_ROW.keys());
    let occurences: Map<number, Map<string, number>> = new Map<number, Map<string, number>>()
    searchResults.forEach((result, index) => {
        outputData.push(createReportRow(result, fileName, index, occurences))
    })

    const reportRowsArray: string[][] = []
    const descriptionRow = Array.from(DESCRIPTION_ROW.values());
    reportRowsArray.push(descriptionRow)

    outputData.forEach((output) => {
        let array: string[] = []
        headerRow.forEach((key) => {
            array.push(output.get(key)!)
        })
        reportRowsArray.push(array)
    })
    return {headerRow, reportRowsArray}
}


function createReportRow(result: any, fileName: string, index: number, occurences: Map<number, Map<string, number>>) {
    let row: Map<string, string> = new Map();
    row.set(FILE_NAME_COLUMN, fileName)
    row.set(ANNOTATION_TYPE, "Search Result")
    row.set(ORIGINAL_TEXT_COLUMN, result.resultStr)
    row.set(PAGE_NUMBER_COLUMN, result.pageNum)
    row.set(OCCURRENCE_COLUMN, getOccurrenceOfOriginalTextInPage(result, occurences))
    row.set(ANNOTATION_ID_COLUMN, result.Id)
    row.set(ORDERING_ID_COLUMN, index.toString())
    row.set(MATCH_CATEGORY_COLUMN, getDisplayCategory(result.type))
    row.set(STATUS_COLUMN, "None")//I think this is always None for the search results
    row.set(CREATION_DATE_COLUMN, "")
    row.set(USERNAME, "")
    row.set(QUADS, JSON.stringify(result.quads).replaceAll(",", ";"))//we replace the ","
    // so that the csv doesn't assume it is a different column

    row.set(REPLACEMENT_TEXT_COLUMN, "")
    row.set(PATIENT_ID_COLUMN, "")
    row.set(START_DATE_COLUMN, "")
    row.set(STANDARDIZED_DATE_COLUMN, "")
    row.set(STUDY_ID_COLUMN, "")
    row.set(APPLIED_RULES, "")
    row.set(REPLACEMENT_METHOD, "")
    row.set(REPLACED_START_INDEX, "")
    row.set(REPLACED_END_INDEX, "")
    return row
}

function getOccurrenceOfOriginalTextInPage(annotation: any, occurences: Map<number, Map<string, number>>) {
    const pageIndex = annotation.pageNum
    if (!occurences.has(pageIndex)) occurences.set(pageIndex, new Map())//putIfAbsent
    const pageOccurrences = occurences.get(pageIndex)!;
    const originalText = annotation.resultStr;
    (pageOccurrences.has(originalText)) ? pageOccurrences.set(originalText, (pageOccurrences.get(originalText)!) + 1)
        : pageOccurrences.set(originalText, 1)
    return pageOccurrences.get(originalText)!.toString()
}
