import React, {useState} from "react";
import PageRangeInput from '../PageRangeInput';
import './AnnotationFilter.scss'
import {CATEGORY_SPLITTER} from "../../constants";
import {Icon} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Returns either 'cci', 'cbi' or 'normal'
// First check if the label is CCI or CBI. Then check if the name is CCI or CBI. If none of those are true, return 'normal'.
export function getStyleCategory(label: string) {
    if (label.toLowerCase() === 'cci' || label.toLowerCase() === 'cbi') {
        return label.toLowerCase();
    } else {
        return 'normal'
    }
}

//Returns a two element array with the style category and the display category.
//The "style category" is either cci, cbi, or normal. This determines the style of the redaction (i.e. it's overlay text and color)
//The "display category" is something like age, date, or patient ID. It is the category that is displayed in the UI.
export function getStyleAndDisplayCategory(type: string) {
    if (!type) {
        return ['normal', 'text']
    }
    if (type.indexOf(CATEGORY_SPLITTER) === -1) {
        return [getStyleCategory(type), type]
    }
    const rawStyleAndDisplayCategory = type.split(CATEGORY_SPLITTER);
    return [getStyleCategory(rawStyleAndDisplayCategory[0]), rawStyleAndDisplayCategory[1]]
}

//Creates a box where the user can choose a page range, a search phrase, and a match category to filter by. This is
//used for both redaction search results and redactions.
const AnnotationFilter = (props: {pageRangeChangeCallBack: any,
                                    searchPhraseChangeCallBack: any,
                                    categoryChangeCallBack: any,
                                    sortChangeCallBack: any,
                                    regexInputChangeCallBack: any,
                                    categories: string[],
                                    limitedOptions: boolean}) => {
    const [searchPhrase, setSearchPhrase] = useState<string|undefined>(undefined)
    const [displayFilters, setDisplayFilters] = useState<boolean>(false)
    const [category, setCategory] = React.useState(undefined)
    const [sortOrder, setSortOrder] = React.useState("Asc")
    const [regexInput, setRegexInput] = React.useState<string|undefined>(undefined)

    function pageRangeOnChange(newPageRange: undefined|(number|undefined)[]) {
        //Page Range Input uses undefined but for the filters we want to use positive and negative infinity
        const correctedPageRange = newPageRange === undefined? undefined :
            [newPageRange[0] === undefined? Number.NEGATIVE_INFINITY : newPageRange[0],
                newPageRange[1] === undefined? Number.POSITIVE_INFINITY : newPageRange[1]];
        props.pageRangeChangeCallBack(correctedPageRange)
    }

    function pageRangeOnClear() {
        props.pageRangeChangeCallBack(undefined)
    }

    function searchPhraseOnChange(event: any) {
        setSearchPhrase(event.target.value)
        props.searchPhraseChangeCallBack(event.target.value)
    }

    function clearSearchPhrase() {
        setSearchPhrase("")
        props.searchPhraseChangeCallBack(undefined)
    }

    function categoryOnChange(event: any) {
        setCategory(event.target.value)
        props.categoryChangeCallBack(event.target.value)
    }

    function clearCategory(event: any) {
        setCategory(undefined)
        props.categoryChangeCallBack(undefined)
    }

    function sortOnChange(event: any) {
        setSortOrder(event.target.value);
        props.sortChangeCallBack(event.target.value);
    }

    function regexInputOnChange(event: any) {
        setRegexInput(event.target.value)
        props.regexInputChangeCallBack(event.target.value)
    }

    function clearRegexInput() {
        setRegexInput("")
        props.regexInputChangeCallBack(undefined)
    }

    return <div className="box">
        <div className="extra-options">
            <button className='Button' onClick={() => {setDisplayFilters(!displayFilters)}}>Filter results
                {displayFilters ? "  ↑" : "  ↓"}</button>
        </div>
        { displayFilters &&
            <div>
                <div className="filter-container">
                    <li>
                        <div className="filter-label">Page Range:</div>
                    </li>
                    <li>
                        <PageRangeInput pageRangeChangeCallBack={pageRangeOnChange}
                                        clearPageRangeCallBack={pageRangeOnClear}
                                        placeholder='filter by page range'/>
                    </li>
                </div>
                <div className="filter-container">
                    <li>
                        <div className="filter-label">Search Phrase:</div>
                    </li>
                    <li>
                        <div className="input-container">
                            <input
                                type="text"
                                autoComplete="off"
                                onChange={searchPhraseOnChange}
                                placeholder='filter by phrase'
                                aria-label='filter by phrase'
                                id="SearchPhrase_input"
                                value={searchPhrase}
                            />
                            {(searchPhrase !== undefined && searchPhrase.length > 0) && (
                                <button
                                    className="clearField-button"
                                    onClick={clearSearchPhrase}
                                    aria-label='clear search phrase'
                                >
                                    <CloseIcon/>
                                </button>
                            )
                            }
                        </div>
                    </li>

                </div>
                <div className="filter-container">
                    <li>
                        <div className="filter-label">Category:</div>
                    </li>
                    <li>
                        <div className="input-container">
                            <div>
                                {category ? (
                                    <div>
                                        <button onClick={clearCategory}>
                                            {getStyleAndDisplayCategory(category)[1]} <span>X</span>
                                        </button>
                                    </div>
                                ) : (
                                    <div>
                                        <select value="" onChange={categoryOnChange}>
                                            <option value="" disabled>
                                                Filter by category
                                            </option>
                                            {props.categories.map((category, index) => (
                                                <option key={index} value={category}>
                                                    {getStyleAndDisplayCategory(category)[1]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </div>
                        </div>
                    </li>
                </div>
                {!props.limitedOptions && <div className="filter-container">
                    <li>
                        <div className="filter-label">Sort:</div>
                    </li>
                    <li>
                        <select value={sortOrder} defaultValue="Asc" onChange={sortOnChange}>
                            <option value="Asc">Ascending</option>
                            <option value="Desc">Descending</option>
                        </select>
                    </li>
                </div>}
                {!props.limitedOptions && <div className="filter-container">
                    <li>
                        <div className="filter-label">Search Pattern:</div>
                    </li>
                    <li>
                        <div className="input-container">
                            <input
                                type="text"
                                autoComplete="off"
                                onChange={regexInputOnChange}
                                placeholder='filter by search pattern'
                                aria-label='filter by search pattern'
                                id="RegexInput_input"
                                value={regexInput}
                            />
                            {(regexInput !== undefined && regexInput.length > 0) && (
                                <button
                                    className="clearField-button"
                                    onClick={clearRegexInput}
                                    aria-label='filter by search pattern'
                                >
                                    <CloseIcon/>
                                </button>
                            )
                            }
                        </div>
                    </li>
                </div>}
            </div>
        }
    </div>;
}

export default AnnotationFilter
