import moment from "moment";

//These first two lists are formats where it could be unclear which number represents a month and which represents a day.
const DAY_MONTH_DATE_FORMATS = [
    "DD-MM-YY",
    "DD-MM-YYYY",
    "D-M-YY",
    "D-M-YYYY" //Just one D will work for one or two-digit numbers. So this format works for 1/2/2024 and 11/12/2024. But not 01/02/2024
];

const MONTH_DAY_DATE_FORMATS = [
    "MM-DD-YY",
    "MM-DD-YYYY",
    "M-D-YY",
    "M-D-YYYY"
];

const OTHER_DATE_FORMATS = [
    //note - these should all have DATE_UNIT_SEPARATOR and no other
    //punctuation besides pattern details
    //MMM is for Jan, MMMM is for January. Each format does NOT accept the other one.
    "MMMM-D-YYYY",
    "D-MMMM-YYYY",
    "DD-MMM-YYYY",
    "DD-MMM-YY",
    "D-MMMM-YYYY",
    "D-MMM-YYYY",
    "D-MMM-YY",
    "DDMMMMYYYY",
    "DDMMMMYY",
    "DDMMMYY",
    "DDMMMYYYY",
    "DMMMYYYY",
    "DDMMM-YYYY",
    "DDMMM-YY",
    "DDMMMM-YY",
    "DDMMMM-YYYY",
    "YYYY-M-D",
    "YYYY-MM-DD",
    "DD-MMMYY",
    "DD-MMMYYYY",
    "MMMD-YYYY",
    "MMMDD-YYYY",
    //these don't work in javascript
    // "d'th'-MMMM-YYYY",
    // //12th march 2000
    // "dd'th'-MMMM-YYYY",
    // //3rd march 2000
    // "dd'rd'-MMMM-YYYY",
    // "d'rd'-MMMM-YYYY",
    // "dd'nd'-MMMM-YYYY",
    // "d'nd'-MMMM-YYYY",
    // "dd'st'-MMMM-YYYY",
    // "d'st'-MMMM-YYYY",
    // "d'th'-MMM-YYYY",
    // //12th march 2000
    // "dd'th'-MMM-YYYY",
    // //3rd march 2000
    // "dd'rd'-MMM-YYYY",
    // "d'rd'-MMM-YYYY",
    // "dd'nd'-MMM-YYYY",
    // "d'nd'-MMM-YYYY",
    // "dd'st'-MMM-YYYY",
    // "d'st'-MMM-YYYY",
    "MMMM-DD",
    "MMMM-DD'st'",
    "MMMM-DD'nd'",
    "MMMM-DD'rd'",
    "MMMM-DD'th'",
    "MMMM-D",
    "MMMM-D'st'",
    "MMMM-D'nd'",
    "MMMM-D'rd'",
    "MMMM-D'th'",
    "MMMM-D-YYYY",
    "MMMM-D-YYYY",
    "D-MMMM-YYYY",
    "MMM-D-YYYY",
    "MMM-D'st'-YYYY",
    "MMM-D'nd'-YYYY",
    "MMM-D'th'-YYYY",
    "MMMM-D-YYYY",
    "MMMM-D'st'-YYYY",
    "MMMM-D'nd'-YYYY",
    "MMMM-D'th'-YYYY",
    "MMMM-YYYY",
    "MMM-YYYY",
    "MMM-YY",
    "MM-YYYY",
    "MMMYYYY",
    "YYYY",
    "MM-DD"];

const DEFAULT_DATE_FORMATS: string[] = [
    ...MONTH_DAY_DATE_FORMATS,
    ...DAY_MONTH_DATE_FORMATS,
    ...OTHER_DATE_FORMATS,
];

export function parseDate(dateStr: string): moment.Moment|undefined {
    const normalizedDateStr = normalizeDateStr(dateStr)
    let parsedDate: moment.Moment|undefined = undefined;

    for (const dateFormat of DEFAULT_DATE_FORMATS) {
        parsedDate = moment(normalizedDateStr, dateFormat, true)
        if (parsedDate.isValid()) {
            break;
        } else {
            parsedDate = undefined;
        }
    }

    if (parsedDate === undefined || !parsedDate.isValid()) {
        console.log(`${dateStr} is not in one of the supported formats. We'll see if we can detect the format.`);
        //This isn't ideal because when it's parsed this way the format isn't stored. So we do it as a last resort.
        parsedDate = moment(dateStr);
        if (parsedDate === undefined || !parsedDate.isValid()) {
            console.log(`Failed to parse date string when transforming: ${dateStr}`);
            parsedDate = undefined;
        }
    }
    return parsedDate;
}

export const DATE_UNIT_SEPARATOR = '-';

//There are several symbols that get used in between numbers in dates, like slashes, dots, or spaces. One of the steps
//here is to replace all of those with dashes so that in the date formats above we can assume the strings have dashes.
function normalizeDateStr(dateStr: string): string {
    return dateStr
        .trim()
        .replace('of', '')
        .replace(/\s+|,|-|\.|\/|\\/g, DATE_UNIT_SEPARATOR)
        .replace(new RegExp(`${DATE_UNIT_SEPARATOR}+`, 'g'), DATE_UNIT_SEPARATOR);
}
