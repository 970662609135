import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';


const ProgressBar = (props: {totalItems: number, itemsCompleted: number, verb: string, units: string}) => {
    const {totalItems, itemsCompleted, verb, units} = props;
    const label = totalItems === 0 ? '' : `${verb} ${itemsCompleted} out of ${totalItems} ${units}`;

    const getCompletionPercentage = () => {
        if (totalItems === 0) {
            return 0;
        }
        return 100 * (itemsCompleted / totalItems)
    }

    return (
        <div className="progress-container">
            <div className="progress-label">{label}</div>
            <br/>
            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={getCompletionPercentage()} style={{ height: 15, borderRadius: 5 }} />
            </Box>
        </div>
    );
};

export default ProgressBar;
