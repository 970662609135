import React, { useEffect, useRef, useState } from "react";
import { BaseProject } from "../../../../models/BaseProject";
import CircleIcon from '@mui/icons-material/Circle';
import ReactDOM from "react-dom";
import { renderToString } from "react-dom/server";
import { Button, TextField, useTheme } from "@mui/material";
import { useCustomModal } from "../../../modals/custom-message-modal";
import { StyledBackArrow, StyledCloseIcon } from "../../../../components/close-button";
import "./project-create-wizard.scss";
import "../../../../components/wizard.scss";
import { SubmissionType } from "../../../../models/SubmissionType";
import {
    getApiProjectsById,
    getApiProjectSubmissionTypes,
    postApiProjectsCreate
} from "../../../../services/project";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { getApiTemplates } from "../../../../services/template";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hook";
import { loadTemplates, projectTemplatesSelector } from "../../../../redux/projectTemplates";
import { colorPalette } from "../../../../constants";
import { useAuthService } from "../../../../contexts/auth-context";
import { getApiUsers } from "../../../../services/user";
import { UsersAvatarList } from "../../../../components/users-avatar-list";
import { showSnackbar } from "../../../../redux/snackbar";
import { UsersSelect } from "../../../../components/users-select";
import { postApiTaskListsCreate } from "../../../../services/task.list";
import { useNavigate } from "react-router-dom";
import { loadProject, projectsSelector, selectProject } from "../../../../redux/projects";
import { InfoTenantUser } from "../../../../models/InfoTenantUser";
import ErrorIcon from '@mui/icons-material/Error';
import { MarkStyle } from "../../../../models/MarkStyle";
import { markStyles } from "../../../../constants";

const defaultSteps = [ProjectName, ProjectSubmission, ProjectMarkStyles, ProjectTemplate, ProjectShare, ProjectColor, ProjectReview];
const initialProject: BaseProject = {
    name: "",
    color: "#5C87FF",
    studyId: "",
    submissionTypeId: 0,
    organizationShare: true,
    markStyleName: null,
    isArchived: false,
}

export function ProjectCreateWizard() {

    const nav = useNavigate();
    const auth = useAuthService();
    const { hideModal } = useCustomModal();
    const dispatch = useAppDispatch();
    const pageRef = useRef(null);
    const [step, setStep] = useState(0);
    const [project, setProject] = useState(initialProject);
    const [list, setList] = useState("");
    const [allUsers, setAllUsers] = useState<InfoTenantUser[]>([]);
    const [sharedUsers, setSharedUsers] = useState<InfoTenantUser[]>([]);
    const [submissionTypes, setSubmissionTypes] = useState<SubmissionType[]>([]);
    const [markStyleOptions, setMarkStyleOptions] = useState<SubmissionType[]>([]);
    const [review, setReview] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        getApiProjectSubmissionTypes(controller.signal).then(types => {
            setSubmissionTypes(types);
            setMarkStyleOptions(markStyles);
            const defaultType = types.find(type => type.name === 'None');
            const defaultMarkStyle = markStyleOptions.find(style => style.name === 'Custom');
            if (defaultType && defaultMarkStyle) {
                setProject({...project, submissionTypeId: defaultType.id, markStyleName: defaultMarkStyle.name })
            }
        });
        getApiTemplates(controller.signal).then(templates => dispatch(loadTemplates(templates)));
        getApiUsers({ includeDeleted: false }, controller.signal).then(users => {
            setAllUsers(users);
            setSharedUsers(users.filter(u => u.id === auth.loginInfo?.tenant?.user?.id));
        });
        return () => controller.abort();
    }, [])

    useEffect(() => {
        const container = ReactDOM.findDOMNode(pageRef.current)?.parentElement?.parentElement;
        if (container && container.children?.length > 1) {
            container?.removeChild(container?.children[1]);
        }
        container?.insertAdjacentHTML("beforeend", renderToString(bubbles));
        if (container) {
            container.style.flexDirection = "column";
            for (let i = 0; i < 6; i++) {
                document.getElementById(`bubble-${i}`)?.addEventListener('click', () => jumpToStep(i));
            }
        }
    }, [step, project.name, project.projectTemplateId, list]);

    useEffect(() => {
        setProject({ ...project, users: sharedUsers.map(user => user.id)});
    }, [sharedUsers]);


    const bubbles = <div id="wizardBubbles" style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
        {defaultSteps.map((s, index) =>
            <CircleIcon key={index} id={`bubble-${index}`} color="secondary"
                        style={{ fill: step !== index ? "white" : "var(--secondary)" ,
                            width: 16, margin: 8, cursor: "pointer" }} /> )}
    </div>

    const createProject = () => {
        postApiProjectsCreate(project)
            .then(result => {
                if (project.projectTemplateId === undefined) {
                    postApiTaskListsCreate({ name: list, projectId: +result.id })
                        .then(() => nav(`/app/user/workflow/projects/${result.id}`))
                        .catch(() => dispatch(showSnackbar({ message: "Error creating task list!" , type: "error" })));
                } else {
                    nav(`/app/user/workflow/projects/${result.id}`);
                }
                getApiProjectsById(result.id)
                    .then(project => dispatch(loadProject(project)))
                    .then(() => dispatch(selectProject(result.id)));
                hideModal();
            })
            .catch(() => dispatch(showSnackbar({ message: "Error creating project!" , type: "error" })));
    }

    const jumpToStep = (step: number) => {
        if (!((step > 0 && project.name === "") || (step > 3 && project.projectTemplateId === undefined && list === ""))) {
            setStep(step);
            if (step === 6) {
                setReview(true);
            }
        }
    }

    const goToNextStep = () => {
        if (step === defaultSteps.length - 1) {
            createProject();
        } else {
            if (review) {
                setStep(6);
            } else {
                if (step === 5 && !review) {
                    setReview(true);
                }
                setStep(step + 1);
            }
        }
    }

    const goToPreviousStep = () => {
        if (step === 0) {
            closeModal();
        } else {
            setStep(step - 1);
        }
    }

    const closeModal = () => {
        const container = ReactDOM.findDOMNode(pageRef.current)?.parentElement?.parentElement;
        if (container && container.children?.length > 1) {
            container?.removeChild(container?.children[1]);
        }
        hideModal();
    }

    return <div ref={pageRef} style={{ width: step === 6 ? 600: 420, minHeight: 345 }}>
        {React.createElement(defaultSteps[step],
            { project, setProject, goToNextStep, goToPreviousStep, jumpToStep, closeModal,
                list, setList, sharedUsers, setSharedUsers, allUsers, submissionTypes, review, markStyleOptions })}
    </div>
}

function ProjectName(p: { project: BaseProject, setProject: (project: BaseProject) => void,
    goToNextStep: () => void, goToPreviousStep: () => void, closeModal: () => void, review: boolean }) {

    const projects = useAppSelector((state) => projectsSelector(state));
    const [error,setError] = useState(false);

    const updateProject = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const project = { ...p.project };
        project[ev.target.id as "name" | "studyId"] = ev.target.value
        p.setProject(project);
        if (error && project.name !== ""
            && !projects.some(pr => pr.name.toLowerCase() === project.name.trim().toLowerCase())) {
            setError(false);
        }
    }

    const submitProjectName = () => {
        if (p.project.name.trim() !== ""
            && !projects.some(project => project.name.toLowerCase() === p.project.name.trim().toLowerCase())) {
            p.goToNextStep();
        } else {
            setError(true);
        }
    }

    return <div className="project-create-wrapper">
        <StyledCloseIcon onClick={p.closeModal}/>
        <h5 style={{ textAlign: "center", marginTop: 0, marginBottom: 32 }}>Add a new Project</h5>
        <div style={{ marginBottom: 8 }}>Enter a Project name</div>
        <TextField id="name" value={p.project.name} onChange={updateProject} placeholder="Project name"
                   required
                   helperText={error ?
                       (projects.some(project => project.name.toLowerCase() === p.project.name.trim().toLowerCase()) ?
                           <span style={{ marginLeft: -14 }}>
                               <ErrorIcon sx={{ width: 12, height: 12 }} />
                               <span style={{ marginLeft: 5, verticalAlign: "text-bottom" }}>Project name already exists</span>
                           </span> :
                           <span style={{ marginLeft: -14 }}>
                               <ErrorIcon sx={{ width: 12, height: 12 }} />
                               <span style={{ marginLeft: 5, verticalAlign: "text-bottom" }}>Project name is required</span>
                           </span>)
                       : ""}
                   error={error} />
        <div style={{ marginBottom: 8, marginTop: 24 }}>Enter a Study ID</div>
        <TextField id="studyId" value={p.project.studyId} onChange={updateProject} placeholder="Study ID"/>
        <Button style={{ marginTop: 32, width: p.review ? "fit-content" : 103, alignSelf: "center" }} variant="contained" color="secondary"
                onClick={submitProjectName}
        >
            {p.review ? "REVIEW PROJECT DETAILS" : "NEXT"}
        </Button>
    </div>
}

function ProjectSubmission(p: { project: BaseProject, setProject: (project: BaseProject) => void, review: boolean,
    goToNextStep: () => void, goToPreviousStep: () => void, closeModal: () => void, submissionTypes: SubmissionType[] }) {

    const updateSubmissionType = (submissionTypeId: number) => p.setProject({ ...p.project, submissionTypeId });

    return <div className="project-create-wrapper">
        <StyledBackArrow onClick={p.goToPreviousStep} />
        <StyledCloseIcon onClick={p.closeModal}/>
        <h5 style={{ textAlign: "center", marginTop: 0, marginBottom: 32 }}>Type of submission</h5>
        {p.submissionTypes.map(type => <div style={{ display: "flex", marginBottom: 18, cursor: "pointer" }} key={type.id}>
            {+type.id === p.project.submissionTypeId ? <RadioButtonCheckedIcon color="secondary" /> :
                <RadioButtonUncheckedIcon color="disabled" onClick={() => updateSubmissionType(+type.id)} />}
            <div style={{ marginLeft: 16 }}>{type.name}</div>
        </div>)}
        <Button style={{ marginTop: 32, width: p.review ? "fit-content" : 103, alignSelf: "center" }}
                variant="contained" color="secondary"
                onClick={p.goToNextStep}
        >
            {p.review ? "REVIEW PROJECT DETAILS" : "NEXT"}
        </Button>
    </div>
}

function ProjectTemplate(p: { project: BaseProject, setProject: (project: BaseProject) => void,
    goToNextStep: () => void, goToPreviousStep: () => void, closeModal: () => void, review: boolean,
    list: string, setList: (list: string) => void }) {

    const templates = useAppSelector((state) => projectTemplatesSelector(state));
    const [error,setError] = useState(false);

    const submitProjectTemplate = () => {
        if (p.project.projectTemplateId !== undefined || p.list.trim() !== "") {
            p.goToNextStep();
        } else {
            setError(true);
        }
    }

    const updateProjectTemplate = (projectTemplateId: number | undefined) =>
        p.setProject({ ...p.project, projectTemplateId });

    return <div className="project-create-wrapper">
        <StyledBackArrow onClick={p.goToPreviousStep} />
        <StyledCloseIcon onClick={p.closeModal}/>
        <h5 style={{ textAlign: "center", marginTop: 0, marginBottom: 32 }}>Use a template</h5>
        {templates.map(template => <div style={{ display: "flex", marginBottom: 18, cursor: "pointer" }} key={template.id}>
            {template.id === p.project.projectTemplateId ? <RadioButtonCheckedIcon color="secondary" /> :
                <RadioButtonUncheckedIcon color="disabled" onClick={() => updateProjectTemplate(template.id)} />}
            <div style={{ marginLeft: 16 }}>{template.name}</div>
        </div>)}
        <div style={{ display: "flex", marginBottom: 18, cursor: "pointer" }}>
            {p.project.projectTemplateId === undefined ? <RadioButtonCheckedIcon color="secondary" /> :
                <RadioButtonUncheckedIcon color="disabled" onClick={() => updateProjectTemplate(undefined)} />}
            <div style={{ marginLeft: 16 }}>No template</div>
        </div>
        {
            p.project.projectTemplateId === undefined && <>
                <TextField id="name" value={p.list}
                           onChange={(ev: React.ChangeEvent<HTMLInputElement>) => p.setList(ev.target.value)}
                           placeholder="List name"
                           required helperText={error ? <span style={{ marginLeft: -14 }}>
                               <ErrorIcon sx={{ width: 12, height: 12 }} />
                               <span style={{ marginLeft: 5, verticalAlign: "text-bottom" }}>List name is required</span>
                           </span> : ""} error={error} />
            </>
        }
        <Button style={{ marginTop: 32, width: p.review ? "fit-content" : 103, alignSelf: "center" }}
                variant="contained" color="secondary"
                onClick={submitProjectTemplate}
        >
            {p.review ? "REVIEW PROJECT DETAILS" : "NEXT"}
        </Button>
    </div>
}

function ProjectShare(p: { project: BaseProject, setProject: (project: BaseProject) => void,
    goToNextStep: () => void, goToPreviousStep: () => void, closeModal: () => void, review: boolean,
    sharedUsers: InfoTenantUser[], setSharedUsers: (users: InfoTenantUser[]) => void, allUsers: InfoTenantUser[] }) {

    const auth = useAuthService();
    const dispatch = useAppDispatch();

    const removeUser = (user: InfoTenantUser) => {
        if (user.id !== auth.loginInfo?.tenant?.user?.id) {
            p.setSharedUsers(p.sharedUsers.filter(u => u.id !== user.id));
        } else {
            dispatch(showSnackbar({ message: "You can't remove yourself from project!", type: "error" }));
        }
    }

    const addUser = (userIds: number | number[] | undefined) => {
        if (userIds && Array.isArray(userIds)) {
            const users = p.allUsers.filter(user => userIds.includes(user.id));
            p.setSharedUsers(users);
        }
    }

    return <div className="project-create-wrapper">
        <StyledBackArrow onClick={p.goToPreviousStep} />
        <StyledCloseIcon onClick={p.closeModal}/>
        <h5 style={{ textAlign: "center", marginTop: 0, marginBottom: 32 }}>Share project with</h5>
        <div style={{ display: "flex", marginBottom: 18, cursor: "pointer" }}>
            {p.project.organizationShare ? <RadioButtonCheckedIcon color="secondary" /> :
                <RadioButtonUncheckedIcon color="disabled"
                                          onClick={() => p.setProject({ ...p.project, organizationShare: true })} />}
            <div style={{ marginLeft: 16 }}>My organization</div>
        </div>
        <div style={{ display: "flex", marginBottom: 18, cursor: "pointer" }}>
            {!p.project.organizationShare ? <RadioButtonCheckedIcon color="secondary" /> :
                <RadioButtonUncheckedIcon color="disabled"
                                          onClick={() => p.setProject({ ...p.project, organizationShare: false })} />}
            <div style={{ marginLeft: 16 }}>Selected users</div>
        </div>
        <div style={{ display: "flex" }}>
            <UsersAvatarList users={p.sharedUsers} removeUser={removeUser} shortView={false} />
            <UsersSelect allUsers={p.allUsers} selectedUsers={p.sharedUsers} multiple={false} onChange={addUser}
                         color="blue" />
        </div>
        <Button style={{ marginTop: 32, width: p.review ? "fit-content" : 103, alignSelf: "center" }}
                variant="contained" color="secondary"
                onClick={p.goToNextStep}
        >
            {p.review ? "REVIEW PROJECT DETAILS" : "NEXT"}
        </Button>
    </div>
}

function ProjectMarkStyles(p: { project: BaseProject, setProject: (project: BaseProject) => void, review: boolean,
    goToNextStep: () => void, goToPreviousStep: () => void, closeModal: () => void, markStyleOptions: MarkStyle[] }) {

    const updateMarkStyle = (markStyleName: string) => p.setProject({ ...p.project, markStyleName });

    return <div className="project-create-wrapper">
        <StyledBackArrow onClick={p.goToPreviousStep} />
        <StyledCloseIcon onClick={p.closeModal}/>
        <h5 style={{ textAlign: "center", marginTop: 0, marginBottom: 32 }}>Mark Styles</h5>
        {p.markStyleOptions.map(markStyle => <div style={{ display: "flex", marginBottom: 18, cursor: "pointer" }} key={markStyle.id}>
            {markStyle.name === p.project.markStyleName ? <RadioButtonCheckedIcon color="secondary" /> :
                <RadioButtonUncheckedIcon color="disabled" onClick={() => updateMarkStyle(markStyle.name)} />}
            <div style={{ marginLeft: 16 }}>{markStyle.name}</div>
        </div>)}
        <Button style={{ marginTop: 32, width: p.review ? "fit-content" : 103, alignSelf: "center" }}
                variant="contained" color="secondary"
                onClick={p.goToNextStep}
        >
            {p.review ? "REVIEW PROJECT DETAILS" : "NEXT"}
        </Button>
    </div>
}

function ProjectColor(p: { project: BaseProject, setProject: (project: BaseProject) => void,
    goToNextStep: () => void, goToPreviousStep: () => void, closeModal: () => void, review: boolean }) {

    const theme = useTheme();

    return <div className="project-create-wrapper" style={{ padding: "48px 32px" }}>
        <StyledBackArrow onClick={p.goToPreviousStep} />
        <StyledCloseIcon onClick={p.closeModal}/>
        <h5 style={{ textAlign: "center", marginTop: 0, marginBottom: 32 }}>Choose a color for the Project</h5>
        <div className="wizard-color-align">
            <label style={{ display: "flex" }} >
                <div className="wizard-color-profile"
                     style={{ backgroundColor: p.project.color }}>
                    {p.project.name.charAt(0).toUpperCase()}
                </div>
            </label>
            <div className="wizard-color-wrap">
                {colorPalette.map(o =>
                    <label key={o.color} className="wizard-color-circle"
                           onClick={() => p.setProject({ ...p.project, color: o.color })}
                           style={{ backgroundColor: o.color,
                               border: p.project.color === o.color ? `2px solid ${theme.palette.text.primary}` : "" }} />)}
            </div>
        </div>
        <Button style={{ marginTop: 32, width: p.review ? "fit-content" : 103, alignSelf: "center" }}
                variant="contained" color="secondary"
                onClick={p.goToNextStep}
        >
            {p.review ? "REVIEW PROJECT DETAILS" : "NEXT"}
        </Button>
    </div>
}

function ProjectReview(p: { project: BaseProject, setProject: (project: BaseProject) => void, closeModal: () => void,
    goToNextStep: () => void, goToPreviousStep: () => void, jumpToStep: (step: number) => void,
    list: string, sharedUsers: InfoTenantUser[], submissionTypes: SubmissionType[] }) {

    const templates = useAppSelector((state) => projectTemplatesSelector(state));

    return <div className="project-create-wrapper">
        <StyledBackArrow onClick={p.goToPreviousStep} />
        <StyledCloseIcon onClick={p.closeModal}/>
        <h5 style={{ textAlign: "center", marginTop: 0, marginBottom: 32 }}>Project details</h5>
        <div className="project-review-item" onClick={() => p.jumpToStep(0)}>
            <div style={{ fontSize: 17, letterSpacing: 0.49 }}>Project name</div>
            <div className="project-review-value">{p.project.name}</div>
        </div>
        <div className="project-review-item" onClick={() => p.jumpToStep(0)}>
            <div style={{ fontSize: 17, letterSpacing: 0.49 }}>Study ID</div>
            <div className="project-review-value">{p.project.studyId}</div>
        </div>
        <div className="project-review-item" onClick={() => p.jumpToStep(1)}>
            <div style={{ fontSize: 17, letterSpacing: 0.49 }}>Type of submission</div>
            <div className="project-review-value">
                {p.submissionTypes.find(type => +type.id === p.project.submissionTypeId)?.name || 'None'}
            </div>
        </div>
        <div className="project-review-item" onClick={() => p.jumpToStep(2)}>
            <div style={{ fontSize: 17, letterSpacing: 0.49 }}>Mark Styles</div>
            <div className="project-review-value">
                {p.project.markStyleName || 'None'}
            </div>
        </div>
        <div className="project-review-item" onClick={() => p.jumpToStep(2)}>
            <div style={{ fontSize: 17, letterSpacing: 0.49 }}>Template used</div>
            <div className="project-review-value">
                {templates.find(t => t.id === p.project.projectTemplateId)?.name || 'No template'}
            </div>
        </div>
        {p.project.projectTemplateId === undefined && p.list !== "" &&
            <div className="project-review-item" onClick={() => p.jumpToStep(2)}>
                <div style={{ fontSize: 17, letterSpacing: 0.49 }}>List</div>
                <div className="project-review-value">{p.list}</div>
            </div>
        }
        <div className="project-review-item" onClick={() => p.jumpToStep(3)}>
            <div style={{ fontSize: 17, letterSpacing: 0.49 }}>Shared with</div>
            <div className="project-review-value">
                {p.project.organizationShare ? "My organization" :
                    <UsersAvatarList users={p.sharedUsers} shortView={true} />}
            </div>
        </div>
        <div className="project-review-item" onClick={() => p.jumpToStep(4)}>
            <div style={{ fontSize: 17, letterSpacing: 0.49 }}>Project color</div>
            <div style={{ width: 32, height: 32, backgroundColor: p.project.color, borderRadius: 4 }} />
        </div>
        <Button style={{ marginTop: 32, width: 190, alignSelf: "center" }} variant="contained" color="secondary"
                onClick={p.goToNextStep}
        >
            CREATE PROJECT
        </Button>
    </div>
}
