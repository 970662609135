import './single-project-template.scss';
import React from "react";
import { useEffectAsync } from "../../hooks/async-effect";
import { getApiTemplatesById } from "../../services/template";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { ProjectComponent } from "../../components/project/project-component";
import { setIsTemplate, setProjectId } from "../../redux/action-bar";
import { loadTemplate, projectTemplateSelector } from "../../redux/projectTemplates";
import { showSnackbar } from "../../redux/snackbar";
import { useParams } from 'react-router-dom';

export function SingleProjectTemplate() {

    const id = parseInt(window.location.href.split('/').pop()!);
    const dispatch = useAppDispatch();
    const singleTemplate = useAppSelector((state) => projectTemplateSelector(state, id));
    const { taskListId } = useParams();

    useEffectAsync(async () => {
        fetchTemplate();
    }, [id])

    const fetchTemplate = () => {
        dispatch(showProgressLine());
        getApiTemplatesById(id)
            .then(template => {
                dispatch(loadTemplate(template));
                dispatch(hideProgressLine());
                dispatch(setProjectId(id));
                dispatch(setIsTemplate(true));
            })
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error loading template!", type: "error" }));
            })
    }

    return <div className="single-project-template-wrapper">
        {singleTemplate &&
            <ProjectComponent projectId={singleTemplate.id} projectName={singleTemplate.name} shortView={false}
                              taskLists={taskListId ? singleTemplate.taskLists?.filter(tl => tl.id === +taskListId)! : singleTemplate.taskLists || []} fetchProject={fetchTemplate} isTemplate={true} />
        }
    </div >
}
