import { AccountLoginRequest } from "../models/AccountLoginRequest";
import { Tenant } from "../models/Tenant";
import { UserLoginRequest } from "../models/UserLoginRequest";
import { RefreshTokenRequest } from "../models/RefreshTokenRequest";
import { AuthResponse } from "../models/AuthResponse";
import { MFASetupResponse } from "../models/MFASetupResponse";
import { MFALoginResponse } from "../models/MFALoginResponse";
import { VerifySoftwareTokenRequest } from "../models/VerifySoftwareTokenRequest";
import { MFALoginRequest } from "../models/MFALoginRequest";
import { SignUpRequest } from "../models/SignUpRequest";
import { ConfirmForgotPasswordRequest } from "../models/ConfirmForgotPasswordRequest";
import { ResetPasswordRequest } from "../models/ResetPasswordRequest";
import { ChangePasswordRequest } from "../models/ChangePasswordRequest";
import { InfoTenantUser } from "../models/InfoTenantUser";
import { User } from "../models/User";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
export function postApiUsersAccountLogin(body?: AccountLoginRequest, signal?: AbortSignal): Promise<Tenant> {
    const url = new URL(`/api/users/account-login`, baseUrl);
    return fetchAndParse<Tenant>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiUsersLogin(body?: UserLoginRequest | RefreshTokenRequest, signal?: AbortSignal): Promise<AuthResponse | MFASetupResponse | MFALoginResponse> {
    const url = new URL(`/api/users/login`, baseUrl);
    return fetchAndParse<AuthResponse | MFASetupResponse | MFALoginResponse>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiUsersLogout(body?: RefreshTokenRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/users/logout`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiUsersVerifyTotp(body?: VerifySoftwareTokenRequest, signal?: AbortSignal): Promise<string> {
    const url = new URL(`/api/users/verify-totp`, baseUrl);
    return fetchAndParse<string>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiUsersVerifyCode(body?: MFALoginRequest, signal?: AbortSignal): Promise<AuthResponse> {
    const url = new URL(`/api/users/verify-code`, baseUrl);
    return fetchAndParse<AuthResponse>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiUsersSignUp(body?: SignUpRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/users/sign-up`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiUsersConfirmForgotPassword(body?: ConfirmForgotPasswordRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/users/confirm-forgot-password`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiUsersResetPassword(body?: ResetPasswordRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/users/reset-password`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiUsersChangePassword(body?: ChangePasswordRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/users/change-password`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function getApiUsers({ includeDeleted }: {
    includeDeleted?: boolean;
}, signal?: AbortSignal): Promise<InfoTenantUser[]> {
    const url = new URL(`/api/users`, baseUrl);
    addQueryParameter(url, "includeDeleted", includeDeleted);
    return fetchAndParse<InfoTenantUser[]>(url.toString(), { method: "GET", headers, signal });
}
export function getApiUsersById(id: number, signal?: AbortSignal): Promise<User> {
    const url = new URL(`/api/users/${id}`, baseUrl);
    return fetchAndParse<User>(url.toString(), { method: "GET", headers, signal });
}
