import { createSlice } from "@reduxjs/toolkit";
import { FileState } from "../models/FileState";
import { RootState } from "./store";

const defaultFiles: FileState[] = [];

const initialState = {
    files: defaultFiles,
}

const files = createSlice({
    name: "files",
    initialState: initialState,
    reducers: {
        loadFiles: (state, action) => {
            state.files = action.payload;
        },
        updateFile: (state, action) => {
            const index = state.files.findIndex((file: FileState) => file.id === action.payload.id);
            state.files[index] = action.payload; 
        }
    }
});

export const { loadFiles, updateFile } = files.actions;
export const filesSelector = (state: RootState) => state.files.files;
export default files.reducer;