import React, { useState } from 'react';

const SearchAcrossDocsContext = React.createContext<any>(false);

export default SearchAcrossDocsContext;

export function SearchAcrossDocsContextProvider(props: { children: JSX.Element }) {
    const [searchAcrossDocs, setSearchAcrossDocs] = useState();
    return <SearchAcrossDocsContext.Provider value={{ searchAcrossDocs, setSearchAcrossDocs }}>{props.children}</SearchAcrossDocsContext.Provider>
}
