import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { useCustomModal } from "../../modals/custom-message-modal";
import { AddCustomFieldProjectDetailsModal } from "../../modals/modal-content/add-custom-field-project-details-modal";
import { ProjectDetailsCustomField } from "../../../components/project/project-details-custom-field";
import { Project } from "../../../models/Project";
import {loadProject, projectSelector} from "../../../redux/projects";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux-hook";
import { selectedProjectSelector } from "../../../redux/projects";
import { EditStudyIdModal } from "../../modals/modal-content/edit-study-id-modal";
import { EditSubmissionModel } from "../../modals/modal-content/edit-submission-modal";
import { SubmissionType } from "../../../models/SubmissionType";
import {getApiProjectSubmissionTypes, putApiUpdateProject} from "../../../services/project";
import { SetCustomFieldModal } from "../../modals/modal-content/set-custom-field-modal";
import { useAuthService } from "../../../contexts/auth-context";
import {useNavigate} from "react-router";
import { EditMarkStyleModal } from "../../modals/modal-content/edit-mark-style-modal";
import { markStyles } from "../../../constants";
import {hideProgressLine, showProgressLine} from "../../../redux/progress-line";
import {createCopyOfDefaultPatternSetForProject} from "../../../services/patternSets";
import {loadPatternSet} from "../../../redux/patternSets";
import {showSnackbar} from "../../../redux/snackbar";

export type CustomFieldProjectDetails = {
    name: string,
    value: string
}

export function ProjectDetailsComponent(props: { fetchProject: () => void, totalPages: number, scopePages: number }) {
    const [isButtonHover, setIsButtonHover] = useState(false);
    const [clickedEditPatterns, setClickedEditPatterns] = useState(false);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { showModal } = useCustomModal();
    const [projectCustomFields, setProjectCustomFields] = useState<CustomFieldProjectDetails[]>([]);
    const [submissionTypes, setSubmissionTypes] = useState<SubmissionType[]>([]);
    const projectId = useAppSelector((state) => selectedProjectSelector(state));
    const project: Project | undefined = useAppSelector((state) => projectSelector(state, projectId));
    const auth = useAuthService();
    const nav = useNavigate();

    useEffect(() => {
        const controller = new AbortController();
        getApiProjectSubmissionTypes(controller.signal).then(res => setSubmissionTypes(res));
    }, [])

    useEffect(() => {
        const arr: CustomFieldProjectDetails[] = [];
        if (project.projectDetails) {
            Object.keys(project.projectDetails).forEach(key => {
                if (key === "totalPages") return;
                if (key === "totalScopePages") return;
                if (key === "totalOutOfScopePages") return;
                arr.push({ name: key, value: (project.projectDetails as any)[key] });
                setProjectCustomFields([...arr]);
            });
        } else {
            setProjectCustomFields([]);
        }

    }, [project.projectDetails, project.id])

    const handleEditPatternsClick = () => {
        setClickedEditPatterns(true)
        if (missingPatterns) {
            dispatch(showProgressLine());
            createCopyOfDefaultPatternSetForProject(auth.loginInfo?.user?.name??'user', project!.name, project!.id, auth.loginInfo?.tenant?.schema).then(newPS => {
                dispatch(loadPatternSet(newPS))
                const updatedProject = {...project, patternSetID: newPS.id } as Project
                putApiUpdateProject(project!.id, updatedProject).then(() => {
                    dispatch(loadProject(updatedProject))
                    dispatch(hideProgressLine())
                    nav(`/app/user/workflow/projects/patterns/${newPS.id}`)
                }).catch(reason => {
                    dispatch(showSnackbar({
                        message: "Error adding patterns!", type: "error"
                    }))
                    dispatch(hideProgressLine())
                })
            }).catch(reason => {
                dispatch(showSnackbar({
                    message: "Error Creating patterns!", type: "error"
                }))
                dispatch(hideProgressLine())
            })
        } else {
            nav(`/app/user/workflow/projects/patterns/${project!.patternSetID}`)
        }
    }

    const filteredProjectCustomFields = projectCustomFields.filter((fpcf) => fpcf.value !== "");
    const nameLength = Math.max(...(filteredProjectCustomFields.map(el => el.name.length))) * 7.5;
    const missingPatterns = project?.patternSetID === undefined || project?.patternSetID === null
    return <div>
        <div className="single-project-template-horizontal-line" style={{ marginTop: 0 }} />
        <div style={{ margin: "16px 16px" }}>
            {auth.hasUserRoleAccess() && <Button
                color="secondary"
                variant="outlined"
                disabled={clickedEditPatterns}
                onClick={handleEditPatternsClick}
                style={{
                    display: "flex", marginBottom: 8,
                }}>
            EDIT PATTERNS
            </Button>}


            {auth.hasPMRoleAccess() && <Button
                variant="outlined"
                disabled={filteredProjectCustomFields.length >= 15}
                onClick={(el) => showModal(AddCustomFieldProjectDetailsModal, {
                    oldProjectDetails: project.projectDetails!,
                    project: project, fetchProject: props.fetchProject
                })}
                onMouseEnter={() => setIsButtonHover(true)}
                onMouseLeave={() => setIsButtonHover(false)}
                style={{
                    display: "flex", marginBottom: 8,
                    backgroundColor: false ? "#E4EFF4" : isButtonHover ? "#F6FAFB" : "",
                    color: filteredProjectCustomFields.length <= 15 ? theme.palette.secondary.main : "lightgray"
                }}>
                <AddIcon style={{ marginRight: 4 }} /> ADD CUSTOM FIELD
            </Button>}
            <div style={{
                overflowY: "scroll",
                height: "calc(100vh - 420px)",
            }}>
                <div style={{ display: "flex", flexDirection: "column", marginBottom: 1 }}>
                    <ProjectDetailsCustomField canBeEdited={auth.hasPMRoleAccess()} canBeDeleted={false} nameLength={nameLength}
                        customField={{ name: "ID", value: project.studyId ?? "" }} isLastItem={!project.submissionType &&
                            !props.totalPages && !props.scopePages && !projectCustomFields.length}
                        edit={() => showModal(EditStudyIdModal, { value: project.studyId, project, fetchProject: props.fetchProject })} />
                    <ProjectDetailsCustomField canBeEdited={auth.hasPMRoleAccess()} canBeDeleted={false} nameLength = {nameLength}
                        customField={{ name: "SUBMISSION", value: project.submissionType?.name ?? "" }} isLastItem={
                            !props.totalPages && !props.scopePages && !projectCustomFields.length && !project.markStyleName
                        } edit={() => showModal(EditSubmissionModel, {
                            value: project.submissionType!, project,
                            fetchProject: props.fetchProject, submissionTypes
                        })} />
                    <ProjectDetailsCustomField canBeEdited={false} canBeDeleted={false} nameLength = {nameLength}
                        customField={{ name: "MARK STYLE", value: project.markStyleName ?? "" }} isLastItem={
                            !props.totalPages && !props.scopePages && !projectCustomFields.length
                        } edit={() => showModal(EditMarkStyleModal, {
                            value: project.markStyleName, project,
                            fetchProject: props.fetchProject, markStyles: markStyles,
                        })} />
                    {props.totalPages > 0 && <ProjectDetailsCustomField canBeEdited={false} canBeDeleted={false} isLastItem={false}
                        customField={{ name: "TOTAL NUMBER OF PAGES", value: props.totalPages.toString() }} nameLength={nameLength} />}
                    {props.scopePages > 0 && <ProjectDetailsCustomField canBeEdited={false} canBeDeleted={false} isLastItem={false}
                        customField={{ name: "TOTAL IN SCOPE PAGES", value: props.scopePages.toString() }} nameLength = {nameLength} />}
                    {props.totalPages > 0 && <ProjectDetailsCustomField canBeEdited={false} canBeDeleted={false} isLastItem={
                        !projectCustomFields.length
                    } nameLength={nameLength}
                        customField={{ name: "TOTAL OUT OF SCOPE PAGES", value: (props.totalPages - props.scopePages).toString() }} />}
                    {filteredProjectCustomFields.map((pcf, i) => <ProjectDetailsCustomField key={i} project={project}
                        nameLength = {nameLength} edit={() => showModal(SetCustomFieldModal, {
                            value: pcf.value, project, fetchProject: props.fetchProject,
                            field: pcf.name, existingCustomFields: []
                        })}
                        canBeEdited={auth.hasPMRoleAccess()} canBeDeleted={auth.hasPMRoleAccess()}
                        isLastItem={i === filteredProjectCustomFields.length - 1}
                        customField={{ name: pcf.name ?? "", value: pcf.value ?? "" }} fetchProject={props.fetchProject} />)}
                </div>
            </div>
        </div>
    </div>
}
