import { createSlice } from '@reduxjs/toolkit'
import { RootState } from "./store";

type SnackbarAction = {
    payload: {
        message: string | JSX.Element,
        type: string,
    },
    type: string
}

const initialState = {
    isOpen: false,
    message: "" as string | JSX.Element,
    type: "info",
}

const snackbar = createSlice({
   name: "snackbar",
   initialState,
   reducers: {
       showSnackbar: (state, action: SnackbarAction) => {
           state.isOpen = true;
           state.message = action.payload.message;
           state.type = action.payload.type;
       },
       hideSnackbar: (state) => {
           state.isOpen = false;
       }
   }
});

export const { showSnackbar, hideSnackbar } = snackbar.actions;
export const snackbarSelector = (state: RootState) => state.snackbar;

export default snackbar.reducer
