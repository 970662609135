import {useCustomModal} from "./custom-message-modal";
import React, {useRef} from "react";
import ModalIconContent from "./modal-content/modal-icon-content";

const OverwriteTransformsWarningModal = (props : { continue: ()=>any }) => {
    const { hideModal } = useCustomModal();
    const modalRef = useRef<HTMLDivElement>(null);

    return <div ref={modalRef} style={{position: "relative",
        width: 350, display: "flex", alignItems: 'center',
        flexDirection: "column", justifyContent: "center", padding: "20px 30px"
    }}>
        <p>This will overwrite the existing text in the After column. Do you want to continue?</p>
        <div style={{marginTop:"10px"}}>
            <button onClick={() => {hideModal()}} className={"sanitize-first-modal"} style={{backgroundColor:"#e86122"}}>Cancel</button>
            <button onClick={()=>{hideModal();props.continue()}} className={"sanitize-first-modal"} style={{margin:"0px 10px"}}>Continue</button>
        </div>

    </div>


};

export default OverwriteTransformsWarningModal;