import { Avatar, Box, Button, FormControl, Input, InputAdornment, Menu, MenuItem, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { UserAssign } from "./project-filters";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hook";
import { InfoTenantUser } from "../models/InfoTenantUser";
import { usersSelector } from "../redux/users";
import { getUserInitials } from "../services/utils";
import { updateFilters, projectFiltersSelector } from "../redux/project-filters";
import { selectedProjectSelector } from "../redux/projects";

type UserApplyType = "FILTER" | "ASSIGN";

export function UsersApply(props: {
    selectedArray: InfoTenantUser[], icon: JSX.Element, type: UserApplyType, onApply: (selectedUsers: InfoTenantUser[],
        setSelectedUsers: ((users: InfoTenantUser[]) => void)) => void, anchorEl: HTMLElement | null,
    setAnchorEl: (value: HTMLElement | null) => void, setSelectedArray?: (users: InfoTenantUser[]) => void
}) {
    const [assigneesSearchInput, setAssigneesSearchInput] = useState("");
    const [usersMenuItems, setUsersMenuItems] = useState<JSX.Element[]>([]);
    const handleMenuClose = () => props.setAnchorEl(null);
    const open = Boolean(props.anchorEl);
    const users = useAppSelector((state) => usersSelector(state, false));

    const addUserToArray = (items: JSX.Element[], user: UserAssign) => {
        items.push(<UserApplyMenuItem key={user.id}
            setSelectedUsers={props.setSelectedArray!}
            user={user} selectedUsers={props.selectedArray} isUser={user.id !== 0}
            type={props.type} />)
    }

    useEffect(() => {
        const items: JSX.Element[] = [];
        const unassigned = { id: 0, name: props.type === "FILTER" ? "Unassigned" : "Unassign", role: "USER", color: null };
        if (!assigneesSearchInput || assigneesSearchInput === "") {
            addUserToArray(items, unassigned);
        }
        props.selectedArray.filter(user => user.id !== 0).forEach(userFiltered => {
            if (!userFiltered.name?.toLocaleLowerCase().includes(assigneesSearchInput.toLocaleLowerCase())) return;
            addUserToArray(items, userFiltered);
        })
        users.forEach(user => {
            if (assigneesSearchInput && assigneesSearchInput !== "") {
                if (!user?.name?.toLocaleLowerCase().includes(assigneesSearchInput.toLocaleLowerCase())) return;
            }
            if (!props.selectedArray.includes(user)) {
                addUserToArray(items, user);
            }
        });
        setUsersMenuItems(items);
    }, [assigneesSearchInput, props.selectedArray, users])

    const assigneeButtonItem = [
        <Box textAlign="center">
            <Button variant="contained" color="secondary"
                onClick={() => {
                    props.onApply(props.selectedArray, props.setSelectedArray!);
                    props.setAnchorEl(null);
                }} style={{ marginTop: 7, marginBottom: 16 }}>{props.type === "FILTER" ? "DONE" : "APPLY"}</Button>
        </Box>
    ]

    const assigneesItems = [
        <FormControl variant="standard">
            <Input
                id="input-with-icon-adornment"
                disableUnderline
                placeholder="Search for users"
                value={assigneesSearchInput}
                style={{ marginLeft: 10, padding: 5 }}
                onChange={(e) => setAssigneesSearchInput(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon color="secondary" />
                    </InputAdornment>
                }
            />
        </FormControl>,
        <div className="project-filters-assignees-hr"></div>
    ].concat(usersMenuItems).concat(assigneeButtonItem);

    return <>
        {props.icon}
        {assigneesItems && open && <Menu
            id="basic-menu"
            anchorEl={props.anchorEl}
            open={open}
            onClose={handleMenuClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            PaperProps={{ sx: { minWidth: 245, marginTop: 1 } }}
        >
            {assigneesItems}
        </Menu>}
    </>
}

function UserApplyMenuItem(p: {
    user: UserAssign, type: UserApplyType,
    isUser: boolean, selectedUsers: UserAssign[], setSelectedUsers: (users: UserAssign[]) => void,
}) {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const projectId = useAppSelector((state) => selectedProjectSelector(state));
    const [isHovered, setIsHovered] = useState(false);
    const isSelected = p.selectedUsers?.map(us => us.id).includes(p.user.id);
    const filters = useAppSelector((state) => projectFiltersSelector(state, projectId))

    const userSelect = () => {
        if (p.selectedUsers.map(a => a.id).includes(p.user.id)) {
            if (p.type === "FILTER") {
                dispatch(updateFilters({
                    ...filters,
                    assignees: p.selectedUsers.filter(user => user.id !== p.user.id)
                }));
            } else {
                p.setSelectedUsers(p.selectedUsers.filter(user => user.id !== p.user.id));
            }
        } else {
            if (p.type === "FILTER") {
                dispatch(updateFilters({
                    ...filters,
                    assignees: [...p.selectedUsers, p.user]
                }));
            } else {
                if (p.selectedUsers.length && (p.user.id === 0 && p.user.name === "Unassign")) {
                    p.selectedUsers.length = 0;
                } else if (p.selectedUsers.find(su => (su.id === 0 && su.name === "Unassign"))) return;
                p.setSelectedUsers([...p.selectedUsers, p.user])
            }
        }
    }

    return <MenuItem key={`${p.user.id}-${isSelected}`} onClick={userSelect} style={{
        display: "flex", alignItems: "center", margin: 8, justifyContent: "space-between",
        backgroundColor: isSelected || isHovered ? theme.palette.lightGrayishBlue3.main : "transparent"
    }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
    >
        <div style={{ display: "flex" }}>
            <Avatar sx={{ bgcolor: p.isUser ? (p.user.color ?? theme.palette.secondary.main) : "lightgray", width: 24, height: 24, fontSize: 14 }}>
                {p.isUser ? getUserInitials((p.user as InfoTenantUser).name!) : ""}
            </Avatar>
            <span style={{ marginLeft: 12, fontWeight: isSelected ? "600" : "normal", backgroundColor: "" }}>
                {(p.user as InfoTenantUser).name}
            </span>
        </div>
        {isSelected && <span className="project-filter-item-check">✓</span>}
    </MenuItem>
}
