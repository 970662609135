import { createSlice } from '@reduxjs/toolkit'
import { RootState } from "./store";
import { Project } from "../models/Project";

export const defaultProjects: Project[] = []
export const defaultProjectIdsJustArchived: number[] = []

const defaultProject: Project = {
    id: -1,
    name: "",
    color: "#5C87FF",
    studyId: "",
    submissionTypeId: 0,
    organizationShare: true,
    patternSetID: null,
    markStyleName: null,
    isArchived: false,
}

const projectsInitialState = {
    projects: defaultProjects,
    loaded: false,
    selected: 0,
    latestProjectChangeId: -1,
    projectIdsJustArchived: defaultProjectIdsJustArchived,
}

const projects = createSlice({
    name: 'projects',
    initialState: projectsInitialState,
    reducers: {
        loadProjects: (state, action) => {
            state.projects = action.payload;
            state.loaded = true;
        },
        loadProject: (state, action) => {
            if (state.projects.find(project => project.id === action.payload.id)) {
                state.projects = state.projects.map(project => project.id === action.payload.id ? action.payload : project);
            } else {
                state.projects.push(action.payload)
            }
        },
        loadArchivedProject: (state, action) => {
            if (state.projects.find(project => project.id === action.payload.id)) {
                state.projects = state.projects.map(project => project.id === action.payload.id ? action.payload : project);
                if (action.payload.isArchived) {
                    state.projectIdsJustArchived.push(action.payload.id);
                }
            } else {
                state.projects.push(action.payload)
            }
        },
        selectProject: (state, action) => {
            state.selected = action.payload;
        },
        setProjectsLoaded: (state, action) => {
            state.loaded = action.payload;
        },
        removeProject: (state, action) => {
            state.projects = state.projects.filter(project => project.id !== action.payload);
        },
        dismissProjectIdsJustArchived: (state) => {
            state.projectIdsJustArchived = [];
        },
        updateTask: (state, action) => {
            state.projects = state.projects.map(project => {
                if (project.id === action.payload.projectId) {
                    return {
                        ...project,
                        taskLists: project.taskLists?.map(taskList => {
                            if (taskList.id === action.payload.taskListId) {
                                return {
                                    ...taskList,
                                    tasks: taskList.tasks?.map(task => {
                                        if (task.id === action.payload.task.id) {
                                            return action.payload.task;
                                        }
                                        if (task.id === action.payload.task.parentTaskId) {
                                            return {
                                                ...task,
                                                subtasks: task.subtasks?.map(subtask => {
                                                    if (subtask.id === action.payload.task) {
                                                        return action.payload.task;
                                                    }
                                                    return subtask;
                                                })
                                            }
                                        }
                                        return task;
                                    })
                                }
                            }
                            return taskList;
                        })
                    }
                }
                return project;
            })
        }
    }
})

export const { loadProjects, selectProject, loadProject, loadArchivedProject, setProjectsLoaded, removeProject, dismissProjectIdsJustArchived, updateTask } = projects.actions
export const projectsSelector = (state: RootState) => state.projects.projects
export const loadedProjectsSelector = (state: RootState) => state.projects.loaded
export const selectedProjectSelector = (state: RootState) => state.projects.selected
export const projectsJustArchivedSelector = (state: RootState) => state.projects.projectIdsJustArchived
export const projectSelector = (state: RootState, projectId: number) =>
    state.projects.projects.find(project => +project.id === projectId) || defaultProject

export default projects.reducer
