import TextField from "@mui/material/TextField";
import { useCustomModal } from "../custom-message-modal";
import './remove-entity-modal.scss';
import { StyledBackArrow, StyledCloseIcon } from "../../../components/close-button";
import { getApiProjectsExistingCustomFields } from "../../../services/project";
import { ProjectDetails } from "../../../models/ProjectDetails";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import { SetCustomFieldModal } from "./set-custom-field-modal";
import { Project } from "../../../models/Project";
import { AddCustomFieldProjectDetailsModal } from "./add-custom-field-project-details-modal";

function ExistingCustomFieldComponent(props: { ecf: string, onClick: () => void }) {
    const [isHovering, setIsHovering] = useState(false);

    return <span onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} onClick={props.onClick} style={{
        color: isHovering ? "#217DA2" : "initial", marginTop: 8, cursor: "pointer"
    }}>{props.ecf}</span>
}

export function AddExistingCustomFieldProjectDetails(props: {
    existingCustomFields: string[] | undefined, oldProjectDetails: ProjectDetails, project: Project, fetchProject: () => void,
}) {
    const { hideModal, showModal } = useCustomModal();
    const [searchValue, setSearchValue] = useState("");
    const [existingCustomFieldsState, setExistingCustomFieldsState] = useState<string[]>([]);

    useEffect(() => {
        const controller = new AbortController();
        if (props.existingCustomFields) setExistingCustomFieldsState(props.existingCustomFields)
        else {
            getApiProjectsExistingCustomFields(controller.signal).then(res => setExistingCustomFieldsState(res));
        }
    }, [props.existingCustomFields])

    const getExistingCustomField = (selectedCustomField: string) => {
        const cfKey = existingCustomFieldsState.find(ecf => ecf === selectedCustomField);
        showModal(SetCustomFieldModal, {
            fetchProject: props.fetchProject, field: cfKey!, project: props.project, value: "",
            existingCustomFields: props.existingCustomFields!
        })
    }
    const filteredCustomFields = existingCustomFieldsState.filter(ecf => ecf.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));

    return <div style={{
        width: 648, height: 677, display: "flex",
        flexDirection: "column", marginTop: 36, overflowX: "hidden"
    }}>
        <div style={{ display: "flex", flexDirection: "column", marginRight: 45, marginLeft: 45 }}>
            <StyledBackArrow onClick={() => showModal(AddCustomFieldProjectDetailsModal, {
                fetchProject: props.fetchProject,
                project: props.project, oldProjectDetails: props.oldProjectDetails
            })} />
            <StyledCloseIcon onClick={() => hideModal()} />
            <div style={{ marginTop: 15, fontSize: 25, textAlign: "center" }}>
                Existing fields
            </div>
            <label className="wizard-content-label" style={{ fontSize: 17, marginTop: 16, marginBottom: 18, letterSpacing: 0.49 }}>
                Add one of the existing fields created by other Project Managers in this account.</label>
            <TextField placeholder="Search" style={{ width: 548, height: 48 }} value={searchValue} onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon color="secondary" />
                        </InputAdornment>
                    ),
                }}
            />
            <div style={{ display: "flex", flexDirection: "column", overflowY: "auto", marginTop: 16, marginBottom: 40 }}>
                {filteredCustomFields.map(ecf => < ExistingCustomFieldComponent ecf={ecf} onClick={() => getExistingCustomField(ecf)} />)}
                {!existingCustomFieldsState.length && <span>There are no other existing fields</span>}
            </div>
        </div>
    </div>
}
