export const baseUrl: string = process.env.REACT_APP_BASE_URL || "http://localhost:5000/"
export const wsUrl: string = process.env.REACT_APP_WS_URL || "ws://localhost:5000/api/rls/websocket"

//Returns 'dev', 'qa', 'prod', or 'local-dev' based on the URL provided.
export const getEnvDir = (baseUrl: string) => {
    try {
        const pattern = /\b\w+(?=\.rlsciences)/
        // @ts-ignore
        const match = pattern.exec(baseUrl)[0]
        return match === 'app' ? 'prod' : match
    } catch (e) {
        return "local-dev"
    }
}
export const envDir: string = getEnvDir(baseUrl)

export const headers: Record<string, string> = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

//Use this when you need to send and recieve multipart/form-data or anything else besides JSONs.
//Note: An error occurs when you set Content Type to multipart/form-data. You need to not include that field and let
//the browser figure out the content type based on the body.
export const getHeadersWithoutJSON: () => Record<string, string> = () => {
    let copy = Object.assign({}, headers);
    delete copy['Content-Type'];
    delete copy['Accept']
    return copy;
}

