import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { dismissProjectIdsJustArchived, loadedProjectsSelector, loadProject, projectsJustArchivedSelector, projectsSelector, selectProject } from "../../redux/projects";
import React, { useEffect, useState } from "react";
import { getApiProjectsById, putApiProjectIsArchived } from "../../services/project";
import "../pages.scss";
import "./projects.scss";
import { ArrowBack } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Link} from "@mui/material";
import { ProjectCreateWizard } from "./projects/wizards/project-create-wizard";
import { useCustomModal } from "../modals/custom-message-modal";
import { Project } from "../../models/Project";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ProjectComponent } from "../../components/project/project-component";
import {ProjectFilter, ProjectFilterBar} from "../../components/project-filters";
import { actionBarIsVisibleSelector } from "../../redux/action-bar";
import {projectFiltersSelector, updateFilters, updateShowAllProjects} from "../../redux/project-filters";
import { useAuthService } from "../../contexts/auth-context";
import { DeletionSnackBar } from "../../components/deletion-snackbar";
import { showSnackbar } from "../../redux/snackbar";
import { arch } from "os";
import { ArchivedProjectsComponent } from "../../components/archived-projects-component";
import { CompletedProjectsComponent } from "../../components/completed-projects-component";
import {DataGridPro, GridColDef, GridRenderCellParams, GridRowsProp, GridToolbar} from "@mui/x-data-grid-pro";
import moment from "moment";
import {statusList, StatusType} from "../../constants";
import {userSettingsSelector} from "../../redux/userSettings";

export function Projects() {

    const auth = useAuthService();
    const dispatch = useAppDispatch();
    const loaded = useAppSelector((state) => loadedProjectsSelector(state));
    const actionBarIsVisible = useAppSelector((state) => actionBarIsVisibleSelector(state));
    const nav = useNavigate();
    const { showModal } = useCustomModal();
    const filters = useAppSelector((state) => projectFiltersSelector(state, 0));
    const [selectedProjectIds, setSelectedProjectIds] = useState<number[]>([]);
    const projectsObj = useAppSelector((state) => state.projects);
    const projects = projectsObj.projects;
    const archivedProjectIds = useAppSelector((state) => projectsJustArchivedSelector(state));
    const projectTableHeaders = ['Project Name', 'Status', 'Created at', 'Start Date', 'Due Date', 'Owner', 'Type of Submission']
    const userSettings = useAppSelector((state) => userSettingsSelector(state));
    const dateFormat = userSettings.regional.dateFormat;

    const setFilter = <T extends ProjectFilter, K extends keyof T>(filterKey: K, newValue: T[K]) => {
        dispatch(updateFilters({ ...filters, [filterKey]: newValue }))
    }

    useEffect(() => {
        filters.showArchivedProjects && setFilter("showArchivedProjects", !filters.showArchivedProjects)
        filters.viewCompletedProjects && setFilter("viewCompletedProjects", !filters.viewCompletedProjects)
        dispatch(updateShowAllProjects(true));
        return () => {
            dispatch(updateShowAllProjects(false));
        };
    }, []);

    const fetchProject = (id: number) => {
        getApiProjectsById(id)
            .then(project => dispatch(loadProject(project)))
    }

    const onClickUndoProjectArchive = () => {
        const controller = new AbortController();
        archivedProjectIds.forEach((id: number) => {
            putApiProjectIsArchived(id, {isArchived: false}, controller.signal)
                .then(() => {
                    fetchProject(id);
                })
                .catch(() => {
                    dispatch(showSnackbar({ message: "Error undoing project archive!", type: "error" }));
                }
            );
        });
        dispatch(showSnackbar({ message: `Project${archivedProjectIds.length > 1 ? "s" : ""} restored!`, type: "info" }));
        dispatch(dismissProjectIdsJustArchived());
    }

    const onClickDismissProjectArchive = () => {
        dispatch(dismissProjectIdsJustArchived());
    }

    function getColumns() {
        let columnsObject: GridColDef[] = []
        projectTableHeaders.map((header, index) => {
            if (index===0) {
                columnsObject.push({field: header, headerName: header, headerClassName: 'cell-left-border projects-table-header', minWidth: 230, width: 300,
                    renderCell: params => {
                        return <Button title={params.row.projectName} style={{
                            width: '100%',
                            textOverflow: 'ellipsis',
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            display: "block",
                            height: '100%',
                            backgroundColor: params.row.color,
                            color: "white",
                            font: 'inherit',
                        }} onClick={() => {
                            dispatch(selectProject(+params.row.id));
                            nav(`/app/user/workflow/projects/${params.row.id}`);
                        }}>
                            <span>
                                {params.row.projectName}
                            </span>
                        </Button>
                    }
                })
            } else if (index===3 || index===4) {//for the createdat and duedate fields we output the date format if it is empty
                columnsObject.push({field: header, headerName: header, headerClassName: 'cell-left-border projects-table-header', cellClassName: 'cell-left-border table-left-padding', minWidth: 120,  width: 120, renderCell: params => {
                        if (params.row[header]===null) {
                            return <p style={{color: "lightgray"}}>{dateFormat}</p>
                        } else {
                            return <p>{params.row[header]}</p>
                        }
                    }
                })
            } else {
                columnsObject.push({
                    field: header,
                    headerName: header,
                    minWidth: 120,
                    width: index === 6 ? 160 : 120,
                    cellClassName: 'cell-left-border table-left-padding',
                    headerClassName: 'cell-left-border projects-table-header'
                })
            }
        })
        return columnsObject
    }

    function getProjectsRows() {
        let rows: GridRowsProp = [];
        for(let project of projects) {
            if (!project.isArchived) { 
                rows = rows.concat({
                    id: project.id,
                    [projectTableHeaders[0]]: project.name,
                    [projectTableHeaders[1]]: project.status ? statusList[project.status as StatusType].name : '',
                    [projectTableHeaders[2]]: formatDate(project.createdAt),
                    [projectTableHeaders[3]]: formatDate(project.startDate),
                    [projectTableHeaders[4]]: formatDate(project.dueDate),
                    [projectTableHeaders[5]]: project.createdByName,
                    [projectTableHeaders[6]]: project.submissionType?.name,
                    color: project.color,
                    projectName: project.name
                })
            }
        }

        return rows;
    }

    function formatDate(dateString?: string) {
        if (dateString) {
            const dateMoment = moment(dateString);
            return dateMoment.format(dateFormat)
        }
        return null
    }

    return <div style={{overflow: "hidden"}}>
        {archivedProjectIds.length > 0 && <DeletionSnackBar type="ProjectArchive" onClickUndoDeletion={onClickUndoProjectArchive}
            onClickDismissDeletion={onClickDismissProjectArchive} multipleArchivedProjects={archivedProjectIds.length > 1} />}
        <div className="body-container">
            <div className="page-wrapper" style={{ padding: 0, flexGrow: 1 }}>
                <div className="page-header-small" style={{ padding: "12px 16px" }}>
                    <ArrowBack className='arrow-back' onClick={() => nav(-1)} />
                    {filters.showArchivedProjects ? "Archived Projects" : (filters.viewCompletedProjects ? "Completed Projects" : "Projects")}
                </div>
                {/*we have removed these filters for now. We might need them in the future */}
                {/*{loaded &&*/}
                {/*    <ProjectFilterBar projectId={0} selectedProjectIds={selectedProjectIds} />}*/}
                <div style={{ height: `calc(100vh - var(--headerHeight) - var(--footerHeight) - 176px${actionBarIsVisible ? " - 60px" : ""}`,
                    overflowY: "auto" }}
                    id="project-data-0"
                >
                    {loaded ?
                        (!filters.showArchivedProjects ?
                            (!filters.viewCompletedProjects ?
                                (projects.length === 0 ?
                                    <div style={{ margin: 32, backgroundColor: "var(--lightGrayishBlue)",
                                        display: "flex", flexDirection: "column", alignItems: "center", height: 205 }}>
                                        <div style={{ marginTop: 32, marginBottom: 40, textAlign: "center",
                                            fontSize: 21, fontWeight: 600, letterSpacing: 0.15}}>
                                            There are no projects added yet. <br />Please add a project.
                                        </div>
                                        {auth.hasPMRoleAccess() && <Button variant="contained" color="secondary"
                                                onClick={() => showModal(ProjectCreateWizard, {})}>
                                            ADD PROJECT
                                        </Button>}
                                    </div>
                                    :
                                    <div style={{ paddingTop: 16, overflow: "hidden" }}>
                                        <Box>
                                            <DataGridPro autoPageSize={false}
                                                         autoHeight={true} rowSpacingType={"border"}
                                                         rows={getProjectsRows()}
                                                         sx={{
                                                             '& .MuiDataGrid-cell': {
                                                                 padding: 0
                                                             },
                                                         }}
                                                         disableDensitySelector
                                                         disableColumnSelector
                                                         columns={getColumns()}
                                                         slots={{ toolbar: GridToolbar }}
                                                         slotProps={{
                                                             toolbar: {
                                                                 showQuickFilter: true,
                                                             },
                                                         }}
                                                         pagination
                                                         initialState={{
                                                             pagination: {paginationModel: {pageSize: 5}},
                                                         }}
                                                         pageSizeOptions={[5, 10, 25]}
                                                         keepColumnPositionIfDraggedOutside={true}
                                            />
                                        </Box>
                                    </div>
                                )
                                :
                                <CompletedProjectsComponent projects={projects} fetchProject={fetchProject}
                                    setSelectedCompletedProjectIds={setSelectedProjectIds} />
                            )
                                :
                            (<>
                            <ArchivedProjectsComponent projects={projects} fetchProject={fetchProject}
                                setSelectedArchivedProjectIds={setSelectedProjectIds}/>
                            </>)
                        )
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    </div>
}
