import "./search-pattern-generator.css"
import {TextChunkTable} from "./text-chunk-table";
import {SplitText} from "./split-text";
import {Selections} from "./selections";

export function TextChunkContainer(props: {splitText: SplitText, selections: Selections, setSelections: (arg: Selections) => void}) {
    return <div className="row no-gutters bg-light mt-3 pr-4 rounded" id="rg_pattern_wizard">
        <div className="col-12 col-md-11 py-4 pl-4">
            <h5>We split your text into chunks. Please select what you want the pattern to match for each
                chunk.</h5>
            <div className="row no-gutters bg-white border rounded p-2" id="rg_wizard_container">
                <div className="rg-match-container">
                    <TextChunkTable splitText={props.splitText} selections={props.selections} setSelections={props.setSelections}/>
                </div>
            </div>
        </div>
    </div>
}
