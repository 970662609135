import React from 'react';
import {ListItem as MuiListItem, ListItemIcon, ListItemText} from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";
import {BlockOutlined} from "@mui/icons-material";

interface Props {
    lists: any;
    iconBefore?: React.ReactNode;
    iconAfter?: React.ReactNode;
}
const ListItem:React.FC<Props> = (props) => {
    const {lists, iconBefore, iconAfter} =  props
    return(
        lists && lists.length > 0 && lists.map((list: any, index: number)=>(
            <MuiListItem key={index.toString()}>
                {iconBefore && <ListItemIcon>{iconBefore}</ListItemIcon>}
                <ListItemText primary={list.text}/>
                {iconBefore && <ListItemIcon>{iconAfter}</ListItemIcon>}
                {list.rightIconValue && list.rightIconValue === 'valid' ? <ListItemIcon><CheckIcon/></ListItemIcon> : <ListItemIcon><BlockOutlined/></ListItemIcon>}
            </MuiListItem>
        ))
    )
}
export default ListItem