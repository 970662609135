import React, {useEffect, useRef, useState} from 'react';
import {useCustomModal} from "./custom-message-modal";
import ModalIconContent from "./modal-content/modal-icon-content";

//This asks the user to choose a match category for the mark they just added.
const DockLockConsentModal = (props : { onSave: () => void,onRemoveSelected: ()=>void }) => {
    const { hideModal } = useCustomModal();
    const modalRef = useRef<HTMLDivElement>(null);
    const onSubmit = () => {
        props.onSave()
        hideModal()
    }


    return <div ref={modalRef} style={{position: "relative",
        width: 350, display: "flex", alignItems: 'center',
        flexDirection: "column", justifyContent: "center", padding: "20px 30px"
    }}>
        <ModalIconContent isDone/>
        <p style={{marginBottom:"0px",textAlign:"center"}}>
            Your document is ready
        </p>

        <div style={{marginTop:"10px"}}>
            <button onClick={props.onSave} className={"sanitize-first-modal"} style={{margin:"0px 10px"}}>Ok</button>
        </div>

    </div>


};

export default DockLockConsentModal;
