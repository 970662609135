import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCustomModal } from "../modals/custom-message-modal";
import { useAdminService } from "../../contexts/super-admin-context";
import { AccountModal } from "../modals/account-modal";
import './accounts.scss';
import { AddAccountWizard } from "./wizards/add-account-wizard";
import { LoadPage } from "../../components/load-page";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { accountListSelector } from "../../redux/account-list";

export function AccountsAdminPage() {
    const { getAccounts, clearAccountData } = useAdminService();
    const nav = useNavigate();
    const { showModal } = useCustomModal();
    const dispatch = useAppDispatch();
    const [submitted, setSubmitted] = useState<boolean>(false);
    const accounts = useAppSelector((state) => accountListSelector(state));

    useEffect(() => {
        dispatch(showProgressLine());
        getAccounts().then(() => dispatch(hideProgressLine()));
        clearAccountData();
    }, [])

    const onClickAccount = (id: string) => {
        nav(`/app/admin/account?id=${id}`)
    }

    const creatingAccount = (value: boolean) => setSubmitted(value);

    return <div style={{ flexGrow: 1 }}>
        {submitted && <LoadPage />}
        <AccountModal>
            <div >
                <div className="header-accounts">
                    <p className="title">Accounts</p>
                    <Button onClick={() => showModal(AddAccountWizard, { creatingAccount })} 
                            variant="contained" color="secondary" className="button">ADD ACCOUNT</Button>
                </div>
                <span>
                    You can add and manage all the accounts that are registered for RLS products.
                </span>
                <div className="account-wrapper">
                    {accounts?.map(acc => <div key={acc.id} onClick={() => onClickAccount(acc.id)} className="account">
                        <div className="account-circle" style={{ backgroundColor: acc.color }}>
                            <span className="account-letter" >{acc.name.charAt(0).toUpperCase()}</span>
                        </div>
                        <span className="account-name">{acc.name}</span></div>)}
                </div>
            </div>
        </AccountModal>
    </div>
}
