import { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';

export function FilterInnerButton(p: { onClick: () => void, groupBy: typeof p.title, title: string }) {

    const [isHover, setIsHover] = useState(false);

    return <div onClick={p.onClick} className="my-tasks-gorup-by-modal-item"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                style={{ backgroundColor: isHover ? "#EDF5F8" : "white"}}>
            <span style={{ fontSize: 15, letterSpacing: 0.24, fontWeight: p.groupBy === p.title ? 600 : "normal"}}>
                {p.title}
            </span>
            {p.groupBy === p.title && <CheckIcon sx={{ color: "#217DA2", width: 16, height: 16, marginRight: "16px!important" }} />}
        </div>
}
