import {useContext, useEffect, useState} from "react";
import "./multipanel.scss"
// @ts-ignore
import Tabs from 'react-responsive-tabs';

// IMPORTANT you need to include the default styles
import 'react-responsive-tabs/styles.css';
import {SearchPanel} from "./SearchPanel/search-panel";
import { TransformPanel } from "./TransformsPanel/transform-panel";
import RedactionPanel from "./RedactionPanel";
import {loadRedactionAnnotations, RedactionPanelContainer} from "./RedactionPanel/RedactionPanelContainer";
import ReplacedPanel from "./ReplacedPanel/ReplacedPanel";
import WebViewerContext from "../../contexts/webviewer-context";


export const Multipanel = (props: {redactionSearchPatterns: {label: string, type: string, regex: RegExp}[]}) => {

    const { setInstance,instance } = useContext(WebViewerContext);
    const [documentLoading, setDocumentLoading] = useState(false);

    const getLoadingPanel = () => {
        return <div className="loading-panel">
            <div>Loading...</div>
        </div>
    }

    const panelContents = [
        { name: 'Search', content: <SearchPanel redactionSearchPatterns={props.redactionSearchPatterns}/>},
        { name: 'Redact', content: documentLoading ? getLoadingPanel() : <RedactionPanelContainer redactionSearchPatterns={props.redactionSearchPatterns}/> },
        { name: 'Transform', content: documentLoading ? getLoadingPanel() : <TransformPanel/> },
        { name: 'Replaced', content: documentLoading ? getLoadingPanel() : <ReplacedPanel/> },
    ];

    //When the user switches to a new document, turn on documentLoading. Once an annotation changes on that new document,
    //turn off documentLoading. This is because after a big document loads it can be awhile until the annotations load.
    useEffect(() => {
        const onAnnotationChanged = (annotations: any[], action: string) => {
            setDocumentLoading(false);
            instance.Core.annotationManager.removeEventListener('annotationChanged', onAnnotationChanged);
        }

        const onNewDocument = () => {
            setDocumentLoading(true);
            instance.Core.annotationManager.addEventListener('annotationChanged', onAnnotationChanged);
        }

        if (instance) {
            instance.Core.documentViewer.addEventListener('beforeDocumentLoaded', onNewDocument);
        }
        return () => {
            if (instance) {
                instance.Core.annotationManager.removeEventListener('annotationChanged', onAnnotationChanged);
                instance.Core.documentViewer.removeEventListener('beforeDocumentLoaded', onNewDocument);
            }
        }
    }, [instance]);

    function getTabs() {
        return panelContents.map((panelContent, index) => ({
            title: panelContent.name,
            getContent: () => panelContent.content,
            /* Optional parameters */
            key: index,
            tabClassName: 'tab_class',
            panelClassName: 'panel',
        }));
    }

    return <div className='multipanel' data-element="multipanel" style={{ height: "calc(100vh - 154px)" }}>
        <Tabs transform={false} items={getTabs()} unmountOnExit={false} />
    </div>
};