import { createSlice } from '@reduxjs/toolkit'
import { RootState } from "./store";
import { UserSettings } from "../models/UserSettings";

export const defaultUserSettings: UserSettings = {
    color: "#5C87FF",
    company: "",
    regional: {
        timezone: "Etc/GMT",
        timeFormat: 12,
        dateFormat: "DD/MM/YYYY"
    },
    notifications: {
        email: {
            enabled: true,
            startTime: "09:00",
            startTimeAmPm: "am",
            endTime: "05:00",
            endTimeAmPm: "pm",
            taskUpdates: true,
            mentions: false
        },
        inApp: {
            enabled: false,
            taskUpdates: false,
            mentions: false
        }
    }
}

const initialState = {
    settings: defaultUserSettings,
    loaded: false
}

const userSettings = createSlice({
    name: 'userSettings',
    initialState,
    reducers: {
        loadSettings: (state, action) => {
            state.settings = action.payload;
            state.loaded = true;
        },
        resetSettings: (state) => {
            state.settings = defaultUserSettings;
            state.loaded = false;
        }
    }
})

export const { loadSettings, resetSettings } = userSettings.actions
export const userSettingsSelector = (state: RootState) => state.userSettings.settings
export const userSettingsLoadedSelector = (state: RootState) => state.userSettings.loaded;

export default userSettings.reducer
