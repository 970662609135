import React, { useState } from 'react';

const WebViewerContext = React.createContext<any>({});

export default WebViewerContext;

//TODO We could use this to split the code in webviewer.tsx into different files
//see https://docs.apryse.com/documentation/web/faq/react-global-instance/
export function WebViewerContextProvider(props: { children: JSX.Element }) {
  const [instance, setInstance] = useState();
  return <WebViewerContext.Provider value={{ instance, setInstance }}>{props.children}</WebViewerContext.Provider>
}
