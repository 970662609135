import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialState = {
    loading: 0
}

const progressLine = createSlice({
    name: "progressLine",
    initialState,
    reducers: {
        showProgressLine: (state) => {
            state.loading = state.loading + 1;
        },
        hideProgressLine: (state) => {
            state.loading = state.loading - 1;
        }
    }
});

export const { showProgressLine, hideProgressLine } = progressLine.actions;

export const progressLineSelector = (state: RootState) => state.progressLine.loading > 0

export default progressLine.reducer
