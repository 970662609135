
class FilterRule {
    public wordsForbiddenBefore: string[] = [];
    public patternForbiddenBefore: RegExp | undefined;
    public wordsForbiddenAfter: string[] = [];
    public patternForbiddenAfter: RegExp | undefined;
    public wordsForbiddenInPageTitle: string[] = [];
    public patternForbiddenInPageTitle: RegExp | undefined;

    constructor() {

    }

    public shouldFilter(searchResult: string, textBefore: string, textAfter: string, pageTitle: string) {
        const sr = searchResult.toLowerCase()
        const tb = textBefore.toLowerCase()
        const ta = textAfter.toLowerCase()
        const pt = pageTitle.toLowerCase()

        return this.wordsForbiddenBefore.some(forbiddenWord => tb.includes(forbiddenWord.toLowerCase())) ||
            (this.patternForbiddenBefore !== undefined && this.patternForbiddenBefore.test(tb)) ||
            this.wordsForbiddenAfter.some(forbiddenWord => ta.includes(forbiddenWord.toLowerCase())) ||
            (this.patternForbiddenAfter !== undefined && this.patternForbiddenAfter.test(ta)) ||
            this.wordsForbiddenInPageTitle.some(forbiddenWord => pt.includes(forbiddenWord.toLowerCase())) ||
            (this.patternForbiddenInPageTitle !== undefined && this.patternForbiddenInPageTitle.test(pt));
    }

    //This is the same as above except it logs the reason why the results were filtered. It's less efficient and will produce
    //a lot of logs so I'm not using it, but I'm keeping it here because it's useful for debugging.
    public shouldFilterVerbose(searchResult: string, textBefore: string, textAfter: string, pageTitle: string) {
        const sr = searchResult.toLowerCase()
        const tb = textBefore.toLowerCase()
        const ta = textAfter.toLowerCase()
        const pt = pageTitle.toLowerCase()
        let shouldFilter = false

        if (this.wordsForbiddenBefore.some(forbiddenWord => tb.includes(forbiddenWord.toLowerCase()))) {
            console.log(`Filtered out ${sr} because one of these forbidden words appeared before it ${this.wordsForbiddenBefore}`)
            shouldFilter = true
        }
        if (this.wordsForbiddenAfter.some(forbiddenWord => ta.includes(forbiddenWord.toLowerCase()))) {
            console.log(`Filtered out ${sr} because one of these forbidden words appeared after it ${this.wordsForbiddenAfter}`)
            shouldFilter = true
        }
        if (this.wordsForbiddenInPageTitle.some(forbiddenWord => pt.includes(forbiddenWord.toLowerCase()))) {
            console.log(`Filtered out ${sr} because one of these forbidden words appeared in the page title ${this.wordsForbiddenInPageTitle}`)
            shouldFilter = true
        }
        if ((this.patternForbiddenBefore !== undefined && this.patternForbiddenBefore.test(tb))) {
            console.log(`Filtered out ${sr} because the text before matched the forbidden pattern ${this.patternForbiddenBefore}`)
            shouldFilter = true
        }
        if ((this.patternForbiddenAfter !== undefined && this.patternForbiddenAfter.test(ta))) {
            console.log(`Filtered out ${sr} because the text after matched the forbidden pattern ${this.patternForbiddenAfter}`)
            shouldFilter = true
        }
        if ((this.patternForbiddenInPageTitle !== undefined && this.patternForbiddenInPageTitle.test(pt))) {
            console.log(`Filtered out ${sr} because the page title matched the forbidden pattern ${this.patternForbiddenInPageTitle}`)
            shouldFilter = true
        }

        return shouldFilter
    }
}

export default FilterRule
