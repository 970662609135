import React, { useEffect, useState } from "react";
import { Wizard } from "../../../components/wizard";
import { AccountColor, AccountWizard } from "../../admin/wizards/add-account-wizard";

export function UserColorWizard(props: { initials: string, color: string, setColor: (color: string) => void}) {

    const onSubmit = () => props.setColor(currentAccount.selectedColor!);

    const [currentAccount, setCurrentAccount] = useState<AccountWizard>({
        name: props.initials,
        selectedColor: props.color,
        onSubmit: () => { }
    })

    useEffect(() => {
        setCurrentAccount({ ...currentAccount, onSubmit: onSubmit })
    }, [currentAccount.name, currentAccount.selectedColor])

    return <div className="wizard-dialog" >
        <Wizard childrenData={currentAccount}>
            {[<AccountColor key={0} account={currentAccount} setAccount={setCurrentAccount}
                            title='Pick a color for your User Icon' />]}
        </Wizard>
    </div>
}
