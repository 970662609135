import {SplitText} from "./split-text";
import {Selections} from "./selections";

import "./search-pattern-generator.css"
import {
    anyCharType,
    anyLength,
    ellipses, endsWith, exactly,
    exactPhrase, followedBy, letters, nothing,
    numbers,
    numbersOrLetters,
    orFewer, orMore,
    partialMatch, punctuation, startsWith
} from "./constants";
import {Button} from "@mui/material";


//Patterns for parsing
//Check if the entire string matches
const digitsPattern: RegExp = /^[\d０-９]+$/;
const lettersPattern: RegExp = /^[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/;

export function TextChunkTable(props: {splitText: SplitText, selections: Selections, setSelections: (arg0: Selections) => void}) {

    const columns = props.splitText.pieces
    const puncColumns = props.splitText.punctuationMarks

    const exactMatchSelections = props.selections.exactMatchSelections
    const quantifierSelections = props.selections.quantifierSelections
    const startsWithSelections = props.selections.startsWithSelections
    const followedBySelections = props.selections.followedBySelections
    const endsWithSelections = props.selections.endsWithSelections
    const optionalSelections = props.selections.optionalSelections

    //TODO some of this logic is repeated in selections.ts . Refactor that into one place
    const addExactMatchSelector = (text: string, index: number, isPunctuation: boolean) => {
        const exact = `${exactPhrase} "${text}"`;
        const partial = partialMatch;
        let charType = numbersOrLetters;

        if (digitsPattern.test(text)) {
            charType = numbers;
        } else if (lettersPattern.test(text)) {
            charType = letters;
        } else if (isPunctuation) {
            charType = punctuation;
        }

        const any = `${anyCharType} ${charType}`;

        const handleSelectorChange = (event: { target: { value: string }; }) => {
            setSelectionAtIndex('exactMatchSelections', index, event.target.value);
        };

        return (
            <td key={`exact-match-${index}`}>
                <select value={exactMatchSelections[index]} onChange={handleSelectorChange}>
                    {isPunctuation ? (
                        <>
                            <option key={any}>{any}</option>
                            <option key={exact}>{exact}</option>
                            <option key={nothing}>{nothing}</option>
                        </>
                    ) : (
                        <>
                            <option key={exact}>{exact}</option>
                            <option key={partial}>{partial}</option>
                            <option key={any}>{any}</option>
                        </>
                    )}
                </select>
            </td>
        );
    };
    const addQuantifierSelector = (text: string, index: number, isPunctuation: boolean) => {
        const sameLength = `${exactly} ${text.length}`;
        const xorFewer = `${text.length} ${orFewer}`;
        const xorMore = `${text.length} ${orMore}`;

        const handleSelectorChange = (event: { target: { value: string }; }) => {
            setSelectionAtIndex('quantifierSelections', index, event.target.value);
        };
        if (exactMatchSelections[index] === undefined) {
            console.log('here')
        }


        const shouldDisplay = displayQuantifierCell(text, index)

        return (
            <td key={`quant-${index}`}>
                <select value={quantifierSelections[index]} onChange={handleSelectorChange} disabled={!shouldDisplay} hidden={!shouldDisplay}>
                    <option key={anyLength}>{anyLength}</option>
                    <option key={sameLength}>{sameLength}</option>
                    <option key={xorFewer}>{xorFewer}</option>
                    <option key={xorMore}>{xorMore}</option>
                </select>
            </td>
        );
    };

    const addStartsWithSelector = (text: string, index: number) => {
        const options = [];
        options.push(<option key="default">{`${startsWith}${ellipses}`}</option>);
        for (let i = 1; i < text.length; i++) {
            options.push(<option key={i}>{`${startsWith} ${text.substring(0, i)}`}</option>);
        }
        options.push(<option key="numbers">{`${startsWith} ${numbers}`}</option>);
        options.push(<option key="letters">{`${startsWith} ${letters}`}</option>);

        const handleSelectorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            setSelectionAtIndex('startsWithSelections', index, event.target.value);
        };

        const shouldDisplay = displayPartialMatchCell(text, index)

        return (
            <td key={`starts-${index}`}>
                <select value={startsWithSelections[index]} onChange={handleSelectorChange} disabled={!shouldDisplay} hidden={!shouldDisplay}>
                    {options}
                </select>
            </td>
        );
    };

    function addFollowedBySelector(text: string, index: number) {
        const handleSelectorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            setSelectionAtIndex('followedBySelections', index, event.target.value);
        };
        const shouldDisplay = displayPartialMatchCell(text, index)
        return (
            <td key={`followed-${index}`}>
                <select value={followedBySelections[index]} onChange={handleSelectorChange} disabled={!shouldDisplay} hidden={!shouldDisplay}>
                    <option>{`${followedBy} ${numbersOrLetters}`}</option>
                    <option>{`${followedBy} ${numbers}`}</option>
                    <option>{`${followedBy} ${letters}`}</option>
                </select>
            </td>
        );
    }

    function addEndsWithSelector(text: string, index: number): JSX.Element {

        const handleSelectorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            setSelectionAtIndex('endsWithSelections', index, event.target.value);
        };

        const options = [];
        options.push(<option key="default">{`${endsWith} ${ellipses}`}</option>);
        for (let i = text.length - 1; i >= 1; i--) {
            options.push(
                <option key={i}>{`${endsWith} ${text.substring(i)}`}</option>
            );
        }
        options.push(<option key="numbers">{`${endsWith} ${numbers}`}</option>);
        options.push(<option key="letters">{`${endsWith} ${letters}`}</option>);
        const shouldDisplay = displayPartialMatchCell(text, index)
        return (
            <td key={`ends-${index}`}>
                <select value={endsWithSelections[index]} onChange={handleSelectorChange} disabled={!shouldDisplay} hidden={!shouldDisplay}>
                    {options}
                </select>
            </td>
        );
    }

    function addOptionalCheckbox(index: number) {
        const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const checked = event.target.checked
            const newOptionalSelections = [...optionalSelections]
            newOptionalSelections[index] = checked
            //If the user marked a text chunk as optional, mark the punctuation around it as optional as well.
            if (index - 1 >= 0 && puncColumns.includes(columns[index - 1])) {
                newOptionalSelections[index - 1] = checked
            }
            if (index + 1 <= columns.length - 1 && puncColumns.includes(columns[index + 1])) {
                newOptionalSelections[index + 1] = checked
            }
            let newSelections = props.selections.clone()
            newSelections.optionalSelections = newOptionalSelections
            //reset before and after table
            newSelections.resetBeforeAndAfter()
            props.setSelections(newSelections)
        }

        return (
            <td key={`optional-${index}`}>
                <label>optional</label>
                <input type="checkbox" checked={optionalSelections[index]} onChange={onChange}/>
            </td>
        )
    }

    const setSelectionAtIndex = (fieldName: string, index: number, newValue: string) => {
        let newSelections = props.selections.clone()
        newSelections[fieldName][index] = newValue
        newSelections.resetBeforeAndAfter()
        props.setSelections(newSelections)
    }

    const displayRow = (displayCell: (chunk: string, index: number) => boolean) => {
        return columns.some((chunk, index) => displayCell(chunk, index))
    }

    const displayQuantifierCell = (chunk: string, index: number) => {
        return exactMatchSelections[index].startsWith(anyCharType) && !puncColumns.includes(chunk)
    }

    const displayPartialMatchCell = (chunk: string, index: number) => {
        return exactMatchSelections[index] === partialMatch
    }

    const resetTable = () => {
        let newSelections = new Selections(props.splitText.pieces, props.splitText.punctuationMarks)
        props.setSelections(newSelections)
    }

    return <div className="table-and-reset-button-container">
        <table id="wizard_table">
            <tbody>
            <tr>
                {columns.map((chunk, index) => (
                    <td key={index}>{chunk}</td>
                ))}
            </tr>
            <tr>
                {columns.map((chunk, index) => (
                    addExactMatchSelector(chunk, index, puncColumns.includes(chunk))
                ))}
            </tr>
            <tr style={{ display: displayRow(displayQuantifierCell)? "" : "none" }}>
                {columns.map((chunk, index) => (
                    addQuantifierSelector(chunk, index, puncColumns.includes(chunk))
                ))}
            </tr>
            <tr style={{ display: displayRow(displayPartialMatchCell)? "" : "none" }}>
                {columns.map((chunk, index) => (
                    addStartsWithSelector(chunk, index)
                ))}
            </tr>
            <tr style={{ display: displayRow(displayPartialMatchCell)? "" : "none" }}>
                {columns.map((chunk, index) => (
                    addFollowedBySelector(chunk, index)
                ))}
            </tr>
            <tr style={{ display: displayRow(displayPartialMatchCell)? "" : "none" }}>
                {columns.map((chunk, index) => (
                    addEndsWithSelector(chunk, index)
                ))}
            </tr>
            <tr>
                {columns.map((chunk, index) => (
                    addOptionalCheckbox(index)
                ))}
            </tr>
            </tbody>
        </table>
        <Button variant="contained" color="secondary" id="rg_button_copy" onClick={resetTable}>Reset</Button>
    </div>
}
