import { CustomField } from "../models/CustomField";
import { CustomFieldValue } from "../models/CustomFieldValue";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
export function getApiCustomFields(signal?: AbortSignal): Promise<CustomField[]> {
    const url = new URL(`/api/custom-fields`, baseUrl);
    return fetchAndParse<CustomField[]>(url.toString(), { method: "GET", headers, signal });
}
export function getApiCustomFieldsValues(signal?: AbortSignal): Promise<CustomFieldValue[]> {
    const url = new URL(`/api/custom-fields-values`, baseUrl);
    return fetchAndParse<CustomFieldValue[]>(url.toString(), { method: "GET", headers, signal });
}
export function postApiCustomFieldsValues(body?: CustomFieldValue, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/custom-fields-values`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}

export function putApiCustomFieldsSorting(sourceId: number, targetId: number, signal?: AbortSignal): Promise<any> {
    const url = new URL(`/api/custom-fields-sorting`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify({sourceId, targetId}), headers, signal });
}
export function putApiCustomFieldsValues(body?: CustomFieldValue, signal?: AbortSignal): Promise<CustomFieldValue> {
    const url = new URL(`/api/custom-fields-values`, baseUrl);
    return fetchAndParse<CustomFieldValue>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function deleteApiCustomFieldsValuesById(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/custom-fields-values/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
export function getApiCustomFieldsValuesTaskListById(id: number, signal?: AbortSignal): Promise<CustomFieldValue[]> {
    const url = new URL(`/api/custom-fields-values/task-list/${id}`, baseUrl);
    return fetchAndParse<CustomFieldValue[]>(url.toString(), { method: "GET", headers, signal });
}
