import { useCustomModal } from "../custom-message-modal";
import { StyledCloseIcon } from "../../../components/close-button";

export function DeleteSinglePMModal() {
    const { hideModal } = useCustomModal();

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <StyledCloseIcon onClick={hideModal} />
            <span style={{ fontSize: 15, fontWeight: "600", marginTop: 10, width: "95%" }} >
                You cannot delete the only user assigned as a Project Manager in the account.
            </span>
            <p style={{ fontSize: 15 }}>
                After inviting another Project Manager you’ll be able to delete that user.
            </p>
        </div>
    </div>
}
