import { Template } from "../../../models/Template";
import { Button, styled } from "@mui/material";
import { useCustomModal } from "../../modals/custom-message-modal";
import { deleteApiTemplatesById } from "../../../services/template";
import { useAppDispatch } from "../../../hooks/redux-hook";
import { useState } from "react";
import { showSnackbar } from "../../../redux/snackbar";
import { ButtonSpinner } from "../../../components/button-spinner";
import { StyledCloseIcon } from "../../../components/close-button";
import { removeTemplate } from "../../../redux/projectTemplates";

const StyledCancelButton = styled(Button)({
    color: '#217DA2',
    borderColor: '#217DA2'
});

export function ProjectTemplateDelete(props: { template: Template }) {

    const { hideModal } = useCustomModal();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const deleteTemplate = () => {
        const controller = new AbortController();
        setLoading(true);
        deleteApiTemplatesById(props.template.id, controller.signal)
            .then(() => {
                dispatch(removeTemplate(props.template.id));
                hideModal();
            })
            .then(() => dispatch(showSnackbar({
                message: `Project template ${props.template.name} has been removed from this account.`,
                type: "info"
            })))
            .catch(() => {
                setLoading(false);
                dispatch(showSnackbar({
                    message: "Error deleting template!",
                    type: "error"
                }));
            });
    }

    return <div className="wizard-dialog" style={{ padding: 32, display: 'flex', flexDirection: 'column' }}>
        <StyledCloseIcon onClick={() => loading ? {} : hideModal()} />
        <span style={{ color: '#C13826', fontSize: 24, fontWeight: 600, marginBottom: 16 }}>
            Remove {props.template.name}?
        </span>
        <span style={{ marginBottom: 24 }}>This template will be removed from the account, but
            all the projects based on this template will remain and
            will not be removed or deleted.</span>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledCancelButton disabled={loading} variant="outlined" onClick={hideModal}>CANCEL</StyledCancelButton>
            <Button disabled={loading} variant="contained" color="secondary" onClick={deleteTemplate}>
                REMOVE TEMPLATE {loading && <ButtonSpinner />}
            </Button>
        </div>
    </div>
}
