
import { Autocomplete, Avatar, Box, Popper, TextField } from "@mui/material";
import AddUser from "../provisional_icons/assign-user.svg";
import AddUserBlue from "../provisional_icons/assign-user-blue.svg";
import AddUserDisabled from "../provisional_icons/assign-user-disabled.svg";
import React, { useEffect, useRef, useState } from "react";
import { Search } from "@mui/icons-material";
import { getUserInitials } from "../services/utils";
import { InfoTenantUser } from "../models/InfoTenantUser";
import { UsersAvatarList } from "./users-avatar-list";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hook";
import { selectedProjectSelector } from "../redux/projects";
import { useAuthService } from "../contexts/auth-context";
import { setShowToolbarAfterDeletion } from "../redux/action-bar";

export function UsersSelect(p: {
    allUsers: InfoTenantUser[], selectedUsers: InfoTenantUser[], multiple: boolean,
    noLeft?: boolean, taskRef?: HTMLElement | null, onChange: (value: number | number[] | undefined) => void,
    showAvatar?: boolean, color?: string, size?: number, maxAvatars?: number, isTaskList?: boolean
}) {

    const auth = useAuthService();
    const projectId = useAppSelector((state) => selectedProjectSelector(state));
    const userList = p.allUsers.filter(user => !p.selectedUsers.find(su => su.id === user.id));
    const [isAutocompleteVisible, setIsAutocompleteVisible] = useState(false);
    const autocompleteRef = useRef(null);
    const userIconRef = useRef(null);
    const windowHandlerRef = useRef((ev: MouseEvent) => {
        const element = autocompleteRef.current! as HTMLElement;
        const iconElement = userIconRef.current! as HTMLElement;
        if (element !== null && iconElement !== null) {
            const el = ev.target as HTMLElement;
            if (!(element.contains(el) || iconElement.contains(el))) {
                setIsAutocompleteVisible(false);
            }
        }
    });
    const dispatch = useAppDispatch();
    const windowScrollRef = useRef(() => {
        setIsAutocompleteVisible(false);
    });
    const textFieldRef = useRef<string>();

    useEffect(() => {
        const scrollElement = document.getElementById(`project-data-${projectId || 0}`);
        if (isAutocompleteVisible) {
            if (userIconRef.current !== null && autocompleteRef.current !== null) {
                const rect = (userIconRef.current as HTMLElement).getBoundingClientRect();
                (autocompleteRef.current as HTMLElement).style.top = `${rect.top + rect.height + 8}px`;
                (autocompleteRef.current as HTMLElement).style.left = `${(userIconRef.current as HTMLElement).getBoundingClientRect().left - 100}px`;
            }
            if (scrollElement) {
                scrollElement.addEventListener('scroll', windowScrollRef.current);
            }
            setTimeout(() => {
                if (textFieldRef.current && document.getElementById(textFieldRef.current) && isAutocompleteVisible) {
                    document.getElementById(textFieldRef.current)!.focus();
                }
            }, 100);
        } else {
            window.removeEventListener('click', windowHandlerRef.current);
            if (scrollElement) {
                scrollElement.removeEventListener('scroll', windowScrollRef.current);
            }
        }
    }, [isAutocompleteVisible]);

    const selectUser = () => {
        dispatch(setShowToolbarAfterDeletion(false));
        if (p.allUsers.length === p.selectedUsers.length) {
            return;
        }
        if (isAutocompleteVisible) {
            setIsAutocompleteVisible(false);
            return
        }
        setIsAutocompleteVisible(true);
        window.addEventListener('click', windowHandlerRef.current);
    }

    const popperId = "popperId";
    return p.multiple ?
        <></>
        :
        p.allUsers.length > 0 ?
            <div style={p.showAvatar ? { marginTop: 4, display: "flex", justifyContent: "center" } : { marginLeft: 16 }}>
                {p.showAvatar && <>
                    <UsersAvatarList users={p.selectedUsers} shortView={false} maxAvatars={p.maxAvatars}
                        removeUser={(user) =>
                            p.onChange(p.selectedUsers.filter(su => su.id !== user.id).map(su => su.id))}
                        small={p.size !== 32} />
                </>
                }

                {!auth.hasPMRoleAccess() || (p.selectedUsers.length > 0 && p.isTaskList) ? <></> :
                    <>
                        {p.selectedUsers.length > 0 && <div style={{ width: 8 }} />}
                        {p.color === "blue" && p.allUsers.length > p.selectedUsers.length ?
                            <img alt="add user" src={AddUserBlue}
                                style={{ width: p.showAvatar ? (p.size ?? 24) : 36, cursor: "pointer" }}
                                ref={userIconRef}
                                onClick={selectUser} />
                            :
                            <img alt="add user" src={AddUser}
                                style={{ width: p.showAvatar ? (p.size ?? 24) : 36, cursor: "pointer" }}
                                ref={userIconRef}
                                onClick={selectUser} />
                        }
                    </>
                }
                {isAutocompleteVisible && <Autocomplete
                    ref={autocompleteRef}
                    size="medium"
                    PopperComponent={(props) => <Popper id={popperId} {...props} style={{ minWidth: 244, width: "fit-content" }}
                        placement="bottom-start" />}
                    open={isAutocompleteVisible}
                    openOnFocus={true}
                    style={{ minWidth: 244, position: "fixed", zIndex: 1001, backgroundColor: "#FFF" }}
                    key={userList.length}
                    options={userList.map(user => ({ id: user.id, label: user.name, color: user.color }))}
                    renderInput={(params) => {
                        const newParams = {
                            ...params,
                            InputProps: {
                                ...params.InputProps,
                                endAdornment: undefined,
                                startAdornment: <Search style={{ color: "var(--normalBlue)" }} />
                            }
                        }
                        textFieldRef.current = params.id;
                        return <TextField {...newParams} placeholder="Search for users"
                            style={{ width: document.getElementById(popperId)?.offsetWidth }} />
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" {...props} style={{ height: 40, margin: "8px 0" }} key={option.id}>
                            <Avatar sx={{ width: 24, height: 24, bgcolor: option.color ?? "var(--normalBlue)", fontSize: 12 }}>
                                {getUserInitials(option.label)}
                            </Avatar>
                            <div style={{ fontWeight: 600, marginLeft: 16 }}>{option.label}</div>
                        </Box>
                    )}
                    onChange={(ev, value) => {
                        if (value !== null) {
                            p.onChange([...(p.selectedUsers.map(su => su.id)), value.id]);
                        }
                    }} />}
            </div>
            :
            <img alt="add user" src={AddUserDisabled} style={{ marginLeft: 16 }} />
}
