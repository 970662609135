import { TaskList } from "../../models/TaskList";
import { Task } from "../../models/Task";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { ProjectListTable } from "./project-list-table";

export function GroupTable(p: { title: string, color: string, taskList: TaskList, displayedTasks: Task[], projectId: number, projectName: string,
    newTask: boolean, setNewTask: (value: boolean) => void, isTemplate: boolean, refreshList: (key?: string) => void }) {

    const [toggle, setToggle] = useState(true);

    return <div style={{ display: "flex", flexDirection: "column", marginTop: 32 }}>
        <div style={{ display: "flex", fontSize: 21, fontWeight: 600, letterSpacing: 0.15, color: p.color, alignItems: "center" }}>
            {toggle ?
                <ArrowDropDownIcon sx={{width: 24, height: 24, marginRight: "8px!important", cursor: "pointer"}}
                                   onClick={() => setToggle(!toggle)} />
                :
                <ArrowRightIcon sx={{width: 24, height: 24, marginRight: "8px!important", cursor: "pointer"}}
                                onClick={() => setToggle(!toggle)} />
            }
            {p.title}
            <span style={{ fontSize: 13,  letterSpacing: 0.4, fontWeight: "normal",
                marginLeft: 8, marginTop: 4, color: "#223250" }}>
                ({p.displayedTasks.length})
            </span>
        </div>
        {toggle && <ProjectListTable key={`tableList-${p.title}`} taskList={p.taskList} displayedTasks={p.displayedTasks} newTask={p.newTask}
                                     setNewTask={p.setNewTask} refreshList={p.refreshList} isTemplate={p.isTemplate}
                                     projectId={p.projectId} projectName={p.projectName} groupName={p.title} />}
    </div>
}
