const DefaultRedactSearchPatterns = [
  {
    label: 'Patient or Subject ID',
    type: 'patient_subj_id',
    regex: /(?<!=\s)(?<=(?:patient|subject|subject\\sno|subject\\snumber|subj)s?\.?\\s?(?:id)?:?\\s?)\\d+-?\\d*(?!\.\\d)(?!%)/gi,
  },
  {
    label: 'Patient IDs',
    type: 'patient_ids',
    regex: /(?<=(?:patient|subject|subject\sno|subject\snumber|subj)s?\.?\\s?(?:id)?:?\\s?[\\d,\\s()]{3,150}(?:and\\s)?\d{0,10}\\s?\(?)\\d+(?!\.\\d)(?!%)/gi,
  },
  {
    label: '8 digit doesn\'t start with 0',
    type: '8digits_nozero',
    regex: /(?<![.)])[1-9]\\d{7}/gi,
  },
  {
    label: '6 digit number',
    type: '6digits',
    regex: /(?<![.)])\\d{6}(?!\.\\d)/gi,
  },
  {
    label: '1 to 5 digit number labeled as a patient ID',
    type: 'patient_id_15',
    regex: /(?<!=\\s)(?<!\.)\\b\\d{1,5}-?\\d*(?![.\\/])\\b(?!\.)(?!\\s\(\\d{1,2}\.\\d\))(?!%)/gi,
  },
  {
    label: '4 digit number, followed by more than 4 digits',
    type: '4digits',
    regex: /\\d{4}-\\s*\\d{4,}/gi,
  },
  {
    label: 'Two letters, 3 digits, 4-6 digits',
    type: '2letters_3digits_46digits',
    regex: /[a-z]{2}\\d{3}-\\s?\\d{4,6}/gi,
  }
];
export default DefaultRedactSearchPatterns;
