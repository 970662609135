import {Pattern} from "../models/Pattern";

export const ManualMarkMenu = (props: {categories: {label: string, type: string}[],
                                        onChange: (newCategory: string) => void,
                                        selectedItem: string|undefined,
                                        patternMap: Map<string, Pattern>}) => {

    const handleChange = (event: any) => {
        props.onChange(event.target.value);
    }

    const getElementColor = (patternName: string) => {
        if (props.patternMap.has(patternName) && props.patternMap.get(patternName)?.color) {
            return props.patternMap.get(patternName)!.color;
        } else {
            return '#FFFFFF';
        }
    }

    return (
        <select style={{ height: '32px'}}
            title={"Manual Mark Category"}
            onChange={handleChange}
        >
            <option value="" disabled selected={props.selectedItem === undefined} hidden={true}>Manual Mark Category</option>
            {props.categories.map(category =>
                <option key={category.type} id={category.type} value={category.type} selected={props.selectedItem !== undefined && props.selectedItem === category.type}>
                    {category.label}
                </option>
            )}
        </select>
    );
}

