import React, { useState } from 'react';
import {markStyles} from "../constants";

const SelectedMarkStyleContext = React.createContext<any>(markStyles[0]);

export default SelectedMarkStyleContext;

export function SelectedMarkStyleContextProvider(props: { children: JSX.Element }) {
    const [selectedMarkStyle, setSelectedMarkStyle] = useState(markStyles[0]);
    return <SelectedMarkStyleContext.Provider value={{ selectedMarkStyle, setSelectedMarkStyle }}>{props.children}</SelectedMarkStyleContext.Provider>
}
