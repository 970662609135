import React, {useContext, useState} from 'react';
import { useDispatch } from 'react-redux';
import {useCustomModal} from "../pages/modals/custom-message-modal";
import WebViewerContext from '../contexts/webviewer-context';
import {hideProgressLine, showProgressLine} from "../redux/progress-line";
import {SketchPicker} from 'react-color'
import classNames from "classnames";
import './OutlineColorPickerModal.scss'
import './AnnotationRepeatPopup.scss'
import {convertHexStringToRgbArray} from "./webviewer";
import {showSnackbar} from "../redux/snackbar";

export default function OutlineColorPickerModal(props: {annotations: any[]}) {
    const dispatch = useDispatch();
    const { setInstance,instance } = useContext(WebViewerContext);
    const annotationManager = instance.Core.annotationManager;
    const [standerdizeColor, setStanderdizeColor] = useState(true);
    const [selectedColor, setSelectedColor] = useState<string>('#000000');
    const [processing, setProcessing] = useState(false)
    const {hideModal} = useCustomModal();

    const pallete = [
        '#F1A099',
        '#FFC67B',
        '#FFE6A2',
        '#80E5B1',
        '#92E8E8',
        '#A6A1E6',
        '#E2A1E6',
        '#E44234',
        '#FF8D00',
        '#FFCD45',
        '#00CC63',
        '#25D2D1',
        '#4E7DE9',
        '#C544CE',
        '#88271F',
        '#B54800',
        '#F69A00',
        '#007A3B',
        '#167E7D',
        '#2E4B8B',
        '#76287B',
        '#FFFFFF',
        '#CDCDCD',
        '#9C9C9C',
        '#696969',
        '#272727',
        '#000000',
    ];

    const handleColorChange = (newColor: any) => {
        setSelectedColor(newColor.hex);
    }

    const handleColorSelect = (newColor: string) => {
        setSelectedColor(newColor);
    }
    const handleCancelClick = () => {
        hideModal()
    }

    const standerdizeOutlineColor = async () => {
        props.annotations.forEach((annotation) => {
            annotation.StrokeColor = annotation.FillColor
            annotationManager.redrawAnnotation(annotation);
        })
    }

    const setSelectedOutlineColor = async () => {
        const rgbColor = convertHexStringToRgbArray(selectedColor)
        const color = new instance.Core.Annotations.Color(rgbColor[0], rgbColor[1], rgbColor[2])
        props.annotations.forEach((annotation) => {
            annotation.StrokeColor = color
            annotationManager.redrawAnnotation(annotation);
        })
    }

    const changeAnnotationsOutlineColor = async () => {
        if (standerdizeColor) {
            await standerdizeOutlineColor()
        } else {
            await setSelectedOutlineColor()
        }
    }

    const handleOkClick = () => {
        setProcessing(true);
        dispatch(showProgressLine());
        setTimeout(function() {
            changeAnnotationsOutlineColor().then(() => {
                dispatch(showSnackbar({ message: `Outline Color successfully changed!`, type: "info" }));
                exit();
            }).catch((error) => {
                console.log(error);
                dispatch(showSnackbar({ message: `Error updating status`, type: "error" }));
                exit();
            });
        }, 10);
    }

    const exit = () => {
        setProcessing(false);
        dispatch(hideProgressLine());
        hideModal()
    }

    const transparentIcon = (
        <svg
            width="100%"
            height="100%"
            className={classNames({
                transparent: true,
            })}
        >
            <line stroke="#d82e28" x1="0" y1="100%" x2="100%" y2="0" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );

    return (
        <div style={{margin: 20}}>
            <div data-element="colorPalette">
                <div style={{display: "flex", textAlign:"left", justifyContent: "space-between"}}>
                    <input type={"checkbox"} className={'checkButton'} checked={standerdizeColor} onChange={()=> setStanderdizeColor(!standerdizeColor)}/>
                    <p>Standardize annotation outline color per current regulation</p>
                </div>
                <div className={standerdizeColor ? 'disabled-div' : ''}>
                <div
                    data-element="colorPalette"
                    className={classNames({
                        'ColorPalette': true,
                        padding: false
                    })}
                    style={{marginBottom: 20, cursor: standerdizeColor ? 'none' : ''}}
                    aria-disabled={true}
                >
                    {pallete.map((bg, i) => (
                        !bg
                            ? <div key={i} className="dummy-cell" />
                            : <button
                                key={i}
                                className="cell-container"
                                onClick={() => handleColorSelect(bg)}
                            >
                                <div
                                    className={classNames({
                                        'cell-outer': true,
                                        active: selectedColor === bg,
                                    })}
                                >
                                    <div
                                        className={classNames({
                                            cell: true,
                                            border: bg === '#FFFFFF',
                                        })}
                                        style={{ backgroundColor: bg }}
                                    >
                                        {bg === 'transparency' && transparentIcon}
                                    </div>
                                </div>
                            </button>
                    ))}
                </div>

                <SketchPicker
                        //@ts-ignore
                        color={selectedColor}
                        disableAlpha
                        onChange={handleColorChange}
                        presetColors={[]}/>
                </div>
            </div>
            <div className="button-container">
                <button className="rounded-button cancel-button" onClick={handleCancelClick}
                        disabled={processing}>Cancel
                </button>
                <button className="rounded-button ok-button" onClick={handleOkClick}
                        disabled={processing}>OK
                </button>
            </div>
        </div>
    )
}

