import React, { ChangeEvent, useState } from "react";
import "./project/project.scss"
import { GridColDef, GridRenderCellParams, GridRowsProp } from "@mui/x-data-grid-pro";
import {Box, Link } from "@mui/material";
import { Project } from "../models/Project";
import { useAppSelector } from "../hooks/redux-hook";
import { useNavigate } from "react-router-dom";
import { projectFiltersSelector } from "../redux/project-filters";
import { DataGrid } from "@mui/x-data-grid";
import { CustomPaginationFooter } from "./archived-projects-component";

export function CompletedProjectsComponent(p: { projects: Project[], fetchProject: (id: number) => void, setSelectedCompletedProjectIds: (ids: number[]) => void }) {
    const nav = useNavigate();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const filters = useAppSelector((state) => projectFiltersSelector(state, 0));
    const completedProjects = p.projects.filter((project: Project) => project!.projectStatusDates!.status === "COMPLETE" && !project.isArchived);
    const data = getRows();
    const rowsPerPage = 25;
    const totalPages = Math.ceil(completedProjects.length / rowsPerPage)

    function getRows() {
        let rows: GridRowsProp = [];
        if (p.projects.length === 0) {
            return rows;
        }

        for(let project of completedProjects) {
            if (filters.searchTerm === "" || project.name.toLowerCase().includes(filters.searchTerm.toLowerCase())) {
                rows = rows.concat( {
                    id: project.id,
                    col1: project.name,
                });
            }
        }

        return rows;
    }

    const columns: GridColDef[] = [
        {
            field: 'col1', headerName: 'Project Name', flex: 10, renderCell: (params: GridRenderCellParams) => {
                return <Link component="button" underline="hover" onClick={() => {
                    nav(`/app/user/workflow/projects/${params.row.id}`)
                }}>
                    {params.row.col1}
                
                </Link>
            }
        },
    ];

    return <Box style={{ minHeight: 350, height: 500, maxHeight: 600, width: '100%' }}>
        <DataGrid
            sx={{
                // disable cell selection style
                '.MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                // pointer cursor on ALL rows
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer'
                }
              }}
            initialState={{
                pagination: { pageSize: 25 },
            }}
            rowHeight={30}
            style={{ height: "calc(100vh - 348px)" }}
            rows={getRows()}
            hideFooter={true}
            // @ts-ignore
            columns={columns}
            pagination
            page={currentPage - 1}
            checkboxSelection
            onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = data.filter((row) =>
                    selectedIDs.has(row.id),
                );
                p.setSelectedCompletedProjectIds(selectedRows.map((row) => row.id));
            }}
            disableRowSelectionOnClick={true}
            {...data}
        />
        <CustomPaginationFooter currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} noData={completedProjects.length === 0} />
    </Box>
}