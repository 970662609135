

import Button from "@mui/material/Button";
import { useCustomModal } from "../custom-message-modal";
import './remove-entity-modal.scss';
import { StyledBackArrow, StyledCloseIcon } from "../../../components/close-button";
import { useState } from "react";
import { putApiProjectsById } from "../../../services/template";
import { Project } from "../../../models/Project";
import { ButtonSpinner } from "../../../components/button-spinner";
import { SubmissionType } from "../../../models/SubmissionType";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export function EditSubmissionModel(props: { value: SubmissionType, project: Project, fetchProject: () => void, submissionTypes: SubmissionType[] }) {
    const { hideModal } = useCustomModal();
    const [submitted, setSubmitted] = useState(false);
    const [selected, setSelected] = useState<SubmissionType>(props.value);

    const onSubmit = () => {
        setSubmitted(true);
        putApiProjectsById(props.project.id, {
            name: props.project.name,
            color: props.project.color,
            studyId: props.project.studyId,
            submissionTypeId: selected.id,
            organizationShare: props.project.organizationShare,
            users: [],
            markStyleName: props.project.markStyleName,
            isArchived: props.project.isArchived || false,
        })
            .then(() => {
                props.fetchProject();
                hideModal();
            })
            .catch(e => {
                setSubmitted(false);
            })
    }

    return <div style={{ width: 420, height: 414, display: "flex", marginTop: 36 }}>
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <StyledBackArrow onClick={() => hideModal()} />
            <StyledCloseIcon onClick={() => hideModal()} />
            <p style={{ textAlign: "center", marginTop: 10, fontSize: 25, }}>Edit type of submission</p>
            <div style={{ marginLeft: 45, }}>
                {props.submissionTypes.map(type => <div style={{ display: "flex", marginBottom: 18, cursor: "pointer" }} key={type.id}>
                    {+type.id === selected?.id ? <RadioButtonCheckedIcon color="secondary" /> :
                        <RadioButtonUncheckedIcon color="disabled" onClick={() => setSelected(type)} />}
                    <div style={{ marginLeft: 16 }}>{type.name}</div>
                </div>)}
            </div>
            <Button style={{ marginTop: 32, width: 178, alignSelf: "center" }}
                variant="contained" color="secondary" disabled={submitted}
                onClick={onSubmit}
            >
                SAVE CHANGES {submitted && <ButtonSpinner />}
            </Button>
        </div>
    </div>
}
