import React from "react";
import { Fade, Snackbar } from "@mui/material";
import "./snackbar.scss";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hook";
import { hideSnackbar, snackbarSelector } from "../redux/snackbar";

export function CustomSnackbar() {

    const snackbar = useAppSelector((state) => snackbarSelector(state));
    const dispatch = useAppDispatch();

    return (
        <Snackbar
            open={snackbar.isOpen}
            onClose={() => dispatch(hideSnackbar())}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={3000}
            TransitionComponent = {Fade}
        >
            <div className={snackbar.type === "info" ? "snackbar-message-info" : "snackbar-message-error"}>
                {snackbar.message}
            </div>
        </Snackbar>
    );
}
