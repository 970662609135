import { Button, Menu, MenuItem, TextField, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hook";
import { CustomField } from "../models/CustomField";
import { customFieldsSelector, loadCustomFieldsValues, cfvByTaskListSelector, updateCustomFieldValue, createCustomFieldValue, deleteCustomFieldValue } from "../redux/custom-fields";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEventHandler } from "../hooks/handle-events";
import { deleteApiCustomFieldsValuesById, getApiCustomFieldsValuesTaskListById, postApiCustomFieldsValues, putApiCustomFieldsValues } from "../services/custom.fields";
import { Task } from "../models/Task";
import { CustomFieldValue } from "../models/CustomFieldValue";
import AddIcon from '@mui/icons-material/Add';
import { hideProgressLine, showProgressLine } from "../redux/progress-line";
import { showSnackbar } from "../redux/snackbar";
import { loadProject, projectSelector, selectedProjectSelector } from "../redux/projects";
import { CustomFieldKeyTypes } from "./project/task";
import { putApiProjectsProjectCustomFieldsById } from "../services/project";
import { useAuthService } from "../contexts/auth-context";
import { setShowToolbarAfterDeletion } from "../redux/action-bar";

export function CustomFieldComponent(props: { isTemplate: boolean, task: Task }) {

    const auth = useAuthService();
    const [anchorElCustomFields, setAnchorElCustomFields] = useState<HTMLElement | null>(null);
    const [newCustomField, setNewCustomField] = useState<CustomField>();
    const [customFieldsValuesTask, setCustomFieldValuesTask] = useState<CustomFieldValue[]>([]);
    const [isButtonHover, setIsButtonHover] = useState(false);
    const customFields: CustomField[] = useAppSelector((state) => customFieldsSelector(state));
    const customFieldsValues = useAppSelector((state) => cfvByTaskListSelector(state, props.task.taskListId)).customFieldsValues;
    const theme = useTheme();
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const dispatch = useAppDispatch();
    const openedCustomFields = Boolean(anchorElCustomFields);

    useEffect(() => {
        getApiCustomFieldsValuesTaskListById(props.task.taskListId).
        then(cfv => dispatch(loadCustomFieldsValues({
            taskListId: props.task.taskListId,
            customFieldsValues: cfv
        })));
    }, [props.task]);

    useEffect(() => {
        setCustomFieldValuesTask(customFieldsValues.filter(cvf => cvf.taskId === props.task.id));
    }, [customFieldsValues])

    return <div style={{ marginBottom: 32 }}>
        {auth.hasPMRoleAccess() && <Button disabled={props.isTemplate}
                onClick={(el) => {
                    dispatch(setShowToolbarAfterDeletion(false));
                    setAnchorElCustomFields(el.currentTarget);
                }}
                onMouseEnter={() => setIsButtonHover(true)}
                onMouseLeave={() => setIsButtonHover(false)}
                style={{
                    display: "flex", marginBottom: 8,
                    backgroundColor: openedCustomFields ? "#E4EFF4" : isButtonHover && !props.isTemplate ? "#F6FAFB" : "",
                    color: props.isTemplate ? "gray" : theme.palette.secondary.main
                }}>
            <AddIcon style={{ marginRight: 4 }} /> ADD CUSTOM FIELD
        </Button>}
        {customFieldsValuesTask.map((cfvt, index) =>
            <CustomFieldTextField index={index} key={cfvt.id} customFieldValue={cfvt} task={props.task}
                                  name={customFields.find(cf => cf.id === cfvt.customFieldId)?.name!} isNewCustomField={false} />
        )}
        {newCustomField && <CustomFieldTextField index={undefined} task={props.task} customFieldShortName={newCustomField.shortName}
                                                 customFieldValue={{ id: -1, taskId: props.task.id, customFieldId: newCustomField.id, valueString: "" }}
                                                 name={newCustomField?.name!} isNewCustomField setNewCustomField={setNewCustomField} />
        }
        {openedCustomFields &&
            <Menu
                id="basic-menu"
                anchorEl={anchorElCustomFields}
                open={openedCustomFields}
                onClose={() => setAnchorElCustomFields(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                style={{ padding: 0, marginTop: 8 }}
                PaperProps={{ sx: { width: 201 } }}
            >
                {customFields.filter(cf => !customFieldsValuesTask.some(cfvt => cf.id === cfvt.customFieldId)).map(cf =>
                    <MenuItem
                        sx={{ fontSize: 15, letterSpacing: 0.24 }}
                        key={cf.name}
                        onMouseEnter={onMouseEventHandler} onMouseLeave={onLeaveMouseEventHandler}
                        onClick={() => {
                            setAnchorElCustomFields(null);
                            setNewCustomField(cf);
                        }}>
                        {cf.name}
                    </MenuItem>)}
            </Menu>
        }
    </div>
}

function CustomFieldTextField (p: { name: string, index: number | undefined, isNewCustomField: boolean,
    customFieldValue: CustomFieldValue, task: Task, customFieldShortName?: string,
    setNewCustomField?: (customField: CustomField | undefined) => void }) {

    const auth = useAuthService();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [isFocused, setIsFocused] = useState(p.isNewCustomField);
    const [isHover, setIsHover] = useState(false);
    const [value, setValue] = useState<string | null | undefined>(p.customFieldValue.valueString || "");
    const [isEditEnabled, setIsEditEnabled] = useState(false);
    const ref = useRef<HTMLInputElement | null>(null);
    const projectId = useAppSelector((state) => selectedProjectSelector(state));
    const project = useAppSelector((state) => projectSelector(state, projectId));

    useEffect(() => {
       setValue(p.customFieldValue.valueString);
    }, [p.customFieldValue.valueString]);


    useEffect(() => {
        if (p.isNewCustomField) {
            ref.current?.focus();
        }
    }, [isFocused])

    const borderFocused = `2px solid ${theme.palette.primary.main}`;
    const borderUnfocused = `1px solid lightgray`;
    const border = isFocused && auth.hasPMRoleAccess() ? borderFocused : borderUnfocused;

    const textFieldOnClick = () => {
        setIsFocused(true);
        setValue(p.customFieldValue.valueString);
        if (!isFocused) {
            setIsEditEnabled(true);
        }
    }

    const checkOnClick = () => {
        dispatch(showProgressLine());
        setIsFocused(false);
        if (p.isNewCustomField && !isEditEnabled) {
            const newValue = {
                id: -1,
                customFieldId: p.customFieldValue.customFieldId,
                taskId: p.task.id,
                valueString: value
            };
            postApiCustomFieldsValues(newValue)
                .then((customFieldValue) => {
                    dispatch(createCustomFieldValue({ taskListId: p.task.taskListId, customFieldValue }))
                    p.setNewCustomField!(undefined);
                    const projectCustomFields = project.projectCustomFields || {}
                    if (!projectCustomFields[p.customFieldShortName! as CustomFieldKeyTypes]) {
                        const updateProjectCustomFields = {
                            ...projectCustomFields,
                            [p.customFieldShortName! as CustomFieldKeyTypes]: true
                        }
                        putApiProjectsProjectCustomFieldsById(project.id, updateProjectCustomFields)
                            .then(() => {
                                dispatch(loadProject({ ...project, projectCustomFields: updateProjectCustomFields }));
                                dispatch(hideProgressLine());
                            })
                    }
                })
                .then(() => dispatch(hideProgressLine()))
                .catch(() => {
                    dispatch(hideProgressLine());
                    dispatch(showSnackbar({ message: "Error adding custom field value!", type: "error" }));
                });
        } else {
            putApiCustomFieldsValues({ ...p.customFieldValue, valueString: value })
                .then(() => {
                    dispatch(updateCustomFieldValue({
                        taskListId: p.task.taskListId,
                        customFieldValue: { ...p.customFieldValue, valueString: value }
                    }));
                })
                .then(() => dispatch(hideProgressLine()))
                .catch(() => {
                    dispatch(hideProgressLine());
                    dispatch(showSnackbar({ message: "Error adding custom field value!", type: "error" }));
                });
        }
    }

    const cancelOnClick = () => {
        setIsFocused(false);
        if (p.setNewCustomField) {
            p.setNewCustomField(undefined);
        }
    }

    const deleteOnClick = () => deleteApiCustomFieldsValuesById(p.customFieldValue.id)
        .then(() => dispatch(deleteCustomFieldValue({
            taskListId: p.task.taskListId,
            id: p.customFieldValue.id
        })))
        .then(() => dispatch(hideProgressLine()))
        .catch(() => {
            dispatch(hideProgressLine());
            dispatch(showSnackbar({ message: "Error deleting custom field value!", type: "error" }));
        })

    return <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} style={{
        display: "flex",
        backgroundColor: isFocused ? "white" : isHover ? "#F5F6F7" : "white", alignItems: "center",
        height: isFocused ? 44 : 46,
        borderLeft: border,
        borderBottom: border,
        borderRight: border,
        borderTop: border
    }}>
        <div style={{ padding: 11, display: "inline-block", width: 120, backgroundColor: !isFocused ? "white" : "initial" }}>
            {p.name}
        </div>
        <div style={{ borderRight: border, paddingTop: 25, paddingBottom: 23, marginLeft: isFocused ? -1 : 0 }} />
        <div style={{
            display: "flex", justifyContent: "space-between", flexGrow: 1, marginRight: 16, marginLeft: 16,
            alignItems: "center"
        }}>
            <TextField autoFocus inputRef={ref} onClick={textFieldOnClick} style={{ padding: 7, width: "100%" }}
                       inputProps={{ style: { fontSize: 17, letterSpacing: 0.49 } }}
                       InputProps={{
                           disableUnderline: true,
                           readOnly: !isFocused,
                           autoFocus: true,
                       }}
                       disabled={!auth.hasPMRoleAccess()}
                       value={value}
                       onChange={(el) => setValue(el.target.value)}
                       variant="standard"
                       onKeyPress={(e) => {
                           if (e.key === "Enter") {
                               checkOnClick();
                           }
                       }}
            />
            {auth.hasPMRoleAccess() ?
                (isFocused ? <>
                    <CheckCircleIcon color="primary" style={{ cursor: "pointer" }} onClick={checkOnClick} />
                    <CancelIcon color="disabled" style={{ marginLeft: 5, cursor: "pointer" }} onClick={cancelOnClick} /> </> :
                isHover && <CancelIcon style={{ color: "gray", cursor: "pointer" }} onClick={deleteOnClick}
                />)
                : <></>
            }
        </div>
    </div>
}
