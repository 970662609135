import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { AuthentificationModal } from "../modals/auth-modal";
import { EmailErrors, PasswordErrors, useValidation } from "../../hooks/validation";
import './login.scss';
import '../pages.scss';
import { ErrorHelper } from "../../components/error-helper";
import { ErrorBox } from "../../components/error-box";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAuthService } from "../../contexts/auth-context";
import { NavBar } from "../../components/navBar";
import { ButtonSpinner } from "../../components/button-spinner";
import { useNavigate } from "react-router";
import { Footer } from "../../components/footer";

type RegInfo = {
    email: string,
    password: string
}

export function LoginPage() {
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [errorCount, setErrorCount] = useState(5);
    const [submitted, setSubmitted] = useState(false);
    const auth = useAuthService();
    const nav = useNavigate();
    const { register, handleSubmit, formState: { errors }, setError } = useForm<RegInfo>();
    const { signInExpressions } = useValidation();

    useEffect(() => {
        sessionStorage.removeItem('editing-master-pattern')
        if (auth.loginInfo) {
            if (auth.loginInfo.user?.role === "SUPER") {
                auth.setSuperAdminPerspective();
                nav("/adminchoice");
            } else if (auth.loginInfo.user?.role === "AM") {
                nav("/adminchoice");
            } else {
                auth.setUserPerspective();
                nav("/");
            }
        } else {
            localStorage.removeItem("accountList");
        }
    }, [auth]);

    const onSubmit: SubmitHandler<RegInfo> = async (data) => {
        setSubmitted(true);
        const login = await auth.login(data);
        if (login === PasswordErrors.INVALID) {
            setError("password", { type: "validate" });
            setErrorCount(errorCount > 0 ? errorCount - 1 : 0);
        }
        setSubmitted(false);
    }

    return <div>
        <NavBar />
        <div className="body-container">
            <div className='box-container'>
                <AuthentificationModal>
                    <div className="content-box">
                        <span className="header">Welcome back!</span>
                        <p className="details">
                            Log in into account
                        </p>
                        {errorCount < 5 && errorCount > 0 ?
                            <ErrorBox
                                errorTitle={<span>Attempts remaining: <b>{errorCount}</b></span>}
                                errorText={<span><b>Warning:</b> After <b>5 consecutive</b> unsuccessful login attempts, your account will be locked.</span>}
                            />
                            : errorCount === 0 ?
                                <ErrorBox
                                    errorTitle={<span><b>Your account has been locked.</b></span>}
                                    errorText={<span>Please wait <b>15 minutes</b> before trying to log in again. If you have forgotten your password, please try to reset it by clicking Forgot Password.</span>}
                                /> : <></>}
                        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }}>
                            <TextField
                                error={!!errors.email}
                                variant="outlined"
                                id="outlined-error-helper-text"
                                label="Email"
                                {...register("email", { required: true, pattern: signInExpressions.emailExpression })}
                                inputProps={{ style: { fontSize: 17 } }}
                                style={{ marginTop: 23 }}
                            />
                            {errors.email && errors.email.type === "required" && <ErrorHelper helperText={EmailErrors.EMPTY} />}
                            {errors.email && errors.email.type === "pattern" && <ErrorHelper helperText={EmailErrors.INVALID} />}
                            <TextField
                                error={!!errors.password}
                                label='Password'
                                variant="outlined"
                                type={passwordVisibility ? "text" : "password"}
                                {...register("password", { required: true, minLength: 8 })}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setPasswordVisibility(!passwordVisibility)}
                                            >
                                                {passwordVisibility ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                inputProps={{ style: { fontSize: 17, fontFamily: !passwordVisibility ? "initial" : "" } }}
                                style={{ marginTop: 22 }}
                            />
                            {errors.password && errors.password.type === "required" && <ErrorHelper helperText={PasswordErrors.EMPTY} />}
                            {errors.password && errors.password.type === "minLength" && <ErrorHelper helperText={PasswordErrors.FEW_CHARACTERS} />}
                            {errors.password && errors.password.type === "validate" && <ErrorHelper helperText={PasswordErrors.INVALID} />}
                            <div className="forgot-pass-container">
                                <a className="forgotPass" href="/forgot-password">Forgot password?</a>
                            </div>
                            <Button disabled={submitted} type="submit" variant="contained" color="secondary" style={{ marginTop: 16 }}>
                                LOG IN {submitted && <ButtonSpinner />}
                            </Button>
                        </form>
                    </div>
                </AuthentificationModal>
            </div>
        </div>
        <Footer />
    </div>
}
