import "./error-box.scss";

export function ErrorBox(p: { errorText: JSX.Element, errorTitle: JSX.Element }) {
    return <div className="error-box-container">
        <p className="error-box-text">
            {p.errorTitle}
            <br />
            {p.errorText}
        </p>
    </div>
}
