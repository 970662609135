import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectTemplateName } from "./project-template-name";
import { Wizard } from "../../../components/wizard";
import { WizardData } from "../../../models/WizardData";
import { ProjectTemplateList } from "./project-template-list";
import { postApiTemplatesCreate } from "../../../services/template";
import { postApiTaskListsCreate } from "../../../services/task.list";
import { BaseTemplate } from "../../../models/BaseTemplate";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hook";
import { showSnackbar } from "../../../redux/snackbar";
import { projectTemplatesSelector } from "../../../redux/projectTemplates";
export function ProjectTemplateWizard() {

    let navigate = useNavigate();
    const initialProjectTemplate = {
        name: "",
        color: "#fff",
        onSubmit: () => { }
    }
    const initialProjectTemplateTaskList = {
        name: "",
        projectId: -1,
        onSubmit: () => { }
    }

    const [projectTemplate, setProjectTemplate] = useState<WizardData>(initialProjectTemplate);
    const [projectTemplateTaskList, setProjectTemplateTaskList] = useState<WizardData>(initialProjectTemplateTaskList);
    const [error, setError] = useState({
        empty: false,
        alreadyExists: false
    });
    const [taskListError, setTaskListError] = useState(false);
    const dispatch = useAppDispatch();
    const templates = useAppSelector((state) => projectTemplatesSelector(state));

    const errorEmpty = projectTemplate.name.trim() === "";
    const errorAlreadyExists = (templates.some(template =>
        template.name.toLocaleLowerCase().trim() === projectTemplate.name.trim().toLocaleLowerCase()));

    const onErrorNext = () => {
        setError({ empty: errorEmpty, alreadyExists: errorAlreadyExists });
        return errorEmpty || errorAlreadyExists;
    }

    useEffect(() => {
        setProjectTemplate({ ...projectTemplate, onSubmit: () => createProjectTemplate(), onErrorNext });
    }, [projectTemplate.name, projectTemplateTaskList.name]);

    useEffect(() => {
        if (error.empty || error.alreadyExists) {
            setError({empty: errorEmpty, alreadyExists: errorAlreadyExists})
        }
        if (taskListError) {
            setTaskListError(projectTemplateTaskList.name.trim() === "");
        }
    }, [projectTemplate.name, projectTemplateTaskList.name])

    const createProjectTemplate = () => {
        if (projectTemplateTaskList.name.trim() === "") {
            setTaskListError(true);
            return true;
        } else {
            setTaskListError(false);
        }
        const template = projectTemplate as BaseTemplate;
        postApiTemplatesCreate({ name: template.name.trim(), color: template.color })
            .then(res => postApiTaskListsCreate({ projectId: res.id, name: projectTemplateTaskList.name.trim() })
                .then(() => navigate('/app/user/workflow/project-template/' + res.id))
                .catch(() => dispatch(showSnackbar({ message: "Error creating Template List!", type: "error" }))))
            .catch(() => dispatch(showSnackbar({ message: "Error creating template!", type: "error" })));
    }

    return <div className="wizard-dialog" >
        <Wizard childrenData={projectTemplate}>
            <ProjectTemplateName projectTemplate={projectTemplate} setProjectTemplate={setProjectTemplate} error={error} />
            <ProjectTemplateList isTemplate projectTemplateTaskList={projectTemplateTaskList}
                setProjectTemplateTaskList={setProjectTemplateTaskList} error={{ empty: taskListError, alreadyExists: false }} />
        </Wizard>
    </div>
}
