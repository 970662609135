import { useState } from "react";
import baseline_close from "../../../provisional_icons/baseline-close.png";
import Button from "@mui/material/Button";
import { useAdminService } from "../../../contexts/super-admin-context";
import { useCustomModal } from "../custom-message-modal";
import { Account } from "../../../models/Account";
import { User } from "../../../models/User";
import { ButtonSpinner } from "../../../components/button-spinner";
import { useAppDispatch } from "../../../hooks/redux-hook";
import { showSnackbar } from "../../../redux/snackbar";

export function RemoveUserModal(account: Account, user: User, fetchUsers?: () => void) {
    const { deleteUserFromAccount } = useAdminService();
    const { hideModal } = useCustomModal();
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const onSubmit = () => {
        setLoading(true);
        deleteUserFromAccount(user.id, account.id)
            .then(() => {
                if (fetchUsers) {
                    fetchUsers();
                }
                hideModal();
            })
            .then(() => dispatch(showSnackbar({
                message: `${user.name || "User"} has been removed from the ${account.name} account.`,
                type: "info"
            })))
            .catch(() => {
                setLoading(false);
                dispatch(showSnackbar({
                    message: `Error removing ${user.name || "user"} from the ${account.name} account!`,
                    type: "error"
                }));
            });
    }

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img alt="close" src={baseline_close} className="remove-entity-close-button" onClick={() => loading ? {} : hideModal()} />
            <span className="remove-entity-title">
                Remove {user.name ?? user.email} from {account.name} ?
            </span>
            <p className="remove-entity-disclaimer" >
                This person will be removed from the account, but their history, projects, tasks, comments, and everything else will remain and will not be deleted.
            </p>
            <span className="remove-entity-disclaimer" style={{ marginTop: 8 }}>
                You can reactivate users from the <b>Removed users section.</b>
            </span>
            <div className="remove-entity-button-list" style={{ marginTop: 20 }} >
                <Button color="secondary" variant="outlined" onClick={hideModal} disabled={loading}>CANCEL</Button>
                <Button disabled={loading} onClick={onSubmit} color="secondary" variant="contained">
                    REMOVE USER {loading && <ButtonSpinner />}
                </Button>
            </div>
        </div>
    </div>
}
