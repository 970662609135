import { useEffect, useState } from "react";
import { Wizard } from "../../../components/wizard";
import { ProjectTemplateList } from "./project-template-list";
import { WizardData } from "../../../models/WizardData";
import { postApiTaskListsCreate } from "../../../services/task.list";
import { useCustomModal } from "../../modals/custom-message-modal";
import { TaskList } from "../../../models/TaskList";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hook";
import { actionBarTasksListsSelector, setTaskLists } from "../../../redux/action-bar";
import {showSnackbar} from "../../../redux/snackbar";

export function ProjectTemplateListWizard(p?: { projectId: number, isTemplate: boolean, refreshList: () => void }) {
    const { hideModal } = useCustomModal();
    const taskLists: TaskList[] = useAppSelector((state) => actionBarTasksListsSelector(state));
    const dispatch = useAppDispatch();

    const initialProjectTemplateTaskList = {
        name: "",
        projectId: -1,
        onSubmit: () => { }
    }

    const [projectTemplateTaskList, setProjectTemplateTaskList] = useState<WizardData>(initialProjectTemplateTaskList);
    const [error, setError] = useState({
        empty: false,
        alreadyExists: false
    });

    useEffect(() => {
        setProjectTemplateTaskList({ ...projectTemplateTaskList, onSubmit: () => createTaskList() });
    }, [projectTemplateTaskList.name]);

    const errorEmpty = projectTemplateTaskList.name.trim() === "";
    const errorAlreadyExists = (taskLists.some(taskList =>
        taskList.name.toLocaleLowerCase().trim() === projectTemplateTaskList.name.trim().toLocaleLowerCase()));

    useEffect(() => {
        if (error.alreadyExists || error.empty) {
            setError({empty: errorEmpty, alreadyExists: errorAlreadyExists});
        }
    }, [projectTemplateTaskList.name])

    const createTaskList = () => {
        setError({ empty: errorEmpty, alreadyExists: errorAlreadyExists });
        if (errorEmpty || errorAlreadyExists) {
            return true;
        }
        postApiTaskListsCreate({ projectId: p?.projectId ?? -1, name: projectTemplateTaskList.name })
            .then((taskList) => {
                dispatch(setTaskLists(taskLists.concat([taskList])));
                dispatch(showSnackbar({message: "Task list is created successfully", type: "info"}));
                p?.refreshList();
                hideModal();
            });
    }

    const wizardArray = [<ProjectTemplateList key={0} isTemplate={p?.isTemplate!} error={error}
        projectTemplateTaskList={projectTemplateTaskList} setProjectTemplateTaskList={setProjectTemplateTaskList} />]

    return <div className="wizard-dialog" >
        <Wizard childrenData={projectTemplateTaskList}>
            {wizardArray}
        </Wizard>
    </div>
}
