import { Task } from "../models/Task";
import { BaseTask } from "../models/BaseTask";
import { UpdateTaskStatusRequest } from "../models/UpdateTaskStatusRequest";
import { TaskCustomFieldValues } from "../models/TaskCustomFieldValues";
import { TaskCustomFieldValuesResponse } from "../models/TaskCustomFieldValuesResponse";
import { AssignTasksRequest } from "../models/AssignTasksRequest";
import { Comment } from "../models/Comment";
import { HistoryResult } from "../models/HistoryResult";
import { BaseComment } from "../models/BaseComment";
import { Worklog } from "../models/Worklog";
import { BaseWorklog } from "../models/BaseWorklog";
import { TaskTimeTrackSummary } from "../models/TaskTimeTrackSummary";
import { UpdateTaskTimeTrackedByUserIdRequest } from "../models/UpdateTaskTimeTrackedByUserIdRequest";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
import {addFilesToTaskRequest} from "../models/addFilesToTaskRequest";
import {bulkAddFilesToTaskRequest} from "../models/bulkAddFilesToTaskRequest";
import {removeFilesFromTaskRequest} from "../models/removeFilesFromTaskRequest";
export function getApiTasks({ taskListId }: {
    taskListId: number;
}, signal?: AbortSignal): Promise<Task[]> {
    const url = new URL(`/api/tasks`, baseUrl);
    addQueryParameter(url, "taskListId", taskListId);
    return fetchAndParse<Task[]>(url.toString(), { method: "GET", headers, signal });
}
export function getApiTasksById(id: number, signal?: AbortSignal): Promise<Task> {
    const url = new URL(`/api/tasks/${id}`, baseUrl);
    return fetchAndParse<Task>(url.toString(), { method: "GET", headers, signal });
}
export function putApiTasksById(id: number, body?: BaseTask, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApiTasksStatusById(id: number, body?: UpdateTaskStatusRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/status/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApiTaskTimeTrackedByUserId(id: number, body?: UpdateTaskTimeTrackedByUserIdRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/time/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function deleteApiTasksById(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
export function postApiTasksCreate(body?: BaseTask, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/create`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiTasksBulkCreate(body?: Task[], signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/bulk-create`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiTasksBulkUpdate(body?: Task[], signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/bulk-update`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiTasksBulkCreateCfv(body?: TaskCustomFieldValues[], signal?: AbortSignal): Promise<TaskCustomFieldValuesResponse> {
    const url = new URL(`/api/tasks/bulk-create-cfv`, baseUrl);
    return fetchAndParse<TaskCustomFieldValuesResponse>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function deleteApiTasksBulkDelete(body?: number[], signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/bulk-delete`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", body: JSON.stringify(body), headers, signal });
}
export function postApiTasksBulkUndoDelete(body?: number[], signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/bulk-undo-delete`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiTasksAssign(body?: AssignTasksRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/assign`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function getApiTasksCommentsById(id: number, signal?: AbortSignal): Promise<Comment> {
    const url = new URL(`/api/tasks/comments/${id}`, baseUrl);
    return fetchAndParse<Comment>(url.toString(), { method: "GET", headers, signal });
}
export function deleteApiTasksCommentsById(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/comments/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
export function getApiTasksComments({ taskId }: {
    taskId: number;
}, signal?: AbortSignal): Promise<Comment[]> {
    const url = new URL(`/api/tasks/comments`, baseUrl);
    addQueryParameter(url, "taskId", taskId);
    return fetchAndParse<Comment[]>(url.toString(), { method: "GET", headers, signal });
}
export function getApiTasksHistory({ taskId }: {
    taskId: number;
}, signal?: AbortSignal): Promise<HistoryResult[]> {
    const url = new URL(`/api/tasks/history`, baseUrl);
    addQueryParameter(url, "taskId", taskId);
    return fetchAndParse<HistoryResult[]>(url.toString(), { method: "GET", headers, signal });
}
export function postApiTasksCommentsCreate(body?: BaseComment, signal?: AbortSignal): Promise<Comment> {
    const url = new URL(`/api/tasks/comments/create`, baseUrl);
    return fetchAndParse<Comment>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function getApiTasksByTaskIdWorklogs(taskId: number, signal?: AbortSignal): Promise<Worklog[]> {
    const url = new URL(`/api/tasks/${taskId}/worklogs`, baseUrl);
    return fetchAndParse<Worklog[]>(url.toString(), { method: "GET", headers, signal });
}
export function postApiTasksWorklogCreate(body?: BaseWorklog, signal?: AbortSignal): Promise<Worklog> {
    const url = new URL(`/api/tasks/worklog/create`, baseUrl);
    return fetchAndParse<Worklog>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function getApiTasksWorklogById(id: number, signal?: AbortSignal): Promise<Worklog> {
    const url = new URL(`/api/tasks/worklog/${id}`, baseUrl);
    return fetchAndParse<Worklog>(url.toString(), { method: "GET", headers, signal });
}
export function deleteApiTasksWorklogById(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/worklog/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
export function postApiTasksWorklogByIdTimerStart(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/worklog/${id}/timer/start`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: null, headers, signal });
}
export function postApiTasksWorklogByIdTimerStop(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/worklog/${id}/timer/stop`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: null, headers, signal });
}
export function postApiTasksLinkFilesToTask(id: number, body?: addFilesToTaskRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/${id}/files`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}

export function postApiTasksBulkLinkFilesToTask(body?: bulkAddFilesToTaskRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/bulk-files`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function deleteApiTasksUnlinkFilesToTask(id: number, body?: removeFilesFromTaskRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/tasks/${id}/files`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", body: JSON.stringify(body), headers, signal });
}
export function putApiTasksWorklog(body?: Worklog, signal?: AbortSignal): Promise<Worklog> {
    const url = new URL(`/api/tasks/worklog`, baseUrl);
    return fetchAndParse<Worklog>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function getApiTasksWorklogSummaryById(id: number, signal?: AbortSignal): Promise<TaskTimeTrackSummary[]> {
    const url = new URL(`/api/tasks/worklog/summary/${id}`, baseUrl);
    return fetchAndParse<TaskTimeTrackSummary[]>(url.toString(), { method: "GET", headers, signal });
}
export function getApiTasksByIdWorklogSummary(id: number, signal?: AbortSignal): Promise<TaskTimeTrackSummary> {
    const url = new URL(`/api/tasks/${id}/worklog/summary`, baseUrl);
    return fetchAndParse<TaskTimeTrackSummary>(url.toString(), { method: "GET", headers, signal });
}
