import { createSlice } from '@reduxjs/toolkit'
import { RootState } from "./store";
import { TaskTimeTrackSummary } from "../models/TaskTimeTrackSummary";

const defaultTasksTimeTrackSummary: TaskTimeTrackSummary[] = [];
const initialState = {
    taskTimeTrackingSummary: defaultTasksTimeTrackSummary
}

const taskTimeTracking = createSlice({
    name: 'taskTimeTracking',
    initialState,
    reducers: {
        clearTaskTimeTracking: (state) => {
            state.taskTimeTrackingSummary = [];
        },
        loadTasksTimeTracking: (state, action) => {
            state.taskTimeTrackingSummary = [ ...state.taskTimeTrackingSummary , ...action.payload ];
        },
        updateTaskTimeTracking: (state, action) => {
            state.taskTimeTrackingSummary = [
                ...state.taskTimeTrackingSummary.filter(tts => tts.taskId !== action.payload.taskId),
                action.payload
            ]
        },
        removeTaskTimeTracking: (state, action) => {
            state.taskTimeTrackingSummary = state.taskTimeTrackingSummary.filter(tts => tts.taskId !== action.payload);
        }
    }
})

export const { clearTaskTimeTracking, loadTasksTimeTracking, updateTaskTimeTracking, removeTaskTimeTracking } = taskTimeTracking.actions

export const taskTimeTrackingSelector = (state: RootState, taskId: number) =>
    state.taskTimeTracking.taskTimeTrackingSummary.find(tts => tts.taskId === taskId)
export const tasksTimeTrackingSelector = (state: RootState, taskIds: number[]) => taskIds.length === 0 ? [] :
    state.taskTimeTracking.taskTimeTrackingSummary.filter(tts => taskIds.includes(tts.taskId))

export default taskTimeTracking.reducer
