import { ChangePasswordComponent } from '../../components/change-password';
import { NavBar } from '../../components/navBar';
import { AuthentificationModal } from '../modals/auth-modal';
import './signup.scss';

export function ResetPassword() {
    const headerDetails = <span className="detail" style = {{marginTop: 10}}>
        Please enter a new password for your account.<br /> The password must be different from the previous used passwords.
    </span>

    return <div>
        <NavBar />
        <div className='container'>
            <AuthentificationModal>
                <ChangePasswordComponent details={headerDetails} />
            </AuthentificationModal>
        </div>
    </div>
}
