import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuthService } from '../contexts/auth-context';
import { useQuery } from '../hooks/utils';
import { useValidation } from '../hooks/validation';
import { ErrorHelper } from './error-helper';
import '../pages/common/signup.scss';

type ChangePassword<T> = {
    newPassword: T,
    confirmNewPassword: T,
    currentPassword?: T
}

export function ChangePasswordComponent(p: { details: JSX.Element }) {
    const [passwordVisibility, setPasswordVisibility] = useState<ChangePassword<boolean>>({
        newPassword: false,
        confirmNewPassword: false
    });
    const [isPasswordValidationError, setIsPasswordValidationError] = useState<boolean>();
    const { passwordRequirements, requirementsValidation, createPasswordValidation } = useValidation();
    const { confirmResetPassword } = useAuthService();
    const { register, handleSubmit, formState: { errors }, getValues, watch, setError } = useForm<ChangePassword<string>>();
    const query = useQuery();

    const onSubmit: SubmitHandler<ChangePassword<string>> = async (data) => {
        if (data.newPassword !== data.confirmNewPassword) {
            setError("confirmNewPassword", { type: "value" });
            setError("newPassword", { type: "value" });
        } else {
            confirmResetPassword({ email: query.get("email")!, confirmationCode: query.get("code")!, password: data.confirmNewPassword })
        }
    }

    useEffect(() => {
        const password = getValues("newPassword");
        createPasswordValidation(password);
        if (isPasswordValidationError !== undefined) {
            setIsPasswordValidationError(!!requirementsValidation.length);
        }
    }, [watch("newPassword")])

    return <div className="contentBox">
        <span style={{ textAlign: "center", fontSize: 25 }}>Change password</span>
        {p.details}
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }}>
            <TextField
                error={!!errors.newPassword}
                label='New password'
                variant="outlined"
                id="new-password"
                type={passwordVisibility.newPassword ? "text" : "password"}
                {...register("newPassword", {
                    required: true,
                })}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPasswordVisibility({ ...passwordVisibility, newPassword: !passwordVisibility.newPassword })}
                            >
                                {passwordVisibility.newPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                inputProps={{ style: { fontSize: 17, fontFamily: !passwordVisibility.newPassword ? "initial" : "" } }}
                style={{ marginTop: 22 }}
            />
            <TextField
                error={!!errors.confirmNewPassword}
                label='Confirm new password'
                variant="outlined"
                id="confirm-password"
                type={passwordVisibility.confirmNewPassword ? "text" : "password"}
                {...register("confirmNewPassword", {
                    required: true,
                })}
                disabled={!!requirementsValidation.length}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPasswordVisibility({ ...passwordVisibility, confirmNewPassword: !passwordVisibility.confirmNewPassword })}
                            >
                                {passwordVisibility.confirmNewPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                inputProps={{ style: { fontSize: 17, fontFamily: !passwordVisibility.confirmNewPassword ? "initial" : "" } }}
                style={{ marginTop: 22 }}
            />
            {errors.confirmNewPassword && errors.confirmNewPassword.type === "value" && <ErrorHelper helperText="Please make sure your passwords match." />}
            {isPasswordValidationError && <ErrorHelper helperText="Password must be:" />}
            <div className={isPasswordValidationError ? "password" : "error-password"}>
                {!isPasswordValidationError && <div style={{ marginBottom: 4, marginTop: 16 }}>New password must be:</div>}
                {passwordRequirements?.map(item => <div style={{ display: "flex", alignItems: "center" }}
                    key={item}
                    className={requirementsValidation?.includes(item) && !isPasswordValidationError ? "main-li" :
                        requirementsValidation.includes(item) && isPasswordValidationError ? "error-li" : "gray-li"
                    }
                >
                    <div style={{ width: 8, height: 8, backgroundColor: "#217DA2", borderRadius: 4, marginRight: 12 }} /> {item}
                </div>
                )}
            </div>
            <Button type="submit" style={{ marginTop: 20 }} variant="contained" color="secondary" onClick={() => setIsPasswordValidationError(!!requirementsValidation.length)}>CHANGE PASSWORD</Button>
        </form>
    </div>
}
