import {createSlice} from "@reduxjs/toolkit";
import {PatternSet} from "../models/PatternSet";
import { RootState } from "./store";
import {Pattern} from "../models/Pattern";


export const defaultPatternSets: PatternSet[] = []

const defaultPatternSet: PatternSet = {
    id: 0,
    name: "",
    version: 0,
    creationDate: "",
    lastModifiedDate: "",
    lastModifier: "",
    projectID: -1,
    patterns: []
}

const patternSetsInitialState = {
    patternSets: defaultPatternSets,
    loaded: false
}

const patternSets = createSlice({
    name: 'patternSet',
    initialState: patternSetsInitialState,
    reducers: {
        loadPatternSets: (state, action) => {
            state.patternSets = action.payload;
            state.loaded = true;
        },
        loadPatternSet: (state, action) => {
            if (state.patternSets.find(patternSet => patternSet.id === action.payload.id)) {
                state.patternSets = state.patternSets.map(patternSet => patternSet.id === action.payload.id ? action.payload : patternSet);
            } else {
                state.patternSets.push(action.payload);
            }
        },
        setPatternSetsLoaded: (state, action) => {
            state.loaded = action.payload;
        },
        removePatternSet: (state, action) => {
            state.patternSets = state.patternSets.filter(patternSet => patternSet.id !== action.payload.id);
        },
        addPatternsToSet: (state, action) => {
            if (action.payload && action.payload.length > 0) {
                const setID = action.payload[0].master_id
                state.patternSets.filter(patternSet => patternSet.id === setID)[0].patterns = action.payload
            }
        },
        removePattern: (state, action) => {
            const patternSet : PatternSet | undefined = state.patternSets.find(patternSet => patternSet.id === action.payload.master_id)
            if (patternSet) {
                patternSet.patterns = patternSet?.patterns.filter(pattern => pattern.id !== action.payload.id)
            }
        },
        updatePattern: (state, action) => {
            const patternSet : PatternSet | undefined = state.patternSets.find(patternSet => patternSet.id === action.payload.master_id)
            if (patternSet) {
                let pattern: Pattern | undefined = patternSet.patterns.find(pattern => pattern.id === action.payload.id)
                if (pattern) {
                    Object.assign(pattern, action.payload);
                }
            }
        },
        updateModifiedTimeOnPatternSet: (state, action) => {
            const patternSet : PatternSet | undefined = state.patternSets.find(patternSet => patternSet.id === action.payload.master_id)
            if (patternSet) {
                patternSet.lastModifiedDate = action.payload.date
                patternSet.lastModifier = action.payload.username
            }
        }
    }
})

export const { loadPatternSets, loadPatternSet, setPatternSetsLoaded, removePatternSet, addPatternsToSet, removePattern, updatePattern, updateModifiedTimeOnPatternSet } = patternSets.actions
export const PatternSetsSelector = (state: RootState) => state.patternSets.patternSets
export const patternSetSelector = (state: RootState, patternSetId: number) =>
    state.patternSets.patternSets.find(patternSet => patternSet.id === patternSetId) || defaultPatternSet
export const patternSetsLoadedSelector = (state : RootState) => state.patternSets.loaded

export default patternSets.reducer
