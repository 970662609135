import { useEffect, useRef, useState } from "react";
import { headers, wsUrl } from "../services/config";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hook";
import { receiveNewNotification } from "../redux/notifications";
import { Notification } from "../models/Notification";
import { loadProject, projectsSelector, removeProject, selectedProjectSelector } from "../redux/projects";
import { getApiProjectsById } from "../services/project";
import { getProjectByTaskId, getTaskById } from "../services/utils";
import { Project } from "../models/Project";
import { fetchProjects } from "../services/init";
import { useNavigate } from "react-router-dom";
import { useAuthService } from "../contexts/auth-context";
import { getApiCustomFieldsValuesTaskListById } from "../services/custom.fields";
import { loadCustomFieldsValues } from "../redux/custom-fields";
import { SubTask } from "../models/SubTask";
import { Task } from "../models/Task";

export function NotificationsService() {

    const { loginInfo } = useAuthService();
    const url = new URL(wsUrl);
    // Local testing
    // const url = new URL(`${wsUrl}/${loginInfo?.tenant?.clientId}_${loginInfo?.tenant?.user?.id}`)
    const dispatch = useAppDispatch();
    const nav = useNavigate();
    const projects = useAppSelector((state) => projectsSelector(state));
    const selectedProject = useAppSelector((state) => selectedProjectSelector(state));
    const initProjects = useRef<Project[]>([]);
    const totalProjectsIds = useRef<number[] | null>(null);
    const [ws, setWs] = useState<WebSocket>();

    useEffect(() => {
        const newWs = new WebSocket(url);
        newWs.onopen = () => {
            newWs.send(JSON.stringify({
                AccessToken: loginInfo?.cognito?.AccessToken,
                accountId: loginInfo?.tenant?.clientId,
                tenant: loginInfo?.tenant?.schema,
                userId: loginInfo?.tenant?.user?.id,
                impersonate: headers["x-impersonate"] === "true" ? true : false
            }));
        }
        setWs(newWs);
        return () => {
            if (ws !== undefined) {
                ws.close()
            }
        };
    }, [loginInfo]);

    useEffect(() => {
        if (ws !== undefined && projects.length > 0) {
            ws.onmessage = (event) => {
                try {
                    const notification: Notification = JSON.parse(event.data);
                    if (notification.type === "projectShare") {
                        const [text, projectId, projectName] = notification.content!.split("-");
                        if (notification.content!.includes("removed")) {
                            dispatch(removeProject(+projectId));
                            if (selectedProject === +projectId) {
                                nav('/app/user/workflow/projects')
                            }
                        }
                        if (notification.content!.includes("added")) {
                            getApiProjectsById(+projectId).then(project => dispatch(loadProject(project)));
                        }
                    } else if (notification.type === "customField") {
                        const task: Task | SubTask | undefined = getTaskById(projects, notification.taskId!);
                        if (task) {
                            getApiCustomFieldsValuesTaskListById(task.taskListId).then(cfv => dispatch(loadCustomFieldsValues({
                                taskListId: task.taskListId,
                                customFieldsValues: cfv
                            })));
                        }
                    } else if (notification.type === "editPatterns") {
                        //no special instructions for this kind of notification.
                    } else {
                        const project = getProjectByTaskId(projects, notification.taskId!) as Project
                        if (project) {
                            getApiProjectsById(project.id).then(p => dispatch(loadProject(p)));
                        } else {
                            const controller = new AbortController();
                            totalProjectsIds.current = null;
                            initProjects.current = [];
                            fetchProjects(controller.signal, dispatch, totalProjectsIds.current, initProjects.current);
                        }
                    }
                    dispatch(receiveNewNotification(notification));
                } catch (e) {
                }
            }
        }
    }, [projects, ws]);


    return <></>
}
