import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import { WizardData } from "../../../models/WizardData";
import { ChildContext } from "../../../components/wizard";
import ErrorIcon from '@mui/icons-material/Error';

export function ProjectTemplateList(props: {
    isTemplate: boolean, projectTemplateTaskList: WizardData, error: { empty: boolean, alreadyExists: boolean },
    setProjectTemplateTaskList: (p: WizardData) => void
}) {
    const setValue = React.useContext(ChildContext);

    useEffect(() => {
        setValue({ title: props.isTemplate ? `Create a List for your Template` : `Create task list`, canLeaveStep: () => true });
    }, [])

    return <div className="wizard-content">
        <br />
        <label className="wizard-content-label ">Enter a Name for the List</label>
        <TextField
            value={props.projectTemplateTaskList.name}
            error={props.error.empty || props.error.alreadyExists}
            helperText={(props.error.alreadyExists || props.error.empty) && <span style={{ marginLeft: -14 }}>
                <ErrorIcon sx={{ width: 12, height: 12 }} />
                <span style={{ marginLeft: 5, verticalAlign: "text-bottom" }}>{props.error.empty ?
                    "Task list name is required." : "Task list name already exists."}</span>
            </span>}
            onChange={e => props.setProjectTemplateTaskList({ ...props.projectTemplateTaskList, name: e.currentTarget.value })}
            placeholder='List Name'
        />
    </div>
}
