import { createSlice } from '@reduxjs/toolkit'
import { Template } from '../models/Template';
import { RootState } from "./store";

export const defaultProjectTemplates: Template[] = [{
    color: "",
    id: 0,
    name: "",
    taskLists: []
}]

const projectTemplatesInitialState = {
    templates: defaultProjectTemplates,
    loaded: false
}

const projectTemplates = createSlice({
    name: 'projectTemplates',
    initialState: projectTemplatesInitialState,
    reducers: {
        loadTemplates: (state, action) => {
            state.templates = action.payload;
            state.loaded = true;
        },
        loadTemplate: (state, action) => {
            if (state.templates.find(template => template.id === action.payload.id)) {
                state.templates = state.templates.map(template => template.id === action.payload.id ? action.payload : template);
            } else {
                state.templates.push(action.payload);
            }
        },
        setTemplatesLoaded: (state) => {
            state.loaded = true;
        },
        removeTemplate: (state, action) => {
            state.templates = state.templates.filter(template => template.id !== action.payload);
        }
    }
})

export const { loadTemplates, loadTemplate, setTemplatesLoaded, removeTemplate } = projectTemplates.actions
export const projectTemplatesSelector = (state: RootState) => state.projectTemplates.templates
export const projectTemplateSelector = (state: RootState, templateId: number) =>
    state.projectTemplates.templates.find(template => template.id === templateId)
export const projectTemplatesLoadedSelector = (state : RootState) => state.projectTemplates.loaded

export default projectTemplates.reducer
