import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import './project-filters.scss';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import baseline_close from "../provisional_icons/baseline-close.png";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEventHandler } from "../hooks/handle-events";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { ConditionalWrapper } from "./conditional-wrapper";
import { blueIconColor, statusList, StatusType } from "../constants";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MoreActionsComponent } from "./more-actions-component";
import { CustomDatePicker } from "./custom-date-picker";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useAppDispatch, useAppSelector } from "../hooks/redux-hook";
import moment from "moment";
import CloseIcon from '@mui/icons-material/Close';
import { updateFilters, projectFiltersSelector } from "../redux/project-filters";
import { InfoTenantUser } from "../models/InfoTenantUser";
import { UsersApply } from "./users-apply";
import { useAuthService } from "../contexts/auth-context";
import { usersSelector } from "../redux/users";
import { selectedProjectSelector } from "../redux/projects";
import { setShowToolbarAfterDeletion } from "../redux/action-bar";
import { ArchiveProjectComponent, RemoveProjectComponent, RestoreProjectComponent } from "./archived-project-actions";

export type UserAssign = InfoTenantUser;
export type GroupByType = "Status" | "Due date" | "Batch";
export type ExpandedType = "Expanded";

export type ProjectFilter = {
    projectId: number,
    searchTerm: string,
    groupBy: GroupByType | undefined,
    filter: {
        keyword: string | undefined,
        status: {
            type: StatusType | undefined,
            color: string | undefined
        },
        date: string | null
    },
    showMyTasks: boolean | undefined,
    assignees: UserAssign[],
    showSubtasks: ExpandedType | undefined,
    showArchivedProjects: boolean | undefined,
    viewCompletedProjects: boolean | undefined,
}

const componentPropsTooltip = {
    tooltip: {
        sx: {
            padding: 0,
            margin: 0,
            minWidth: 115,
            borderRadius: 0,
            bgcolor: "white",
        },
    },
}

export function ProjectFilterBar(p: { projectId: number, selectedProjectIds: number[]}) {

    const auth = useAuthService();
    const dispatch = useAppDispatch();
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const users = useAppSelector((state) => usersSelector(state, true));
    const filters = useAppSelector((state) => projectFiltersSelector(state, p.projectId));
    const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);
    const [anchorElCompletedProjects, setAnchorElCompletedProjects] = useState<HTMLElement | null>(null);
    const [anchorElArchivedProjects, setAnchorElArchivedProjects] = useState<HTMLElement | null>(null);
    const [anchorElGroupBy, setAnchorElGroupBy] = useState<HTMLElement | null>(null);
    const [anchorElFilter, setAnchorElFilter] = useState<HTMLElement | null>(null);
    const [anchorElMyTasks, setAnchorElMyTasks] = useState<HTMLElement | null>(null);
    const [anchorElAssignees, setAnchorElAssignees] = useState<HTMLElement | null>(null);
    const [anchorElSubtasks, setAnchorElSubtasks] = useState<HTMLElement | null>(null);

    const setFilter = <T extends ProjectFilter, K extends keyof T>(filterKey: K, newValue: T[K]) => {
        dispatch(updateFilters({ ...filters, [filterKey]: newValue }))
    }

    useEffect(() => {
        const filtersArray = [filters.filter.keyword, filters.filter.status.type, filters.filter.date];
        let counter = 0;
        for (let filter of filtersArray) {
            if (filter) counter++;
        }
        setSelectedFiltersCount(counter);
    }, [filters.filter])

    const clearFiltersOnClick = () => {
        setFilter("filter", { keyword: "", date: null, status: { color: undefined, type: undefined } });
    }

    const GroupByItem = (props: { name: GroupByType | undefined, jsxChangedData?: JSX.Element[] }) => <MenuItem
        onClick={() => setFilter("groupBy", props.name)}
        onMouseEnter={onMouseEventHandler} onMouseLeave={onLeaveMouseEventHandler}
        style={{
            fontSize: 14, display: "flex", justifyContent: "space-between",
            fontWeight: filters.groupBy === props.name ? "600" : "normal"
        }}
    >
        {props.jsxChangedData ?
            props.jsxChangedData.map((item, index) => <div key={index}>{item}</div>)
            : <span>{props.name}</span>}
        {filters.groupBy === props.name && <span className="project-filter-item-check">✓</span>}
    </MenuItem>

    const groupByItems = [
        <p className="project-filters-modal-title">GROUP BY</p>,
        <GroupByItem name="Status" />,
        <GroupByItem name="Due date" />,
        <GroupByItem name="Batch" />,
        <GroupByItem name={undefined} jsxChangedData={[
            <span style={{ fontWeight: filters.groupBy === undefined ? "600" : "normal" }}>None</span>,
            <span style={{ fontSize: 12, color: "lightgray", marginTop: 2, marginLeft: 4 }}>(default)</span>
        ]} />
    ]

    const filterItems = [
        <p className="project-filters-modal-title">FILTERS</p>,
        <div>
            <div className="project-filters-filter-wrapper">
                <span>Keyword</span>
                <TextField size="small" placeholder="keyword" value={filters.filter.keyword ?? ""}
                    onChange={(e) =>
                        setFilter("filter", { ...filters.filter, keyword: e.target.value })}
                    InputProps={{ style: { height: 32, width: 159 } }} />
            </div>
            <div className="project-filters-filter-wrapper">
                <span>Status</span>
                <StatusFilter projectId={p.projectId} />
            </div>
            <div className="project-filters-filter-wrapper">
                <span>Due date</span>
                <div className="project-filters-filter-date-wrapper">
                    <CustomDatePicker value={new Date(filters.filter.date || "")} dontShowValue
                        setValue={(value: Date | null) =>
                            setFilter("filter", {
                                ...filters.filter,
                                date: moment(value).format("YYYY-MM-DD 00:00:00")
                            })}
                        name="" shortView={true}
                        icon={<EventAvailableIcon style={{ color: blueIconColor }} />} />
                    <span className={`project-filters-filter-date${!filters.filter.date ? "-disabled" : ""}`}>
                        {filters.filter.date ? moment(filters.filter.date).format("DD/MM/YYYY") : "DD/MM/YYYY"}
                    </span>
                    {filters.filter.date && <CloseIcon onClick={() =>
                        setFilter("filter", { ...filters.filter, date: null })}
                        style={{ color: "lightgray", cursor: "pointer" }} />}
                </div>
            </div>
            <Button style={{ marginLeft: 15, marginTop: 25, marginBottom: 20, width: 146 }}
                variant="outlined" color="secondary" onClick={clearFiltersOnClick}>CLEAR FILTERS</Button>
        </div>
    ]

    return <div className="project-filter-bar-wrapper">
        <div className="project-filter-bar-search">
            <TextField
                id="input-with-icon-textfield"
                value={filters.searchTerm}
                onChange={(event) => setFilter("searchTerm", event.target.value)}
                InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon color="secondary" />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        filters.searchTerm && <InputAdornment position="end">
                            <IconButton onClick={() => setFilter("searchTerm", "")}>
                                <img alt="close" src={baseline_close} style={{ width: 20, height: 20 }} />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                placeholder="Search"
                style={{
                    marginLeft: 16, padding: 8, backgroundColor: "white", width: 210,
                    border: "1px solid #E6E7E8", borderRadius: 4
                }}
                variant="standard"
            />
        </div>
        {p.projectId === 0 &&
        filters.showArchivedProjects &&
            <div>
                <RestoreProjectComponent selectedArchivedProjectIds={p.selectedProjectIds} />
                <RemoveProjectComponent selectedArchivedProjectIds={p.selectedProjectIds}/>
            </div>
        }
        {p.projectId === 0 &&
        filters.viewCompletedProjects &&
            <div>
                <ArchiveProjectComponent selectedProjectIds={p.selectedProjectIds} />
            </div>
        }
        <div style={{ marginRight: 16,  }}>
            {!filters.showArchivedProjects && !filters.viewCompletedProjects &&
                <>
                    <ProjectFilterItem key="groupByItem" name="Group by" selectedValue={filters.groupBy} paperItems={groupByItems}
                        anchorEl={anchorElGroupBy} setAnchorEl={setAnchorElGroupBy} closeModalOnSelect paperWidth={139} />
                    <ProjectFilterItem key="filterItem" name="Filter" selectedValue={selectedFiltersCount} paperItems={filterItems}
                        anchorEl={anchorElFilter} setAnchorEl={setAnchorElFilter}
                        paperWidth={269} icon={<FilterAltIcon color="secondary" />} closeModalOnSelect={false} />
                    {auth.hasUserRoleAccess() &&
                        <ProjectFilterItem key="myTaskItem" name="My tasks" selectedValue={filters.showMyTasks}
                            anchorEl={anchorElMyTasks}
                            setAnchorEl={setAnchorElMyTasks}
                            onClick={() => setFilter("showMyTasks", !filters.showMyTasks)} closeModalOnSelect />
                    }
                    <ProjectFilterItem key="usersItem" name="Assignees" paperItems={[<></>]} userArray
                        selectedValue={filters.assignees.length !== 0 ? filters.assignees.length : false}
                        anchorEl={anchorElAssignees} setAnchorEl={setAnchorElAssignees} paperWidth={245} onClick={() => { }}
                        clearOnClick={() => setFilter("assignees", [])}
                        tooltipArray={filters.assignees} closeModalOnSelect={false} />
                    <ProjectFilterItem key="subtaskItem" name="Subtasks" selectedValue={filters.showSubtasks}
                        anchorEl={anchorElSubtasks} setAnchorEl={setAnchorElSubtasks} closeModalOnSelect
                        onClick={() => setFilter("showSubtasks", !filters.showSubtasks ? "Expanded" : undefined)} />
                </>
            }
            {(auth.hasAdminRoleAccess() || auth.hasPMRoleAccess()) && !filters.showArchivedProjects && p.projectId === 0 &&
                <ProjectFilterItem key="completedProjects"
                    name={!filters.viewCompletedProjects ? "View completed projects" : "Back to projects list"}
                    selectedValue={filters.viewCompletedProjects}
                    anchorEl={anchorElCompletedProjects}
                    setAnchorEl={setAnchorElCompletedProjects}
                    onClick={() => setFilter("viewCompletedProjects", !filters.viewCompletedProjects)} closeModalOnSelect />
            }
            {(auth.hasAdminRoleAccess() || auth.hasPMRoleAccess()) && !filters.viewCompletedProjects && p.projectId === 0 &&
                <ProjectFilterItem key="archivedProjects"
                    name={!filters.showArchivedProjects ? "View archived projects" : "Back to projects list"}
                    selectedValue={filters.showArchivedProjects}
                    anchorEl={anchorElArchivedProjects}
                    setAnchorEl={setAnchorElArchivedProjects}
                    onClick={() => setFilter("showArchivedProjects", !filters.showArchivedProjects)} closeModalOnSelect />
            }
        </div>
    </div>
}

export function ProjectFilterItem(p: {
    name: string, selectedValue: string | boolean | number | undefined, anchorEl: HTMLElement | null,
    setAnchorEl: (anchorEl: HTMLElement | null) => void, closeModalOnSelect: boolean,
    onClick?: () => void, paperItems?: JSX.Element[], paperWidth?: number, icon?: JSX.Element, userArray?: boolean,
    clearOnClick?: () => void, tooltipArray?: UserAssign[]
}) {
    const open = Boolean(p.anchorEl);
    const handleMenuClose = () => p.setAnchorEl(null);
    const theme = useTheme();
    const projectId = useAppSelector((state) => selectedProjectSelector(state));
    const filters = useAppSelector((state) => projectFiltersSelector(state, projectId));
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (p.closeModalOnSelect) {
            handleMenuClose();
        }
    }, [p.selectedValue])

    const tooltipUserItem = p.tooltipArray?.map((e, index) => <div key={index}>
        <div className="project-filters-tooltip-wrapper">
            <span>{e.name}</span>
        </div>
        <div className="project-filters-tooltip-space" />
    </div>)

    const filterOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        dispatch(setShowToolbarAfterDeletion(false));
        p.setAnchorEl(e.currentTarget);
        if (p.onClick) {
            p.onClick();
        }
    }

    const clearOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (p.clearOnClick) {
            p.clearOnClick();
            e.stopPropagation();
        }
    }

    const iconButton = <div onClick={filterOnClick} className={!p.selectedValue && !open ? "project-filter-item" : open ?
        "project-filter-item-toggle" : "project-filter-item-selected"}>
        {p.icon ?? <></>}
        <span style={{ fontSize: 13, marginLeft: !p.icon ? 0 : 5 }}>{p.name}</span>
        {typeof p.selectedValue !== "boolean" && p.selectedValue !== 0 && <span style={{ fontSize: 13 }}>
            {p.selectedValue && `: ${p.selectedValue}`}
        </span>}
        {(!!p.selectedValue && p.clearOnClick) &&
            <IconButton sx={{ p: 0 }} onClick={clearOnClick}
                style={{
                    width: 14, height: 14, borderRadius: 7, border: "2px solid white", fontSize: 8, marginTop: 2,
                    marginLeft: 6, backgroundColor: theme.palette.darkGrayishBlue.main, color: "white"
                }}>
                <span style={{ textAlign: "center" }}>X</span>
            </IconButton>
        }
    </div>

    return <>
        {p.userArray ? <UsersApply anchorEl={p.anchorEl} setAnchorEl={p.setAnchorEl} icon={iconButton}
            selectedArray={filters.assignees} type="FILTER" onApply={() => { }} /> :
            <>
                <ConditionalWrapper condition={!!p.clearOnClick && p.selectedValue !== 0} wrapper={(children: JSX.Element) =>
                    <Tooltip title={tooltipUserItem!} componentsProps={componentPropsTooltip}>{children}</Tooltip>}
                >
                    {iconButton}
                </ConditionalWrapper>
                {p.paperItems && open && <Menu
                    id="basic-menu"
                    anchorEl={p.anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    PaperProps={{ sx: { width: p.paperWidth! } }}
                >
                    {p.paperItems}
                </Menu>}
            </>}
    </>
}

function StatusFilter(p: { projectId: number }) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const filters = useAppSelector((state) => projectFiltersSelector(state, p.projectId));
    const dispatch = useAppDispatch();

    const projectStatusMenuItems = Object.keys(statusList).map(status =>
        <div key={status} onClick={() => {
            dispatch(updateFilters({
                ...filters, filter: {
                    ...filters.filter, status: {
                        type: status, color: statusList[status as StatusType].color
                    }
                }
            }))
            setAnchorEl(null);
        }} style={{
            cursor: "pointer", marginTop: 2, width: 156, height: 25, backgroundColor: statusList[status as StatusType].color,
            color: "white", display: "flex", alignItems: "center"
        }}>
            <span style={{ marginLeft: 10, }}>{statusList[status as StatusType].name}</span>
        </div>
    )

    return <div style={{
        display: "flex", flexGrow: 0.4
    }}>
        <MoreActionsComponent id={0} anchorEl={anchorEl} setAnchorEl={setAnchorEl}
            menuHorizontalPosition="center"
            menuItems={projectStatusMenuItems}
            anchorOriginHorizontal="center"
            icon={<div style={{
                backgroundColor: filters.filter.status.color ?? "white", display: "flex",
                justifyContent: "space-around", alignItems: "center", border: "1px solid lightgray",
                height: 32, minWidth: 155
            }}>
                <span style={{
                    fontSize: 10, color: filters.filter.status.color ? "white" : theme.palette.text.primary,
                }}>
                    {filters.filter.status.type ? statusList[filters.filter.status.type].name : "SELECT STATUS"}
                </span>
                <ArrowDropDownIcon style={{
                    color: filters.filter.status.color ?
                        "transparent" : theme.palette.secondary.main, marginLeft: 10
                }} />
            </div>
            }
        />
    </div>
}

