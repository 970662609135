import React from 'react';
import {List as MuiList} from '@mui/material';
import ListItem from "./ListItem";
interface Props{
    lists: any;
    iconBefore?: React.ReactNode;
    iconAfter?: React.ReactNode;
    subTitle?: React.ReactNode | string
}
const List:React.FC<Props> = (props) => {
    const {subTitle, iconAfter, iconBefore, lists} = props;
    const renderSubHeader = () => {
        return subTitle ? subTitle : ''
    }
    return(
        <MuiList
            sx={{ width: '100%',bgcolor: 'background.paper' }}
            subheader={renderSubHeader}
        >
        <ListItem lists={lists} iconAfter={iconAfter} iconBefore={iconBefore}/>
        </MuiList>
    )
}
export default List