import { Button, FormControl, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { NavBar } from "../../components/navBar";
import { useAuthService } from "../../contexts/auth-context";
import { useAdminService } from "../../contexts/super-admin-context";
import { useEffectAsync } from "../../hooks/async-effect";
import { useEventHandler } from "../../hooks/handle-events";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { headers } from "../../services/config";
import { postApiUsersAccountLogin } from "../../services/user";
import { AuthentificationModal } from "../modals/auth-modal";
import './admin-choices.scss';
import './login.scss';
import { accountListSelector, setAccountList } from "../../redux/account-list";
import { useEffect } from "react";

export function AdminChoices() {
    const theme = useTheme();
    const { getAccounts } = useAdminService();
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const { register, handleSubmit, getValues } = useForm<{ account: string }>();
    const { verifyUserRole, setUserPerspective, setSuperAdminPerspective, setLoginInfo, loginInfo } = useAuthService();
    const accounts = useAppSelector((state) => accountListSelector(state));
    const dispatch = useAppDispatch();
    const accountList = localStorage.getItem("accountList");

    useEffect(() => {
        localStorage.removeItem("impersonate");
    }, [])

    useEffect(() => {
        dispatch(setAccountList(JSON.parse(accountList!)));
    }, [accountList])

    useEffectAsync(async () => {
        if (((verifyUserRole("SUPER") || verifyUserRole("AM")) && !loginInfo?.tenant?.schema)) {
            await getAccounts();
        }
    }, [loginInfo])

    const onSubmit = () => {
        const acc = accounts?.find(a => a.id === getValues("account"));
        postApiUsersAccountLogin({ accountId: acc?.id! }).then((tenant) => {
            headers['X-Tenant'] = acc?.schema!;
            localStorage.setItem("impersonate", "true");
            setLoginInfo({ ...loginInfo, tenant: tenant });
            setUserPerspective();
        });
    };

    if (!verifyUserRole("SUPER") && !verifyUserRole("AM")) return <></>
    return <div>
        <NavBar />
        <div className='box-container'>
            <AuthentificationModal>
                <div className="box-content">
                    {verifyUserRole("SUPER") && <>
                        <p>To manage accounts access admin dashboard</p>
                        <Button color="secondary" variant="contained" onClick={setSuperAdminPerspective}>ACCESS ADMIN DASHBOARD</Button>
                        <br />
                        <div className="horizontal-line"></div>
                    </>}
                    {verifyUserRole("AM") && <>
                        <p style={{ fontSize: 25, textAlign: "center", marginBottom: 16 }}>Select an account to access it</p>
                    </>}
                    <p>
                        {verifyUserRole("SUPER") ? "To access an account and manage the users within it please select an account" :
                            "You have access to multiple accounts. Please select an account to access it."}
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="admin-choice-label">Select Account</InputLabel>
                            <Select
                                size="small"
                                defaultValue=""
                                labelId="admin-choice-label"
                                label="Select Account"
                                color="secondary"
                                style={{ backgroundColor: "white", color: theme.palette.text.primary }}
                                {...register("account", { required: true })}
                            >
                                {accounts?.map((acc) => (
                                    <MenuItem
                                        key={acc.id}
                                        value={acc.id}
                                        onMouseEnter={onMouseEventHandler}
                                        onMouseLeave={onLeaveMouseEventHandler}>{acc.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <br />
                        <Button type="submit" color="secondary" variant="contained" style={{ marginTop: 10 }}>ACCESS ACCOUNT</Button>
                    </form>
                </div>
            </AuthentificationModal>
        </div>
    </div>
}
