import { InternalUser } from "../../../models/InternalUser";
import baseline_close from "../../../provisional_icons/baseline-close.png";
import Button from "@mui/material/Button";
import { ButtonSpinner } from "../../../components/button-spinner";
import { useCustomModal } from "../custom-message-modal";
import { useState } from "react";
import { useAppDispatch } from "../../../hooks/redux-hook";
import { deleteApiRlsUsersById } from "../../../services/rls.user";
import { hideProgressLine, showProgressLine } from "../../../redux/progress-line";
import { showSnackbar } from "../../../redux/snackbar";

export function RemoveSuperAdminModal(user: InternalUser, fetchSuperAdmins: () => void) {
    const { hideModal } = useCustomModal();
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const removeSuperAdmin = () => {
        setLoading(true);
        dispatch(showProgressLine());
        deleteApiRlsUsersById(user.id).then(() => {
            hideModal();
            fetchSuperAdmins();
            dispatch(showSnackbar({
                message: `Super admin ${user.name ?? user.email} has been removed from the RLS platform.`,
                type: "info"
            }))
            dispatch(hideProgressLine());
            setLoading(false);
        })
            .catch(e => {
                dispatch(showSnackbar({ message: "Error removing super admin", type: "error" }));
            })
    }

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img alt="close" src={baseline_close} className="remove-entity-close-button" onClick={() => loading ? {} : hideModal()} />
            <span className="remove-entity-title" >
                Remove super admin {user.name ?? user.email} from RLS platform?
            </span>
            <p className="remove-entity-disclaimer" style={{ fontWeight: "600" }} >
                This person will be removed from the platform and they will no longer be able to login into the RLS platform.<br /><br />
                <span style={{ fontWeight: "normal" }}>You can reactivate this user from the</span> Removed super admins section by clicking “Reactivate user” button.
            </p>
            <div className="remove-entity-button-list" style={{ marginTop: 20 }} >
                <Button color="secondary" variant="outlined" onClick={hideModal} disabled={loading}>CANCEL</Button>
                <Button disabled={loading} onClick={removeSuperAdmin} color="error" variant="contained">
                    REMOVE USER {loading && <ButtonSpinner />}
                </Button>
            </div>
        </div>
    </div>
}
