import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomFieldProjectDetails } from "../../pages/user/projects/project-details";
import { useCustomModal } from "../../pages/modals/custom-message-modal";
import { RemoveCustomFieldProjectDetailsModal } from "../../pages/modals/modal-content/remove-custom-field-project-details-modal";
import { Project } from "../../models/Project";

export function ProjectDetailsCustomField(props: {
    customField: CustomFieldProjectDetails, canBeEdited: boolean, nameLength: number,
    canBeDeleted: boolean, isLastItem?: boolean, edit?: () => void, fetchProject?: () => void, project?: Project
}) {
    const { showModal } = useCustomModal();
    return <div style={{
        display: "flex", alignItems: "center", justifyContent: "space-between",
        borderBottom: !props.isLastItem ? "2px solid #E6E7E8" : "", height: 36
    }}>
        <div style={{ flexGrow: 1 }}>
            <span style={{ fontSize: 11, letterSpacing: 1.5, minWidth: 204, width: props.nameLength, display: "inline-block" }}>{
                props.customField.name.toUpperCase()
            }</span>
            <span style={{ fontSize: 15, fontWeight: "600", marginLeft: 16 }}>{props.customField.value}</span>
        </div>
        <div style={{
            display: "flex", width: 58, marginRight: 10, justifyContent: (props.canBeEdited && props.canBeDeleted) ?
                "space-between" : "flex-end"
        }}>
            {props.canBeEdited && <ModeEditIcon style={{ color: "#939598", cursor: "pointer" }} onClick={props.edit} />}
            {props.canBeDeleted && <DeleteIcon style={{ color: "#939598", cursor: "pointer" }} onClick={() =>
                showModal(RemoveCustomFieldProjectDetailsModal, {
                    customField: props.customField, fetchProject: props.fetchProject!,
                    project: props.project!
                })} />}
        </div>
    </div>
}
