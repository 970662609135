import React, {useContext} from 'react';
import {useCustomModal} from "../../pages/modals/custom-message-modal";
import {useAppSelector, useAppDispatch} from "../../hooks/redux-hook";
import {pageMarginsSelector, setFooter, setHeader, setLeft, setRight} from "../../redux/pagemargins";
import {selectProject} from "../../redux/projects";
import WebViewerContext from "../../contexts/webviewer-context";


const ChangeMarginsModal = () => {
    const { hideModal } = useCustomModal();
    const { setInstance,instance } = useContext(WebViewerContext);
    const dispatch = useAppDispatch();

    const pageMargins = useAppSelector(state => pageMarginsSelector(state));

    return (
        <div style={{'maxWidth': '600px', 'padding': '5px'}}>
            <h1>Change Search Margins</h1>
            <p>Any text in the margins you set here will not be searched. This is useful if there's any repeated content
            on the sides of the page that you don't want to see in the search results</p>
            <div style={{display: 'grid', width: 350, gridTemplateColumns: 'auto auto'}}>
                <p style={{marginBottom: 20}}>Page Margin Footer (%)</p>
                <input width={"inherit"} defaultValue={pageMargins.footer}
                       style={{backgroundColor: 'inherit', height: 30, marginTop: 5}} type={"number"} max={100}
                       onChange={(event => {
                           dispatch(setFooter(event.target.valueAsNumber));
                           instance.Core.documentViewer.trigger('changePageMarginFooter', { marginFooter: event.target.valueAsNumber})
                       })}/>
                <p style={{marginBottom: 20}}>Page Margin Header (%)</p>
                <input width={"inherit"} defaultValue={pageMargins.header}
                       style={{backgroundColor: 'inherit', height: 30, marginTop: 5}} type={"number"} max={100}
                       onChange={(event => {
                           dispatch(setHeader(event.target.valueAsNumber));
                           instance.Core.documentViewer.trigger('changePageMarginHeader', {marginHeader: event.target.valueAsNumber})
                       })}/>
                <p style={{marginBottom: 20}}>Page Margin Left (%)</p>
                <input width={"inherit"} defaultValue={pageMargins.left}
                       style={{backgroundColor: 'inherit', height: 30, marginTop: 5}} type={"number"} max={100}
                       onChange={(event => {
                           dispatch(setLeft(event.target.valueAsNumber));
                           instance.Core.documentViewer.trigger('changePageMarginLeft', {marginLeft: event.target.valueAsNumber})
                       })}/>
                <p style={{marginBottom: 20}}>Page Margin Right (%)</p>
                <input width={"inherit"} defaultValue={pageMargins.right}
                       style={{backgroundColor: 'inherit', height: 30, marginTop: 5}} type={"number"} max={100}
                       onChange={(event => {
                           dispatch(setRight(event.target.valueAsNumber));
                           instance.Core.documentViewer.trigger('changePageMarginRight', {marginRight: event.target.valueAsNumber})
                       })}/>
            </div>
            <div className={'container'}>
                <button className='change-status-btn' onClick={() => hideModal()}>OK</button>
            </div>
        </div>
    );
};

export default ChangeMarginsModal;
