import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { projectSelector, selectedProjectSelector } from "../../redux/projects";
import { usersSelector } from "../../redux/users";
import { Check, Close, Search } from "@mui/icons-material";
import { Avatar, Button, TextField } from "@mui/material";
import { useState } from "react";
import { InfoTenantUser } from "../../models/InfoTenantUser";
import { getUserInitials } from "../../services/utils";
import { actionBarIsVisibleSelector } from "../../redux/action-bar";
import { roles } from "../../pages/admin/roles-overview";
import { Role } from "../../pages/admin/single-account";
import { postApiProjectsAssignUsers } from "../../services/project";
import { AssignUsersToProject } from "../../models/AssignUsersToProject";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { showSnackbar } from "../../redux/snackbar";
import { RemoveUsers } from "../../pages/modals/remove-users";

export function ShareProjectMenu(p: { showUserMenu: (value: boolean) => void, fetchProject: () => void }) {

    const dispatch = useAppDispatch();
    const projectId = useAppSelector((state) => selectedProjectSelector(state));
    const project = useAppSelector((state) => projectSelector(state, projectId));
    const users = useAppSelector((state) => usersSelector(state, false));
    const actionBarIsVisible = useAppSelector((state) => actionBarIsVisibleSelector(state));
    const [searchInput, setSearchInput] = useState("");
    const [selectedUsers, setSelectedUsers] = useState(project.organizationShare ? users.map(user => user.id) :
        (project.users?.map(user => user.id) || []));
    const [showRemoveUsers, setShowRemoveUsers] = useState(false);
    const [removedUsers, setRemovedUsers] = useState<InfoTenantUser[]>([]);
    const [addedUsers, setAddedUsers] = useState<InfoTenantUser[]>([]);

    const displayedUsers = users.filter(user =>
        searchInput === "" || user.name.toLowerCase().includes(searchInput.toLowerCase()));

    const selectAll = () => {
        setSelectedUsers(users.map(user => user.id));
    }

    const selectUser = (id: number) => {
        if (selectedUsers.includes(id)) {
            setSelectedUsers(selectedUsers.filter(userId => userId !== id));
        } else {
            setSelectedUsers([...selectedUsers, id]);
        }
    }

    const updateUsers = () => {
        const organizationShare = users.length === selectedUsers.length;
        const initialUsers = project.organizationShare ? users : project.users;
        const auIds = selectedUsers?.filter(id => !initialUsers?.map(iu => iu.id).includes(id));
        const au = users.filter(u => auIds.includes(u.id));
        const ru = initialUsers?.filter(user => !selectedUsers.includes(user.id));
        if (!organizationShare && ru && ru.length > 0) {
            setRemovedUsers(ru);
            setAddedUsers(au);
            setShowRemoveUsers(true);
        } else {
            saveUsers(au);
        }
    }

    const saveUsers = (au: InfoTenantUser[]) => {
        if (showRemoveUsers) {
            setShowRemoveUsers(false);
        }
        dispatch(showProgressLine());
        const organizationShare = users.length === selectedUsers.length;
        const body: AssignUsersToProject = {
            projectId,
            organizationShare,
            users: organizationShare ? undefined : selectedUsers
        }
        postApiProjectsAssignUsers(body)
            .then(() => {
                dispatch(hideProgressLine());
                p.showUserMenu(false);
                p.fetchProject();
                // future feature: add notistack for snackbars stack
                if (au.length) {
                    const userNames = au.map(u => u.name).join(", ");
                    const message: string = au.length > 3 ?
                        `You shared the project with ${au.length} more users.` :
                        `You shared the project with the following users: ${userNames}.`
                    dispatch(showSnackbar({ message, type: "info" }))
                    setTimeout(() => {
                        const organizationMessage = "You shared the project with the whole organization.";
                        if (organizationShare) {
                            dispatch(showSnackbar({ message: organizationMessage, type: "info" }))
                        }
                    }, 3500)
                }
                setTimeout(() => {
                    if (removedUsers.length > 0) {
                        const userNames = removedUsers.map(u => u.name).join(", ");
                        const message: string = removedUsers.length > 3 ?
                            `${removedUsers.length} users no longer have access to this project.` :
                            removedUsers.length > 1 ? `Following users: ${userNames} no longer have access to this project.` :
                                `${removedUsers[0].name} no longer has access to this project.`
                        dispatch(showSnackbar({ message, type: "info" }))
                    }
                }, au.length ? 3500 : 0);
            })
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error sharing project!", type: "error" }));
            })
    }

    const hasChanges = () => !!project && ((project.users === undefined && selectedUsers.length > 0) ||
        (project.users !== undefined && (project.users.length !== selectedUsers.length ||
            project.users.filter(user => !selectedUsers.includes(user.id)).length > 0 ||
            selectedUsers.filter(userId => project.users && !project.users.find(u => u.id === userId)).length > 0)))


    return <div style={{
        width: 220, backgroundColor: "white", margin: "32px 0",
        display: "flex", flexDirection: "column", overflow: "hidden"
    }}>
        <div style={{ display: "flex", justifyContent: "space-between", margin: 16 }}>
            <span style={{ fontSize: 15, letterSpacing: 0.24, fontWeight: 600 }}>Share with</span>
            <Close onClick={() => p.showUserMenu(false)} sx={{ color: "#939598", cursor: "pointer" }} />
        </div>
        <TextField
            value={searchInput}
            onChange={(ev) => setSearchInput(ev.target.value)}
            sx={{ margin: "0 8px", height: 48, width: 204, borderColor: "#E6E7E8" }}
            InputProps={{
                startAdornment: <Search sx={{ color: "#217da2" }} />
            }}
            inputProps={{
                style: {
                    paddingLeft: 8,
                    fontSize: 15,
                    height: 15
                }
            }}
        />
        <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 8px", alignItems: "center" }}>
            <span style={{ fontSize: 11 }}>USERS</span>
            <span style={{ color: "#217DA2", fontSize: 13, letterSpacing: 0.4, cursor: "pointer" }}
                onMouseEnter={ev => (ev.target as HTMLElement).style.textDecoration = 'underline'}
                onMouseLeave={ev => (ev.target as HTMLElement).style.textDecoration = ''}
                onClick={selectAll}>
                select all
            </span>
        </div>
        {displayedUsers.length === 0 ?
            <div style={{ textAlign: "center", color: "#939598", fontStyle: "italic", fontSize: 15, letterSpacing: 0.24 }}>
                No results
            </div>
            :
            <div style={{ overflowY: "auto", maxHeight: `calc(100vh - 116px - 300px${actionBarIsVisible ? " - 60px" : ""})` }}>
                {displayedUsers.map(user => <UserItem key={user.id}
                    user={user}
                    selected={selectedUsers.includes(user.id)}
                    selectUser={selectUser} />
                )}
            </div>
        }
        <Button variant="contained" color="secondary"
            style={{ width: 148, height: 39, alignSelf: "center", marginTop: 24 }}
            key={selectedUsers.length}
            disabled={!hasChanges()}
            onClick={updateUsers}
        >
            SAVE CHANGES
        </Button>
        {showRemoveUsers && <RemoveUsers projectName={project.name} users={removedUsers}
            restrictUsers={() => saveUsers(addedUsers)} showRemoveUsers={setShowRemoveUsers} />}
    </div>
}

function UserItem(p: { user: InfoTenantUser, selected: boolean, selectUser: (id: number) => void }) {

    const [hover, setHover] = useState(false);

    return <div style={{
        display: "flex", alignItems: "center", cursor: "pointer",
        backgroundColor: p.selected ? "#EDF5F8" : hover ? "#F5F6F7" : "white", margin: "2px 8px"
    }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => p.selectUser(p.user.id)}>
        <Avatar sx={{ width: 24, height: 24, bgcolor: p.user.color ?? "var(--normalBlue)", fontSize: 12, margin: "0 8px" }}>
            {getUserInitials(p.user.name)}
        </Avatar>
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, margin: 4 }}>
            <span style={{ fontWeight: 600 }}>{p.user.name}</span>
            {p.user.roles!.map(role => <span key={role} style={{ fontSize: 13, letterSpacing: 0.4 }}>{roles[role as Role]}</span>)}
        </div>
        {p.selected && <Check sx={{ margin: "0 8px", color: "#217DA2", width: 14 }} />}
    </div>
}
