import { useTheme } from "@mui/material/styles";

export function useEventHandler() {
    const theme = useTheme();
    const onMouseEventHandler = (e: any) => {
        const event = e.target as HTMLInputElement;
        event.style.backgroundColor = theme.palette.lightGrayishBlue3.main;
    }

    const onLeaveMouseEventHandler = (e: any) => {
        const event = e.target as HTMLInputElement;
        event.style.backgroundColor = "white";
    }

    const onMouseDisabledEventHandler = (e: any) => {
        const event = e.target as HTMLInputElement;
        event.style.backgroundColor = theme.palette.grey[200];
    }

    return {
        onMouseEventHandler,
        onLeaveMouseEventHandler,
        onMouseDisabledEventHandler
    }
}
