import { Button } from "@mui/material";
import { NavBar } from "../../components/navBar";
import { AuthentificationModal } from "../modals/auth-modal";
import './login.scss';
import { useNavigate } from "react-router-dom";

export function TwoFactorAuthInfo() {
    const nav = useNavigate();
    return <div>
        <NavBar />
        <div className="box-container">
            <AuthentificationModal>
                <div className="content-box">
                    <span className="title">Two-step verification is required by your administrator</span>
                    <p className="info-paragraph">
                        After you sign in, you’ll need to enter a security code sent to your registered email address.
                    </p>
                    <span style={{ fontSize: 15 }}>
                        Two factor authentication adds an extra layer of security to verify and protect your account and data.
                    </span>
                    <br />
                    <Button variant="contained" color="secondary" onClick={() => nav("/login")}>GO TO LOGIN</Button>
                </div>
            </AuthentificationModal>
        </div>
    </div>
}
