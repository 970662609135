import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const emptyDeletedTaskIds: number[] = []

const initialState = {
    deletedTaskIds: emptyDeletedTaskIds,
}

const deletedTasks = createSlice({
    name: 'deletedTasks',
    initialState,
    reducers: {
        setDeletedTasks: (state, action) => {
            state.deletedTaskIds = [ action.payload, ...state.deletedTaskIds ];
        },
        resetDeletedTasks: (state) => {
            state.deletedTaskIds = [];
        }
    }
});

export const { setDeletedTasks, resetDeletedTasks } = deletedTasks.actions;

export const deletedTasksSelector = (state: RootState) => state.deletedTasks.deletedTaskIds;

export default deletedTasks.reducer;