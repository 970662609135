import React, { useState } from 'react';

const FilterFunctionContext = React.createContext<any>({'function': () => {}});

export default FilterFunctionContext;

export function FilterFunctionContextProvider(props: { children: JSX.Element }) {
    const [filterFunction, setFilterFunction] = useState();
    return <FilterFunctionContext.Provider value={{ filterFunction, setFilterFunction }}>{props.children}</FilterFunctionContext.Provider>
}
