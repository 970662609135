import { CircularProgress } from "@mui/material";

export function LoadPage() {
    return <div style={{ height: "100vh", width: "100vw", position: "absolute", top: 0, left: 0, backgroundColor: "#F5F6F7", zIndex: 2000 }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <CircularProgress color="secondary" size={48} thickness={5} />
            <div style={{ fontSize: 21, fontWeight: 600, letterSpacing: 0.15, marginTop: 32, textAlign: "center" }}>
                This action may take a few seconds. <br />Please wait
            </div>
        </div>
    </div>
}
