import "./footer.scss"
import logo from "../provisional_icons/Logo-RLS.png";
import React from "react";
import { useNavigate } from "react-router-dom";

export function Footer() {

    const navigate = useNavigate();

    return <div className="footer-wrapper">
        <div style={{ display: "flex", alignItems: "center" }}>
            <img style={{ cursor: 'pointer', marginLeft: 32, height: 27 }} src={logo} alt='logo'
                 onClick={() => navigate('/app/user/landing-page')} />
            <div style={{ marginLeft: 64 }}>
                ©{new Date().getFullYear()} Real Life Sciences, LLC.
            </div>
        </div>
        <div style={{ display: "flex" }}>
            <a href="https://rlsciences.com/about/" target="_blank" className="footer-link">About Us</a>
            <a href="https://rlsciences.com/privacy-policy/" target="_blank" className="footer-link">Privacy Policy</a>
        </div>
    </div>
}
