import "./search-pattern-generator.css"
import {TextInput} from "./text-input";
import {TextChunkContainer} from "./text-chunk-container";
import {useState} from "react";
import {BeforeAndAfterContainer} from "./before-and-after-container";
import {SplitText} from "./split-text";
import {Selections} from "./selections";
import {PatternContainer} from "./pattern-container";
import {exampleText} from "./constants";

export function SearchPatternGenerator() {

    const [text, setText] = useState(exampleText)
    const [splitText, setSplitText]  = useState<SplitText>(new SplitText(text))
    const [useExtendedAlphabet, setUseExtendedAlphabet] = useState<boolean>(false)
    const [selections, setSelections] = useState<Selections>(new Selections(splitText.pieces, splitText.punctuationMarks))

    const onInputChange = (newText: string, useExtendedAlphabet: boolean) => {
        setText(newText)
        const newSplitText = new SplitText(newText)
        setSplitText(newSplitText)
        setUseExtendedAlphabet(useExtendedAlphabet)
        setSelections(new Selections(newSplitText.pieces, newSplitText.punctuationMarks))
    }

    return <div className="container-fluid py-3" id="rg_mainContainer">
        <TextInput text={text} useExtendedAlphabet={useExtendedAlphabet} onChange={onInputChange}/>
        <TextChunkContainer splitText={splitText} selections={selections} setSelections={setSelections}/>
        <BeforeAndAfterContainer splitText={splitText} selections={selections} setSelections={setSelections}/>
        {/*This won't be useful until we add table recognition*/}
        {/*<TableHeaderContainer/>*/}
        <PatternContainer selections={selections} splitText={splitText} useExtendedAlphabet={useExtendedAlphabet} />
    </div>
}
