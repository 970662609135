import {
    anyCharType,
    exactPhrase,
    partialMatch,
    before,
    notBefore,
    after,
    notAfter,
    isLookbehind,
    isLookahead,
    numbersOrLetters,
    letters,
    punctuation,
    digitOrLetterPattern,
    digitPattern,
    numbers,
    letterPattern,
    punctuationPattern,
    exactly,
    orFewer,
    orMore,
    startsWith,
    followedBy,
    endsWith,
    ellipses,
    extendedLetterPattern,
    strictPunctuationPattern, extendedDigitOrLetterPattern, nothing, extendedDigitPattern
} from "./constants";
import {Selections} from "./selections"
import {SplitText} from "./split-text";
import Button from "@mui/material/Button";
import {showSnackbar} from "../../../redux/snackbar";
import {useAppDispatch} from "../../../hooks/redux-hook";


export function PatternContainer(props: {selections: Selections, splitText: SplitText, useExtendedAlphabet: boolean}) {

    const dispatch = useAppDispatch();

    const columns: string[] = props.splitText.pieces
    const quantifierSelections: string[] = props.selections.quantifierSelections
    const beforeAndAfterSelections: string[] = props.selections.beforeAndAfterSelections
    const startsWithSelections = props.selections.startsWithSelections
    const followedBySelections = props.selections.followedBySelections
    const endsWithSelections = props.selections.endsWithSelections
    const exactMatchSelections: string[] = props.selections.exactMatchSelections
    const optionalCheckboxes: boolean[] = props.selections.optionalSelections

    function getLetterPattern(): RegExp {
        return props.useExtendedAlphabet ? extendedLetterPattern : letterPattern;
    }

    function getDigitPattern(): RegExp {
        return props.useExtendedAlphabet ? extendedDigitPattern : digitPattern
    }

    function getPunctuationPattern(): RegExp {
        return props.useExtendedAlphabet ? strictPunctuationPattern : punctuationPattern;
    }

    function getLettersOrNumbersPattern(): RegExp {
        return props.useExtendedAlphabet ? extendedDigitOrLetterPattern : digitOrLetterPattern;
    }

    function getOutputPattern(): string {

        const parts: string[] = [];
        let lookbehindInProgress = false;
        let lookaheadInProgress = false;

        for (let index = 0; index < columns.length; index++) {
            let pattern = "";
            const chunk = columns[index];
            const exactMatchSelection = exactMatchSelections[index];

            if (exactMatchSelection?.startsWith(exactPhrase) === true) {
                pattern += escapeRegExp(chunk).replace(" ", "\\s"); // The escape function doesn't work with spaces
            } else if (exactMatchSelection?.startsWith(anyCharType) === true) {
                pattern = getAnyCharactersPattern(exactMatchSelection, quantifierSelections[index], chunk);
            } else if (exactMatchSelection?.startsWith(partialMatch) === true) {
                pattern = getPartialMatchPattern(index);
            } else if (exactMatchSelection?.startsWith(nothing) === true) {
                pattern = "";    
            }

            //Remove the quantifier on punctuation in lookarounds because PDFTron doesn't support that. See note in Selections.ts
            if (isLookahead(beforeAndAfterSelections[index]) || isLookbehind(beforeAndAfterSelections[index])) {
                pattern = pattern.replace('+', `{${columns[index].length}}`)
            }

            // Add lookarounds if specified
            if (isLookbehind(beforeAndAfterSelections[index]) && !lookbehindInProgress) {
                const selection = beforeAndAfterSelections[index];
                if (selection === before) {
                    pattern = `(?<=${pattern}`;
                }
                if (selection === notBefore) {
                    pattern = `(?<!${pattern}`;
                }
                lookbehindInProgress = true;
            }
            if (lookbehindInProgress && !isLookbehind(beforeAndAfterSelections[index + 1])) {
                pattern += ")";
                lookbehindInProgress = false;
            }

            if (isLookahead(beforeAndAfterSelections[index]) && !lookaheadInProgress) {
                const selection = beforeAndAfterSelections[index];
                if (selection === after) {
                    pattern = `(?=${pattern}`;
                }
                if (selection === notAfter) {
                    pattern = `(?!${pattern}`;
                }
                lookaheadInProgress = true;
            }
            if (lookaheadInProgress && (index === columns.length - 1 || !isLookahead(beforeAndAfterSelections[index + 1]))) {
                pattern += ")";
                lookaheadInProgress = false;
            }

            // Surround with ()? if optional
            if (optionalCheckboxes[index] && pattern !== "") {
                pattern = `(${pattern})?`;
            }

            parts.push(pattern);
        }

        return parts.join('');
    }

    function getAnyCharactersPattern(exactMatchSelection: string, quantifierSelection: string | undefined, chunk: string): string {
        let pattern = "";

        if (exactMatchSelection.endsWith(numbersOrLetters)) {
            pattern += getLettersOrNumbersPattern().source;
        } else if (exactMatchSelection.endsWith(numbers)) {
            pattern += getDigitPattern().source;
        } else if (exactMatchSelection.endsWith(letters)) {
            pattern += getLetterPattern().source;
        } else if (exactMatchSelection.endsWith(punctuation)) {
            pattern += getPunctuationPattern().source;
        } else {
            pattern += getLettersOrNumbersPattern().source;
        }

        if (quantifierSelection?.startsWith(exactly) === true) {
            pattern += `{${chunk.length}}`;
        } else if (quantifierSelection?.endsWith(orFewer) === true) {
            pattern += `{1,${chunk.length}}`;
        } else if (quantifierSelection?.endsWith(orMore) === true) {
            pattern += `{${chunk.length},}`;
        } else {
            pattern += "+";
        }

        return pattern;
    }

    function getPartialMatchPattern(index : number) : string {
        let pattern: string = ""
        const startsWithPhrase: string = startsWithSelections[index]?.replace(startsWith, "")?.trim()
        pattern += addNumbersAndLettersPatterns(startsWithPhrase)
        const followedByPhrase: string = followedBySelections[index]?.replace(followedBy, "")?.trim()
        pattern += addNumbersAndLettersPatterns(followedByPhrase)
        const endsWithPhrase: string = endsWithSelections[index]?.replace(endsWith, "")?.trim()
        pattern += addNumbersAndLettersPatterns(endsWithPhrase)
        return pattern
    }

    function addNumbersAndLettersPatterns(phrase : string) : String {
        let pattern = ""
        if (phrase === numbers) {
            pattern += getDigitPattern().source
            pattern += "+"
        } else if (phrase === letters) {
            pattern += getLetterPattern().source
            pattern += "+"
        } else if (phrase === numbersOrLetters) {
            pattern += getLettersOrNumbersPattern().source
            pattern += "+"
        } else if (phrase !== ellipses) {
            pattern += escapeRegExp(phrase).replace(" ", "\\s")
        }
        return pattern
    }

    // Helper function to escape regular expression special characters
    function escapeRegExp(string: string): string {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    async function copyToClipboard(text: string): Promise<void> {
        try {
            await navigator.clipboard.writeText(text);
            dispatch(showSnackbar({
                message: 'Pattern copied to clipboard',
                type: "info"
            }))
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    }

    return <div className="row no-gutters bg-light mt-3 pr-4 rounded" id="rg_regex_result_container">
        <div className="col-sm-1 p-4 d-none d-sm-none d-md-block text-center">
            <h3 className="display-3 text-secondary">5</h3>
        </div>
        <div className="col-12 col-md-11 py-4 pl-4">
            <h5>Regular expression</h5>
            <input type="text" maxLength={1000} id="rg_raw_input_text" className="form-control rg-code" readOnly={true} value={getOutputPattern()}/>
            <div className="row mt-3">
                <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                    <Button variant="contained" color="secondary" id="rg_button_copy" onClick={() => {copyToClipboard(getOutputPattern())}}>Copy Pattern</Button>
                </div>
            </div>
        </div>
    </div>
}
