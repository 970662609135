import { UserSearchRequest } from "../models/UserSearchRequest";
import { InternalUser } from "../models/InternalUser";
import { AvailableAccountUsersRequest } from "../models/AvailableAccountUsersRequest";
import { TenantUser } from "../models/TenantUser";
import { NewUserRequest } from "../models/NewUserRequest";
import { NewManagerRequest } from "../models/NewManagerRequest";
import { User } from "../models/User";
import { AdminUpdateUserRequest } from "../models/AdminUpdateUserRequest";
import { AdminAddUserRoleRequest } from "../models/AdminAddUserRoleRequest";
import { DeleteUserRequest } from "../models/DeleteUserRequest";
import { DeleteUserRoleRequest } from "../models/DeleteUserRoleRequest";
import { ReactivateUserRequest } from "../models/ReactivateUserRequest";
import { MigrateUserRequest } from "../models/MigrateUserRequest";
import { AssignAccountRequest } from "../models/AssignAccountRequest";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
export function postApiRlsUsersSearch(body?: UserSearchRequest, signal?: AbortSignal): Promise<InternalUser[]> {
    const url = new URL(`/api/rls/users/search`, baseUrl);
    return fetchAndParse<InternalUser[]>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiRlsUsersAvailableForAccount(body?: AvailableAccountUsersRequest, signal?: AbortSignal): Promise<InternalUser[]> {
    const url = new URL(`/api/rls/users/available-for-account`, baseUrl);
    return fetchAndParse<InternalUser[]>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function putApiUsers(body?: TenantUser, signal?: AbortSignal): Promise<TenantUser> {
    const url = new URL(`/api/users`, baseUrl);
    return fetchAndParse<TenantUser>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function postApiRlsUsersInvite(body?: NewUserRequest, signal?: AbortSignal): Promise<User> {
    const url = new URL(`/api/rls/users/invite`, baseUrl);
    return fetchAndParse<User>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiRlsManagersInvite(body?: NewManagerRequest, signal?: AbortSignal): Promise<User> {
    const url = new URL(`/api/rls/users/ManagerInvite`, baseUrl);
    return fetchAndParse<User>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function putApiRlsUsersById(id: number, body?: AdminUpdateUserRequest, signal?: AbortSignal): Promise<InternalUser> {
    const url = new URL(`/api/rls/users/${id}`, baseUrl);
    return fetchAndParse<InternalUser>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function postApiRlsAddUserRole(body?: AdminAddUserRoleRequest, signal?: AbortSignal): Promise<InternalUser> {
    const url = new URL(`/api/rls/users/roles`, baseUrl);
    return fetchAndParse<InternalUser>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function deleteApiRlsUserRoleById(body?: DeleteUserRoleRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/rls/users/roles`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", body: JSON.stringify(body), headers, signal });
}
export function deleteApiRlsUsersById(id: number, body?: DeleteUserRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/rls/users/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", body: JSON.stringify(body), headers, signal });
}
export function postApiRlsUsersReactivateById(id: number, body?: ReactivateUserRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/rls/users/reactivate/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiRlsUsersMigrate(body?: MigrateUserRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/rls/users/migrate`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiRlsUsersAssignAccount(body?: AssignAccountRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/rls/users/assign-account`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
