import React, { useCallback, useEffect, useState } from 'react';
import RedactionSearchResult from './RedactionSearchResult';
import { Choice } from '@pdftron/webviewer-react-toolkit';
import CollapsiblePanelGroup from '../CollapsiblePanelGroup';
import './RedactionSearchResultGroup.scss';
import RedactionSearchResultContainer from "./RedactionSearchResultContainer";

const RedactionSearchResultGroup = (props) => {
    const {
        pageNumber,
        searchResults,
        selectedSearchResultIndexes,
        setSelectedSearchResultIndexes,
    } = props;

    const groupResultIndexes = searchResults.map((result) => result.index);
    const [allItemsChecked, setAllItemsChecked] = useState(false);

    useEffect(() => {
        const allResultsSelected = groupResultIndexes.reduce((allSelected, currentIndex) => {
            return selectedSearchResultIndexes[currentIndex] && allSelected;
        }, true);

        setAllItemsChecked(allResultsSelected);
    }, [selectedSearchResultIndexes, groupResultIndexes]);

    const checkAllResults = useCallback((event) => {
        const checked = event.target.checked;
        groupResultIndexes.forEach((resultIndex) => {
            selectedSearchResultIndexes[resultIndex] = checked;
        });
        setAllItemsChecked(checked);
        setSelectedSearchResultIndexes({ ...selectedSearchResultIndexes });
    }, [selectedSearchResultIndexes, groupResultIndexes]);

    const checkResult = useCallback((event, index) => {
        const checked = event.target.checked;
        selectedSearchResultIndexes[index] = checked;
        setSelectedSearchResultIndexes({ ...selectedSearchResultIndexes });
    }, [selectedSearchResultIndexes]);

    const header = () => {
        return (
            <div style={{display: "flex"}}>
                <input type={"checkbox"}
                       checked={allItemsChecked ?? false}
                       onChange={checkAllResults}
                       label={`Page ${pageNumber}`}
                       className="redaction-search-results-page-number"
                />
                <label>Page {pageNumber}</label>
            </div>
        );
    };

    const style = {
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingTop: '8px',
        paddingBottom: '4px',
    };

    return (
        <CollapsiblePanelGroup header={header} role="row" style={style}>
            <div role="list">
                {searchResults.map((searchResult, index) => (
                    <RedactionSearchResultContainer
                        checked={selectedSearchResultIndexes[searchResult.index]}
                        checkResult={checkResult}
                        searchResult={searchResult}
                        key={`${index}-${pageNumber}`}
                    />)
                )}
            </div>
        </CollapsiblePanelGroup>
    );
};

export default RedactionSearchResultGroup;