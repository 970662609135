import {Box, LinearProgress, Typography, useTheme,} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router";
import { MoreActionsComponent } from "../../../components/more-actions-component";
import { useEventHandler } from "../../../hooks/handle-events";
import baseline_arrow_back from "../../../provisional_icons/baseline-arrow-back.png";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useCustomModal } from "../../modals/custom-message-modal";
import { ProjectEditNameAndStudyIDWizard } from "./wizards/project-edit-name-studyID";
import { ProjectFilterBar } from "../../../components/project-filters";
import { CustomDatePicker } from "../../../components/custom-date-picker";
import { blueIconColor, statusList, StatusType } from "../../../constants";
import { StatusMenuComponent } from "../../../components/status-menu-component";
import { getApiProjectsById, putApiProjectsProjectStatusDatesById } from "../../../services/project";
import { Project } from "../../../models/Project";
import { ProjectComponent } from "../../../components/project/project-component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hook";
import { hideProgressLine, showProgressLine } from "../../../redux/progress-line";
import { showSnackbar } from "../../../redux/snackbar";
import { actionBarIsVisibleSelector, setIsTemplate, setProjectId, setShowToolbarAfterDeletion } from "../../../redux/action-bar";
import { loadProject, projectSelector } from "../../../redux/projects";
import { UsersAvatarList } from "../../../components/users-avatar-list";
import moment from "moment-timezone";
import {useLocation, useParams} from "react-router-dom";
import { ShareProjectMenu } from "../../../components/project/share-project-menu";
import { useAuthService } from "../../../contexts/auth-context";
import { customFieldsSelector, customFieldsValuesSelector } from "../../../redux/custom-fields";
import { ProjectDetailsComponent } from "./project-details";
import Button from "@mui/material/Button";
import {uploadFileUsingS3} from '../../../components/file-management'
import {FilesComponent} from "../../../components/files-component";
import {StyledCloseIconUploader} from "../../../components/close-button";
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FolderIcon from "@mui/icons-material/Folder";
import {BlockOutlined, Refresh} from "@mui/icons-material";
import { ExportProjectComponent } from "../../../components/project/export-project";
import {
    deleteApiFileById,
    FileScanPayload,
    FileScanResponse, FileScanTagInfo,
    filesScanning,
    getApiFilesByProjectId, updateFileForVirusScanStatus
} from "../../../services/files";
import {filesSelector, loadFiles} from "../../../redux/file-management";
import { FileState } from "../../../models/FileState";
import { putApiFileOpenStatusById } from "../../../services/files";
import {LinkFilesToTaskModal} from "../../modals/modal-content/link-files-to-task-modal";
import { ArchiveProjectComponent, RemoveProjectComponent, RestoreProjectComponent } from "../../../components/archived-project-actions";
import { userSettingsSelector } from "../../../redux/userSettings";
import { saveAs } from 'file-saver';
import './single-project.scss'
import {clear} from "@testing-library/user-event/dist/clear";
import CheckIcon from "@mui/icons-material/Check";
import List from "../../../components/common/List";
import {TaskList} from "../../../models/TaskList";
export type ProjectTabSelected = "Task Listing" | "Project Details | File Management";
let tableSelectedFiles: any[];
tableSelectedFiles = [];

export function setSelectedFiles(selectedFiles : any) {
    tableSelectedFiles = selectedFiles;
}

export async function markDocsAsOpened(publicUrls: any[], files: FileState[]) {
    for (const url of publicUrls) {
        const fileName = getFileNameFromURL(url)
        const file = files.find(file => file.name === fileName);
        if (file) {
            await putApiFileOpenStatusById(file.id, { isDocOpen: true });
        } else {
            throw new Error("File not found");
        }
    }
}

export function navToDocs(publicUrls: any[], files: FileState[], showModal: any, nav: any, projectId?: number, taskId?: number, initialTaskStatus?: string, isRestoring?: boolean, tag?: string) {
    if(publicUrls) {
        const currentlyOpenedDocs = files.filter(file => file.isDocOpen);
        if (currentlyOpenedDocs.length > 0) {
            const filesRequestedButAlreadyInUse: FileState[] = [];
            for (const url of publicUrls) {
                const fileName = getFileNameFromURL(url)
                const file = files.find(file => file.name === fileName);
                if (file) {
                    if (currentlyOpenedDocs.some(openedDoc => openedDoc.id === file.id)) {
                        filesRequestedButAlreadyInUse.push(file);
                    }
                } else {
                    throw new Error("File not found");
                }
            }
        }

        markDocsAsOpened(publicUrls, files);

        nav(`/app/user/workflow/projects/${projectId}/viewer`, {
            state: {
                files: publicUrls,
                taskId: taskId,
                initialTaskStatus: initialTaskStatus,
                isRestoring: isRestoring,
                tag: tag
            }
        });
    }
};

export function getFileNameFromURL(url: string) {
    try {
        //These URLS look like this
        //https://bucket-name.s3.amazonaws.com/test/22/documents/Document%20Name.pdf?AWSAccessKeyId=SDFJKSDK

        //Get everything after the final slash
        const fileNameWithAccessKey = url.split("/").pop();
        //Remove the question mark and everything after it. That's used in a URL to denote extra information
        const fileName = fileNameWithAccessKey?.split("?")[0];
        //Finally, use this method to remove encoded characters, like %20 for space.
        return decodeURIComponent(fileName!);
    } catch (e) {
        console.log(`Couldn't get file name from this URL ${url}`)
        return undefined;
    }
}

export function SingleProject() {

    async function refreshTable() {
        getApiFilesByProjectId(id)
            .then((files) => {
                setFileObjects(files);
                dispatch(loadFiles(files));
            }).catch((e) => {
                console.log(e)
                dispatch(showSnackbar({ message: "Error loading files!", type: "error" }));
            });
    }

    const auth = useAuthService();
    const theme = useTheme();
    const nav = useNavigate();
    const dispatch = useAppDispatch();
    const { showModal } = useCustomModal();
    const location = useLocation();
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [totalPages, setTotalPages] = useState(0);
    const [scopePages, setScopePages] = useState(0);
    const [showShareProject, setShowShareProject] = useState(false);
    const lastUrlElement = window.location.pathname.split("/").pop()
    const defaultTab = lastUrlElement === 'file-management' ? 'File Management' : 'Task Listing';
    const ref = useRef<any>(null);
    const [projectTabSelected, setProjectTabSelected] = useState<"Task Listing" | "Project Details" | "File Management">(defaultTab);
    const { projectId, taskListId } = useParams();
    const id = +projectId!;
    const project: Project | undefined = useAppSelector((state) => projectSelector(state, id));
    const actionBarIsVisible = useAppSelector((state) => actionBarIsVisibleSelector(state));
    const customFields = useAppSelector((state) => customFieldsSelector(state));
    const customFieldsValues = useAppSelector((state) => customFieldsValuesSelector(state));
    const [files, setFiles] = useState<any[]>([]);
    const [fileObjects, setFileObjects] = useState<FileState[]>([]);
    const [noFilesSelected, setNoFilesSelected] = useState<boolean>(true);
    const [taskListObject, setTaskListObject] = useState<Record<string, TaskList>>({});
    const [selectedTaskLists, setSelectedTaskList] = useState<any>({})
    const [selectedKey,setSelectedKey] = useState('')
    const projectFiles: FileState[] = useAppSelector(filesSelector);
    const userSettings = useAppSelector((state) => userSettingsSelector(state));
    const dateFormat = userSettings.regional.dateFormat;

    const getFiles = async () => {
        if (projectId) {
            getApiFilesByProjectId(Number(projectId)).then((data: any) => setFiles(data))
                .catch((err: any) => console.error(err))
        }
    }

    const ShowUpload = () => {
        const { hideModal } = useCustomModal();
        const [failedFiles, setFailedFiles] = useState<any[]>([]);
        const [containsFailedFiles, setContainsFailedFiles] = useState(false);
        const [waiting, setWaiting] = useState(false)
        const [isScanning, setIsScanning] = useState<boolean | undefined>(undefined);
        const [tags, setTags] = useState<FileScanResponse['tags']>([])

        const handleChangeStatus = ({ meta }: any, status: any) => {
            if (status==='removed' && failedFiles.some(failedFileName => filterFilename(failedFileName) === filterFilename(meta.name))) {
                failedFiles.splice(failedFiles.indexOf(meta.name),1)
            }
            if (failedFiles.length>0) {
                setContainsFailedFiles(true)
            } else {
                setContainsFailedFiles(false)
            }
        }

        function filterFilename(filename: string) {
            const fileParts = filename.split('/')
            return fileParts[fileParts.length - 1];
        }

        const handleSubmit = async (files: { file: any; }[], allFiles: any[]) => {
            const controller = new AbortController();
            dispatch(showProgressLine())
            setWaiting(true)
            setIsScanning(undefined)
            const promises: any = [];
            files.forEach((file: any) => promises.push(uploadFileUsingS3(file.file, project.id, auth.loginInfo?.tenant?.user?.id, false, false)));

            let payload = {
                files: [],
                projectId: project.id.toString()
            } as FileScanPayload
            const startScanning = async () => {
                setIsScanning(true)
                const response = await filesScanning(payload, controller.signal);
                if (response.isCompleted) {
                    stopScanning(response);
                    await refreshTable();
                } else {
                    await startScanning();
                }
            }

            try {
                const response = await Promise.all(promises);
                payload  = {
                    ...payload,
                    files: response.filter(file=>file !== null)
                }
                allFiles.forEach(f => f.remove())
                dispatch(showSnackbar({
                    message: "Files successfully uploaded. Virus scanning is processing...",
                    type: "info"
                }));
                if(payload.files.length > 0) {
                    await startScanning();
                }
                dispatch(hideProgressLine());
                setWaiting(false)
                await refreshTable()
            } catch (e) {
                setIsScanning(false);
                setWaiting(false);
                await refreshTable()
                dispatch(hideProgressLine());
                hideModal()
                dispatch(showSnackbar({message: "Error uploading files!", type: "error"}));
            }
        }

        const stopScanning = async (response: FileScanResponse) =>{
            setTags(response.tags)
            updateFileStatus(response.tags)
            dispatch(hideProgressLine());
            setWaiting(false)
            setIsScanning(false)
        }

        const updateFileStatus = async (tags: FileScanTagInfo[]) => {
            const controller = new AbortController();
            const infectedFiles = tags.filter(tag=>tag.tag === 'infected');
            if(infectedFiles.length > 0){
                const promises: any = [];
                infectedFiles.forEach((file: any) => promises.push(updateFileForVirusScanStatus({virusScanStatus: 'infected', id:file.id}, controller.signal)));
                 await Promise.all(promises);
            }
        }


        return (
            <div className="upload-file-modal-container">

                <div className="wizard-title">
                    <StyledCloseIconUploader onClick={hideModal} />
                    <span>{"RLS File Uploader"}</span>
                         </div>
            <Dropzone
                validate={file => {
                    const isInCurrentProject = projectFiles.find((projectFile: FileState) => projectFile.name === file.file.name);
                    if (isInCurrentProject) {
                        setFailedFiles([...failedFiles, file.file.name])
                        dispatch(showSnackbar({ message: "Error uploading files!", type: "error" }));
                        return 'Error'
                    }
                    return false;
                }}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                maxFiles={100}
                // .xlxs,, .xls .pdf, SAS, .xpt, Database (JDBC), and .deid (RLS Risk)
                accept={"application/pdf,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/x-sas-data,application/x-sas-xport,application/arx,.deid"}
                inputContent="Click here or drag and drop"
                submitButtonDisabled={files => files.length < 1 || failedFiles.length>0 || waiting}
                styles={{ dropzone: { minHeight: 450, minWidth: 400, padding:8 } }}
            />
                {waiting && <div className="custom">
                    <p>{isScanning ? 'Virus Scanning' : 'Uploading'}</p>
                    <LinearProgress className="linear-progress" />
                    <small>please wait...</small>
                </div> }
                {(containsFailedFiles) && <p style={{color: "#FF0000", marginLeft: 10}}>
                    Attention! The file you are attempting to upload({failedFiles[0]}) already exists within this project in the file management system.
                    To prevent redundancy and maintain an organized structure, we kindly request that you review the existing file before proceeding. You may consider renaming your file or making any necessary modifications to the existing one.
                </p>}
                {!waiting && isScanning === false && <div className="custom">
                    <Box sx={{textAlign: 'left', width: '100%'}}>
                        <Typography variant="h6" paddingLeft="20px">Virus Scanning Summary:</Typography>
                    </Box>
                    <List
                        lists={tags.map(tag=>({text: tag.fileName, rightIconValue: tag.tag === 'clean' ? 'valid' : 'invalid'}))}
                        iconBefore={<FolderIcon/>}
                    />
                    <Button variant='contained'  onClick={hideModal}>Ok</Button>
                </div> }
            </div>
        )
    }

    useEffect(() => {
        if (project && project.taskLists && customFieldsValues.length > 0) {
            const cfv = customFieldsValues.filter(cfv => project.taskLists!.some(tl => tl.id === cfv.taskListId));
            const totalPagesCF = customFields.find(cf => cf.shortName === "nrPages");
            const scopePagesCF = customFields.find(cf => cf.shortName === "pagesInScope");
            if (totalPagesCF) {
                setTotalPages(cfv.reduce((prevValue: number, value) =>
                    prevValue + value.customFieldsValues.filter(c => c.customFieldId === totalPagesCF.id)
                        .reduce((totalValue: number, c) => totalValue + (+c.valueString! || 0), 0)
                    , 0));
            }
            if (scopePagesCF) {
                setScopePages(cfv.reduce((prevValue: number, value) =>
                    prevValue + value.customFieldsValues.filter(c => c.customFieldId === scopePagesCF.id)
                        .reduce((totalValue: number, c) => totalValue + (+c.valueString! || 0), 0)
                    , 0));
            }
        }
    }, [customFieldsValues, customFields, projectTabSelected]);

    useEffect(() => {
        if (window.location.pathname.split("/").pop() !== "file-management") {
            setProjectTabSelected("Task Listing");
        }
    }, [projectId])

    const fetchProject = (key?: string) => {
        setSelectedKey(key ?? '')
        dispatch(showProgressLine());
        const controller = new AbortController();
        getApiFilesByProjectId(id)
            .then((files) => {
                setFileObjects(files);
                dispatch(loadFiles(files));
                getFiles();
            }).catch(() => {
                dispatch(showSnackbar({ message: "Error loading files!", type: "error" }));
            });
        getApiProjectsById(id, controller.signal)
            .then(project => dispatch(loadProject(project)))
            .then(() => {
                dispatch(hideProgressLine());
                dispatch(setProjectId(id));
                dispatch(setIsTemplate(false));
            })
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error loading project!", type: "error" }));
            });
    }

    const setDate = (value: Date | null, type: "start" | "due") => {
        dispatch(showProgressLine())
        const body = type === "start" ?
            {
                ...project!.projectStatusDates,
                startDate: value ? moment(value).format("YYYY-MM-DD 00:00:00") : undefined
            }
            :
            {
                ...project!.projectStatusDates,
                dueDate: value ? moment(value).format("YYYY-MM-DD 00:00:00") : undefined
            }

        putApiProjectsProjectStatusDatesById(project!.id, body)
            .then(() => {
                dispatch(hideProgressLine())
                fetchProject()
            })
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error setting date", type: "error" }))
            })
    }

    function getUrlsFromSelectedRows() {
        let urlList : string[] = [];

        // @ts-ignore
        tableSelectedFiles.forEach(row => {
            console.log(row.id);
            // @ts-ignore
            urlList.push(fileObjects[row.id].url);
        })
        return urlList;
    }

    function getIdsFromSelectedRows() {
        let keys = getKeysFromSelectedRows()
        let idList : number[] = [];

        for (const key of keys) {
            const fileId = fileObjects.find(file => file.name === key)?.id;
            if (fileId) {
                idList.push(fileId)
            }
        }

        return idList;
    }

    function getKeysFromSelectedRows() {
        let keyList : string[] = [];

        // @ts-ignore
        tableSelectedFiles.forEach(row => {
            // @ts-ignore
            keyList.push(fileObjects[row.id].name);
        })
        return keyList;
    }

    const goToDocs = (publicUrls: string[]) => {
        console.log(publicUrls)
        for (const url of publicUrls) {
            const filepath = url.split("?")[0]
            if (!filepath.endsWith('pdf')) {
                dispatch(showSnackbar({message: "Only PDFs can be opened in Docs", type: "error"}));
                return
            }
        }

        if (publicUrls) {
            getApiFilesByProjectId(id).then((files) => {
                setFileObjects(files);
                dispatch(loadFiles(files));
                const projectIdNum = projectId ? parseInt(projectId) : undefined
                navToDocs(publicUrls, files, showModal, nav, projectIdNum)
            })
        }
    }

    async function handleDelete(keys: string[]) {
        for (const key of keys) {
            const fileNameSplit = key.split("/");
            const fileName = fileNameSplit[fileNameSplit.length - 1];
            const fileId = fileObjects.find(file => file.name === fileName)?.id;
            if (fileId) {
                await deleteApiFileById(fileId);
            }
        }
        await refreshTable();
    }

    async function downloadFiles(): Promise<void> {
        try {
            for (const file of tableSelectedFiles) {
                const url = fileObjects[file.id].url
                const response = await fetch(url!);
                const blob = await response.blob();
                const filename = fileObjects[file.id].name;
                saveAs(blob, filename);
                console.log(`Downloaded file: ${filename}`);
            }

            console.log('All files downloaded successfully.');
        } catch (error) {
            console.error('An error occurred while downloading files:', error);
            dispatch(showSnackbar({message: "Error Downloading Files!", type: "error"}));
        }
        dispatch(hideProgressLine());
    }

    const setStatus = (status: StatusType) => {
        dispatch(showProgressLine());
        putApiProjectsProjectStatusDatesById(project!.id, { ...project!.projectStatusDates, status })
            .then(() => {
                dispatch(hideProgressLine());
                fetchProject();
            })
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error setting status", type: "error" }));
            })
    }

    function reset() {
        setNoFilesSelected(true)
        setFileObjects([])
        tableSelectedFiles = []
    }
    useEffect(() => {
        const listObject: any = {}
        project?.taskLists?.filter(tl => taskListId ? tl.id === +taskListId : true).map(tl => listObject[`${tl.id}`] = tl);
        setTaskListObject(listObject)
        const keys = Object.keys(listObject);
        let index = selectedKey !== '' ? keys.indexOf(selectedKey) : keys.length - 1;
        keys.length > 0 && setSelectedTaskList(listObject[keys[index]])
        if(ref?.current){
            ref?.current.resetSelectedTaskList(keys[index])
        }
    }, [project, selectedKey]);
    const renderSwitch = (state: "Task Listing" | "Project Details" | "File Management") => {
        switch (state) {
            case "Task Listing":
                return <div>
                    <ProjectFilterBar projectId={project.id} selectedProjectIds={[]}/>
                    {Object.keys(selectedTaskLists).length > 0 &&  <div style={{
                        height: `calc(100vh - 184px - 129px - 60px - 40px${actionBarIsVisible ? " - 60px" : ""})`,
                        borderTop: "1px solid var(--lightGrayishBlue2)", overflowY: "auto"
                    }} id={`project-data-${id}`}>

                        <ProjectComponent ref={ref} projectId={+project.id} projectName={project.name}
                                          taskLists={[selectedTaskLists]}
                                          taskListObject={taskListObject}
                                          changeSelectedTask={(id: string)=>setSelectedTaskList(taskListObject[id])}
                                          fetchProject={fetchProject} isTemplate={false} shortView={false}/>
                    </div>
                    }
                </div>
            case "Project Details":
                return <div><ProjectDetailsComponent fetchProject={fetchProject} totalPages={totalPages}
                                                     scopePages={scopePages}/></div>
            case "File Management":
                return <div>
                    <div>
                        <Button onClick={() => showModal(ShowUpload, {})} color="secondary" variant="outlined"
                            style={{width: "fit-content", alignSelf: "center"}}>
                            Upload File(s)
                        </Button>
                        <Button onClick={() => {refreshTable()}}
                            color="secondary" variant="outlined" style={{width: "fit-content", alignSelf: "center"}}
                            startIcon={<Refresh/>}>
                            Refresh
                        </Button>
                        <Button onClick={() => {goToDocs(getUrlsFromSelectedRows())}}
                            color="secondary" variant="outlined" style={{width: "fit-content", alignSelf: "center"}} disabled={noFilesSelected}>
                            Go to DOCS
                        </Button>
                        {auth.hasPMRoleAccess() && <Button onClick={() => downloadFiles()}
                                color="secondary" variant="outlined"
                                style={{width: "fit-content", alignSelf: "center"}}
                                startIcon={<DownloadIcon/>} disabled={noFilesSelected}>
                            Download
                        </Button>}
                        <Button onClick={() => handleDelete(getKeysFromSelectedRows())}
                            color="secondary" variant="outlined" style={{width: "fit-content", alignSelf: "center"}} disabled={noFilesSelected}
                            startIcon={<DeleteIcon/>}>
                            Archive
                        </Button>
                        <Button onClick={() => {showModal(() => LinkFilesToTaskModal(project, id, getIdsFromSelectedRows(), fetchProject), {})}
                        }
                                color="secondary" variant="outlined" style={{width: "fit-content", alignSelf: "center"}} disabled={noFilesSelected}
                        >
                            Link to a task
                        </Button>
                    </div>
                    <FilesComponent projectId={projectId} files={fileObjects} selectedRows={tableSelectedFiles} setNoFilesSelected={setNoFilesSelected} onDoubleClickRow={() => goToDocs(getUrlsFromSelectedRows())}/>
                </div>
        }};

    useEffect(() => {
        if (auth.hasPMRoleAccess()) {
            getFiles();
        }
    }, [])

    useEffect(() => {
        reset()
    }, [location.pathname])

        useEffect(() => {
            fetchProject();
        }, [id])

        useEffect(() => {
            dispatch(setShowToolbarAfterDeletion(false));
        }, [projectTabSelected])

        let menuItems = [
            <MenuItem
                key={0}
                onMouseEnter={onMouseEventHandler}
                onMouseLeave={onLeaveMouseEventHandler}
                onClick={() => {
                    setAnchorEl(null);
                    showModal(ProjectEditNameAndStudyIDWizard, {project, refreshList: fetchProject});
                }}>
                Edit project name and ID
            </MenuItem>,
            <MenuItem
                key={1}
                onMouseEnter={onMouseEventHandler}
                onMouseLeave={onLeaveMouseEventHandler}
                onClick={async () => {
                    setAnchorEl(null);
                    setShowShareProject(auth.hasPMRoleAccess());
                }}>
                Share project
            </MenuItem>,
            <MenuItem
                key={2}
                onMouseEnter={onMouseEventHandler}
                onMouseLeave={onLeaveMouseEventHandler}
                onClick={() => {
                    setAnchorEl(null);
                }}>
                Export report
            </MenuItem>,
            <ExportProjectComponent project={project} totalPages={totalPages} scopePages={scopePages} keyProp={0}
                onMouseEventHandler={onMouseEventHandler}
                onLeaveMouseEventHandler={onLeaveMouseEventHandler}
                setAnchorEl={setAnchorEl} />
        ]

        if ((auth.hasAdminRoleAccess() || auth.hasPMRoleAccess()) && !project.isArchived) {
            menuItems.push(
                <ArchiveProjectComponent selectedProjectIds={[project.id]} isMenuItem={true} />
            )
        }

        if ((auth.hasAdminRoleAccess() || auth.hasPMRoleAccess()) && project.isArchived) {
            menuItems.push(
                <RestoreProjectComponent selectedArchivedProjectIds={[project.id]} isMenuItem={true} />
            )

            menuItems.push(
                <RemoveProjectComponent selectedArchivedProjectIds={[project.id]} isMenuItem={true} />
            )
        }

        return project === undefined ? <></> :
            <div className="single-project-template-wrapper">
                <div className="single-project-template-box"
                     style={{
                         width: `calc(100vw - 256px - ${showShareProject ? '266px' : '64px'}`,
                         marginRight: showShareProject ? 14 : 32
                     }}>
                    <div className="single-project-template-header">
                        <div className="single-project-template-back">
                            <img alt="back" src={baseline_arrow_back} onClick={() => nav("/app/user/workflow/projects")}
                                 className="single-project-temaplate-back-button"/>
                            <span className="single-project-template-name">{project?.name}</span>
                            {project.isArchived &&
                                <span className="single-project-template-archived">
                                    {`ARCHIVED ${moment(project.archivedAt).format(dateFormat)}`}
                                </span>
                            }
                        </div>
                        <div style={{flexGrow: 1}}></div>
                        {auth.hasPMRoleAccess() && <div style={{marginTop: 3}}>
                            <MoreActionsComponent id={0} anchorEl={anchorEl} setAnchorEl={setAnchorEl}
                                                  menuHorizontalPosition="right" menuItems={menuItems}/>
                        </div>}
                    </div>
                    <div className="single-project-template-horizontal-line"/>
                    <div style={{display: "flex"}}>
                        <div style={{display: "flex"}}>
                            {auth.hasPMRoleAccess() ?
                                <StatusMenuComponent
                                    status={(project.projectStatusDates?.status ?? "NOT_STARTED") as StatusType}
                                    onUpdate={(status) => setStatus(status)} projectStatus={true}/>
                                :
                                <div style={{
                                    display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 24,
                                    letterSpacing: 0.1, color: "white", fontSize: 15, fontWeight: 600, borderRadius: 4,
                                    backgroundColor: statusList[(project.projectStatusDates?.status || "NOT_STARTED") as StatusType].color,
                                    width: 158, height: 40, whiteSpace: "nowrap"
                                }}>
                                    {statusList[(project.projectStatusDates?.status || "NOT_STARTED") as StatusType].name}
                                </div>
                            }
                            <div style={{marginLeft: 24, alignSelf: "flex-end"}}>
                                <CustomDatePicker value={project.projectStatusDates?.startDate ?
                                    new Date(project.projectStatusDates?.startDate) : null}
                                                  setValue={(date) => setDate(date, 'start')} name="START DATE"
                                                  readonly={!auth.hasPMRoleAccess()}
                                                  icon={<CalendarTodayIcon
                                                      style={{color: auth.hasPMRoleAccess() ? blueIconColor : "#E6E7E8"}}/>}/>
                            </div>
                            <div style={{marginLeft: 24, alignSelf: "center"}}>
                                <CustomDatePicker value={project.projectStatusDates?.dueDate ?
                                    new Date(project.projectStatusDates?.dueDate) : null}
                                                  setValue={(date) => setDate(date, 'due')} name="DUE DATE"
                                                  readonly={!auth.hasPMRoleAccess()}
                                                  icon={<EventAvailableIcon
                                                      style={{color: auth.hasPMRoleAccess() ? blueIconColor : "#E6E7E8"}}/>}/>
                            </div>
                        </div>
                        <div style={{flexGrow: 1}}></div>
                        <div style={{display: "flex", alignItems: "center", marginRight: 16}}>
                            <span>Shared with</span>
                            <span style={{marginLeft: 16}}>
                            {project.organizationShare ? "My organization" :
                                <UsersAvatarList users={project.users || []} shortView={true}/>}
                        </span>
                        </div>
                    </div>
                    <div className="single-project-template-horizontal-line" style={{marginTop: 14}}/>
                    <div className="task-details-comment-menu-wrapper" style={{marginBottom: 0}}>
                        <div className="task-details-comment-menu-component"
                             onClick={() => setProjectTabSelected("Task Listing")} style={{
                            color: projectTabSelected === "Task Listing" ? theme.palette.secondary.main : "#414042",
                            paddingLeft: 14,
                            paddingRight: 14,
                            borderBottom: projectTabSelected === "Task Listing" ? `2px solid ${theme.palette.secondary.main}` : ""
                        }}>Task Listing
                        </div>
                        <div className="task-details-comment-menu-component"
                             onClick={() => setProjectTabSelected("Project Details")} style={{
                            color: projectTabSelected === "Project Details" ? theme.palette.secondary.main : "#414042",
                            paddingLeft: 16, paddingRight: 16, borderBottom: projectTabSelected === "Project Details" ?
                                `2px solid ${theme.palette.secondary.main}` : ""
                        }}>Project Details
                        </div>
                        {auth.hasPMRoleAccess() ?
                            <div className="task-details-comment-menu-component"
                                 onClick={() => setProjectTabSelected("File Management")} style={{
                                color: projectTabSelected === "File Management" ? theme.palette.secondary.main : "#414042",
                                paddingLeft: 16,
                                paddingRight: 16,
                                borderBottom: projectTabSelected === "File Management" ?
                                    `2px solid ${theme.palette.secondary.main}` : ""
                            }}>File Management
                            </div> :
                            <div className="task-details-comment-disabled-menu-component"
                            >File Management</div>
                        }
                    </div>
                    {
                        renderSwitch(projectTabSelected)
                    }
                </div>
                {showShareProject &&
                <ShareProjectMenu key={projectId} showUserMenu={setShowShareProject} fetchProject={fetchProject}/>}
            </div>
    }
