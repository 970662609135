import { PatternSet } from "../models/PatternSet";
import {baseUrl, getHeadersWithoutJSON, headers} from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
import {Pattern} from "../models/Pattern";
import {BasePatternSet} from "../models/BasePatternSet";

export function getApiPatternSets(signal?: AbortSignal): Promise<PatternSet[]> {
    const url = new URL(`/api/pattern-sets`, baseUrl);
    return fetchAndParse<PatternSet[]>(url.toString(), { method: "GET", headers, signal });
}

export function getApiPatternsBySetId(patternSetId: number, signal?: AbortSignal): Promise<Pattern[]> {
    const url = new URL(`/api/pattern-sets/${patternSetId}/patterns`, baseUrl);
    return fetchAndParse<Pattern[]>(url.toString(), { method: "GET", headers, signal });
}

export function getApiDefaultPatterns( signal?: AbortSignal): Promise<Pattern[]> {
    const url = new URL(`/api/pattern-sets/1/patterns`, baseUrl);
    return fetchAndParse<Pattern[]>(url.toString(), { method: "GET", headers, signal });
}

export function putApiPatternSet(body?: PatternSet, signal?: AbortSignal) {
    const url = new URL(`/api/pattern-sets/${body?.id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}

export async function createApiNewVersionOfPatternSet(oldID: number, accountName: string|undefined, body: BasePatternSet, signal?: AbortSignal): Promise<PatternSet> {
    const result = await postApiPatternSetCreate(body, signal);

    const newPS: PatternSet = {
        id: result.id,
        name: result.name,
        version: result.version,
        lastModifier: result.lastModifier,
        lastModifiedDate: result.lastModifiedDate,
        creationDate: result.creationDate,
        projectID: result.projectID,
        patterns: []
    };

    const patterns = await getApiPatternsBySetId(oldID, signal);

    const promises = patterns.map(async (pattern) => {
        const r = await postApiPatternCopy(pattern.id, newPS.id, signal);
        newPS.patterns.push(r);
    });

    await Promise.all(promises);

    return newPS;
}

export async function createNewVersionOfPatternSet(mp: PatternSet, username: string, accountName: string|undefined): Promise<PatternSet> {
    const patternSets: PatternSet[] = await getApiPatternSets()
    const maxVersion: number = Math.max(...patternSets.filter(row => row.name === mp.name).map(row => row.version))
    return createApiNewVersionOfPatternSet(mp.id, accountName, {name: mp.name, version: maxVersion + 1, lastModifier: username, projectID: mp.projectID} as BasePatternSet)
}

export async function createCopyOfPatternSetForProject(ps: PatternSet, username: string, projectName: string, projectID: number, accountName: string|undefined): Promise<PatternSet> {
    return createApiNewVersionOfPatternSet(ps.id, accountName, {name: `${ps.name} (${projectName})`, version: 1, lastModifier: username, projectID: projectID})
}

export async function createCopyOfDefaultPatternSetForProject(username: string, projectName: string, projectID: number, accountName: string|undefined): Promise<PatternSet> {
    return createApiNewVersionOfPatternSet(1, accountName, {name: `Default Patterns (${projectName})`, version: 1, lastModifier: username, projectID: projectID})
}

export function deleteApiPatternSet(id: number, signal?: AbortSignal): Promise<void> {
    return getApiPatternsBySetId(id, signal)
        .then(patterns => {
            patterns.forEach(pattern => {
                deleteApiPatternById(pattern.id, signal)
            })
        })
        .then(r => {
            const url = new URL(`/api/pattern-sets/${id}`, baseUrl);
            return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal })
        })
}

export function postApiPatternSetCreate(body?: BasePatternSet, signal?: AbortSignal): Promise<PatternSet> {
    const url = new URL(`/api/pattern-sets/create`, baseUrl);
    return fetchAndParse<PatternSet>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}

export function putApiPatternDetailsById(id: number, body: FormData, signal?: AbortSignal) {
    const url = new URL(`/api/patterns/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: body, headers: getHeadersWithoutJSON(), signal });
}

export function putApiPatternTextFileById(id: number, body: Blob, signal?: AbortSignal) {
    const url = new URL(`/api/pattern_file/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: body, headers: getHeadersWithoutJSON(), signal });
}

export function postApiPatternCreate(body: FormData, signal?: AbortSignal): Promise<Pattern> {
    const url = new URL(`/api/patterns/create`, baseUrl);
    return fetchAndParse(url.toString(), { method: "POST", body: body, headers: getHeadersWithoutJSON(), signal })
}

export function postApiPatternCopy(sourcePatternId: number, newPatternSetId: number, signal?: AbortSignal): Promise<Pattern> {
    const url = new URL(`/api/patterns/${sourcePatternId}/copy/${newPatternSetId}`, baseUrl);
    return fetchAndParse(url.toString(), { method: "POST", headers, signal });
}

export function deleteApiPatternById(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/patterns/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
