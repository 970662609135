import baseline_close from "../../../provisional_icons/baseline-close.png";
import Button from "@mui/material/Button";
import { useCustomModal } from "../../modals/custom-message-modal";
import { deleteApiTasksBulkDelete } from "../../../services/task";
import { useState } from "react";
import { ButtonSpinner } from "../../../components/button-spinner";
import { useAppDispatch } from "../../../hooks/redux-hook";
import { showSnackbar } from "../../../redux/snackbar";

export function RemoveSubtaskListModal(p: { ids: number[] }) {
    const { hideModal } = useCustomModal();
    const [submitted, setSubmitted] = useState(false);
    const dispatch = useAppDispatch();

    const onSubmit = async () => {
        setSubmitted(true);
        const controller = new AbortController();
        deleteApiTasksBulkDelete(p.ids, controller.signal).then(() => {
            hideModal();
            setSubmitted(false);
        }).then(() => dispatch(showSnackbar({
            message: "Subtask list deleted successfully.",
            type: "info"
        }))).catch(() => dispatch(showSnackbar({
            message: "Error deleting subtask list!",
            type: "error"
        })));
    }

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img src={baseline_close} className="remove-entity-close-button" onClick={hideModal} />
            <span className="remove-entity-title" >
                Delete subtasks list?
            </span>
            <p className="remove-entity-disclaimer" >
                All the subtasks in this list will be deleted from the task.
            </p>
            <div className="remove-entity-button-list" style={{ marginTop: 20 }} >
                <Button color="secondary" variant="outlined" onClick={hideModal}>CANCEL</Button>
                <Button disabled={submitted} onClick={onSubmit} color="secondary" variant="contained">DELETE SUBTASKS LIST {submitted && <ButtonSpinner />}</Button>
            </div>
        </div>
    </div>
}
