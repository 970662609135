import {FileState} from "../../models/FileState";
import React, {useState} from "react";
import {useImportExcel} from "../../hooks/import-excel";
import {getExcelFileNoCache, getFileNoCache} from "../../components/file-management";
import {useCustomModal} from "./custom-message-modal";
import {Row} from "read-excel-file";
import {ANNOTATION_ID_COLUMN, REPLACEMENT_METHOD, REPLACEMENT_TEXT_COLUMN} from "../../constants";
import {LoadingSpinner} from "./load-from-risk-modal";


const TransformFromReportModal = (props: { projectFiles: FileState[], annotations: any[], onDone: () => void }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined);
    const { acceptedExcelExtensions, importExcel } = useImportExcel();
    const {hideModal} = useCustomModal();
    const abortController = new AbortController();


    const reports: FileState[] = props.projectFiles.filter((file) =>
        file.name.endsWith('.xlsx') || file.name.endsWith('.csv')
    );

    const handleCancel = () => {
        abortController.abort();
        setIsLoading(false);
        hideModal()
    };

    const handleDone = () => {
        abortController.abort();
        setIsLoading(false);
        props.onDone();
        hideModal()
    }

    const handleError = (message: string) => {
        setErrorMessage(message);
        setIsLoading(false);
    }

    const handleErrorReadingFile = () => {
        handleError("Could not parse this file. See logs for more details");
    }

    const onImportReport = async (rows: Row[]) => {
        if (rows.length < 3) {
            handleError("This report has less than 3 rows. We expect a report to have at least a header row, description row, and one annotation row.");
            return undefined;
        }
        const idToRowMap = new Map
        const header = rows[0];
        const idIDX = header.indexOf(ANNOTATION_ID_COLUMN);
        const replacementIDX = header.indexOf(REPLACEMENT_TEXT_COLUMN);
        const methodIDX = header.indexOf(REPLACEMENT_METHOD);
        if (idIDX === -1 || replacementIDX === -1 || methodIDX === -1) {
            handleError(`The header row is missing of of these required columns: ${ANNOTATION_ID_COLUMN}, ${REPLACEMENT_TEXT_COLUMN}, ${REPLACEMENT_METHOD}`);
            return undefined;
        }

        rows.forEach((row, index) => {
            const replacementText = row[replacementIDX] ?? "";
            idToRowMap.set(row[idIDX], {replacementText: replacementText, replacementMethod: row[methodIDX]})
        })

        //map annotations here based on ID.
        props.annotations.forEach(annotation => {
            const id = annotation.Id;
            if (id && idToRowMap.has(id)) {
                const replacementText = idToRowMap.get(id).replacementText;
                annotation.setCustomData(REPLACEMENT_TEXT_COLUMN, replacementText === '%REMOVE_MARK%' ? '%RETAIN%' : replacementText);
                annotation.setCustomData(REPLACEMENT_METHOD, idToRowMap.get(id).replacementMethod);
            }
        })
        console.log('loaded transforms from report');
        handleDone();
    }

    const onSelectReport = (selectedReportName: string) => {
        setErrorMessage(undefined);
        setIsLoading(true);
        //Open and parse report
        let reportToLoad: FileState | undefined = undefined;
        for (const report of reports) {
            if (selectedReportName === report.name) {
                reportToLoad = report;
                break;
            }
        }
        if (!reportToLoad || !reportToLoad.url) {
            handleError(`Unable to retrieve this report from the server: ${selectedReportName}`)
            return;
        }
        getExcelFileNoCache(reportToLoad.url).then((data) => {
            return new File([data], reportToLoad!.name)
        }).then((file) => {
            return importExcel(file, onImportReport, handleErrorReadingFile)
        }).catch((e) => {
            handleError("Error opening this report")
            console.log(e);
        })
    }

    return (
        <div className="modal">
            <h2>Load Transforms</h2>

            {
                isLoading? (<LoadingSpinner/>) : (
                    <div style={{textAlign: 'left', marginBottom: '15px'}}>
                        <label style={{display: 'inline-block'}}>Choose Transforms Report:</label>
                        <select
                            style={{display: 'inline-block', marginLeft: '5px'}}
                            onChange={(e) => {
                                onSelectReport(e.target.value);
                            }}
                        >
                            <option value="">Select a report</option>
                            {reports.map((report) => (
                                <option key={report.name} value={report.name}>
                                    {report.name}
                                </option>
                            ))}
                        </select>
                        {
                            errorMessage ? (<p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>) : (<></>)
                        }
                        <div className={"button-container"}>
                            <button className='change-status-btn' onClick={() => handleCancel()}>Cancel</button>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default TransformFromReportModal