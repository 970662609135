import React, {useState} from 'react';
import {useCustomModal} from "../../pages/modals/custom-message-modal";
import {Button, Tooltip} from "@mui/material";
import {getMaxPageRangeDistance} from "../../services/rules/defaultRules";
import {getColumnNameMapAsStrings} from "../../models/DeidData";
import "./TransformSettingsModal.scss"


const TransformSettingsModal = () => {
    const { hideModal } = useCustomModal();
    const [showMap, setShowMap] = useState(false)

    const getDefaultBoolean = (settingName: string) => {
        const setting = localStorage.getItem(settingName)
        return !!(setting && setting === 'true')
    }

    function handleFileInputChange(event: any) {
        const file = event.target.files[0];

        if (file && file.name.endsWith(".csv")) {
            const reader = new FileReader();

            reader.onload = function(event) {
                if (!event || !event.target) {
                    console.error('couldnt read csv file')
                }

                const csv = event!.target!.result;
                //@ts-ignore
                const jsonString = getJsonStringFromCsvString(csv);
                localStorage.setItem('columnNameMap', jsonString)
            };

            reader.readAsText(file);
        } else {
            console.error("Please select a CSV file.");
        }
    }

    function getJsonStringFromCsvString(csv: string): string {
        const lines = csv.split("\n");
        const result = [];

        for (let i = 1; i < lines.length; i++) {
            const trimmedLine = [];
            const currentLine = lines[i].split(",");
            if (currentLine.length < 2) {
                continue;
            }
            for (let j = 0; j < currentLine.length; j++) {
                trimmedLine[j] = currentLine[j].trim();
            }
            result.push(trimmedLine);
        }
        return JSON.stringify(result);
    }

    return (
        <div className={'transformSettingsModal'} style={{'padding': '5px'}}>
            <h1>Transform Settings</h1>
            <div style={{display: 'grid', gridTemplateColumns: 'auto auto'}}>
                <Tooltip title={'We associate each mark with the most recently mentioned patient ID. Use this to set how many pages we should go back to look for a patient ID.'} style={{marginBottom: 20}}>
                    <p>Association Page Distance</p>
                </Tooltip>

                <input width={"inherit"} defaultValue={getMaxPageRangeDistance()}
                       style={{backgroundColor: 'inherit', height: 30, marginTop: 5}} type={"number"}
                       onChange={(event => {
                           localStorage.setItem('associationPageDistance', event.target.value)
                       })}/>
                <Tooltip title={"This should be left off unless you find that the Risk file you're trying to load has open quotation marks in a text field. In that case, turn this on and also ask whoever wrote that data to stop leaving open quotation marks."}>
                    <p style={{marginBottom: 20}}>Ignore quotation marks in RLS Protect Risk CSV files</p>
                </Tooltip>
                <div className={'checkbox-container'}>
                    <input width={"inherit"} defaultChecked={getDefaultBoolean('ignoreQuotationsRisk')}
                           style={{backgroundColor: 'inherit', height: 30, marginTop: 5}} type={"checkbox"}
                           onChange={(event => {
                               localStorage.setItem('ignoreQuotationsRisk', event.target.checked ? 'true' : 'false');
                           })}/>
                </div>
                {/*<Tooltip title={"Advanced logic for associating marks in tables with any IDs on the same row or column"}>*/}
                {/*    <p style={{marginBottom: 20}}>Associate Closest ID in Tables</p>*/}
                {/*</Tooltip>*/}
                {/*<div className={'checkbox-container'}>*/}
                {/*    <input width={"inherit"} defaultChecked={getDefaultBoolean('associateClosestID')}*/}
                {/*           style={{backgroundColor: 'inherit', height: 30, marginTop: 5}} type={"checkbox"}*/}
                {/*           onChange={(event => {*/}
                {/*               localStorage.setItem('associateClosestID', event.target.checked ? 'true' : 'false');*/}
                {/*           })}/>*/}
                {/*</div>*/}
                <Tooltip title={"This is how the system tries to map between mark types and Risk column names. There are some default options but you can also upload a CSV file with custom options. The CSV file should have two items per row in the form mark_type,risk_column_name"}>
                    <p style={{marginBottom: 20}}>Column Name Map</p>
                </Tooltip>
                <input width={"inherit"} defaultValue={""}
                       style={{backgroundColor: 'inherit', height: 30, marginTop: 5, color: "transparent"}} type={"file"} accept={".csv"}
                       onChange={(event => {
                           handleFileInputChange(event)
                       })}/>
            </div>
            <Button variant="contained" color="secondary" className={'transform-button'} onClick={() => setShowMap(currentState => !currentState)}>
                Show/Hide Column Name Map
            </Button>

            {showMap && getColumnNameMapAsStrings().map(s =>
                <p>{s}</p>
            )}

            <div className={'container'}>
                <Button variant="contained" color="secondary" className={'transform-button'} onClick={() => hideModal()}>
                    OK
                </Button>
            </div>
        </div>
    );
};

export default TransformSettingsModal;
