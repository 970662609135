import React from "react";
import "./PageRangeInput.scss"
import {Icon} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

//A box where the user can type in a page or a page range. This is used in the filters and also in the redaction search tab.
export const PageRangeInput = (props: {clearPageRangeCallBack: () => void, pageRangeChangeCallBack: (range: undefined | (number |undefined)[]) => void , placeholder: string, clickPageRangeCallback?: undefined | (() => void)}) => {

    const [pageRange, setPageRange] = React.useState<undefined | (number |undefined)[]>(undefined);
    const [pageRangeText, setPageRangeText] = React.useState("");

    function clearPageRange() {
        setPageRange(undefined)
        setPageRangeText("")
        if (props.clearPageRangeCallBack) {
            props.clearPageRangeCallBack()
        }
    }

    function pageRangeOnChange(event: any) {
        setPageRangeText(event.target.value)
        const newPageRange = parsePageRange(event.target.value)
        setPageRange(newPageRange);
        //Deleting all the text in the field should act the same as clicking the clear button (i.e. it triggers a full search)
        if (event.target.value === '') {
            if (props.clickPageRangeCallback) {
                props.clearPageRangeCallBack()
            }
        } else {
            props.pageRangeChangeCallBack(newPageRange)
        }
    }

    const boxOutlineColor = pageRangeText !== "" && pageRange === undefined? 'red' : 'lightgrey';

    //Highlight the box with red if the user types in an invalid page range.
    const inputStyles = {
        "outlineColor": boxOutlineColor,
        border: `1px solid ${boxOutlineColor}`
    };
    return (<div className="input-container">
        <input
            type="text"
            style={inputStyles}
            autoComplete="off"
            onChange={pageRangeOnChange}
            onClick={props.clickPageRangeCallback}
            placeholder={props.placeholder}
            aria-label = {props.placeholder}
        id = "PageRange_input"
        value = {pageRangeText}
        />
        {
            pageRangeText.length > 0 && (
                <button
                    className="clearPageRange-button"
                    onClick={clearPageRange}
                    aria-label={props.placeholder}
                >
                    <CloseIcon/>
                </button>
            )
        }
    </div>)
}

const PAGE_RANGE_REGEX = /^\d+-\d+$/
//User can type in "95-" to search from page 95 until the end of the document.
const AFTER_PAGE_REGEX = /^\d+-$/
//User can type in "-5" to search from the start of the document up to and including page 5.
const BEFORE_PAGE_REGEX = /^-\d+$/
const SINGLE_PAGE_REGEX = /^\d+$/
const PAGE_RANGE_SEPARATOR = "-";

const parsePageRange = (pageRangeText: string): undefined | (number |undefined)[] => {
    if (PAGE_RANGE_REGEX.test(pageRangeText)) {
        const stringNums = pageRangeText.split(PAGE_RANGE_SEPARATOR);
        return [parseInt(stringNums[0]) , parseInt(stringNums[1])];
    } else if (AFTER_PAGE_REGEX.test(pageRangeText)) {
        const stringNum = pageRangeText.substring(0, pageRangeText.length - 1);
        return [parseInt(stringNum), undefined];
    } else if (BEFORE_PAGE_REGEX.test(pageRangeText)) {
        const stringNum = pageRangeText.substring(1)
        return [1, parseInt(stringNum) ];
    } else if (SINGLE_PAGE_REGEX.test(pageRangeText)) {
        return [parseInt(pageRangeText), parseInt(pageRangeText)];
    } else {
        return undefined;
    }
};

export default PageRangeInput;
