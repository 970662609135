import { StyledCloseIcon } from "../../../components/close-button";
import "./change-name-user-modal.scss";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useCustomModal } from "../custom-message-modal";
import { useState } from "react";
import { useAuthService } from "../../../contexts/auth-context";

export function ChangeNameUserModal(props: { updateName: (name: string) => void }) {

    const auth = useAuthService();
    const { hideModal } = useCustomModal();
    const [inputName, setInputName] = useState(auth.loginInfo?.tenant?.user?.name || auth.loginInfo?.user?.name);

    const saveName = () => {
        if (inputName === undefined || inputName === "") {
            return;
        }
        props.updateName(inputName);
        hideModal();
    }

    return <div className="change-name-wrapper">
        <StyledCloseIcon onClick={hideModal} />
        <span className="change-name-modal-title">Change your Name</span>
        <div className="full-name-label">Enter your Full Name</div>
        <TextField fullWidth placeholder="Full Name" autoComplete="off"
                   value={inputName} onChange={ev => setInputName(ev.target.value)}
        />
        <div className="change-name-buttons-wrapper">
            <Button color="secondary" variant="outlined" onClick={hideModal} style={{ width: 89, height: 39 }}>
                CANCEL
            </Button>
            <Button color="secondary" variant="contained" onClick={saveName} style={{ width: 102, height: 39 }}>
                SAVE
            </Button>
        </div>
    </div>
}
