import React, {useEffect, useRef, useState} from 'react';
import {useCustomModal} from "./custom-message-modal";
import ModalIconContent from "./modal-content/modal-icon-content";

//This asks the user to choose a match category for the mark they just added.
const UnsavedWarningModal = (props : { cancelPropagation: () => void,discardChanges: ()=>void,continueChanges:()=>void }) => {
    const { hideModal } = useCustomModal();
    const modalRef = useRef<HTMLDivElement>(null);
    const onSubmit = () => {
        props.cancelPropagation()
        hideModal()
    }


    return <div ref={modalRef} style={{position: "relative",
        width: 350, display: "flex", alignItems: 'center',
        flexDirection: "column", justifyContent: "center", padding: "20px 30px"
    }}>
        <h4 style={{fontWeight:"bold"}}>Warning</h4>
        <ModalIconContent/>
        <p>You have some unsaved changes in this document.</p>
        <div style={{marginTop:"10px"}}>
            <button onClick={()=>{hideModal();props.continueChanges()}} className={"sanitize-first-modal"} style={{margin:"0px 10px", backgroundColor: '#e60024'}}>Cancel</button>
            <button onClick={() => {hideModal();props.discardChanges()}} className={"sanitize-first-modal"} style={{backgroundColor:"#e86122"}}>Discard Changes</button>
            <button onClick={()=>{hideModal();props.cancelPropagation()}} className={"sanitize-first-modal"} style={{margin:"0px 10px"}}>Save Changes</button>
        </div>

    </div>


};

export default UnsavedWarningModal;
