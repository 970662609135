import React, { useCallback, useContext } from 'react';
import RedactionSearchResult from './RedactionSearchResult';
import WebViewerContext from "../../../contexts/webviewer-context";

const RedactionSearchResultContainer = (props) => {
  const {
    searchResult,
    checked,
    checkResult,
  } = props;

  const { ambientStr, resultStrStart, resultStrEnd, resultStr, icon, index, type } = searchResult;
  const { instance } = useContext(WebViewerContext);

  const onChange = useCallback((event) => {
    checkResult(event, index);
  }, [index, checkResult]);

  const onClickResult = useCallback(() => {
    instance.Core.documentViewer.setActiveSearchResult(searchResult);
  }, [searchResult]);

  return (
    <RedactionSearchResult
      ambientStr={ambientStr}
      resultStrStart={resultStrStart}
      resultStrEnd={resultStrEnd}
      resultStr={resultStr}
      icon={icon}
      type={type}
      isChecked={checked ?? false}
      onChange={onChange}
      onClickResult={onClickResult}
      isActive={false}
      index={index}
    />
  );
};

export default RedactionSearchResultContainer;
