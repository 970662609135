import React, { useState } from "react";
import './navBar.scss'
import logo from '../provisional_icons/Logo-RLS.png';
import { useAuthService } from '../contexts/auth-context';
import { useNavigate } from "react-router-dom";
import { Avatar, Badge, IconButton, LinearProgress, Menu, MenuItem } from "@mui/material";
import { ArrowDropDown, Notifications, Settings } from "@mui/icons-material";
import { getUserInitials } from "../services/utils";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hook";
import { resetSettings, userSettingsSelector } from "../redux/userSettings";
import { progressLineSelector } from "../redux/progress-line";
import { loadProjects, setProjectsLoaded } from "../redux/projects";
import { loadTemplates } from "../redux/projectTemplates";
import { loadUsers } from "../redux/users";
import { resetCustomFieldValues } from "../redux/custom-fields";
import { newNotificationsCounterSelector } from "../redux/notifications";
import { useEventHandler } from "../hooks/handle-events";
import { resetAccountList } from "../redux/account-list";
import { postApiUsersAccountLogin } from "../services/user";
import { headers } from "../services/config";
import { Account } from "../models/Account";
import {loadPatternSets, setPatternSetsLoaded} from "../redux/patternSets";

export function NavBar() {

  const auth = useAuthService();
  const dispatch = useAppDispatch();
  const user = auth.loginInfo?.user;
  const userSettings = useAppSelector((state) => userSettingsSelector(state));
  const showProgressLine = useAppSelector((state) => progressLineSelector(state));
  const notificationsCount = useAppSelector((state) => newNotificationsCounterSelector(state))
  const accounts: Account[] = JSON.parse(localStorage.getItem("accountList")!) ?? [];
  const accountList = accounts.filter(a => a.schema !== headers["X-Tenant"]);

  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElSwitch, setAnchorElSwitch] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isSwitchMenuOpen = Boolean(anchorElSwitch);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleSwitchMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorElSwitch(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);
  const handleSwitchMenuClose = () => setAnchorElSwitch(null);
  const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();

  const logout = () => {
    clearReduxStore();
    auth.logout();
  }

  const clearReduxStore = () => {
    dispatch(loadProjects([]));
    dispatch(setProjectsLoaded(false));
    dispatch(loadTemplates([]));
    dispatch(resetSettings());
    dispatch(loadUsers([]));
    dispatch(resetCustomFieldValues());
    dispatch(resetAccountList());
    dispatch(loadPatternSets([]))
    dispatch(setPatternSetsLoaded(false))
  }

  return <div className="banner">
    <div className='inner-container'>
      <div className={auth.loginInfo ? "logged-in-company" : 'company'}>
        <img style={{ cursor: 'pointer' }} src={logo} alt='logo'
          onClick={() => navigate('/app/user/landing-page')} />
      </div>
    </div>
    <div className="icons-container">
      {(auth.loginInfo?.user?.role === "SUPER" || auth.loginInfo?.user?.role === "AM") && auth.loginInfo.tenant?.schema &&
        <>
          <IconButton
            size="small"
            aria-label="switch account"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            disabled = {accounts.length < 2}
            onClick={handleSwitchMenuOpen}
          >
            <div style={{ width: 228, backgroundColor: isSwitchMenuOpen ? "white" : "#223250", borderRadius: 4 }}>
              <div style={{ display: "flex", margin: "9px 16px", alignItems: "center", justifyContent: "space-between" }}>
                <span style={{ fontSize: 17, letterSpacing: 0.15, color: isSwitchMenuOpen ? "#223250" : "white" }}>
                  {accounts.filter(a => !accountList.includes(a)).pop()?.name}
                </span>
                {accounts.length > 2 && <ArrowDropDown style={{ color: "#217DA2", marginLeft: 28 }} />}
              </div>
            </div>
          </IconButton>
          <Menu
            anchorEl={anchorElSwitch}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            id="switchMenuId"
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={isSwitchMenuOpen}
            onClose={handleSwitchMenuClose}
            PaperProps={{ style: { minWidth: 228 } }}
          >
            <span style={{ fontSize: 11, letterSpacing: 1.5, marginLeft: 16 }}>SWITCH ACCOUNT</span>
            {auth.loginInfo.tenant?.user && accountList.map(al => <MenuItem value={al.id} key={al.id}
              onMouseEnter={onMouseEventHandler} onMouseLeave={onLeaveMouseEventHandler} onClick={() => {
                handleSwitchMenuClose();
                headers["X-Tenant"] = "";
                postApiUsersAccountLogin({ accountId: al.id }).then((tenant) => {
                  headers['X-Tenant'] = tenant.schema!;
                  localStorage.setItem("impersonate", "true");
                  auth.setLoginInfo({ ...auth.loginInfo, tenant });
                  auth.setUserPerspective();
                  window.location.reload();
                });
              }}>
              {al.name}
            </MenuItem>)
            }
          </Menu>
        </>}
      {(user?.role === "ADMIN" || auth.loginInfo?.tenant?.user?.roles?.includes("ADMIN")) &&
        <IconButton
          size="large"
          aria-label="accounts"
          color="inherit"
          onClick={() => navigate(`/app/user/admin?id=${auth.loginInfo?.tenant?.clientId}`)}
        >
          <Settings />
        </IconButton>
      }
      {auth.hasUserRoleAccess() &&
        <IconButton
          size="large"
          aria-label="show new notifications"
          color="inherit"
          onClick={() => navigate(`/app/user/notifications`)}
        >
          <Badge badgeContent={notificationsCount}
            color="error"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            <Notifications />
          </Badge>
        </IconButton>
      }
      {auth.loginInfo?.user?.email && <>
       <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleMenuOpen}
      >
        <Avatar sx={{ bgcolor: userSettings.color, width: 24, height: 24, fontSize: 14 }}>
          {getUserInitials(auth.loginInfo?.tenant?.user?.name || user?.name)}
        </Avatar>
        <ArrowDropDown />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id="accountMenuId"
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {auth.loginInfo?.tenant?.user &&
          <MenuItem onClick={() => {
            navigate("/app/user/my-account");
            handleMenuClose();
          }}>
            My Account
          </MenuItem>
        }
        <MenuItem onClick={logout}>
          Logout
        </MenuItem>
      </Menu>
      </>}
    </div>
    {showProgressLine && <LinearProgress className="linear-progress" />}
  </div>
}
