//Example Text
export const exampleText = "Patient ID: 123"

//Options for the dropdowns
export const exactPhrase: string = "exact phrase";
export const partialMatch: string = "partial match";
export const numbersOrLetters: string = "numbers or letters";
export const ellipses: string = "...";
export const numbers: string = "numbers";
export const letters: string = "letters";
export const anyLength: string = "any length";
export const exactly: string = "exactly";
export const orFewer: string = "or fewer";
export const orMore: string = "or more";
export const punctuation: string = "punctuation";
export const startsWith: string = "starts with";
export const followedBy: string = "followed by";
export const endsWith: string = "ends with";
export const anyCharType: string = "any";
export const includeInMatch: string = "in match";
export const before : string = "before"
export const notBefore : string = "NOT before"
export const after : string = "after"
export const notAfter : string = "NOT after"
export const nothing: string = "nothing";

//Patterns
export const digitPattern: RegExp = /\d/;
export const letterPattern: RegExp = /[a-zA-Z]/;
export const digitOrLetterPattern: RegExp = /\w/;
export const punctuationPattern: RegExp = /\W/;

//These patterns are used for writing systems other than English, 
//including variants on the latin alphabet like in German but also different writing systems like Japanese.

//Looks for a character that is not a digit, latin letter, or punctuation mark. For now, we'll assume anything that matches is an extended letter.
//TODO it would be better to use \p{L} or [:punct:] here but those throw an error when used in the webviewer. I asked Apryse why that is and what kinds
//of regular expressions are supported. Hopefully once they get back to me I can change this to something nicer looking.
export const extendedLetterFinderPattern: RegExp = /[^\w\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/
export const extendedDigitPattern: RegExp = /[\d０-９]/;
export const strictPunctuationPattern: RegExp = /[\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/;
export const extendedLetterPattern: RegExp = /[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/;
export const extendedDigitOrLetterPattern: RegExp = /[^\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/;


//Functions
export const isLookbehind = (text: string) => {
    return text === before || text === notBefore
}

export const isLookahead = (text: string) => {
    return text === after || text === notAfter
}
