import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import { WizardData } from "../../../models/WizardData";
import { ChildContext } from "../../../components/wizard";
import ErrorIcon from '@mui/icons-material/Error';

export function ProjectTemplateName(props: {
    projectTemplate: WizardData, setProjectTemplate: (p: WizardData) => void,
    error: { empty: boolean, alreadyExists: boolean }
}) {

    const setValue = React.useContext(ChildContext);

    useEffect(() => {
        setValue({ title: "Create Project Template", canLeaveStep: () => true });
    }, [])

    return <div className="wizard-content">
        <br />
        <label className="wizard-content-label ">Enter a Project Template Name</label>
        <TextField
            value={props.projectTemplate.name}
            error={props.error.empty || props.error.alreadyExists}
            helperText={(props.error.empty || props.error.alreadyExists) && <span style={{ marginLeft: -14 }}>
                <ErrorIcon sx={{ width: 12, height: 12 }} />
                <span style={{ marginLeft: 5, verticalAlign: "text-bottom" }}>{props.error.empty ?
                    "Template name is required." : "Template name already exists."}</span>
            </span>}
            onChange={e => props.setProjectTemplate({ ...props.projectTemplate, name: e.currentTarget.value })}
            placeholder='Template Name'
        />
    </div>
}
