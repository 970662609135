import SearchStatus from "./SearchPanel/SearchStatus";
import {redactionTypeMap} from "../../constants";

const onResultThrottleTimeout = 100;
let isStillProcessingResults = false;

export const executeRedactionSearch = (searchTerms: any[], settings: any, documentViewer: any, searchModes: any, filterFunction: (searchResult: any) => Promise<boolean>, setSearchStatus: (s: string) => void) => {

    const options = {
        regex: true,
        startPage: settings.startPage,
        endPage: settings.endPage
    };

    // @ts-ignore
    const { textSearch, patternSearch } = searchTerms;
    let searchArray = [...textSearch];
    patternSearch.forEach((ps: any) => {
        searchArray.push(ps.regex.source)
    })

    const searchString = searchArray.join('|');

    // If search string is empty we return and clear searches, or we send the search logic
    // into an infinite loop
    if (searchString === '') {
        documentViewer.clearSearchResults();
        return;
    }

    setSearchStatus(SearchStatus.SEARCH_IN_PROGRESS);
    documentViewer.trigger('processStarted', 'search')
    searchTextFull(searchString, options, documentViewer, searchModes, filterFunction, setSearchStatus, patternSearch)
}

function buildSearchModeFlag(options: any = {}, SearchModes: any) {
    let searchMode = SearchModes.PAGE_STOP | SearchModes.HIGHLIGHT;

    if (options.caseSensitive) {
        searchMode |= SearchModes.CASE_SENSITIVE;
    }
    if (options.wholeWord) {
        searchMode |= SearchModes.WHOLE_WORD;
    }
    if (options.wildcard) {
        searchMode |= SearchModes.WILD_CARD;
    }
    if (options.regex) {
        searchMode |= SearchModes.REGEX;
    }

    searchMode |= SearchModes.AMBIENT_STRING;

    return searchMode;
}

export const searchTextFull = (searchValue: string, options: any, documentViewer: any, searchModes: any,
                               filterFunction: (searchResult: any) => Promise<boolean>, setSearchStatus: (s: string) => void,
                               patternsInUse: { label: string, type: string, regex: RegExp }[]) => {
    //This is what is going on in the webviewer code
    //dispatch(actions.searchTextFull(searchValue, options));

    const searchMode = buildSearchModeFlag(options, searchModes);
    let doneCallback = () => { };

    let hasActiveResultBeenSet = false;
    let throttleResults: any[] = [];
    let resultTimeout: NodeJS.Timeout | null;

    async function onResult(result: any) {
        const shouldFilter = filterFunction && await filterFunction(result)
        if (shouldFilter) {
            console.log('Removing search result')
            return
        } else {
            mapResultToType(result, patternsInUse)
            throttleResults.push(result);
        }

        if (!resultTimeout) {
            if (!isStillProcessingResults) {
                isStillProcessingResults = true;
            }

            resultTimeout = setTimeout(() => {
                documentViewer.displayAdditionalSearchResults(throttleResults);
                throttleResults = [];
                resultTimeout = null;
                doneCallback();
            }, onResultThrottleTimeout);
        }

        if (!hasActiveResultBeenSet) {
            // when full search is done, we make first found result to be the active result
            documentViewer.setActiveSearchResult(result);
            hasActiveResultBeenSet = true;
        }
    }

    function searchInProgressCallback(isSearching: boolean) {
        // execute search listeners when search is complete, thus hooking functionality search in progress event.
        if (isSearching === false) {
            doneCallback = () => {
                const results = documentViewer.getPageSearchResults();
                const searchOptions = {
                    // default values
                    caseSensitive: false,
                    wholeWord: false,
                    wildcard: false,
                    regex: false,
                    searchUp: false,
                    ambientString: true,
                    // override values with those user gave
                    ...options,
                };
                // const nextResultIndex = store?.getState().search?.nextResultIndex;
                //
                // const result = results[nextResultIndex];
                // if (result) {
                //     core.setActiveSearchResult(result);
                // }
                // const searchListeners = getSearchListeners() || [];
                // searchListeners.forEach((listener) => {
                //     try {
                //         listener(searchValue, searchOptions, results);
                //     } catch (e) {
                //         console.error(e);
                //     }
                // });
                documentViewer.trigger('processEnded', 'search')
            };
            isStillProcessingResults = false;

            if (!resultTimeout) {
                doneCallback();
            }
            documentViewer.removeEventListener('searchInProgress', searchInProgressCallback);
        }
    }

    function onDocumentEnd() {
        setSearchStatus(SearchStatus.SEARCH_DONE);
    }

    const numPagesToSearch: number = (options.startPage && options.endPage) ? options.endPage - options.startPage + 1 : documentViewer.getPageCount();
    let pagesSearched: number = 0;

    function onPageEnd() {
        pagesSearched++;
        window.dispatchEvent(new CustomEvent('pageSearched', {detail: {pagesSearched, numPagesToSearch}}))
    }

    function handleSearchError(error: any) {
        //dispatch(actions.setProcessingSearchResults(false));
        console.error(error);
    }
    const textSearchInitOptions: any = {
        'fullSearch': true,
        onResult,
        onDocumentEnd,
        onPageEnd,
        'onError': handleSearchError,
    };

    if (options.startPage) {
        textSearchInitOptions.startPage = options.startPage;
    }
    if (options.endPage) {
        textSearchInitOptions.endPage = options.endPage;
    }

    documentViewer.clearSearchResults();
    documentViewer.textSearchInit(searchValue, searchMode, textSearchInitOptions);
    documentViewer.addEventListener('searchInProgress', searchInProgressCallback);
}

const mapResultToType = (result: any, patternsInUse: { label: string, type: string, regex: RegExp }[]) => {
    if (!patternsInUse) {
        result.type = redactionTypeMap['TEXT'];
        return result;
    }

    if (patternsInUse.length === 1) {
        result.type = patternsInUse[0].type
    } else {
        // Iterate through the patterns and return the first match
        let resultType = undefined
        for (let pattern of patternsInUse) {
            if (pattern.type === 'text') {
                continue;
            }
            if (patternMatchesResult(result, pattern.regex)) {
                resultType = pattern.type
                break;
            }
        }

        // If it didn't match any of the patterns, return the default type which is text
        result.type = resultType === undefined ? redactionTypeMap['TEXT'] : resultType;
    }
    // And also set the icon to display in the panel. If no icon provided use the text icon
    // const { icon = 'icon-form-field-text' } = searchPatterns[result.type] || {};
    // result.icon = icon;
    return result;
}

//This runs the pattern against the ambient string of the search result (containing the match with surrounding words).
//That's so that the pattern can properly use lookbehinds or lookaheads. However, it does require the actual match to
//be in the resultStr, to make sure we're not matching something before or after the result.
function patternMatchesResult(searchResult: any, pattern: RegExp) {
    //First check if the pattern matches the result string, if so we don't need to test further.
    if (pattern.test(searchResult.resultStr)) {
        return true;
    }

    //TODO the ambient string may not be long enough. We can expand it using the same logic as in smart-filter-manager.
    const result = pattern.exec(searchResult.ambientStr);
    if (result !== null && result.index >= searchResult.resultStrStart && result.index <= searchResult.resultStrEnd) {
        return true
    }
    return false
}