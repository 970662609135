import "./master-patterns-library.scss";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks/redux-hook";
import patternSets, {loadPatternSets, PatternSetsSelector} from "../../redux/patternSets";
import React, {useState} from "react";
import {PatternSet} from "../../models/PatternSet";
import {convertDateTimeFromUTCToTimeZoneIncludeTime} from "../../services/utils";
import {userSettingsSelector} from "../../redux/userSettings";
import {Delete, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {Pattern} from "../../models/Pattern";
import {getApiPatternSets,} from "../../services/patternSets";
import {hideProgressLine, showProgressLine} from "../../redux/progress-line";
import {showSnackbar} from "../../redux/snackbar";
import {useCustomModal} from "../modals/custom-message-modal";
import {PatternSetDelete} from "./wizards/pattern-set-delete";
import {AddPatternsToProjectModal} from "../modals/modal-content/add-patterns-to-project-modal";
import {useAuthService} from "../../contexts/auth-context";
import {newPatternName} from "./single-pattern-set";



export function MasterPatternsLibrary() {
    const userSettings = useAppSelector((state) => userSettingsSelector(state));
    const dispatch = useAppDispatch();
    const auth = useAuthService();
    let patternSets : PatternSet[] = useAppSelector((state) => PatternSetsSelector(state));
    let masterPatternSets = patternSets.filter(patternSet => patternSet.projectID===null)

    //For sorting the table
    const [sortColumn, setSortColumn] = useState<number>(0);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const columns = [
        { label: 'Pattern Set', key: 'name' },
        { label: 'Version', key: 'version' },
        { label: 'Created', key: 'creationDate' },
        { label: 'Last Modified', key: 'lastModifiedDate' },
        { label: 'Modified By', key: 'lastModifier' },
    ];

    const sortedRows = sortRows(masterPatternSets, sortColumn, sortDirection, columns) as PatternSet[];

    const handleSort = (columnIndex: number) => {
        if (sortColumn === columnIndex) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(columnIndex);
            setSortDirection('asc');
        }
    };

    //Modal to confirm if the user wants to delete a pattern set
    const { showModal } = useCustomModal();
    const removeRow = (id: number, version: number, name: string) => {
        showModal(PatternSetDelete, {id: id, name: name, version: version})
            .then(r => {
                dispatch(showProgressLine());
                getApiPatternSets()
                    .then((patternSets) => {
                        dispatch(loadPatternSets(patternSets));
                        dispatch(hideProgressLine())
                    })
                    .catch(() => {
                        dispatch(hideProgressLine());
                        dispatch(showSnackbar({ message: "Error loading pattern sets!", type: "error" }));
                    })
            })
    }

    const addPatternsToProject = () => {
        const patternSetOptions = sortedRows.filter(patternSet => patternSet.projectID === null)
        return showModal(AddPatternsToProjectModal, {patternSets: patternSetOptions})
    }

    if (!auth.hasPMRoleAccess()) return <p>Your user account does not have permission to access this path. Please contact your System Administrator or RLS Customer Support for assistance.</p>

    return <div style={{ flexGrow: 1 }}>
        <div className="page-wrapper">
            <h1>Master Patterns Library</h1>
            <p><Button variant="contained" color="secondary" style={{width: 300}} onClick={addPatternsToProject}>Add Patterns to Project</Button></p>
            <table>
                <HeaderRowWithSortButtons columns={columns} sortColumn={sortColumn} sortDirection={sortDirection} handleSort={handleSort} />
                <tbody>
                    {sortedRows.map((mp, index) =>
                        <PatternSetRow id={mp.id} patternName={mp.name} version={mp.version} key={index} removeRow={removeRow}
                                    creationDate={convertDateTimeFromUTCToTimeZoneIncludeTime(mp.creationDate, userSettings.regional.timezone,
                                        userSettings.regional.dateFormat, userSettings.regional.timeFormat)}
                                    lastModifiedDate={convertDateTimeFromUTCToTimeZoneIncludeTime(mp.lastModifiedDate, userSettings.regional.timezone,
                                        userSettings.regional.dateFormat, userSettings.regional.timeFormat)}
                                    lastModifier={mp.lastModifier} />)}
                </tbody>
            </table>
        </div >
    </div >
}

interface HeaderRowWithSortButtonsProps {
    columns: {label: string, key: string}[],
    sortColumn: number,
    sortDirection: 'asc' | 'desc',
    handleSort: (columnIndex: number) => void
}

export function HeaderRowWithSortButtons({columns, sortColumn, sortDirection, handleSort} : HeaderRowWithSortButtonsProps) {
    return <thead>
    <tr>
        {columns.map((column, index) => (
            <th key={index}>
                {column.label}
                <div className="filter-button" onClick={() => handleSort(index)} style={sortColumn === index? {color: "white"} : {color: "grey"}}>
                    {sortDirection === 'asc' && sortColumn === index ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                </div>
            </th>
        ))}
    </tr>
    </thead>
}

interface PatternSetRowProps {
    id: number;
    patternName: string;
    version: number;
    creationDate: string,
    lastModifiedDate: string,
    lastModifier: string
    removeRow: (id: number, version: number, name: string) => void
}

function PatternSetRow({id, patternName, version, creationDate, lastModifiedDate, lastModifier, removeRow} : PatternSetRowProps) {
    let navigate = useNavigate();
    return <tr>
        <td style={{cursor: 'pointer' }} onClick={() => navigate(`/app/user/workflow/master-patterns-library/${id}`)}>
            <span style={{color: "blue"}}><u>{patternName}</u></span>
        </td>
        <td>
            {version}
            {version !== 1? <Delete className="delete-icon" style={{float: 'right'}} onClick={() => removeRow(id, version, patternName)}/> : <div/>}
        </td>
        <td>{creationDate}</td>
        <td>{lastModifiedDate}</td>
        <td>{lastModifier}</td>
    </tr>
}

export function sortRows(rows : (PatternSet | Pattern)[], sortColumn: number, sortDirection: 'asc' | 'desc', columns: {label: string, key: string}[]): (PatternSet | Pattern)[]  {
    return [...rows].sort((a, b) => {
        //"New Pattern" should always be at the top of the table
        if (a.name.startsWith(newPatternName) && b.name.startsWith(newPatternName)) {
            return a.name < b.name ? 1 : -1;
        }
        if (a.name.startsWith(newPatternName)) {
            return -1;
        }
        if (b.name.startsWith(newPatternName)) {
            return 1;
        }

        if (sortColumn === null) {
            return 0;
        }
        const column = columns[sortColumn].key;
        let valueA: string|number|Pattern[] = a[column];
        let valueB: string|number|Pattern[] = b[column];
        //Sort by version if this column is the same.
        if (valueA === valueB) {
            try {
                valueA = a['version']
                valueB = b['version']
            } catch (e) {
                console.log(e)
            }
        }

        //Parse the strings as dates when appropriate so that it sorts them chronologically.
        if (column.toLowerCase().includes('date')) {
            try {
                valueA = new Date(valueA.toString()).getTime();
                valueB = new Date(valueB.toString()).getTime();
            } catch (e) {
                console.log(e);
            }
        }

        if (typeof valueA === 'string' && typeof valueB === 'string') {
            valueA = valueA.toLowerCase();
            valueB = valueB.toLowerCase();
        }

        if (sortDirection === 'asc') {
            return valueA > valueB ? 1 : -1;
        } else {
            return valueA < valueB ? 1 : -1;
        }
    });
}
