import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useCustomModal } from "../custom-message-modal";
import './remove-entity-modal.scss';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux-hook";
import { showSnackbar } from "../../../redux/snackbar";
import { postApiTasksLinkFilesToTask} from "../../../services/task";
import {StyledCloseIconUploader} from "../../../components/close-button";
import {DataGrid, GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {FileState} from "../../../models/FileState";
import {filesSelector, updateFile} from "../../../redux/file-management";
import {MenuItem, Select} from "@mui/material";
import {fileCategories} from "../../../components/files-component";
import {putApiFileById} from "../../../services/files";
import {selectedProjectSelector} from "../../../redux/projects";

export function LinkFilesToTaskModalGivenTask(p: { taskId: number, refreshProject: () => void, excludedFiles?: number[] }) {//excluded files are files that already exist in the task
    const projectFiles: FileState[] = useAppSelector(filesSelector);
    const projectId = useAppSelector((state) => selectedProjectSelector(state));
    const dispatch = useAppDispatch();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { hideModal } = useCustomModal();
    const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
    const rowsPerPage = 25
    let totalPages: number = 0

    const onSubmit = () => {
        postApiTasksLinkFilesToTask(p.taskId, {files: selectedFiles})
            .then(() => {
                console.log('successfully linked files to a task')
                hideModal();
                p.refreshProject()
                dispatch(showSnackbar({ message: `Successfully linked files to task!`, type: "info" }));
            })
            .catch(() => {
                hideModal();
                dispatch(showSnackbar({ message: "Error linking files to task!", type: "error" }));
            });
    }

    function handleChange(event: any, fileNameOfCategoryChange: string) {
        if (fileNameOfCategoryChange && projectId) {
            const file = projectFiles.find((file: any) => file.name === fileNameOfCategoryChange);
            if (file) {
                const id = file.id;

                putApiFileById(JSON.stringify(id),
                    {id: id, name: file.name, category: event.target.value as string, projectId: projectId,
                        isDocOpen: file.isDocOpen, createdByName: file.createdByName})

                dispatch(updateFile({...file, id: id, name: fileNameOfCategoryChange, category: event.target.value as string, projectId: projectId}))
            }
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'col1', headerName: 'File Name', flex: 15, cellClassName: 'cell-left-border', headerClassName: 'cell-left-border'
        },
        {
            field: 'col2', headerName: 'File Category', flex: 9, renderCell: params => {
                const currentCategory = projectFiles.find((file: FileState) => file.name === params.row.col1)?.category;
                return <Select onChange={(event) => handleChange(event, params.row.col1)}
                               value={currentCategory || "Unassigned"}
                               style={{minWidth: 160, width: "100%"}}>
                    {fileCategories.map((category, index) => {
                        return <MenuItem key={index} value={category.value}> {category.value} </MenuItem>
                    })}
                </Select>
            }

        },
    ]

    function getRows() {
        let rows: GridRowsProp = [];
        if (projectFiles.length === 0) {
            return rows;
        }
        for(let file of projectFiles) {
            if (!p.excludedFiles || !p.excludedFiles.includes(file.id)) {
                rows = rows.concat({
                    id: file.id,
                    col1: file.name,
                });
            }
        }
        totalPages=Math.ceil(rows.length/rowsPerPage)

        return rows;
    }

    return <div style={{marginTop: 10, width: 930}}>
        <div className="wizard-title">
            <StyledCloseIconUploader onClick={hideModal}/>
            <span>{"Select files to link"}</span>
        </div>
    <div style={{margin: 36}}>
        <DataGrid
            initialState={{ pagination: {pageSize: rowsPerPage}}}
            rowHeight={30}
            page={currentPage-1}
            style={{ height: "calc(100vh - 604px)", minWidth: "500px"}}
            rows={getRows()}
            columns={columns}
            hideFooter={true}
            pagination
            checkboxSelection
            onSelectionModelChange={(ids) => {
                // @ts-ignore
                setSelectedFiles(ids);
            }}
        />
        {/*It was requested that the footer show the current page and the number of pages available. since we can't do that */}
        {/*with datagrid. I have created my own footer to display this.*/}
        <div style={{height: 50}} className={"cell-left-border cell-right-border cell-bottom-border"}>
            <p style={{display: "inline-block"}}></p>
            <Button disabled={currentPage===totalPages} className={"page-navigate-button"}
                    onClick={() => setCurrentPage(currentPage+1)}>
                {'>'}
            </Button>
            <Button disabled={currentPage===1} className={"page-navigate-button"} style={{marginLeft: 30}}
                    onClick={() => setCurrentPage(currentPage-1)}>
                {'<'}
            </Button>
            <p style={{display: "inline-block", float: "right"}}> page {currentPage} of {totalPages}</p>
        </div>
        <div className="remove-entity-button-list" >
            <Button color="secondary" variant="outlined" onClick={hideModal}>CANCEL</Button>
            <Button disabled={selectedFiles.length===0} type="submit" onClick={onSubmit} color="secondary" variant="contained">
                SUBMIT
            </Button>
        </div>
    </div>
    </div>
}
