import { Notification } from "../models/Notification";
import { ApiNotificationsMentionBody } from "../models/ApiNotificationsMentionBody";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
export function getApiNotificationsById(id: number, signal?: AbortSignal): Promise<Notification[]> {
    const url = new URL(`/api/notifications/${id}`, baseUrl);
    return fetchAndParse<Notification[]>(url.toString(), { method: "GET", headers, signal });
}
export function deleteApiNotificationsById(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/notifications/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
export function putApiNotifications(body?: number[], signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/notifications`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApiNotificationsMention(body?: ApiNotificationsMentionBody, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/notifications/mention`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
