import moment from "moment";
import { ProjectFilter } from "../components/project-filters";
import { SubTask } from "../models/SubTask";
import { Task } from "../models/Task";
import { TenantUser } from "../models/TenantUser";

export const filterConditions = (subtaskList: SubTask[] | undefined, filters: ProjectFilter, user: TenantUser) => {
    return {
        subtasksStatus: subtaskList?.filter(sb => sb.status === filters.filter.status.type),
        subtasksDueDate: subtaskList?.filter(sb => moment(sb.dueDate!).format("DD/MM/YYYY") === moment(filters.filter.date!).format("DD/MM/YYYY")),
        subtasksAssigned: subtaskList?.filter(sb => sb.assignedUserId && sb.assignedUserId.split(",").includes(user.id.toString())),
        containsSubtasksKeyword: subtaskList?.filter(sb => sb.name.toLocaleLowerCase().includes(filters.filter.keyword?.toLocaleLowerCase()!)),
        usersSubtasksAssigned: filters.assignees.filter(user => subtaskList?.filter(sb => sb.assignedUserId === String(user.id)).length === 0 || 
        (subtaskList?.filter(sb => sb.assignedUserId === String(user.id)).length === 0 && user.id === 0))
    }
}

export const areFiltersActive = (filters: ProjectFilter) => !!filters.assignees.length || !!filters.filter.date || 
    !!filters.filter.keyword || !!filters.filter.status.type! || !!filters.searchTerm || filters.showMyTasks || filters.showSubtasks;

export const filterTasks = (tasks: Task[], filters: ProjectFilter, user: TenantUser) => tasks.filter(task => {
        const conditions = filterConditions(task.subtasks, filters, user);
        if (filters.filter.status.type && (task.status != filters.filter.status.type && !conditions.subtasksStatus?.length)) {
            return false;
        }
        if (filters.filter.date && (moment(task.dueDate).format("DD/MM/YYYY") !== moment(filters.filter.date).format("DD/MM/YYYY") && !conditions.subtasksDueDate?.length)) {
            return false;
        }
        if (filters.showMyTasks && ((!task.assignedUserId && !conditions.subtasksAssigned?.length) || 
            (!conditions.subtasksAssigned?.length && !task.assignedUserId?.split(",").includes(user.id.toString())))) {
            return false;
        }
        if (filters.filter.keyword && (!task.name.toLocaleLowerCase().includes(filters.filter.keyword.toLocaleLowerCase()) && !conditions.containsSubtasksKeyword?.length)) {
            return false;
        }
        if (filters.assignees.length > 0 && ((filters.assignees.filter(user =>
            ((task.assignedUserId && task.assignedUserId.split(",").includes(user.id.toString()))
            || (!task.assignedUserId && user.id === 0))).length === 0) && conditions.usersSubtasksAssigned?.length !== 0)) {
            return false;
        }
        return !(filters.searchTerm && filters.searchTerm !== "" && !task.name.toLocaleLowerCase().includes(filters.searchTerm.toLocaleLowerCase()));
    });
