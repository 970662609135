import {BeforeAndAfterTable} from "./before-and-after-table";
import {SplitText} from "./split-text";
import {Selections} from "./selections";

export function BeforeAndAfterContainer(props: {splitText: SplitText, selections: Selections, setSelections: (arg: Selections) => void}) {
    return <div className="row no-gutters bg-light mt-3 pr-4 rounded" id="rg_context_wizard">
        <div className="col-12 col-md-11 py-4 pl-4">
            <h5>Now select if you want any parts of the pattern to appear before or after the match.</h5>
            <small id="rg_raw_text_input_help" className="form-text text-muted">
                Note that this is disabled for text that does not have a fixed length. For example if you choose the
                option "any letters - any length" for the first word you can't have that word to appear before the match. Try
                choosing another option like "any letters - exactly 8".
            </small>
            <div className="row no-gutters bg-white border rounded p-2" id="before_and_after_container">
                <div className="rg-match-container">
                    <BeforeAndAfterTable splitText={props.splitText} selections={props.selections} setSelections={props.setSelections}/>
                </div>
            </div>
        </div>
    </div>
}
