import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { CustomField } from "../models/CustomField";
import { CustomFieldValue } from "../models/CustomFieldValue";

type CustomFieldsValuesType = { taskListId: number, customFieldsValues: CustomFieldValue[] }

type CustomFieldsType = {
    customFields: CustomField[],
    customFieldsValues: CustomFieldsValuesType[]
}

const initialState: CustomFieldsType = {
    customFields: [],
    customFieldsValues: []
}

const customFields = createSlice({
    name: "customFields",
    initialState,
    reducers: {
        loadCustomFields: (state, action) => {
            state.customFields = action.payload;
        },
        loadCustomFieldsValues: (state, action) => {
            state.customFieldsValues = [
                ...state.customFieldsValues.filter(cfv => cfv.taskListId !== action.payload.taskListId),
                action.payload
            ]
        },
        createCustomFieldValue: (state, action) => {
            if (state.customFieldsValues.find(cfv => cfv.taskListId === action.payload.taskListId)) {
                state.customFieldsValues = state.customFieldsValues = state.customFieldsValues.map(cfv => {
                    if (cfv.taskListId === action.payload.taskListId) {
                        return {
                            ...cfv,
                            customFieldsValues: [...cfv.customFieldsValues, action.payload.customFieldValue]
                        }
                    }
                    return cfv;
                })
            } else {
                state.customFieldsValues.push(action.payload)
            }
        },
        updateCustomFieldValue: (state, action) => {
            state.customFieldsValues = state.customFieldsValues = state.customFieldsValues.map(cfv => {
                if(cfv.taskListId === action.payload.taskListId) {
                    return {
                        ...cfv,
                        customFieldsValues: [
                            ...cfv.customFieldsValues.filter(v => v.id !== action.payload.customFieldValue.id),
                            action.payload.customFieldValue
                        ]
                    }
                }
                return cfv;
            })
        },
        deleteCustomFieldValue: (state, action) => {
            state.customFieldsValues = state.customFieldsValues = state.customFieldsValues.map(cfv => {
                if(cfv.taskListId === action.payload.taskListId) {
                    return {
                        ...cfv,
                        customFieldsValues: cfv.customFieldsValues.filter(v => v.id !== action.payload.id)
                    }
                }
                return cfv;
            })
        },
        resetCustomFieldValues: (state) => {
            state.customFieldsValues = []
        }
    }
});

export const { loadCustomFields, loadCustomFieldsValues, createCustomFieldValue,
    updateCustomFieldValue, deleteCustomFieldValue, resetCustomFieldValues } = customFields.actions

export const customFieldsSelector = (state: RootState) => state.customFields.customFields

export const customFieldsValuesSelector = (state: RootState) => state.customFields.customFieldsValues

export const cfvByTaskListSelector = (state: RootState, taskListId: number) =>
    state.customFields.customFieldsValues.find(cfv => cfv.taskListId === taskListId) || { taskListId: 0, customFieldsValues: [] }

export const cfvByTaskListSelectorAndCustomFieldIdSelector  = (state: RootState, taskListId: number, customFieldId: number) =>
    cfvByTaskListSelector(state, taskListId).customFieldsValues.filter(cfv => cfv.customFieldId === customFieldId)

export default customFields.reducer
