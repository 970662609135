import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import { useCustomModal } from "../custom-message-modal";
import './remove-entity-modal.scss';
import { useNavigate } from "react-router";
import {useAppDispatch} from "../../../hooks/redux-hook";
import { showSnackbar } from "../../../redux/snackbar";
import {deleteApiTasksUnlinkFilesToTask} from "../../../services/task";
import {StyledCloseIconUploader} from "../../../components/close-button";
import {hideProgressLine} from "../../../redux/progress-line";
import {FileState} from "../../../models/FileState";
import {LinkFilesToTaskModalGivenTask} from "./link-files-to-task-modal-given-task";
import {useAuthService} from "../../../contexts/auth-context";
import {getApiFilesWithURLByTaskId, putApiFileById} from "../../../services/files";
import {DocInUseModal} from "./doc-in-use-modal";
import {getFileNameFromURL, navToDocs, markDocsAsOpened} from "../../user/projects/single-project";
import {MenuItem, Select} from "@mui/material";
import {updateFile} from "../../../redux/file-management";
import {DataGrid, GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {fileCategories} from "../../../components/files-component";

export function ShowAvailableTaskFilesModal(p: {projectId: number, taskId: number, files: FileState[], refreshProject: () => void }) {
    const nav = useNavigate();
    const auth = useAuthService();
    const dispatch = useAppDispatch();
    const { hideModal } = useCustomModal();
    const [currentFiles, setCurrentFiles] = useState<FileState[]>(p.files);
    const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState(false);
    const { showModal } = useCustomModal();
    const rowsPerPage = 25
    let totalPages = Math.ceil(currentFiles.length/rowsPerPage)

    useEffect(() => {
        getApiFilesWithURLByTaskId(p.taskId)
            .then(files => {
                setCurrentFiles(files)
                totalPages = currentFiles.length/rowsPerPage
            })
    }, [])

    function handleChange(event: any, fileNameOfCategoryChange: string) {
        if (fileNameOfCategoryChange && p.projectId) {
            const file = currentFiles.find((file: any) => file.name === fileNameOfCategoryChange);
            if (file) {
                const id = file.id;

                putApiFileById(JSON.stringify(id),
                    {id: id, name: file.name, category: event.target.value as string, projectId: p.projectId,
                        isDocOpen: file.isDocOpen, createdByName: file.createdByName})
                    .then(() => {
                        getApiFilesWithURLByTaskId(p.taskId)
                            .then(files => {
                                setCurrentFiles(files)
                            })
                    })

                dispatch(updateFile({...file, id: id, name: fileNameOfCategoryChange, category: event.target.value as string, projectId: p.projectId}))
            }
        }
    }

    const goToDocs = (publicUrls: any[]) => {
        hideModal()
        navToDocs(publicUrls, currentFiles, showModal, nav, p.projectId)
    };


    function getUrlsFromSelectedRows() {
        let urlList : string[] = [];

        currentFiles.forEach(file => {
            if (selectedFiles.includes(file.id)) {
                urlList.push(file.url!);
            }
        })

        return urlList;
    }

    let columns: GridColDef[]
    if (auth.hasPMRoleAccess()) {
        columns = [
            {
                field: 'col1', headerName: 'File Name', flex: 15, cellClassName: 'cell-left-border', headerClassName: 'cell-left-border'
            },
            {
                field: 'col2', headerName: 'File Category', flex: 9, renderCell: params => {
                    const currentCategory = currentFiles.find((file: FileState) => file.name === params.row.col1)?.category;
                    return <Select onChange={(event) => handleChange(event, params.row.col1)}
                                   value={currentCategory || "Unassigned"}
                                   style={{minWidth: 160, width: "100%"}}>
                        {fileCategories.map((category, index) => {
                            return <MenuItem key={index} value={category.value}> {category.value} </MenuItem>
                        })}
                    </Select>
                }

            },
            {
                field: 'col3', headerName: '',flex:3.5, renderCell: params => {
                    return  <Button style={{backgroundColor: "inherit", textDecoration: 'underline', color: "blue", border: "none"}} sx={{fontSize: 1}} color="secondary" variant="outlined" onClick={() => {
                        deleteApiTasksUnlinkFilesToTask(p.taskId, {fileId: params.row.id})
                            .then(p.refreshProject)
                            .then(() => {
                                const newList = currentFiles.filter((file) => file.id !==  params.row.id);
                                if (newList.length === 0) {
                                    hideModal()
                                } else {
                                    setCurrentFiles(newList);
                                }
                                dispatch(showSnackbar({ message: "File removed From task!", type: "info" }));
                                dispatch(hideProgressLine())})
                            .catch(() => {
                                dispatch(hideProgressLine());
                                dispatch(showSnackbar({ message: "Error removing file from task!", type: "error" }));
                            });
                    }
                    } >UNLINK</Button>
                }
            },
        ]
    } else {
        columns = [
            {
                field: 'col1', headerName: 'File Name', flex: 15, cellClassName: 'cell-left-border', headerClassName: 'cell-left-border'
            },
            {
                field: 'col2', headerName: 'File Category', flex: 12.5, cellClassName: 'cell-left-border', headerClassName: 'cell-left-border', renderCell: params => {
                    const currentCategory = currentFiles.find((file: FileState) => file.name === params.row.col1)?.category
                    if (currentCategory!==undefined) {
                        return currentCategory
                    } else {
                        return "Unassigned"
                    }
                }
            },
        ]
    }

    function getRows() {
        let rows: GridRowsProp = [];
        for(let file of currentFiles) {
            if (file) {
                rows = rows.concat({
                    id: file.id,
                    col1: file.name,
                });
            }
        }
        return rows;
    }

    return <div style={{marginTop: 10, width: 930}}>
        <div className="wizard-title">
            <StyledCloseIconUploader onClick={hideModal}/>
            <span>{"Task files"}</span>
        </div>
    <div style={{margin: 36}}>
                <DataGrid
                    rowHeight={30}
                    initialState={{ pagination: {pageSize: rowsPerPage}}}
                    page={currentPage-1}
                    style={{ height: "calc(100vh - 604px)", marginBottom: 0 }}
                    rows={getRows()}
                    columns={columns}
                    hideFooter={true}
                    pagination
                    checkboxSelection
                    onSelectionModelChange={(ids: any) => {
                        // @ts-ignore
                        setSelectedFiles(ids);
                    }}
                />
        {/*It was requested that the footer show the current page and the number of pages available. since we can't do that */}
        {/*with datagrid. I have created my own footer to display this.*/}
        <div style={{height: 50}} className={"cell-left-border cell-right-border cell-bottom-border"}>
            <p style={{display: "inline-block"}}></p>
            <Button disabled={currentPage===totalPages} className={"page-navigate-button"}
                    onClick={() => setCurrentPage(currentPage+1)}>
                {'>'}
            </Button>
            <Button disabled={currentPage===1} className={"page-navigate-button"} style={{marginLeft: 30}}
                    onClick={() => setCurrentPage(currentPage-1)}>
                {'<'}
            </Button>
            <p style={{display: "inline-block", float: "right"}}> page {currentPage} of {totalPages}</p>
        </div>

        { auth.hasPMRoleAccess() && <Button style={{backgroundColor: "inherit", fontSize: 9, border:'1px solid blue', color: "blue"}} onClick={() => {
            hideModal()
            showModal(() => LinkFilesToTaskModalGivenTask({taskId: p.taskId, refreshProject: p.refreshProject, excludedFiles: currentFiles.map(file => file.id)}), {})
        }}>
            LINK MORE FILES
        </Button>}

        <div className="remove-entity-button-list" >
            <Button color="secondary" variant="outlined" onClick={hideModal}>CANCEL</Button>
            <Button disabled={selectedFiles.length===0 || loading} type="submit" onClick={() => goToDocs(getUrlsFromSelectedRows())} color="secondary" variant="contained">
                GO TO DOCS
            </Button>
        </div>
    </div>
    </div>
}
