import React, {useContext, useState} from 'react';
import './AnnotationRepeatPopup.scss';
import PageRangeInput from "./PageRangeInput";
import { useDispatch } from 'react-redux';
import {useCustomModal} from "../pages/modals/custom-message-modal";
import WebViewerContext from '../contexts/webviewer-context';
import {hideProgressLine, showProgressLine} from "../redux/progress-line";

const all_label = 'all';

export default function AnnotationRepeatPopup(props: {annotation: any, pageCount: number}) {
    const dispatch = useDispatch();
    const { setInstance,instance } = useContext(WebViewerContext);
    const annotationManager = instance.Core.annotationManager;
    const [doAllPages, setDoAllPages] = useState(true);
    const [pageRange, setPageRange] = useState<undefined | (number |undefined)[]>(undefined);
    const [processing, setProcessing] = useState(false)
    const {hideModal} = useCustomModal();


    const handlePageRangeChange = (event: any) => {
        const selectedOption = event.target.value;
        setDoAllPages(selectedOption === all_label);
    }

    const handlePageRangeSelect = () => {
        //If the user click the page range text field change the option to page range.
        setDoAllPages(false)
    }

    const handlePageRangeTextChange = (newPageRange: undefined | (number |undefined)[]) => {
        setPageRange(newPageRange);
    }

    const handlePageRangeTextClear = () => {
        setPageRange(undefined);
    }

    const handleCancelClick = () => {
        annotationManager.deselectAnnotation(props.annotation)
        hideModal()
    }

    const copyAnnotationToOtherPage = (annotation: any, pageNumber: number) => {
        let copy = new instance.Core.Annotations.RedactionAnnotation();
        copy.PageNumber = pageNumber;
        copy.Quads = annotation.Quads;
        copy.StrokeColor = annotation.StrokeColor;
        copy.FillColor = annotation.FillColor;
        copy.setContents(annotation.getContents());
        copy.setCustomData('trn-annot-preview', annotation.getCustomData('trn-annot-preview'));
        copy.Author = annotation.Author;
        copy.setCustomData('author', annotation.getCustomData('author'));
        copy.setCustomData('trn-redaction-type', annotation.getCustomData('trn-redaction-type'));
        //@ts-ignore
        copy.OverlayText = annotation.OverlayText;
        //@ts-ignore
        copy.FontSize = annotation.FontSize;
        //@ts-ignore
        copy.TextColor = annotation.TextColor;
        //@ts-ignore
        copy.type = annotation.type;
        //@ts-ignore
        copy.resultStr = annotation.getContents();
        return copy;
    }

    const copyAnnotations = async () => {
        const existingAnnotations = annotationManager.getAnnotationsList().filter((annotation: any) => annotation instanceof instance.Core.Annotations.RedactionAnnotation);
        const existingAnnotationsByPage = existingAnnotations.reduce((map: any, annotation: any) => {
            const pageNumber = annotation.getPageNumber();
            return map.set(pageNumber, (map.get(pageNumber) || []).concat(annotation));
        }, new Map());


        let repeatedAnnotations = []
        const startPage = doAllPages ? 1 : pageRange![0] ?? 0;
        const endPage = doAllPages ? props.pageCount : pageRange![1] ?? props.pageCount

        for (let p = startPage; p <= endPage; p++) {
            //Don't add an annotation on the page with the original.
            if (p === props.annotation.PageNumber) {
                continue;
            }

            //Don't add an annotation if there's already an annotation there.
            const isCovered = existingAnnotationsByPage.has(p) && props.annotation.Quads.every((quad: any) => isQuadFullyCovered(quad, existingAnnotationsByPage.get(p)))
            if (isCovered) {
                continue;
            }

            repeatedAnnotations.push(copyAnnotationToOtherPage(props.annotation, p))
        }

        annotationManager.addAnnotations(repeatedAnnotations);
        //Without this step the copied annotations don't show up until the page re-renders for some other reason.
        //There's probably a more efficient way to do this, but this doesn't take more than a few seconds for thousands of annotations.
        repeatedAnnotations.forEach(annotation => {
            annotationManager.redrawAnnotation(annotation)
        })
    }

    function isQuadFullyCovered(newQuad: any, annotationsOnPage: any[]) {
        //In this coordinate system the origin is in the upper left of the page.
        // There are x and y for four points in this order: lower left, lower right, upper left, upper right.
        for (let annot of annotationsOnPage) {
            console.log(`quads of annotation on page`)
            console.log(annot.Quads)
            for (let quad of annot.Quads) {
                console.log(quad)
                if (contains(quad, newQuad)) {
                    return true
                }
            }
        }
        return false
    }

    function contains(quad1: any, quad2: any) {
        const tolerance = 1;
        return (
            quad1.x1 - tolerance <= quad2.x1 &&
            quad1.y1 + tolerance >= quad2.y1 &&
            quad1.x2 + tolerance >= quad2.x2 &&
            quad1.y3 - tolerance <= quad2.y3
        );
    }

    const handleOkClick = () => {
        if (!doAllPages && !pageRange) {
            return;
        }
        setProcessing(true);
        dispatch(showProgressLine());
        setTimeout(function() {
            copyAnnotations().then(() => {
                exit();
            }).catch((error) => {
                console.log(error);
                exit();
            });
        }, 10);
    }

    const exit = () => {
        setProcessing(false);
        annotationManager.deselectAnnotation(props.annotation)
        dispatch(hideProgressLine());
        hideModal()
    }

    return <div className="repeat-mark-popup">
        <h2>Repeat mark across page range</h2>
        <label>
            <input
                type="radio"
                name="pageRange"
                value={all_label}
                onChange={handlePageRangeChange}
                checked={doAllPages}
            />
            All pages
        </label>

        <label>
            <input
                type="radio"
                name="pageRange"
                value="range"
                onChange={handlePageRangeChange}
                checked={!doAllPages}
            />
            Range:
            <PageRangeInput pageRangeChangeCallBack={handlePageRangeTextChange}
                            clearPageRangeCallBack={handlePageRangeTextClear}
                            clickPageRangeCallback={handlePageRangeSelect}
                            placeholder={'enter a page range'}
            />
        </label>

        <div className="button-container">
            <button className="rounded-button cancel-button" onClick={handleCancelClick} disabled={processing}>Cancel</button>
            <button className="rounded-button ok-button" onClick={handleOkClick} disabled={processing || (!doAllPages && !pageRange)}>OK</button>
        </div>
    </div>
}

