import {baseUrl, getHeadersWithoutJSON, headers} from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
export function postApiFeedbackCreate(data?: FormData, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/feedback/create`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: data, headers: getHeadersWithoutJSON(), signal });
}
export function getApiJiraAuth(signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/jira/auth`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "GET", headers, signal });
}
