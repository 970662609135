import Dialog from "@mui/material/Dialog";
import React, { useState, ComponentProps, useContext, useMemo } from "react";

export const CustomModelContext = React.createContext<IGlobalModalState>({
    isOpen: false,
});

export type CustomModalProps<T> = { onRequestClose: (result?: T) => void }
export type CustomModalComponent<TResult, TProps = {}> = React.ComponentType<CustomModalProps<TResult> & TProps>

export function useCustomModal() {
    const [state, setState] = useContext(GlobalCustomModalContext);
    function showModal<TResult extends unknown, TProps>(component: CustomModalComponent<TResult, TProps>, props: Omit<TProps, keyof CustomModalProps<TResult>>) {
        return new Promise<TResult | undefined>(resolve => {
            setState({
                isOpen: true,
                component: component as any,
                componentProps: props,
                onClosed: (s?: unknown) => {
                    resolve(s as TResult);
                    setState({ isOpen: false });
                }
            })
        });
    }
    return {
        showModal,
        hideModal: () => {
            if (state.onClosed) {
                state.onClosed();
            }
            setState({ isOpen: false });
        },
        isOpen: state.isOpen
    }
}
export interface IGlobalModalState {
    content?: string | JSX.Element;
    isOpen: boolean;
    component?: CustomModalComponent<unknown, unknown>
    componentProps?: unknown,
    onClosed?: (r?: unknown) => void
}
const GlobalCustomModalContext = React.createContext<[IGlobalModalState, React.Dispatch<IGlobalModalState>]>([{ isOpen: false }, () => { }]);
export function GlobalCustomModal(p: React.PropsWithChildren<{}>) {
    const [state, setState] = useState<IGlobalModalState>({
        isOpen: false,
    });
    const contextValue = useMemo(() => [state, setState] as [IGlobalModalState, React.Dispatch<IGlobalModalState>], [state, setState])
    return <GlobalCustomModalContext.Provider value={contextValue}>
        <CustomModal {...p} onRequestClose={state.onClosed!} open={state.isOpen}
            componentProps={state.componentProps}
            component={state.component!}></CustomModal>
        <>
            {p.children}
        </>
    </GlobalCustomModalContext.Provider>;
}
export function CustomModal(p: ComponentProps<typeof Dialog> & {
    onRequestClose: (result?: unknown) => void,
    component: React.ComponentType<{ onRequestClose: (result?: unknown) => void }>
    componentProps: unknown,
}) {
    const { component: Component, componentProps, ...rest } = p;
    const modalState = useContext(GlobalCustomModalContext);
    const [modalStateInfo] = modalState;
    return <Dialog open={modalStateInfo.isOpen}
        sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    maxWidth: "1000px", // app's maximum modal width
                },
            },
        }}
    >
        {!Component ? <></> : <Component onRequestClose={p.onRequestClose} {...componentProps} />}
    </Dialog>
}
