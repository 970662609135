import Avatar from "@mui/material/Avatar";
import {
    convertMinutesToHours,
    getCaretCoordinates,
    getCaretIndex,
    getUserInitials,
    setCaretPosition,
} from "../../services/utils";
import { MoreActionsComponent } from "../more-actions-component";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import IconButton from "@mui/material/IconButton";
import { blueIconColor, statusList, StatusType } from "../../constants";
import TaskIcon from "@mui/icons-material/Task";
import Button from "@mui/material/Button";
import React, { useEffect, useRef, useState } from "react";
import { Task } from "../../models/Task";
import {
    deleteApiTasksCommentsById,
    getApiTasksComments,
    getApiTasksHistory,
    postApiTasksCommentsCreate
} from "../../services/task";
import { Comment } from "../../models/Comment";
import { HistoryResult } from "../../models/HistoryResult";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import MenuItem from "@mui/material/MenuItem";
import { useEventHandler } from "../../hooks/handle-events";
import { useTheme } from "@mui/material/styles";
import { isToday } from "date-fns";
import moment from "moment";
import { usersSelector } from "../../redux/users";
import { TaskList } from "../../models/TaskList";
import { useNavigate } from "react-router-dom";
import { showSnackbar } from "../../redux/snackbar";
import { putApiNotificationsMention } from "../../services/notifications";
import { Autocomplete, Popper, TextField, Tooltip, useMediaQuery } from "@mui/material";
import { Search } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { GroupByUserWorklog } from "../../pages/user/task-details";
import { cfvByTaskListSelector } from "../../redux/custom-fields";
import { useAuthService } from "../../contexts/auth-context";
import { ConditionalWrapper } from "../conditional-wrapper";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { setShowToolbarAfterDeletion } from "../../redux/action-bar";
//@ts-ignore
import DOMPurify from 'dompurify';


export type TaskLinkListener = {
    id: string,
    taskId: number
}

export function Comments(p: {
    task: Task, showToolbarAfterDeletion: boolean, taskLists: TaskList[],
    projectName: string, isTemplate: boolean, worklogs: GroupByUserWorklog[]
}) {

    const auth = useAuthService();
    const theme = useTheme();
    const nav = useNavigate();
    const smallWindow = useMediaQuery('(max-width:1750px)');
    const commentRef = useRef<HTMLDivElement | null>(null);
    const carretPos = useRef(0);
    const dispatch = useAppDispatch();
    const users = useAppSelector((state) => usersSelector(state, false));
    const [cursorPosition, setCursorPosition] = useState<{ x: number, y: number }>();
    const [commentList, setCommentList] = useState<Comment[]>();
    const [commentTabSelected, setCommentTabSelected] = useState<"Comment section" | "Activity log">("Comment section");
    const [activityHistory, setActivityHistory] = useState<HistoryResult[]>();
    const [showUserSelect, setShowUserSelect] = useState(false);
    const [showTaskSelect, setShowTaskSelect] = useState(false);
    const [hasComment, setHasComment] = useState(false);
    const listeners = useRef<TaskLinkListener[]>([])
    const commentSection = useRef(null);
    const [mentionedUsers, setMentionedUsers] = useState<number[]>([]);
    const [tasksOptions, setTasksOptions] = useState<MentionOption[]>([]);
    const [usersOptions, setUsersOptions] = useState<MentionOption[]>([]);
    const [commentBoxFocus, setCommentBoxFocus] = useState(false);
    const customFieldsValues = useAppSelector((state) => cfvByTaskListSelector(state, p.task.taskListId)).customFieldsValues;

    const commentPlaceholder = p.isTemplate ?
        "You can add a comment for this task and also tag a user if the user has access to this project." :
        "You can add a comment, mention a user or tag a task or a subtask";

    useEffect(() => {
        fetchComments();
    }, [p.task]);

    useEffect(() => {
        let tasksAndSubtasks: Task[] = [];
        p.taskLists.forEach(taskList => {
            taskList.tasks?.forEach(task => {
                tasksAndSubtasks = tasksAndSubtasks.concat(task).concat(task.subtasks as Task[]);
            })
        })
        setTasksOptions(tasksAndSubtasks.map(task => ({
            id: task.id, name: task.name, projectName: p.projectName, taskName: tasksAndSubtasks.find(ts => ts.id === task.parentTaskId)?.name,
            taskListName: p.taskLists.find(tl => tl.id === task.taskListId)?.name, isSubtask: !!task.parentTaskId
        })));
    }, [p.taskLists]);

    useEffect(() => {
        setUsersOptions(users.map(user => ({ id: user.id, name: user.name, color: user.color! })));
    }, [users]);

    useEffect(() => {
        if (commentList) {
            removeListeners();
            listeners.current = [];
            setTimeout(() => {
                commentList.forEach(comment => {
                    comment.content!.match(/"link-[\d.]+-\d+"/g)?.forEach(match => {
                        const link = match.replaceAll("\"", "")
                        const taskId = +link.split("-")[2];
                        const el = document.getElementById(link);
                        if (el) {
                            el.addEventListener('click', () => nav(`/app/user/workflow/task-details/${taskId}`));
                            listeners.current.push({ id: link, taskId });
                        }
                    })
                })
                if (commentSection.current !== null) {
                    (commentSection.current as HTMLElement).scrollTop = (commentSection.current as HTMLElement).scrollHeight;
                }
            }, 1000);
        }
    }, [commentList]);

    useEffect(() => {
        return () => {
            removeListeners();
        };
    }, []);

    useEffect(() => {
        if (showUserSelect || showTaskSelect) {
            carretPos.current = getCaretIndex((commentRef.current as Node));
        }
    }, [showUserSelect, showTaskSelect]);


    useEffect(() => {
        dispatch(setShowToolbarAfterDeletion(false));
        if (commentTabSelected === "Comment section" && commentSection.current !== null) {
            (commentSection.current as HTMLElement).scrollTop = (commentSection.current as HTMLElement).scrollHeight;
        }
        setMentionedUsers([]);
    }, [commentTabSelected])

    useEffect(() => {
        getApiTasksHistory({ taskId: p.task.id }).then((res: HistoryResult[]) => setActivityHistory(res));
    }, [p.worklogs, customFieldsValues])

    const removeListeners = () => {
        listeners.current.forEach(listener => {
            const el = document.getElementById(listener.id);
            if (el) {
                el.removeEventListener('click', () => nav(`/app/user/workflow/task-details/${listener.taskId}`));
            }
        });
    }

    const fetchComments = () => {
        getApiTasksComments({ taskId: p.task.id }).then((res: Comment[]) => setCommentList(res));
        getApiTasksHistory({ taskId: p.task.id }).then((res: HistoryResult[]) => setActivityHistory(res));
    }

    const mentionUser = (user: MentionOption) => {
        setMentionedUsers([...mentionedUsers, user.id]);
        addTag(`<span id="mention-${user.id}" style="color: #217DA2">@${user.name}</span>`);
    }

    const mentionTask = (value: MentionOption) => {
        addTag(`<span id="link-${Math.random()}-${value.id}" style="color: #217DA2; cursor: pointer">${value.name}</span>`);
        setShowTaskSelect(false);
    }

    const addTag = (tag: string) => {
        debugger
        let position = carretPos.current;
        let text = (commentRef.current as Element).innerHTML.replaceAll("&nbsp;", " ");
        let newText = "";
        if (text.length === 0) {
            newText = tag + "&nbsp";
        } else {
            let pos = 0;
            let isTag = false;
            for (let i = 0; i < text.length; i++) {
                if ((text.substring(i - 6, i - 1) === "DA2\">" || text.substring(i - 10, i - 1) === "pointer\">"
                    || text.substring(i - 8, i - 1) === "</span>" || text.substring(i - 7, i - 1) === "</div>"
                    || text.substring(i - 6, i - 1) === "<div>") && text.substring(i - 1, i + 4) !== "<div>" && text.substring(i - 1, i + 4) !== "<span") {
                    isTag = false;
                    pos++;
                }
                if (text.substring(i, i + 6) === "<span " || text.substring(i, i + 7) === "</span>" ||
                    text.substring(i, i + 5) === "<div>" || text.substring(i, i + 6) === "</div>") {
                    isTag = true;
                }
                if (!isTag) {
                    pos++;
                }
                if (pos === position) {
                    if (text[i] === "<") {
                        newText = newText.slice(0, -1);
                        newText += tag + "&nbsp";
                        newText += text[i];
                    } else {
                        if (text[i] !== "@" && text[i] !== "#") {
                            newText += text[i];
                        }
                        newText += tag + "&nbsp";
                    }
                    pos++;
                } else {
                    newText += text[i];
                }
            }
            if (pos < position) {
                if (text[text.length - 1] === "@" && text[text.length - 1] === "#") {
                    newText.slice(0, -1);
                }
                newText += tag + "&nbsp";
            }
        }
        //This is dangerous because it's adding input strings from the user (either the task name or username) to the inner HTML.
        //This makes it vulnerable to code injection. So it's important to sanitize the new text before setting it.
        (commentRef.current as Element).innerHTML = DOMPurify.sanitize(newText)
        setTimeout(() => {
            (commentRef.current as HTMLElement).focus();
            (commentRef.current as HTMLElement).scrollTop = (commentRef.current as HTMLElement).scrollHeight;
            setCaretPosition(commentRef.current as HTMLElement, tag)
        }, 50);
    }

    const addComment = () => {
        dispatch(showProgressLine());
        postApiTasksCommentsCreate({ taskId: p.task.id, content: (commentRef.current as HTMLElement).innerHTML })
            .then(comment => {
                (commentRef.current as HTMLElement).innerHTML = "";
                fetchComments();
                dispatch(hideProgressLine());
                if (mentionedUsers.length > 0) {
                    mentionedUsers.filter(userId => !p.task.assignedUserId ||
                        !p.task.assignedUserId.split(",").includes(`${userId}`))
                        .forEach(userId => putApiNotificationsMention({ taskId: p.task.id, comment: comment.content, userId }))
                    setMentionedUsers([]);
                }
            });
    }

    const updateComment = (e: React.KeyboardEvent) => {
        setCursorPosition(getCaretCoordinates());
        if (e.key === "Shift") {
            return;
        }
        const selectedObj = window.getSelection();
        const el = selectedObj!.anchorNode!.parentNode!;
        switch (e.key) {
            case "Backspace":
                if ((el as Element).tagName === "SPAN") {
                    if ((el as HTMLElement).id.includes("mention-")) {
                        const userId = (el as HTMLElement).id.replaceAll("mention-", "");
                        setMentionedUsers(mentionedUsers.filter(id => id !== +userId));
                    }
                    el.parentNode!.removeChild(el);
                }
                break;
            case "@":
                setShowUserSelect(true);
                e.preventDefault();
                break;
            case "#":
                setShowTaskSelect(true);
                e.preventDefault();
                break;
            default:
                if (el && (el as Element).tagName === "SPAN") {
                    e.preventDefault();
                }
        }
        if (!hasComment && (commentRef.current as HTMLElement).innerText !== "") {
            setHasComment(true);
        }
        if (hasComment && (commentRef.current as HTMLElement).innerText === "") {
            setHasComment(false);
        }
    }

    const removeComment = (id: number) => {
        setCommentList(commentList?.filter(comment => comment.id !== id));
        fetchComments();
    }

    return <div className="left-border-1 bottom-border task-details-comment-section"
        style={{
            width: smallWindow ? "calc(60% - 17px)" : "calc(50% - 17px)",
            height: `calc(100vh - 184px - 113px - ${p.showToolbarAfterDeletion ? "55px" : "0px"})`,
        }}>
        <div className="bottom-border task-details-comment-menu-wrapper">
            <div className="task-details-comment-menu-component" onClick={() => setCommentTabSelected("Comment section")} style={{
                color: !p.isTemplate && commentTabSelected === "Comment section" ? theme.palette.secondary.main : "#414042",
                borderBottom: commentTabSelected === "Comment section" ? `2px solid ${theme.palette.secondary.main}` : ""
            }}>Comment section</div>
            <div className="task-details-comment-menu-component" onClick={() => setCommentTabSelected("Activity log")} style={{
                color: !p.isTemplate && commentTabSelected === "Activity log" ? theme.palette.secondary.main : "#414042",
                marginLeft: 16, borderBottom: commentTabSelected === "Activity log" ? `2px solid ${theme.palette.secondary.main}` : ""
            }}>Activity log</div>
        </div>
        {commentTabSelected === "Comment section" ? <> <div className="task-details-comment-menu-section" ref={commentSection}>
            {!p.isTemplate && commentList?.map(comment => <div key={comment.id} className="task-details-comment-item">
                <Avatar style={{ margin: 16, backgroundColor: comment.color! ?? "var(--normalBlue)" }}>{getUserInitials(comment.name)}</Avatar>
                <div className="task-details-comment-menu-column">
                    <div className="task-details-comment-row">
                        <span className="task-details-comment-text-wrapper">
                            <span className="task-details-comment-text-name">
                                {comment.name}
                            </span>
                            &nbsp;commented:
                        </span>
                        <div className="task-details-comment-row">
                            <span className="task-details-comment-text-date">{getCommentDateFormat(comment.date)}</span>
                            <div className="task-details-comment-more-wrapper">
                                <CommentDeleteMenuItem id={comment.id} removeComment={removeComment} />
                            </div>
                        </div>
                    </div>
                    <div className="task-details-comment-string" dangerouslySetInnerHTML={{ __html: comment.content }} />
                </div>
            </div>)}
        </div>
            {auth.hasUserRoleAccess() && <div className="task-details-comment-actions-wrapper">
                <div id="editable-div" style={{ border: `2px solid ${commentBoxFocus ? "#374C72" : "#0000001F"}`, height: 80, padding: 8, borderRadius: "4px 4px 0px 0px", overflowY: "auto", display: "block" }}
                    contentEditable={!p.isTemplate}
                    onKeyUp={updateComment}
                    onMouseDown={() => setCommentBoxFocus(true)}
                    onBlur={() => setCommentBoxFocus(false)}
                    placeholder={commentPlaceholder}
                    className="task-details-comment-box"
                    ref={commentRef} />
                {!p.isTemplate ?
                    <div className="task-details-comment-actions-project">
                        <div className="task-details-comment-row">
                            <div className="task-details-comment-mention">
                                <Tooltip title="Mention someone">
                                    <IconButton sx={{ "&:hover": { color: blueIconColor }, marginRight: "16px" }}
                                        onClick={() => setShowUserSelect(!showUserSelect)}>
                                        <AlternateEmailIcon />
                                    </IconButton>
                                </Tooltip>
                                {showUserSelect &&
                                    <CommentMention options={usersOptions}
                                        type="Users"
                                        setOpen={setShowUserSelect}
                                        commentDiv={commentRef.current}
                                        top={cursorPosition && cursorPosition.y > 0 ? cursorPosition.y - 60
                                            : (commentRef.current as HTMLElement).offsetTop - 56}
                                        left={cursorPosition && cursorPosition.x > 0 ?
                                            (cursorPosition.x < window.outerWidth - 250 ? cursorPosition.x : window.outerWidth - 250)
                                            : (commentRef.current as HTMLElement).offsetLeft}
                                        onSelect={mentionUser} />}
                                <Tooltip title="Mention a task">
                                    <IconButton sx={{ "&:hover": { color: blueIconColor } }}
                                        onClick={() => setShowTaskSelect(!showTaskSelect)}>
                                        <TaskIcon />
                                    </IconButton>
                                </Tooltip>
                                {showTaskSelect &&
                                    <CommentMention options={tasksOptions}
                                        type="tasks"
                                        setOpen={setShowTaskSelect}
                                        commentDiv={commentRef.current}
                                        top={cursorPosition && cursorPosition.y > 0 ? cursorPosition.y - 60
                                            : (commentRef.current as HTMLElement).offsetTop - 56}
                                        left={cursorPosition && cursorPosition.x > 0 ?
                                            (cursorPosition.x < window.outerWidth - 250 ? cursorPosition.x : window.outerWidth - 250)
                                            : (commentRef.current as HTMLElement).offsetLeft}
                                        onSelect={mentionTask} />}
                            </div>
                        </div>
                        <Button variant="contained" onClick={addComment}
                            style={{ height: 33, width: 156 }}
                            color="secondary" disabled={!hasComment}>ADD COMMENT</Button>
                    </div> : <div className="task-details-comment-actions-template">
                        <Button variant="contained"
                            style={{ height: 33, width: 156, marginTop: 13 }}
                            color="secondary" disabled >ADD COMMENT</Button>
                    </div>
                }
            </div>}
        </> :
            <div style={{ width: "100%", overflowY: "auto" }}>
                {!p.isTemplate && activityHistory?.map(activity => <ActivityLogItem key={activity.date} activity={activity} />)}
            </div>}
    </div>
}

function getCommentDateFormat(date: string) {
    return isToday(new Date(date)) ? moment(date).add(-5, "s").fromNow() : moment(date).format('MMMM DD [at] h:mm A');
}

function ActivityLogItem(props: { activity: HistoryResult }) {
    const users = useAppSelector((state) => usersSelector(state, true));
    let action, field, newValue, date;

    const isCustomField = props.activity.field === "Number of pages" || props.activity.field === "Pages in scope" || props.activity.field === "Batch"
        || props.activity.field === "Type of report" || props.activity.field === "Redaction marks" || props.activity.field === "Identifiers";

    if (props.activity.action === "CREATE" && props.activity.field !== "worklog" && !isCustomField) action = "created";
    else if ((props.activity.action === "UPDATE" || props.activity.action === "CREATE" && isCustomField) && props.activity.field !== "assignedUserId" &&
        props.activity.field !== "name" && props.activity.field !== "description" && props.activity.field !== "worklog") action = "set";
    else if (props.activity.field === "assignedUserId" && props.activity.newValue !== null) action = <span>
        {props.activity.action === "ASSIGN" ? "assigned to:" : "removed assignee:"}
    </span>;
    else if (props.activity.field === "name" || props.activity.field === "description") action = "changed";
    else if (props.activity.field === "worklog" && (props.activity.action === "UPDATE" || props.activity.action === "EDIT")) action = "changed";
    else if (props.activity.field === "worklog" && props.activity.action === "DELETE") action = "removed";
    else if (props.activity.field === "worklog" && (props.activity.action === "CREATE" || props.activity.action === "STOP_TIMER" || props.activity.action === "ADD"))
        action = "tracked";
    else action = props.activity.action;

    if (props.activity.field === "name" && props.activity.action === "CREATE" && !props.activity.subtaskName) field = "task"
    else if (props.activity.field === "name" && props.activity.action === "CREATE" && props.activity.subtaskName) field = "subtask"
    else if (props.activity.field === "startDate") field = "Start Date";
    else if (props.activity.field === "dueDate") field = "Due Date";
    else if (props.activity.field === "assignedUserId") field = "";
    else if (props.activity.field === "worklog" && (props.activity.action === "UPDATE" || props.activity.action === "DELETE" || props.activity.action === "EDIT"))
        field = "time logged";
    else if (props.activity.field === "worklog" && (props.activity.action === "CREATE" || props.activity.action === "STOP_TIMER" || props.activity.action === "ADD"))
        field = "time";
    else field = props.activity.field;

    if ((props.activity.action === "UPDATE" || (props.activity.action === "CREATE" && isCustomField))
        && props.activity.field !== "assignedUserId" && props.activity.newValue != undefined && props.activity.newValue !== "")
        field = field + " to ";

    if (props.activity.newValue == undefined || props.activity.newValue === "") {
        action = `cleared field ${props.activity.field !== "assignedUserId" ? field : "assignee"}`;
        field = "";
    }

    if ((props.activity.field === "startDate" || props.activity.field === "dueDate") && props.activity.newValue != undefined)
        newValue = moment(props.activity.newValue as string).format("MMMM DD");
    else if (props.activity.field === "assignedUserId")
        newValue = newValue = users.filter(u => u.id === props.activity.newValue).pop()?.name;
    else if (props.activity.field === "worklog") newValue = convertMinutesToHours(props.activity.newValue! as number);
    else newValue = props.activity.newValue;

    if (props.activity.field === "status") newValue = (props.activity.newValue as string).replace("_", " ");

    if (props.activity.field === "worklog" && (props.activity.action === "UPDATE" || props.activity.action === "DELETE" || props.activity.action === "CREATE"))
        date = <span> on <span className="task-details-activity-log-value">
            {moment(props.activity.field !== "worklog" ? props.activity.date : props.activity.worklogDate).format("MMM DD")}
        </span>
        </span>

    return <div className="task-details-activity-log-wrapper">
        <div className="task-details-activity-log-div">
            <span className="task-details-activity-log-name">{props.activity.userName}&nbsp;</span>
            <span className="task-details-activity-log-content">
                {action}&nbsp;{field}{props.activity.field !== "assignedUserId" ? " " : ""}
                {props.activity.field !== "status" ? <>
                    <span className={props.activity.field === "assignedUserId" || props.activity.field === "worklog" ?
                        "task-details-activity-log-value" : ""}>
                        {newValue}
                    </span>
                    {date}
                </> :
                    <span className="task-details-activity-log-status"
                        style={{ backgroundColor: statusList[props.activity.newValue as StatusType].color }}>{newValue}
                    </span>
                }
            </span>
        </div>
        <div className="task-details-activity-log-date">{getCommentDateFormat(props.activity.date)}</div>
    </div>
}

function CommentDeleteMenuItem(p: { id: number, removeComment: (id: number) => void }) {

    const [commentAnchorEl, setCommentAnchorEl] = useState<HTMLElement | null>(null);
    const theme = useTheme();
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const dispatch = useAppDispatch();


    const deleteComment = (id: number) => {
        dispatch(showProgressLine());
        deleteApiTasksCommentsById(id)
            .then(() => {
                dispatch(hideProgressLine());
                p.removeComment(id);
            })
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error deleting comment!", type: "error" }));
            })
    }

    return <MoreActionsComponent
        key={p.id}
        id={p.id}
        menuItems={[
            <MenuItem
                onMouseEnter={onMouseEventHandler}
                onMouseLeave={onLeaveMouseEventHandler}
                onClick={() => deleteComment(p.id)}>
                <span style={{ fontSize: 14, color: theme.palette.error.main }}>
                    Delete comment
                </span>
            </MenuItem>
        ]}
        anchorEl={commentAnchorEl}
        setAnchorEl={setCommentAnchorEl}
        menuHorizontalPosition="right"
        icon={<MoreHorizIcon />}
    />
}

type MentionOption = {
    id: number,
    name: string,
    color?: string,
    projectName?: string,
    taskListName?: string,
    isSubtask?: boolean,
    taskName?: string
}

function CommentMention(p: {
    options: MentionOption[], setOpen: (value: boolean) => void, commentDiv: HTMLElement | null,
    top: number, left: number, onSelect: (option: MentionOption) => void, type: string
}) {

    const autocompleteRef = useRef(null);
    const textFieldRef = useRef<string>();
    const windowListener = useRef((ev: MouseEvent) => {
        if (!(autocompleteRef.current! as HTMLElement).contains(ev.target as HTMLElement)) {
            p.setOpen(false);
        }
    })

    useEffect(() => {
        setTimeout(() => {
            window.addEventListener('click', windowListener.current)
            if (textFieldRef.current && document.getElementById(textFieldRef.current)) {
                document.getElementById(textFieldRef.current)!.focus();
            }
        }, 100);
        return () => {
            window.removeEventListener('click', windowListener.current)
        };
    }, []);

    const popperId = "popperId";
    return <Autocomplete
        ref={autocompleteRef}
        size="medium"
        style={{ minWidth: 250, position: "absolute", top: p.top, left: p.left }}
        open={true}
        openOnFocus={true}
        key={p.options.length}
        options={p.options}
        getOptionLabel={option => option.name}
        renderInput={(params) => {
            const newParams = {
                ...params,
                InputProps: {
                    ...params.InputProps,
                    style: { fontSize: 15 },
                    endAdornment: undefined,
                    startAdornment: <Search style={{ color: "var(--normalBlue)" }} />
                },
            }
            textFieldRef.current = params.id;
            return <TextField onKeyUp={ev => {
                if (ev.key === "Escape") {
                    p.setOpen(false);
                }
            }} style={{ backgroundColor: "white", width: document.getElementById(popperId)?.offsetWidth }} {...newParams} 
                placeholder={`Search for ${p.type === "tasks" ? "Tasks and Subtasks" : p.type}`} />
        }}
        renderOption={(props, option) => (
            <ConditionalWrapper condition={!!option.projectName && !!option.taskListName} wrapper={(children: JSX.Element) =>
                <Tooltip title={`${option.projectName} > ${option.taskListName}${option.isSubtask ? " > " : ""}
                    ${option.isSubtask ? option.taskName : ""}`}>{children}</Tooltip>}
            >
                <Box component="li" {...props} style={p.type === "Users" ? { minHeight: 40, margin: "8px 0" } : {
                    display: "flex", flexDirection: "column", alignItems: "flex-start"
                }} key={option.id}>
                    {option.isSubtask ? <div style={{
                        display: "flex", color: "#223250", cursor: "pointer", alignItems: "center", marginTop: 5
                    }}>
                        <SubdirectoryArrowRightIcon color="disabled" sx={{ color: "#e6e7e8" }} />
                        <div>{option.name}</div>
                    </div> : <>
                        {p.type === "Users" && <Avatar sx={{
                            bgcolor: option.color ?? "var(--normalBlue)", width: 24, height: 24, fontSize: 14
                        }}>
                            {getUserInitials(option.name)}</Avatar>}
                        <div style={p.type === "Users" ? { fontWeight: 600, marginLeft: 16 } : {}}>{option.name}</div>
                    </>}
                </Box>
            </ConditionalWrapper>
        )}
        PopperComponent={({ style, ...props }) => (
            <Popper
                {...props}
                id={popperId}
                style={{...style, minWidth: 244, width: "fit-content" }}
                placement="top"
            />
        )}
        onChange={(ev, value) => {
            p.setOpen(false);
            if (value !== null) {
                p.onSelect(value);
            }
        }} />
}
