import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import MomentUtils from '@date-io/moment'
import { StaticDatePicker } from "@mui/x-date-pickers";
import { MoreActionsComponent } from "./more-actions-component";
import TextField from "@mui/material/TextField/TextField";
import { fixDatePickerPaddings, renderWeekPickerDay } from "./custom-date-picker";
import Button from "@mui/material/Button/Button";
import moment from "moment";

export function StaticCustomDatePicker(props: {
    icon: JSX.Element, value: Date | null, setValue: (value: Date | null) => void,
    setCustomOpen: (value: boolean) => void, onApply: () => void
}) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [fieldValue, setFieldValue] = useState("");
    const windowClickHandler = useRef((ev: MouseEvent) => { });

    useEffect(() => {
        fixDatePickerPaddings(Boolean(anchorEl), windowClickHandler);
        props.setCustomOpen(Boolean(anchorEl));
    }, [Boolean(anchorEl)]);

    const textFieldOnChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFieldValue(e.target.value);
        if (moment(e.target.value, 'DD/MM/YYYY', true)) {
            props.setValue(moment(e.target.value, "DD/MM/YYYY").toDate());
        }
    }

    const datePickerOnChange = (value: Date | null) => {
        props.setValue(value);
        setFieldValue(moment(value).format("DD/MM/YYYY"));
    }

    const clearOnClick = () => {
        props.setValue(null);
        setFieldValue("");
    }

    const todayOnClick = () => {
        props.setValue(new Date());
        setFieldValue(moment(new Date()).format("DD/MM/YYYY"));
    }

    const applyOnClick = () => {
        setAnchorEl(null);
        props.onApply();
    }

    return <MoreActionsComponent id={0} anchorEl={anchorEl} setAnchorEl={setAnchorEl} menuHorizontalPosition="center"
        customHeight={445}
        menuItems={[
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <TextField placeholder="DD/MM/YYYY" value={fieldValue} onChange={textFieldOnChange}
                    style={{ width: 242, height: 56, display: "flex", justifyContent: "center" }} />
                <LocalizationProvider dateAdapter={MomentUtils} >
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        renderDay={renderWeekPickerDay}
                        value={props.value}
                        onChange={datePickerOnChange}
                        renderInput={(params) => <></>}
                    />
                </LocalizationProvider >
                <div style={{ display: "flex", justifyContent: "space-between", width: "82%", marginTop: -10, marginBottom: 10 }}>
                    <span style={{ cursor: "pointer", color: "#217DA2", fontWeight: "600", zIndex: 1 }} onClick={clearOnClick}>Clear</span>
                    <span style={{ cursor: "pointer", color: "#217DA2", fontWeight: "600", zIndex: 1 }} onClick={todayOnClick}>Today</span>
                </div>
                <Button disabled={!props.value} color="secondary" variant="contained" style={{
                    marginBottom: 10
                }} onClick={applyOnClick}>APPLY
                </Button>
            </div>
        ]}
        anchorOriginHorizontal="center"
        paperWidth={256}
        icon={props.icon} />
}
