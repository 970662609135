import { AppBar, Toolbar, Typography, Button, useTheme } from "@mui/material";
import { useEffect } from "react";

export function DeletionSnackBar(p: {
    onClickUndoDeletion: () => void, onClickDismissDeletion: () => void, type: "Task" | "Subtask" | "ProjectArchive",
    deletedTasksCount?: number, multipleArchivedProjects?: boolean
}) {
    const theme = useTheme();

    useEffect(() => {
        if (p.type !== "ProjectArchive") return;
        const timer = setTimeout(() => {
            p.onClickDismissDeletion();
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    return <AppBar position="sticky"
        style={{
            marginTop: 1, marginLeft: 32, width: "calc(100vw - 256px - 64px)",
            backgroundColor: "white",
        }}>
        <Toolbar style={{ minHeight: 55 }}>
            {p.type === "ProjectArchive" ?
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: theme.palette.primary.main, fontSize: 15 }}>
                    {`Project${p.multipleArchivedProjects ? "s" : ""} archived successfully.`}
                </Typography>                
                    :
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: theme.palette.primary.main, fontSize: 15 }}>
                {(p.deletedTasksCount && p.deletedTasksCount > 1) ? `${p.deletedTasksCount} tasks` : p.type} deleted successfully.
            </Typography>}
            <Button color="secondary" onClick={p.onClickUndoDeletion}>UNDO</Button>
            <Button color="secondary" onClick={p.onClickDismissDeletion}>DISMISS</Button>
        </Toolbar>
    </AppBar>
}
