import React, { useState } from 'react';

const LoadTransformsContext = React.createContext<any>({'risk': () => {}, 'category': () => {}, 'report': () => {}});

export default LoadTransformsContext;

export function LoadTransformsContextProvider(props: { children: JSX.Element }) {
    const [loadTransformCallbacks, setLoadTransformCallbacks] = useState();
    return <LoadTransformsContext.Provider value={{ loadTransformCallbacks, setLoadTransformCallbacks }}>{props.children}</LoadTransformsContext.Provider>
}
