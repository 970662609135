import React, { useEffect, useState } from "react";
import { Wizard } from "../../../components/wizard";
import { TextField } from "@mui/material";
import { WizardData } from "../../../models/WizardData";
import { ChildContext } from "../../../components/wizard";
import { Template } from "../../../models/Template";
import { putApiTemplatesById } from "../../../services/template";
import { BaseTemplate } from "../../../models/BaseTemplate";
import { useNavigate } from "react-router-dom";
import { showSnackbar } from "../../../redux/snackbar";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hook";
import ErrorIcon from '@mui/icons-material/Error';
import { projectTemplatesSelector } from "../../../redux/projectTemplates";

export function ProjectTemplateRenameWizard(props: { template: Template }) {

    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [projectTemplate, setProjectTemplate] = useState<WizardData>({ ...props.template, onSubmit: () => { } });
    const [error, setError] = useState({
        empty: false,
        alreadyExists: false
    });
    const [submitted, setSubmitted] = useState(false);
    const templates = useAppSelector((state) => projectTemplatesSelector(state));

    const emptyError = projectTemplate.name.trim() === "";
    const alreadyExistsError = templates.some(template =>
        template.name.toLocaleLowerCase().trim() === projectTemplate.name.trim().toLocaleLowerCase());

    useEffect(() => {
        setProjectTemplate({
            ...projectTemplate, onSubmit: () => {
                setSubmitted(true);
                if (emptyError || alreadyExistsError) {
                    setError({ empty: emptyError, alreadyExists: alreadyExistsError });
                    return true;
                } else {
                    setError({ alreadyExists: false, empty: false });
                }
                const controller = new AbortController();
                const baseTemplate: BaseTemplate = {
                    name: projectTemplate.name,
                    color: props.template.color
                };
                putApiTemplatesById(props.template.id, baseTemplate, controller.signal)
                    .then(() => navigate('/app/user/workflow/project-templates'))
                    .then(() => dispatch(showSnackbar({
                        message: "Project template renamed successfully.",
                        type: "info"
                    })))
                    .catch(() => dispatch(showSnackbar({
                        message: "Error renaming the template!",
                        type: "error"
                    })));
            }
        });
    }, [projectTemplate.name]);

    useEffect(() => {
        if (submitted) {
            setError({ empty: emptyError, alreadyExists: alreadyExistsError });
        }
    }, [projectTemplate.name])

    const wizardArray = [<ProjectTemplateRename key={0} projectTemplate={projectTemplate} error={error}
        setProjectTemplate={setProjectTemplate} />];
    return <div className="wizard-dialog" >
        <Wizard childrenData={projectTemplate}>
            {wizardArray}
        </Wizard>
    </div>
}

function ProjectTemplateRename(props: {
    projectTemplate: WizardData, setProjectTemplate: (p: WizardData) => void,
    error: { empty: boolean, alreadyExists: boolean }
}) {

    const setValue = React.useContext(ChildContext);

    useEffect(() => {
        setValue({ title: "Rename Project Template", canLeaveStep: () => true });
    }, [])

    return <div className="wizard-content">
        <br />
        <label className="wizard-content-label ">Enter a Project Template Name</label>
        <TextField
            value={props.projectTemplate.name}
            error={props.error.alreadyExists || props.error.empty}
            helperText={(props.error.alreadyExists || props.error.empty) && <span style={{ marginLeft: -14 }}>
                <ErrorIcon sx={{ width: 12, height: 12 }} />
                <span style={{ marginLeft: 5, verticalAlign: "text-bottom" }}>{props.error.empty ? "Template name is required." :
                    "Template name already exists."}</span>
            </span>}
            onChange={e => props.setProjectTemplate({ ...props.projectTemplate, name: e.currentTarget.value })}
            placeholder='Project template name'
        />
    </div>
}
