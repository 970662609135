import React, { useEffect, useState } from "react";
import './admin-wizard.scss';
import { ChildContext, Wizard } from "../../../components/wizard";
import { TextField, useTheme } from "@mui/material";
import { useAdminService } from "../../../contexts/super-admin-context";
import { colorPalette } from "../../../constants";

export interface AccountWizard {
    name: string;
    selectedColor?: string,
    onSubmit: () => void
}

export function AddAccountWizard(props: { creatingAccount: (value: boolean) => void }) {
    const { addAccount } = useAdminService();
    const onSubmit = async () => {
        props.creatingAccount(true);
        await addAccount(currentAccount.name, currentAccount.selectedColor!);
        props.creatingAccount(false);
    }

    const [currentAccount, setCurrentAccount] = useState<AccountWizard>({
        name: "",
        selectedColor: "",
        onSubmit: () => { }
    })

    useEffect(() => {
        setCurrentAccount({ ...currentAccount, onSubmit: onSubmit })
    }, [currentAccount.name, currentAccount.selectedColor])

    return <div className="wizard-dialog" >
        <Wizard childrenData={currentAccount}>
            <AddAccount account={currentAccount} setAccount={setCurrentAccount} />
            <AccountColor account={currentAccount} setAccount={setCurrentAccount} />
        </Wizard>
    </div>
}

function AddAccount(props: { account: AccountWizard, setAccount: (p: AccountWizard) => void }) {
    const setValue = React.useContext(ChildContext);

    useEffect(() => {
        setValue({ title: "Add Account", canLeaveStep: () => true });
    }, [])

    return <div className="wizard-content">
        <br />
        <label className="wizard-content-label ">Enter a Account name</label>
        <TextField
            value={props.account.name} onChange={e => props.setAccount({ ...props.account, name: e.currentTarget.value })}
            placeholder='Account name'
        />
    </div>
}

export function AccountColor(props: { account: AccountWizard, setAccount: (p: AccountWizard) => void, title?: string }) {
    const [account, setAccount] = useState({ selectedColor: "" });
    const theme = useTheme();
    const setValue = React.useContext(ChildContext);

    useEffect(() => {
        setValue({ title: props.title || "Choose a color for the Account", canLeaveStep: () => true });
    }, [])

    return <div className="wizard-color-align">
        <label style={{ display: "flex" }} >
            <div className="wizard-color-profile"
                 style={{ backgroundColor: props.account.selectedColor ? props.account.selectedColor : colorPalette[0].color }}>
                {props.account.name.length === 2 ? props.account.name : props.account.name.charAt(0).toUpperCase()}
            </div>
        </label>
        <div className="wizard-color-wrap">
            {colorPalette.map(o =>
                <label key={o.color} className={"wizard-color-circle" + (account.selectedColor == o.color ? "selected" : "")} onClick={() => {
                    props.setAccount({ ...props.account, selectedColor: o.color });
                }} style={{ backgroundColor: o.color, border: props.account.selectedColor === o.color ? `2px solid ${theme.palette.text.primary}` : "" }} ></label>)
            }
        </div>
    </div >
}


