import "../pages.scss";
import "./support-feedback.scss";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Chip, Paper } from "@mui/material";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import React, { useState } from "react";
import { ReportIssueForm } from "./report-issue-form";

export function SupportFeedback() {

    let navigate = useNavigate();
    const [reportIssue, setReportIssue] = useState<string>();
    const [submitted, setSubmitted] = useState(false);

    const submitForm = () => setSubmitted(true);

    const navigateBack = () => {
        if (reportIssue === undefined) {
            navigate(-1)
        } else {
            setReportIssue(undefined);
            setSubmitted(false);
        }
    }

    return <div className="page-wrapper" style={{ paddingTop: 24 }}>
        <div className='page-header'>
            <ArrowBack className='arrow-back'
                       onClick={navigateBack} />
            {reportIssue === undefined ? "Support and feedback" : "Report an issue"}
        </div>
        {submitted ? <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{fontSize: 25, letterSpacing: 0}}>
                    Your request was successfully submitted.
                </div>
                <div>
                    Our Technical Support team will begin troubleshooting your <br/>
                    issue to resolve it and they will get back to you as soon as <br/>possible.<br/>
                    You will get an email on your registered email address with a <br/>possible solution.
                </div>
            </div> :
            reportIssue === undefined ? <>
                    <span>How can we help you today?</span>
                    <div style={{display: "flex", marginTop: 24}}>
                        <Paper elevation={3} className="support-box" style={{cursor: "pointer"}}
                               onClick={() => setReportIssue("Workflow")}>
                            <div className="help-icon-wrapper">
                                <LiveHelpIcon className="help-icon"/>
                            </div>
                            <span className="support-title">Get support</span>
                            <span style={{marginLeft: 16, fontSize: 15, letterSpacing: 0.24}}>
                                Report an issue or a bug you encountered with any of our products and features to get the help you need.
                            </span>
                            <span style={{color: "#217DA2", marginLeft: 16, marginTop: 52, fontSize: 15, fontWeight: 600}}>
                                REPORT AN ISSUE
                            </span>
                        </Paper>
                        <Paper elevation={3} className="support-box">
                            <span className="contact-title">Contact us</span>
                            <span className="contact-text">
                                Have a question or feedback? We’d love to hear from you. Connect with us through the following ways:
                            </span>
                            <div className="contact-wrapper">
                                <div style={{display: "flex", marginBottom: 16}}>
                                    <span className="contact-left-column">Email:</span>
                                    <span className="contact-details-text"
                                          style={{color: "#217DA2", textDecoration: "underline"}}>
                                        supportweb@rlsciences.com
                                    </span>
                                </div>
                                <div style={{display: "flex"}}>
                                    <span className="contact-left-column">Address:</span>
                                    <span className="contact-details-text">
                                        565 E. Swedesford Road <br/>Suite 205 <br/>Wayne, PA 19087
                                    </span>
                                </div>
                            </div>
                        </Paper>
                    </div>
                    <span className="documentation-title">Documentation</span>
                    <div style={{display: "flex", marginTop: 16}}>
                        <Paper elevation={3} className="documentation-box">
                            <div className="doc-wrapper">
                                <MenuBookIcon className="book-icon"/>
                            </div>
                            <div className="doc-title">RLS Protect Docs <br/>User guide</div>
                            <div className="doc-text">
                                Download the user guide to find everything you need to know about using RLS Protect Docs.
                            </div>
                            <div className="guide-link">DOWNLOAD GUIDE</div>
                        </Paper>
                        <Paper elevation={3} className="documentation-box">
                            <div className="doc-wrapper">
                                <MenuBookIcon className="book-icon"/>
                            </div>
                            <div className="doc-title">RLS Protect Risk <br/>User guide</div>
                            <div className="doc-text">
                                Download the user guide to find everything you need to know about using RLS Protect Risk.
                            </div>
                            <div className="guide-link">DOWNLOAD GUIDE</div>
                        </Paper>
                    </div>
                </>
                :
                <>
                <span className="contact-details-text">
                    We are requesting the following information to ensure that we can <br/>
                    rapidly address and resolve any issues you’re having. <br/>
                    We strongly advise you to include videos and images of the problem <br/>
                    you’re having in order to get the problem fixed as quickly as possible.
                </span>
                    <div style={{marginTop: 24, display: "flex", flexDirection: "column"}}>
                        <span className="report-issue-title">Select the product or feature</span>
                        <div style={{display: "flex", marginLeft: 32, marginTop: 8}}>
                            <Chip label="Project Managemenet"
                                  className={reportIssue === "Workflow" ? "report-issue-chip-selected" : "report-issue-chip"}
                                  variant={reportIssue === "Workflow" ? "filled" : "outlined"}
                                  onClick={() => setReportIssue("Workflow")}
                            />
                            <Chip label="Protect Docs"
                                  className={reportIssue === "Protect Docs" ? "report-issue-chip-selected" : "report-issue-chip"}
                                  variant={reportIssue === "Protect Docs" ? "filled" : "outlined"}
                                  onClick={() => setReportIssue("Protect Docs")}
                            />
                            <Chip label="Protect Risk"
                                  className={reportIssue === "Protect Risk" ? "report-issue-chip-selected" : "report-issue-chip"}
                                  variant={reportIssue === "Protect Risk" ? "filled" : "outlined"}
                                  onClick={() => setReportIssue("Protect Risk")}
                            />
                        </div>
                        <ReportIssueForm product={reportIssue} submitForm={submitForm} />
                    </div>
                </>
        }
    </div>
}
