import { InfoTenantUser } from "../models/InfoTenantUser";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const defaultUsers: InfoTenantUser[] = [];

const usersInitialState = {
    users: defaultUsers
}

const users = createSlice({
    name: 'users',
    initialState: usersInitialState,
    reducers: {
        loadUsers: (state, action) => {
            state.users = action.payload;
        }
    }
})

export const { loadUsers } = users.actions
export const usersSelector = (state: RootState, filterView: boolean) =>
    filterView ? state.users.users.filter(user => !isViewOnly(user.roles)) : state.users.users
export const filteredUsersSelector = (state: RootState, availableUsersId: number[]) =>
    state.users.users.filter(user => !isViewOnly(user.roles) && availableUsersId.includes(user.id))

function isViewOnly(roles: string[] | undefined) {
    if (roles && roles.length===1 && roles[0]==="VIEW") {
        return true
    }
    return false
}

export default users.reducer

