import { FormControl, InputLabel, Select, MenuItem, Checkbox, Button } from "@mui/material";
import { useState } from "react";
import { Row } from "read-excel-file";
import { StyledBackArrow, StyledCloseIcon } from "../../../components/close-button";
import { CustomFieldKeyTypes } from "../../../components/project/task";
import { useEventHandler } from "../../../hooks/handle-events";
import { useAppSelector, useAppDispatch } from "../../../hooks/redux-hook";
import { CustomField } from "../../../models/CustomField";
import { CustomFieldValue } from "../../../models/CustomFieldValue";
import { Task } from "../../../models/Task";
import { TaskCustomFieldValues } from "../../../models/TaskCustomFieldValues";
import { customFieldsSelector, loadCustomFieldsValues } from "../../../redux/custom-fields";
import { projectSelector, loadProject } from "../../../redux/projects";
import { showSnackbar } from "../../../redux/snackbar";
import { getApiCustomFieldsValuesTaskListById } from "../../../services/custom.fields";
import { putApiProjectsProjectCustomFieldsById } from "../../../services/project";
import { postApiTasksBulkCreateCfv } from "../../../services/task";
import { useCustomModal } from "../custom-message-modal";

interface CustomFieldImport {
    column: number;
    id: number;
    shortName: string;
}

interface ImportCustomFieldsData {
    numberOfPages: CustomFieldImport;
    numberOfPagesInScope: CustomFieldImport;
    batch: CustomFieldImport;
    typeOfReport: CustomFieldImport;
    tasksColumn: number;
    includeHeader: boolean;
}

export function ImportCustomFieldsExcel(props: {
    cells: Row[], taskListId: number, taskListName: string, tasks: Task[],
    refreshList: () => void, projectId: number, type: "TASKS" | "SUBTASKS"
}) {
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const [importExcelData, setImportExcelData] = useState<ImportCustomFieldsData>({
        includeHeader: false,
        tasksColumn: -1,
        batch: { column: -1, id: -1, shortName: "" },
        numberOfPages: { column: -1, id: -1, shortName: "" },
        numberOfPagesInScope: { column: -1, id: -1, shortName: "" },
        typeOfReport: { column: -1, id: -1, shortName: "" },
    });
    const firstRow = props.cells[0];
    const alphabetArray = "abcdefghijklmnopqrstuvwxyz".split("").map(letter => letter.toUpperCase());
    const columns = alphabetArray.filter((letter, index) => firstRow.length > index);
    const tasksColumn = columns.filter((c, i) => i !== importExcelData?.numberOfPagesInScope.column
        && i !== importExcelData?.batch.column && i !== importExcelData?.typeOfReport.column
        && i !== importExcelData.numberOfPages.column);
    const numberOfPagesColumn = columns.filter((c, i) => i !== importExcelData?.numberOfPagesInScope.column
        && i !== importExcelData?.batch.column && i !== importExcelData?.typeOfReport.column
        && i !== importExcelData.tasksColumn);
    const numberOfPagesInScopeColumn = columns.filter((c, i) => i !== importExcelData?.numberOfPages.column
        && i !== importExcelData?.batch.column && i !== importExcelData?.typeOfReport.column
        && i !== importExcelData.tasksColumn);
    const batchColumn = columns.filter((c, i) => i !== importExcelData?.numberOfPages.column
        && i !== importExcelData.tasksColumn
        && i !== importExcelData?.numberOfPagesInScope.column && i !== importExcelData?.typeOfReport.column);
    const typeOfReportColumn = columns.filter((c, i) => i !== importExcelData?.numberOfPages.column
        && i !== importExcelData?.numberOfPagesInScope.column && i !== importExcelData?.batch.column
        && i !== importExcelData.tasksColumn);
    const customFields: CustomField[] = useAppSelector((state) => customFieldsSelector(state));
    const dispatch = useAppDispatch();
    const { hideModal } = useCustomModal();
    const project = useAppSelector((state) => projectSelector(state, props.projectId));

    const importExcel = () => {
        const tasksWithCustomFields: TaskCustomFieldValues[] = [];
        if (!importExcelData?.includeHeader) {
            props.cells.splice(0, 1);
        }
        props.cells.filter(row => row[importExcelData.tasksColumn] !== null && row[importExcelData.tasksColumn] !== undefined)
            .forEach((row, idx) => {
                const importedCfv: CustomFieldValue[] = [];
                const tasksFound = props.tasks?.filter(ts => ts.name.toLocaleLowerCase() === row[importExcelData.tasksColumn].toString()
                    .toLocaleLowerCase());
                tasksFound?.forEach(taskFound => {
                    const isTaskCustomFieldAlreadySet = tasksWithCustomFields.find(twcf => twcf.task?.id === taskFound?.id);
                    if (!taskFound || isTaskCustomFieldAlreadySet) return;
                    if (row[importExcelData.batch.column] !== null && row[importExcelData?.batch.column] !== undefined) {
                        importedCfv.push({
                            customFieldId: importExcelData.batch.id, id: -1, taskId: taskFound.id,
                            valueString: row[importExcelData.batch.column].toString()
                        });
                    }
                    if (row[importExcelData.numberOfPages.column] !== null && row[importExcelData?.numberOfPages.column] !== undefined) {
                        importedCfv.push({
                            customFieldId: importExcelData.numberOfPages.id, id: -1, taskId: taskFound.id,
                            valueString: row[importExcelData.numberOfPages.column].toString()
                        });
                    }
                    if (row[importExcelData.numberOfPagesInScope.column] !== null && row[importExcelData?.numberOfPagesInScope.column]
                        !== undefined) {
                        importedCfv.push({
                            customFieldId: importExcelData.numberOfPagesInScope.id, id: -1, taskId: taskFound.id,
                            valueString: row[importExcelData.numberOfPagesInScope.column].toString()
                        });
                    }
                    if (row[importExcelData.typeOfReport.column] !== null && row[importExcelData?.typeOfReport.column] !== undefined) {
                        importedCfv.push({
                            customFieldId: importExcelData.typeOfReport.id, id: -1, taskId: taskFound.id,
                            valueString: row[importExcelData.typeOfReport.column].toString()
                        });
                    }
                    tasksWithCustomFields.push({
                        task: taskFound, customFieldValues: importedCfv
                    })
                })

            });
        const projectCustomFields = project.projectCustomFields || {}
        const shortNames = [importExcelData.batch.shortName, importExcelData.numberOfPages.shortName,
        importExcelData.numberOfPagesInScope.shortName, importExcelData.typeOfReport.shortName
        ];
        (async function putImportedCustomFieldsTypesToProjects() {
            for (let sn of shortNames) {
                const shortName = sn as CustomFieldKeyTypes;
                if (!projectCustomFields[shortName]) {
                    const updateProjectCustomFields = {
                        ...projectCustomFields,
                        [shortName! as CustomFieldKeyTypes]: true
                    }
                    await putApiProjectsProjectCustomFieldsById(project.id, updateProjectCustomFields)
                        .then(() => {
                            dispatch(loadProject({ ...project, projectCustomFields: updateProjectCustomFields }));
                        })
                }
            }
        }());
        postApiTasksBulkCreateCfv(tasksWithCustomFields).then(() => props.refreshList()).then(() => {
            getApiCustomFieldsValuesTaskListById(props.taskListId!).
                then(cfv => dispatch(loadCustomFieldsValues({
                    taskListId: props.taskListId,
                    customFieldsValues: cfv
                })))
        })
            .then(() => dispatch(showSnackbar({
                message: `Custom field values imported successfully for ${tasksWithCustomFields.length} tasks in ${props.taskListName}.`,
                type: "info"
            }))).catch(() => dispatch(showSnackbar({
                message: "Excel file couldn't be imported. Please check the file you want to import and try again.",
                type: "info"
            })));
        hideModal();
    }

    return <div style={{
        width: 600, height: 779, display: "flex",
        flexDirection: "column", justifyContent: "center"
    }}>
        <div style={{ display: "flex", flexDirection: "column", marginRight: 45, marginLeft: 45 }}>
            <StyledBackArrow onClick={() => hideModal()} />
            <StyledCloseIcon onClick={() => hideModal()} />
            <div style={{ fontSize: 25, textAlign: "center", marginTop: 20 }}>
                Select the columns to import their values into the relating custom fields
            </div>
            <div style={{ border: "1px solid #C13826", padding: 16, backgroundColor: "#FFEFD0", marginTop: 12 }}>
                <span style={{ fontSize: 15, fontWeight: "600" }}>
                    Importing custom field values from an Excel file will work if the {props.type === "TASKS" ?
                        "tasks" : "subtasks"} names in the list match the
                    tasks names in the Excel file.
                </span>
            </div>
            <label style={{ fontSize: 17, letterSpacing: 0.49, marginTop: 16 }}>Select the column that contains the
                {props.type === "TASKS" ? " task" : " subtask"} names in the Excel file:</label>
            <div style={{
                display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: 17,
                letterSpacing: 0.49, marginTop: 8
            }}>
                <span style={{ marginTop: 10, marginBottom: 10 }}>{props.type === "TASKS" ? "Tasks" : "Subtasks"} names</span>
                <FormControl size="small" style={{ marginTop: 2, width: "50%" }}>
                    <InputLabel style={{ color: "#223250" }} id="select-column-label">Select Column</InputLabel>
                    <Select
                        value={tasksColumn[importExcelData.tasksColumn]}
                        disableUnderline
                        size="small"
                        color="secondary"
                        variant="outlined"
                        labelId="select-column-label"
                        label="Select Column"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={{ style: { height: 250 } }}
                        onChange={(e) => {
                            const index = columns.indexOf(e.target.value as string);
                            setImportExcelData({ ...importExcelData, tasksColumn: index })
                        }}
                    >
                        {tasksColumn.map(letter => <MenuItem
                            value={letter}
                            style={{ backgroundColor: "white" }}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>{letter}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <label className="wizard-content-label" style={{ marginTop: 12, fontSize: 17 }}>Select which columns to import the values
                for each of the custom fields listed below:</label>
            <div style={{
                display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: 17,
                letterSpacing: 0.49, marginBottom: 10
            }}>
                <span>Number of pages</span>
                <FormControl size="small" style={{ marginTop: 2, width: "50%" }}>
                    <InputLabel style={{ color: "#223250" }} id="select-column-label">Select Column</InputLabel>
                    <Select
                        value={numberOfPagesColumn[importExcelData?.numberOfPages.column!]}
                        disableUnderline
                        size="small"
                        color="secondary"
                        variant="outlined"
                        labelId="select-column-label"
                        label="Select Column"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={{ style: { height: 250 } }}
                        onChange={(e) => {
                            const index = columns.indexOf(e.target.value as string);
                            const cf = customFields.find(cf => cf.shortName === "nrPages");
                            setImportExcelData({
                                ...importExcelData!, numberOfPages: {
                                    ...importExcelData?.numberOfPages!,
                                    column: index, id: cf?.id!, shortName: cf?.shortName!
                                }
                            });
                        }}
                    >
                        {numberOfPagesColumn.map(letter => <MenuItem
                            value={letter}
                            style={{ backgroundColor: "white" }}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>{letter}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div style={{
                display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: 17,
                letterSpacing: 0.49, marginBottom: 16
            }}>
                <span>Number of in scope pages</span>
                <FormControl size="small" style={{ marginTop: 2, width: "50%" }}>
                    <InputLabel style={{ color: "#223250" }} id="select-column-label">Select Column</InputLabel>
                    <Select
                        value={numberOfPagesInScopeColumn[importExcelData?.numberOfPagesInScope.column!]}
                        disableUnderline
                        size="small"
                        color="secondary"
                        variant="outlined"
                        labelId="select-column-label"
                        label="Select Column"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={{ style: { height: 250 } }}
                        onChange={(e) => {
                            const index = columns.indexOf(e.target.value as string);
                            const cf = customFields.find(cf => cf.shortName === "pagesInScope");
                            setImportExcelData({
                                ...importExcelData!, numberOfPagesInScope:
                                    { ...importExcelData?.numberOfPagesInScope!, column: index, id: cf?.id!, shortName: cf?.shortName! }
                            });
                        }}
                    >
                        {numberOfPagesInScopeColumn.map(letter => <MenuItem
                            value={letter}
                            style={{ backgroundColor: "white" }}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>{letter}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div style={{
                display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: 17,
                letterSpacing: 0.49, marginBottom: 16
            }}>
                <span>Batch</span>
                <FormControl size="small" style={{ marginTop: 2, width: "50%" }}>
                    <InputLabel style={{ color: "#223250" }} id="select-column-label">Select Column</InputLabel>
                    <Select
                        value={batchColumn[importExcelData?.batch.column!]}
                        disableUnderline
                        size="small"
                        color="secondary"
                        variant="outlined"
                        labelId="select-column-label"
                        label="Select Column"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={{ style: { height: 250 } }}
                        onChange={(e) => {
                            const index = columns.indexOf(e.target.value as string);
                            const cf = customFields.find(cf => cf.shortName === "batch");
                            setImportExcelData({
                                ...importExcelData!, batch: {
                                    ...importExcelData?.batch!, column: index, id: cf?.id!, shortName: cf?.shortName!
                                }
                            });
                        }}
                    >
                        {batchColumn.map(letter => <MenuItem
                            value={letter}
                            style={{ backgroundColor: "white" }}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>{letter}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div style={{
                display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: 17,
                letterSpacing: 0.49, marginBottom: 16
            }}>
                <span>Type of report</span>
                <FormControl size="small" style={{ marginTop: 2, width: "50%" }}>
                    <InputLabel style={{ color: "#223250" }} id="select-column-label">Select Column</InputLabel>
                    <Select
                        value={typeOfReportColumn[importExcelData?.typeOfReport.column!]}
                        disableUnderline
                        size="small"
                        color="secondary"
                        variant="outlined"
                        labelId="select-column-label"
                        label="Select Column"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={{ style: { height: 250 } }}
                        onChange={(e) => {
                            const index = columns.indexOf(e.target.value as string);
                            const cf = customFields.find(cf => cf.shortName === "typeOfReport");
                            setImportExcelData({
                                ...importExcelData!, typeOfReport: {
                                    ...importExcelData?.typeOfReport!,
                                    column: index, id: cf?.id!, shortName: cf?.shortName!
                                }
                            });
                        }}
                    >
                        {typeOfReportColumn.map(letter => <MenuItem
                            value={letter}
                            style={{ backgroundColor: "white" }}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>{letter}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div>
                <Checkbox color='secondary'
                    style={{ padding: 0 }}
                    checked={importExcelData?.includeHeader}
                    onChange={() => setImportExcelData({ ...importExcelData!, includeHeader: !importExcelData?.includeHeader! })}
                />
                <span style={{ fontSize: 14, alignSelf: "center", marginLeft: 10 }}>
                    Include column header (first row of the table)
                </span>
            </div>
            <Button disabled={importExcelData.tasksColumn === -1} onClick={() => importExcel()}
                color="secondary" variant="contained" style={{ width: "fit-content", alignSelf: "center", marginTop: 24 }}>
                IMPORT EXCEL FILE
            </Button>
        </div>
    </div>
}
