import {extendedLetterFinderPattern} from "./constants";
import {hasJapaneseCharacters} from "../single-pattern";

export function TextInput(props: {text: string, useExtendedAlphabet: boolean, onChange: (text: string, useExtendedAlphabet: boolean) => void}) {

    const onTextChange = (newText: string) => {
        let useExtendedAlphabet = false;
        if (extendedLetterFinderPattern.test(newText)) {
            useExtendedAlphabet = true
        }
        props.onChange(newText, useExtendedAlphabet)
    }

    const onCheckboxChange = (checked: boolean) => {
        props.onChange(props.text, checked);
    }

    return (
        <div className="row no-gutters bg-light mt-3 pr-4 rounded position-relative" id="rg_input_container">
            <div className="col-12 col-md-11 py-4 pl-4">
                <div className="d-flex justify-content-between">
                    <h5>Paste a text sample.</h5>
                </div>
                <input
                    type="text"
                    maxLength={1000}
                    id="rg_raw_input_text"
                    className="form-control rg-code"
                    aria-label="Example text"
                    aria-describedby="rg_raw_text_input_help"
                    defaultValue={props.text}
                    onChange={(e) => onTextChange(e.target.value)}
                />
                <small id="rg_raw_text_input_help" className="form-text text-muted">
                    Give us an example of the text you want to match using your pattern.
                    We will provide you with some ideas on how to build a regular expression.
                </small>
                <div className="form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="useExtendedAlphabet"
                        onChange={(e) => onCheckboxChange(e.target.checked)}
                        checked={props.useExtendedAlphabet}
                    />
                    <label className="form-check-label" htmlFor="useExtendedAlphabet">
                        Use extended alphabet
                    </label>
                </div>
                {hasJapaneseCharacters(props.text) && <small id="rg_raw_text_input_help" className="form-text text-muted">
                    Your text may be in a writing system that does not use spaces between words. In order to use this tool,
                    you may need to add spaces so that the text can be split into chunks. In the next step, you can mark
                    the spaces as optional or remove them be selecting "nothing"
                </small>}
            </div>
        </div>
    );

}
