import { useState } from "react";
import baseline_close from "../../../provisional_icons/baseline-close.png";
import Button from "@mui/material/Button";
import { useCustomModal } from "../custom-message-modal";
import { ButtonSpinner } from "../../../components/button-spinner";
import { CustomFieldProjectDetails } from "../../user/projects/project-details";
import { Project } from "../../../models/Project";
import { putApiProjectsProjectDetailsById } from "../../../services/project";
import { ProjectDetails } from "../../../models/ProjectDetails";

export function RemoveCustomFieldProjectDetailsModal(props: {
    customField: CustomFieldProjectDetails, fetchProject: () => void,
    project: Project
}) {
    const { hideModal } = useCustomModal();
    const [loading, setLoading] = useState(false);

    const onSubmit = () => {
        setLoading(true);
        const oldPd = JSON.parse(JSON.stringify(props.project.projectDetails) ?? {});
        const newPd = { ...oldPd };
        delete newPd[props.customField.name];
        putApiProjectsProjectDetailsById(props.project.id, newPd as ProjectDetails).then(() => {
            props.fetchProject();
            hideModal();
        })
            .catch(e => {
                setLoading(false);
            })
    }

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img alt="close" src={baseline_close} className="remove-entity-close-button" onClick={() => loading ? {} : hideModal()} />
            <span className="remove-entity-title">
                Delete custom field {props.customField.name}?
            </span>
            <p className="remove-entity-disclaimer" >
                This custom field will be deleted from the project details along with its value.
            </p>
            <div className="remove-entity-button-list" style={{ marginTop: 20 }} >
                <Button color="secondary" variant="outlined" onClick={hideModal} disabled={loading}>CANCEL</Button>
                <Button disabled={loading} onClick={onSubmit} color="secondary" variant="contained">
                    DELETE CUSTOM FIELD {loading && <ButtonSpinner />}
                </Button>
            </div>
        </div>
    </div>
}
