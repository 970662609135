import moment from "moment-timezone";
import { AuthResponse } from "../models/AuthResponse";
import { UserSettings } from "../models/UserSettings";
import { TenantUser } from "../models/TenantUser";
import { defaultUserSettings } from "../redux/userSettings";
import { baseUrl, headers } from "./config";
import { RefreshTokenRequest } from "../models/RefreshTokenRequest";
import { Project } from "../models/Project";
import { Template } from "../models/Template";
import { SubTask } from "../models/SubTask";
import { Task } from "../models/Task";
import { InternalUser } from "../models/InternalUser";

export function addQueryParameter(url: URL, name: string, value: null | undefined | string | number | boolean | number[] | string[] | boolean[]) {
    if (value == null) return;
    if (Array.isArray(value)) {
        for (let o of value) {
            url.searchParams.append(name, o?.toString());
        }
    } else {
        url.searchParams.append(name, value.toString());
    }
}

function jsonHasMessage<T>(obj: T): obj is T & { message: string } {
    return "message" in obj
}

export async function fetchAndParse<T>(input: RequestInfo, init?: RequestInit, expectText?: boolean): Promise<T> {
    let response = await fetch(input, init).catch(err => console.log(err));
    //TODO we need to figure out why sometimes we get 401 before the token is expired
    if (response === undefined || response!.status === 401) {
        if (init?.body && JSON.parse(init?.body as string).refreshToken) {
            console.log("We got 401 for a refresh token request")
            localStorage.removeItem("userPayload");
            window.location.href = '/login';
        }
        const url = new URL(`/api/users/login`, baseUrl);
        const userPayload = await localStorage.getItem("userPayload");
        let refreshToken;
        let email;
        try {
            const authInfo = JSON.parse(userPayload!) as AuthResponse;
            refreshToken = authInfo.cognito?.RefreshToken;
            email = authInfo.user?.email;
        } catch(e) {
             localStorage.removeItem("userPayload");
             window.location.href = '/login';
        }
        if (refreshToken && email) {
            const refreshTokenRequest: RefreshTokenRequest = { refreshToken, email };
            console.log("trying to refresh the token");
            const authResponse = await fetch(url.toString(), { method: "POST", body: JSON.stringify(refreshTokenRequest), headers })
                .catch(() => {
                     localStorage.removeItem("userPayload");
                     window.location.href = '/login';
                });
            if (authResponse && authResponse.status === 200) {
                try {
                    const authResponseObject: AuthResponse = JSON.parse(await authResponse.text());
                    headers['Authorization'] = `Bearer ${authResponseObject.cognito?.AccessToken}`;
                    localStorage.setItem("userPayload", JSON.stringify(
                        {
                            ...authResponseObject,
                            cognito: { ...authResponseObject.cognito, RefreshToken: refreshToken }
                        }
                    ));
                    response = await fetch(input, init).catch(err => console.log(err));
                } catch (e) {
                    localStorage.removeItem("userPayload");
                    window.location.href = '/login';
                }
            }
        } else {
            localStorage.removeItem("userPayload");
            window.location.href = '/login';
        }
    }
    let json: T | null = null;
    const text: string = response !== undefined ? await response.text() : 'error fetching';

    //In some cases (like sanitizing files) the response will be an encoded string and we don't want to parse it as JSON.
    if (expectText) {
        return text as unknown as T;
    }

    try {
        json = JSON.parse(text);
    } catch { }
    if (response && response?.status >= 300) {
        const message = json !== null ? jsonHasMessage(json) ? json.message : text : text;
        throw {
            url: input,
            body: init?.body,
            status: response!.status,
            statusText: response!.statusText,
            message: message,
            json,
            toLog() {
                return {
                    url: this.url,
                    body: this.body,
                    json: this.json,
                    status: this.status,
                    statusText: this.statusText
                };
            },
            toString() {
                return `${this.status} - ${this.message || this.statusText}`;
            }
        };
    }
    return json as T;
}

export const getUserInitials = (name: string | undefined) => name == undefined ? "NA" : name.split(" ")
    .map((n, i, a) => i === 0 || i === a.length - 1 ? n[0] : "").join("").toUpperCase();

export const dbToUserSettings = (userInfo: AuthResponse) => {
    const tenantUser = userInfo.tenant?.user;
    const [startTime, startTimeAmPm] = tenantUser?.emailNotificationsStartTime !== null &&
    tenantUser?.emailNotificationsStartTime !== "" ?
        convert24To12(convertTimeFromUTCToTimezone(tenantUser?.emailNotificationsStartTime!, tenantUser?.timezone!))
            .split(" ")
        : [defaultUserSettings.notifications.email.startTime, defaultUserSettings.notifications.email.startTimeAmPm];
    const [endTime, endTimeAmPm] = tenantUser?.emailNotificationsEndTime !== null &&
    tenantUser?.emailNotificationsEndTime !== "" ?
        convert24To12(convertTimeFromUTCToTimezone(tenantUser?.emailNotificationsEndTime!, tenantUser?.timezone!))
            .split(" ")
        : [defaultUserSettings.notifications.email.endTime, defaultUserSettings.notifications.email.endTimeAmPm];
    return {
        color: tenantUser?.color || defaultUserSettings.color,
        company: userInfo.tenant?.name,
        regional: {
            timezone: tenantUser?.timezone,
            timeFormat: tenantUser?.timeFormat,
            dateFormat: tenantUser?.dateFormat
        },
        notifications: {
            email: {
                enabled: tenantUser?.emailNotifications,
                startTime,
                startTimeAmPm,
                endTime,
                endTimeAmPm,
                taskUpdates: tenantUser?.emailTaskUpdates,
                mentions: tenantUser?.emailMentions
            },
            inApp: {
                enabled: tenantUser?.inAppNotifications,
                taskUpdates: tenantUser?.inAppTaskUpdates,
                mentions: tenantUser?.inAppMentions
            }
        }
    }
}

export const userSettingsToDb = (userSettings: UserSettings, user: TenantUser | undefined) => {
    if (user === undefined) {
        return;
    }
    const emailNotificationsStartTime = convertTimeFromTimezoneToUTC(
        convert12To24(userSettings.notifications.email.startTime, userSettings.notifications.email.startTimeAmPm),
        userSettings.regional.timezone);
    const emailNotificationsEndTime = convertTimeFromTimezoneToUTC(
        convert12To24(userSettings.notifications.email.endTime, userSettings.notifications.email.endTimeAmPm),
        userSettings.regional.timezone);
    return {
        name: user.name,
        email: user.email,
        color: userSettings.color,
        timezone: userSettings.regional?.timezone,
        dateFormat: userSettings.regional?.dateFormat,
        timeFormat: userSettings.regional?.timeFormat,
        emailNotifications: userSettings.notifications?.email.enabled,
        inAppNotifications: userSettings.notifications?.inApp.enabled,
        emailTaskUpdates: userSettings.notifications?.email.taskUpdates,
        inAppTaskUpdates: userSettings.notifications?.inApp.taskUpdates,
        emailMentions: userSettings.notifications?.email.mentions,
        inAppMentions: userSettings.notifications?.inApp.mentions,
        emailNotificationsEndTime,
        emailNotificationsStartTime,
        id: user.id
    }
}

export const updateLocalStorageUser = (userSettings: UserSettings, auth: AuthResponse) => {
    if (auth.tenant?.user !== undefined) {
        const updatedAuth = {
            ...auth,
            tenant: {
                ...auth.tenant,
                user: userSettingsToDb(userSettings, auth.tenant.user)
            }
        }
        localStorage.setItem("userPayload", JSON.stringify(updatedAuth));
    }
}

const convert12To24 = (time: string, ampm: string) => moment(time + ' ' + ampm, "hh:mm a").format("HH:mm");

const convert24To12 = (time: string) => moment(time, "HH:mm").format("hh:mm a");

const convertTimeFromTimezoneToUTC = (time: string, timezone: string) =>
    moment.tz(time, "HH:mm", timezone).utc().format("HH:mm:ss.sss");

const convertTimeFromUTCToTimezone = (time: string, timezone: string) =>
    moment.tz(time, "HH:mm:ss.sss", "UTC").tz(timezone).format("HH:mm");

export const convertDateTimeFromUTCToTimezone = (date: string, timezone: string, format: string) =>
    moment.tz(date, "YYYY-MM-DD HH:mm:ss", "UTC").tz(timezone).format(format)

export const convertDateTimeFromUTCToTimeZoneIncludeTime = (date: string, timezone: string, dateFormat: string, numHours: number) => {
    let timeFormat = "HH:mm"
    if (numHours === 12) {
        timeFormat = "hh:mm a"
    }
    return moment.tz(date, "YYYY-MM-DD HH:mm:ss", "UTC").tz(timezone).format(dateFormat + " " + timeFormat)
}

export function getCookie(name: string) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }

        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function setCookie(name: string, value: string, minutes: number) {
    let domain, domainParts, date, expires, host;

    if (minutes) {
        date = new Date();
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    else {
        expires = "";
    }

    host = window.location.host;
    if (host.split('.').length === 1) {
        // no "." in a domain - it's localhost or something similar
        document.cookie = name + "=" + value + expires + "; path=/";
    }
    else {
        // Remember the cookie on all subdomains.
        //
        // Start with trying to set cookie to the top domain.
        // (example: if user is on foo.com, try to set
        //  cookie to domain ".com")
        //
        // If the cookie will not be set, it means ".com"
        // is a top level domain and we need to
        // set the cookie to ".foo.com"
        domainParts = host.split('.');
        domainParts.shift();
        domain = '.' + domainParts.join('.');

        document.cookie = name + "=" + value + expires + "; path=/; domain=" + domain;

        // check if cookie was successfuly set to the given domain
        // (otherwise it was a Top-Level Domain)
        if (getCookie(name) == null || getCookie(name) != value) {
            // append "." to current domain
            domain = '.' + host;
            document.cookie = name + "=" + value + expires + "; path=/; domain=" + domain;
        }
    }
}

export const getProjectByTaskId = (projects: Project[] | Template[], taskId: number) =>
    projects.find(project => project.taskLists &&
        project.taskLists.find(taskList => taskList && taskList.tasks && (
            taskList.tasks.find(task => task.id === taskId) ||
            taskList.tasks.find(task => task.subtasks?.find(subtask => subtask.id === taskId)))));

export const getTaskById = (projects: Project[], taskId: number) => {
    let searchedTask: Task | SubTask | undefined;
    projects.forEach(project => {
        if (project.taskLists) {
            project.taskLists.forEach(taskList => {
                if (taskList.tasks) {
                    taskList.tasks.forEach(task => {
                        if (task.id === taskId) {
                            searchedTask = task;
                            return;
                        }
                        if (task.subtasks) {
                            searchedTask = task.subtasks.find(subtask => subtask.id === taskId) || searchedTask;
                            return;
                        }
                    })
                }
                if (searchedTask !== undefined) {
                    return;
                }
            })
        }
        if (searchedTask !== undefined) {
            return;
        }
    })
    return searchedTask;
}

export const getTaskListByTaskId = (project: Project, taskId: number) =>
    project.taskLists!.find(taskList => taskList && taskList.tasks &&
        taskList.tasks.find(task => task.id === taskId || task.subtasks?.find(subtask => subtask.id === taskId)));

export const create_UUID = () => {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export const setEndOfContentEditable = (contentEditableElement: Element) => {
    let range: Range,selection: Selection | null;
    range = document.createRange();
    range.selectNodeContents(contentEditableElement);
    range.collapse(false);
    selection = window.getSelection();
    selection!.removeAllRanges();
    selection!.addRange(range);
}

export const convertMinutesToHours = (m: number) => {
    const hours = (m / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return `${rhours ? rhours : ""}${rhours ? "h" : ""} ${rminutes}m`;
}

export const convertMinutesToHours2 = (m: number) => {
    const hours = (m / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return `${rhours < 10 ? '0' + rhours : rhours}:${rminutes < 10 ? '0' + rminutes : rminutes}`;
}

export const getCaretCoordinates = () => {
    let x = 0,
        y = 0;
    const isSupported = typeof window.getSelection !== "undefined";
    if (isSupported) {
        const selection = window.getSelection();
        if (selection!.rangeCount !== 0) {
            const range = selection!.getRangeAt(0).cloneRange();
            range.collapse(true);
            const rect = range.getClientRects()[0];
            if (rect) {
                x = rect.left;
                y = rect.top;
            }
        }
    }
    return { x, y };
}

export const getCaretIndex = (element: Node) => {
    let position = 0;
    const isSupported = typeof window.getSelection !== "undefined";
    if (isSupported) {
        const selection = window.getSelection();
        if (selection!.rangeCount !== 0) {
            const range = window.getSelection()!.getRangeAt(0);
            const preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            position = preCaretRange.toString().length;
        }
    }
    return position;
}

export const setCaretPosition = (el: HTMLElement, tag: string) => {
    searchChildNodePosition(el as Node, tag);
}

const searchChildNodePosition = (parentNode: ChildNode | Node, tag: string) => {
    parentNode.childNodes.forEach((node, index) => {
        if(node.nodeName === "SPAN" && (node as HTMLElement).outerHTML === tag) {
            const nextNode = parentNode.childNodes[index + 1];
            const range = document.createRange();
            const sel = window.getSelection();
            range.setStart(nextNode, 1);
            range.collapse(true);
            sel!.removeAllRanges();
            sel!.addRange(range);
            return;
        }
        if(node.nodeName === "DIV") {
            searchChildNodePosition(node, tag);
        }
    })
}

export const roleNameGenerator = (user: InternalUser) => {
    const r = user.role === "AM" ? user.accountRoles![0] : user.role;
    switch (r) {
        case "ADMIN": return "Administrator";
        case "PM": return "Project Manager";
        case "ANONYMIZE": return "Anonymize"
        case "REDACTION": return "Redaction";
        case "USER": return "Member";
        case "VIEW": return "View only"
    }
}

export const roleLabelGenerator = (role: string) => {
    switch (role) {
        case "ADMIN": return "Administrator";
        case "PM": return "Project Manager";
        case "ANONYMIZE": return "Anonymize"
        case "REDACTION": return "Redaction";
        case "USER": return "Member";
        case "VIEW": return "View only"
    }
}

export const roleMultiSelectRenderValue = (option: any) => {
    if (option.length === 0) {
        return "Select role(s)";
    }
    if (option.length === 1) {
        return roleLabelGenerator(option[0])
    }
    return 'Multiple(' + option.length + ' roles)';
}
