import React, { useEffect, useState } from 'react';
import ProgressBar from '../ProgressBar';

const SearchProgressBar = () => {
    const [pagesSearched, setPagesSearched] = useState(0);
    const [pagesToSearch, setPagesToSearch] = useState(0);

    const handlePageSearched = (event: any) => {
        setPagesSearched(event.detail.pagesSearched);
        if (pagesToSearch === 0) {
            setPagesToSearch(event.detail.numPagesToSearch)
        }
    };

    useEffect(() => {
        window.addEventListener('pageSearched', handlePageSearched);
        return () => {
            window.removeEventListener('pageSearched', handlePageSearched);
        };
    }, []);

    return <ProgressBar itemsCompleted={pagesSearched} totalItems={pagesToSearch} verb={"Searched"} units={"pages"}></ProgressBar>
}

export default SearchProgressBar;