import React, { useEffect, useState } from "react";
import "../pages.scss";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import baseline_close from "../../provisional_icons/baseline-close.png";
import { ErrorHelper } from "../../components/error-helper";
import { EmailErrors, useValidation } from "../../hooks/validation";
import Button from "@mui/material/Button";
import { SubmitHandler, useForm } from "react-hook-form";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { useAppDispatch } from "../../hooks/redux-hook";
import { useAdminService } from "../../contexts/super-admin-context";
import baseline_delete from "../../provisional_icons/baseline-delete.png";
import { InternalUser } from "../../models/InternalUser";
import { useCustomModal } from "../modals/custom-message-modal";
import { RemoveSuperAdminModal } from "../modals/modal-content/remove-super-admin.modal";
import { postApiRlsUsersSearch } from "../../services/rls.user";
import { Account } from "../../models/Account";
import { getApiRlsAccounts } from "../../services/rls.account";
import { showSnackbar } from "../../redux/snackbar";
import { Role } from "./single-account";
import { MenuItem, Select } from "@mui/material";
import { useEventHandler } from "../../hooks/handle-events";
import { ReactivateUserModal } from "../modals/modal-content/reactivate-user-modal";
import { UserAlreadyExistsModal } from "../modals/modal-content/user-already-exists-modal";
import { ChangeRoleLastPmModal } from "../modals/modal-content/change-role-last-pm-modal";
import { roleMultiSelectRenderValue } from "../../services/utils";

type InviteInfo = {
    email: string
}

type CreateAccountManagerInfo = {
    email: string,
    initialAccountId: string,
    roles: Role[]
}

export function SystemUsers() {

    const dispatch = useAppDispatch();
    const { sendManagerInvite, addAccountUserRole, removeAccountUserRole, usersGroupedByAccount, getAllUsers, getAccounts } = useAdminService();
    const { showModal } = useCustomModal();
    const { register, handleSubmit, formState: { errors }, getValues, resetField, watch, reset } = useForm<InviteInfo>();
    const { register: registerAM, handleSubmit: handleSubmitAM, formState: { errors: errorsAM },
        getValues: getValuesAM, resetField: resetFieldAM, watch: watchAM, reset: resetAM } = useForm<CreateAccountManagerInfo>();
    const { signInExpressions } = useValidation();
    const [users, setUsers] = useState<InternalUser[]>([]);
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [isEmailFieldEmpty, setIsEmailFieldEmpty] = useState(true);
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const [rolesAccountManager, setRolesAccountManager] = useState<string[]>([]);
    const [defaultAccountId, setDefaultAccountId] = useState("");
    const [accountManagers, setAccountManagers] = useState<InternalUser[]>([]);

    useEffect(() => {
        loadAccounts();
        loadUsers();
        loadAccountManagers();
        getAllUsers();
        getAccounts();
    }, []);

    useEffect(() => {
        if (getValuesAM("roles")) {
            setRolesAccountManager(getValuesAM("roles"));
        }
    }, [watchAM("roles")])

    useEffect(() => {
        setDefaultAccountId(getValuesAM("initialAccountId"));
    }, [watchAM("initialAccountId")])

    useEffect(() => {
        setIsEmailFieldEmpty(!getValues("email"));
    }, [watch("email")])

    const loadAccounts = () => {
        getApiRlsAccounts().then(res => setAccounts(res));
    }

    const loadUsers = () => {
        postApiRlsUsersSearch({ role: "SUPER", includeDeleted: true }).then(res => {
            const uniqueUsers: InternalUser[] = [];
            res.forEach(user => {
                if (!uniqueUsers.some(u => u.id === user.id)) {
                    uniqueUsers.push(user);
                }
            })
            setUsers(uniqueUsers);
        })
    }

    const loadAccountManagers = () => {
        postApiRlsUsersSearch({ role: "AM" }).then(res => {
            setAccountManagers(res);
        });
    }

    const onSubmit: SubmitHandler<InviteInfo> = (data) => {
        if (users?.find(au => au.email.trim() === data.email.trim())) {
            showModal(UserAlreadyExistsModal, {
                title: "This user already is a super admin.",
                content: "This email address has already been used to send out an invitation with a super admin role."
            });
        } else {
            dispatch(showProgressLine());
            sendManagerInvite({ email: getValues("email").trim(), role: "SUPER" }).then(() => {
                reset();
                loadUsers();
                dispatch(hideProgressLine());
            });
        }
    }

    const onSubmitAM: SubmitHandler<CreateAccountManagerInfo> = (data) => {
        if (accountManagers.find(am => am.email.trim() === data.email.trim())) {
            showModal(UserAlreadyExistsModal, {
                title: "This user already is an account manager.",
                content: "This email address has already been used to send out an invitation with an account manager role."
            });
        } else {
            dispatch(showProgressLine());
            sendManagerInvite({
                email: data.email.trim(), role: "AM", accountId: data.initialAccountId,
                accountRoles: getValuesAM("roles")
            }).then(() => {
                setRolesAccountManager([]);
                setDefaultAccountId("");
                resetAM();
                loadAccountManagers();
                dispatch(hideProgressLine());
            });
        }
    }

    const removeSuperAdmin = (user: InternalUser) => {
        if (users.length < 3) {
            dispatch(showSnackbar({ message: "You are not allowed to remove last two super admins", type: "error" }));
        } else {
            showModal(() => RemoveSuperAdminModal(user, loadUsers), {});
        }
    }

    const onClick = async (role: Role, userId: number, roleExists: boolean, accountId: string) => {
        dispatch(showProgressLine());
        if (!roleExists) {
            await addAccountUserRole(userId, role, accountId);

        } else {
            await removeAccountUserRole(userId, role, accountId);
        }
        loadAccountManagers();
        dispatch(hideProgressLine());
    }

    const isLastPm = (accountId: string, isPM: boolean) => ((usersGroupedByAccount[(accountId as unknown) as number]) ?? []).filter(u =>
        (u.role === "PM" || u.accountRoles!.some(role => role === "PM")) && isPM).length === 1;

    return <div className="page-wrapper">
        <div className="page-header">System users</div>
        <div style={{ fontSize: 17, letterSpacing: 0.49 }}>
            System users can be super admins and users with multi-account access.
        </div>
        <p style={{ fontSize: 25 }}>Super admins</p>
        <span style={{ fontSize: 17, letterSpacing: 0.49 }}>
            Super admins have complete access to the whole platform and can manage accounts and add users to them.<br />
            A super admin can add and demote other super admins to multi-account access users.<br />
            If there are more than two super admins in the system, a super admin can demote all but the last two for security.<br />
        </span>
        <div style={{ backgroundColor: "var(--lightGrayishBlue3)", marginTop: 32, width: 664, height: 142 }}>
            <div className="inner-invite-box">
                <p>Invite users as Super Admins by email </p>
                <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper" style={{ alignItems: "center" }}>
                    <div style={{ height: 44 }}>
                        <TextField
                            error={!!errors.email}
                            size="small"
                            variant="outlined"
                            id="outlined-error-helper-text"
                            placeholder="Enter email address"
                            {...register("email", { required: true, pattern: signInExpressions.emailExpression })}
                            inputProps={{ style: { fontSize: 17 } }}
                            className="invite-textfield"
                            InputProps={!isEmailFieldEmpty ? {
                                style: { height: 44 },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => resetField("email")}
                                        >
                                            <img alt="close" src={baseline_close} style={{ width: 20, height: 20 }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            } : { style: { height: 44 } }}
                        />
                        {errors.email && errors.email.type === "required" && <ErrorHelper helperText={EmailErrors.EMPTY} />}
                        {errors.email && errors.email.type === "pattern" && <ErrorHelper helperText={EmailErrors.INVALID} />}
                    </div>
                    <Button type="submit" color="secondary" style={{ marginRight: 12 }}
                        variant="contained" className="send-invite-button">
                        SEND INVITE
                    </Button>
                </form>
            </div>
        </div>
        <div style={{ marginTop: 16 }}>
            <p className="role-title">Super Admins</p>
            <table width="90%" className="table">
                <thead>
                    <tr className="table-header">
                        <td className="table-header-name">NAME</td>
                        <td></td>
                        <td>EMAIL</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody className="table-body">
                    {users.filter(u => u.deleted === false).map(user => <tr key={user.id} className="table-entry">
                        <td className="table-body-name">{user.name ? user.name : "-"}</td>
                        <td className="table-body-state" >
                            {!user.name ? <span className="pending-state">Pending</span> : <></>}
                        </td>
                        <td className="table-body-email" >{user.email}</td>
                        <td className="table-delete-item">
                            <img alt="delete" src={baseline_delete} className="delete-item" onClick={() => removeSuperAdmin(user)} />
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
        {!!users.filter(u => u.deleted === true).length && <div style={{ marginTop: 32 }}>
            <p className="role-title" style={{ color: "#939598", fontWeight: "600" }}>Removed super admins</p>
            <table width="90%" className="table">
                <thead>
                    <tr className="table-header">
                        <td className="table-header-name">NAME</td>
                        <td></td>
                        <td>EMAIL</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody className="table-body">
                    {users.filter(u => u.deleted === true).map(user => <tr key={user.id} className="table-entry">
                        <td className="table-body-name" style={{ color: "#939598" }}>{user.name ? user.name : "-"}</td>
                        <td className="table-body-state" style={{ width: 300 }} >
                            {!user.name ? <span className="pending-state">Pending</span> : <></>}
                        </td>
                        <td className="table-body-email" style={{ color: "#939598" }} >{user.email}</td>
                        <td className="table-delete-item" style={{ width: 195 }}>
                            <Button size="small" variant="outlined" color="secondary"
                                onClick={() => showModal(ReactivateUserModal, { user, fetchSuperAdmins: loadUsers })}>REACTIVATE USER</Button>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>}
        <p style={{ fontSize: 25 }}>Multi-account access</p>
        <span style={{ fontSize: 17, letterSpacing: 0.49 }}>
            Multi-account access allows users to log into multiple RLS accounts using one email address and password.<br />
            User with multi-account access can be added to multiple accounts and have different account roles assigned to them.<br />
            Super admins can invite a user into an initial account and then they can go into other accounts and add the same user
            in those accounts or remove them from there.
        </span>
        <div style={{ backgroundColor: "var(--lightGrayishBlue3)", marginTop: 32, width: 1100, height: 162 }}>
            <div className="inner-invite-box">
                <p>Invite users with multi-account access</p>
                <form onSubmit={handleSubmitAM(onSubmitAM)} style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ height: 44, marginLeft: 4 }}>
                        <TextField
                            error={!!errorsAM.email}
                            size="small"
                            variant="outlined"
                            id="outlined-error-helper-text"
                            placeholder="Enter email address"
                            {...registerAM("email", { required: true, pattern: signInExpressions.emailExpression })}
                            inputProps={{ style: { fontSize: 17 } }}
                            InputProps={!isEmailFieldEmpty ? {
                                style: { height: 44 },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => resetFieldAM("email")}
                                        >
                                            <img alt="close" src={baseline_close} style={{ width: 20, height: 20 }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            } : { style: { height: 44, width: 277, backgroundColor: "white" } }}
                        />
                        {errorsAM.email && errorsAM.email.type === "required" && <ErrorHelper helperText={EmailErrors.EMPTY} />}
                        {errorsAM.email && errorsAM.email.type === "pattern" && <ErrorHelper helperText={EmailErrors.INVALID} />}
                    </div>
                    <Select
                        size="small"
                        className="select-role invite-item-height"
                        color="secondary"
                        value={defaultAccountId}
                        displayEmpty
                        style={{ backgroundColor: "white", color: "#223250", minWidth: 275, marginLeft: 24, marginRight: 24 }}
                        {...registerAM("initialAccountId", { required: true })}
                    >
                        <MenuItem
                            value={""}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>Select initial account</MenuItem>
                        {accounts.map(a => <MenuItem
                            key={a.id}
                            value={a.id}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>{a.name}</MenuItem>)}
                    </Select>
                    <Select
                        size="small"
                        placeholder={"Select role(s)"}
                        value={rolesAccountManager}
                        displayEmpty={true}
                        renderValue={roleMultiSelectRenderValue}
                        multiple={true}
                        className="select-role invite-item-height"
                        color="secondary"
                        style={{backgroundColor: "white"}}
                        {...registerAM("roles", { required: true })}
                    >
                        <MenuItem
                            value={"ADMIN"}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>
                            <input type={"checkbox"} readOnly={true}
                                   checked={rolesAccountManager.some(role => role==="ADMIN")} style={{marginRight:10}}/>Administrator</MenuItem>
                        <MenuItem
                            value={"PM"}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>
                            <input type={"checkbox"} readOnly={true}
                                   checked={rolesAccountManager.some(role => role==="PM")} style={{marginRight:10}}/>Project Manager</MenuItem>
                        <MenuItem
                            value={"ANONYMIZE"}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>
                            <input type={"checkbox"} readOnly={true}
                                   checked={rolesAccountManager.some(role => role==="ANONYMIZE")} style={{marginRight:10}}/>Anonymize</MenuItem>
                        <MenuItem
                            value={"REDACTION"}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>
                            <input type={"checkbox"} readOnly={true}
                                   checked={rolesAccountManager.some(role => role==="REDACTION")} style={{marginRight:10}}/>Redaction</MenuItem>
                        <MenuItem
                            value={"USER"}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>
                            <input type={"checkbox"} readOnly={true}
                                   checked={rolesAccountManager.some(role => role==="USER")} style={{marginRight:10}}/>Member</MenuItem>
                        <MenuItem
                            value={"VIEW"}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>
                            <input type={"checkbox"} readOnly={true}
                                   checked={rolesAccountManager.some(role => role==="VIEW")} style={{marginRight:10}}/>View Only</MenuItem>
                    </Select>
                    <Button type="submit" color="secondary" style={{ marginRight: 12, fontSize: 14, letterSpacing: 1.25 }}
                        variant="contained" className="send-invite-button" disabled={defaultAccountId === "" || rolesAccountManager.length === 0}>
                        ADD USER
                    </Button>
                </form>
            </div>
        </div>
        <p className="role-title">Users with multi-account access</p>
        <table width="90%" className="table">
            <thead>
                <tr className="table-header">
                    <td className="table-header-name">NAME</td>
                    <td></td>
                    <td>EMAIL</td>
                    <td>ACCOUNT</td>
                    <td>ROLE</td>
                </tr>
            </thead>
            <tbody className="table-body">
                {accountManagers.map(user => <tr key={String(user.id) + user.accountId} className="table-entry">
                    <td className="table-body-name" style={{ width: 180 }}>{user.name ? user.name : "-"}</td>
                    <td className="table-body-state" style={{ width: 130 }} >
                        {!user.name ? <span className="pending-state">Pending</span> : <></>}
                    </td>
                    <td className="table-body-email" style={{ width: 260 }}>{user.email}</td>
                    <td style={{ paddingRight: 20, width: 185 }}>
                        {user.tenant?.name}
                    </td>
                    <td>
                        <Select
                            multiple={true}
                            value={user.accountRoles!}
                            renderValue={roleMultiSelectRenderValue}
                            disableUnderline
                            variant="standard"
                        >
                            <MenuItem
                                value={"ADMIN"}
                                style={{ backgroundColor: "white" }}
                                onClick={e => onClick("ADMIN", user.id, user.accountRoles!.some(role => role==="ADMIN"), user.accountId!)}
                                defaultChecked={user.accountRoles?.some(role => role==="ADMIN")}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="ADMIN")} style={{marginRight:10}}/>Administrator</MenuItem>
                            <MenuItem
                                value={"PM"}
                                style={{ backgroundColor: "white" }}
                                disabled={isLastPm(user.accountId!, (user.role === "PM" || user.accountRoles!.some(role => role === "PM")))}
                                onClick={e => isLastPm(user.accountId!, (user.role === "PM" || user.accountRoles!.some(role => role === "PM"))) ?
                                    () => showModal(ChangeRoleLastPmModal, {}) : onClick("PM", user.id, user.accountRoles!.some(role => role==="PM"), user.accountId!)}
                                defaultChecked={user.accountRoles?.some(role => role==="PM")}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="PM")} style={{marginRight:10}}/>Project Manager</MenuItem>
                            <MenuItem
                                value={"ANONYMIZE"}
                                onClick={e => onClick("ANONYMIZE", user.id, user.accountRoles!.some(role => role==="ANONYMIZE"), user.accountId!)}
                                defaultChecked={user.accountRoles?.some(role => role==="ANONYMIZE")}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="ANONYMIZE")} style={{marginRight:10}}/>Anonymize</MenuItem>
                            <MenuItem
                                value={"REDACTION"}
                                defaultChecked={user.accountRoles?.some(role => role==="REDACTION")}
                                onClick={e => onClick("REDACTION", user.id, user.accountRoles!.some(role => role==="REDACTION"), user.accountId!)}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="REDACTION")} style={{marginRight:10}}/>Redaction</MenuItem>
                            <MenuItem
                                value={"USER"}
                                onClick={e => onClick("USER", user.id, user.accountRoles!.some(role => role==="USER"), user.accountId!)}
                                defaultChecked={user.accountRoles?.some(role => role==="USER")}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="USER")} style={{marginRight:10}}/>Member</MenuItem>
                            <MenuItem
                                value={"VIEW"}
                                onClick={e => onClick("VIEW", user.id, user.accountRoles!.some(role => role==="VIEW"), user.accountId!)}
                                defaultChecked={user.accountRoles?.some(role => role==="VIEW")}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="VIEW")} style={{marginRight:10}}/>View Only</MenuItem>
                        </Select>
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>
}
