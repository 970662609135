import './App.scss';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import store from './redux/store';
import { Provider } from 'react-redux';
import { AuthenticationContextProvider, useAuthService } from './contexts/auth-context';
import { AppLayout } from './components/appLayout';
import { SignUpPage } from './pages/common/signup';
import { createTheme, ThemeProvider } from '@mui/material';
import { LoginPage } from './pages/common/login';
import { ForgotPassword, ForgotPasswordConfirmation } from './pages/common/forgot-password';
import { TwoFactorAuthCode } from './pages/common/two-factor-code';
import { TwoFactorAuthInfo } from './pages/common/two-factor-info';
import { SingleAccountPage } from './pages/admin/single-account';
import { AuthResponse } from './models/AuthResponse';
import { PropsWithChildren, useEffect } from 'react';
import { ResetPassword } from './pages/common/reset-password';
import { InactivityLogout } from './pages/common/inactivity-logout';
import { AdminChoices } from './pages/common/admin-choices';
import { AccountsAdminPage } from './pages/admin/accounts';
import { SuperAdminContextProvider } from './contexts/super-admin-context';
import { GlobalCustomModal } from './pages/modals/custom-message-modal';
import { LandingPageUser } from './pages/user/landing-page';
import { MyTasks } from './pages/user/my-tasks';
import { MasterPatternsLibrary } from './pages/user/master-patterns-library'
import { SearchPatternGenerator} from "./pages/user/spg/search-pattern-generator";
import { ProjectTemplates } from "./pages/user/project-templates";
import { SingleProjectTemplate } from './pages/user/single-project-template';
import { MyAccount } from "./pages/user/my-account";
import { useAppDispatch, useAppSelector } from "./hooks/redux-hook";
import { userSettingsLoadedSelector } from "./redux/userSettings";
import { RolesOverview } from "./pages/admin/roles-overview";
import { SystemUsers } from "./pages/admin/system-users";
import { SupportFeedback } from "./pages/user/support-feedback";
import { CustomSnackbar } from "./components/snackbar";
import { Projects } from "./pages/user/projects";
import { SingleProject } from './pages/user/projects/single-project';
import moment from 'moment'
import { TaskDetails } from './pages/user/task-details';
import { Notifications } from "./pages/user/notifications";
import { getApiNotificationsById } from "./services/notifications";
import { loadNotifications } from "./redux/notifications";
import { InactivityBar } from "./components/inactivity-bar";
import { AuthentificationModal } from './pages/modals/auth-modal';
import { ChangeUserPassword } from './pages/modals/modal-content/change-user-password';
import { UsersSuperAdmin } from './pages/admin/users';
import {
  SinglePatternSetFromMaster,
  SinglePatternSetFromProject
} from "./pages/user/single-pattern-set";
import {SinglePattern, SinglePatternFromMaster, SinglePatternFromProject} from "./pages/user/single-pattern";
import { WebViewerContextProvider } from './contexts/webviewer-context';
import ProjectDocViewer from './pages/user/projects/docs/project-doc-viewer';
import {
  PatientReportViewer,
  BatchReportViewer,
  MarksReportViewer,
  SearchReportViewer,
  TransformsReportViewer
} from './pages/docs/report-viewer';
import {ComparisonReportViewer} from './pages/docs/comparison-report-viewer'
import LeftPaneDocViewer from "./pages/docs/left-pane-doc-viewer";
import { LicenseInfo } from '@mui/x-license-pro';
import {LogsReportViewer} from "./pages/user/logs-report-viewer";
import {baseUrl, envDir, wsUrl} from "./services/config";
import {INACTIVE_TIME_LIMIT_OUTSIDE_PLATFORM} from "./constants";
import LoadTransformsContext, {LoadTransformsContextProvider} from "./contexts/load-transforms-context";
import {FilterFunctionContextProvider} from "./contexts/filter-function-context";
import {SelectedMarkStyleContextProvider} from "./contexts/selected-mark-style-context";
import {SearchAcrossDocsContextProvider} from "./contexts/SearchAcrossDocsContext";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE || '');

moment.tz.setDefault('Etc/UTC');

async function loadLoginInfo(setUserState: (response: AuthResponse) => void) {
  try {
    const userPayload = localStorage.getItem("userPayload");
    var login = JSON.parse(userPayload!) as AuthResponse;
    setUserState(login);
  } catch (e) {
    return null;
  }
}

function LoginHelper(p: PropsWithChildren<{}>) {
  const auth = useAuthService();
  useEffect(() => {
    loadLoginInfo(async userInfo => {
      if (!userInfo || !userInfo.cognito?.AccessToken) {
        auth.setLoadingLoginInfo(false);
        return;
      }
      auth.setLoginInfo(userInfo);
      userInfo.user?.role === "SUPER" ? auth.setSuperAdminPerspective() : auth.setUserPerspective();
    })
  }, []);
  return <>
    {p.children}
  </>
}

function ApplyContext(props: {
  contexts: Array<(c: { children: JSX.Element }) => JSX.Element>
  children: JSX.Element
}) {
  return props.contexts.reduce((children, Component) => <Component>{children}</Component>, props.children)
}

function HomeScreen() {
  const nav = useNavigate();
  const userPayload = localStorage.getItem("userPayload");
  var login = JSON.parse(userPayload!) as AuthResponse;

  useEffect(() => {
    if (login && login.tenant && !login.tenant.schema) {
      nav("/adminchoice");
    } else {
      nav("/app/user/landing-page");
    }
  }, [])

  return <></>
}

function App() {
  const location = useLocation();
  const nav = useNavigate();
  const auth = useAuthService();
  const passwordAge = localStorage.getItem("passwordAge");

  //Print this out to make sure it's correctly found the environment. If it doesn't then it won't be able to find the
  //patterns stored on AWS.
  console.log("environment:", envDir)
  console.log('checking process env', process.env.REACT_APP_MUI_LICENSE)

  const theme = createTheme({
    palette: {
      primary: {
        main: "#374c72"
      },
      secondary: {
        main: "#217DA2"
      },
      error: {
        main: "#C13826"
      },
      text: {
        primary: "#223250"
      },
      darkGrayishBlue: {
        main: "#939598"
      },
      normalBlue: {
        main: "#238FB5"
      },
      lightGrayishBlue3: {
        main: "#EDF5F8"
      },
      veryDarkGrayishViolet: {
        main: "#414042"
      },
      moderateGreen: {
        main: "#9EBF30"
      },
      strongRed: {
        main: "#c13826"
      }
    },
    typography: {
      fontFamily: "Titillium Web"
    }
  });

  useEffect(() => {
    const timeout = setTimeout(()=>{
      if(localStorage.getItem('userPayload') === null){
        sessionStorage.clear();
        localStorage.removeItem('expireTime');
        localStorage.removeItem('inactiveTimeOutsidePlatform');
        localStorage.removeItem('impersonate');
        if(location.pathname.startsWith("/app") || location.pathname.startsWith("/adminchoice")) {
          nav('/login')
        }
      }
    },0);
    return ()=>clearTimeout(timeout)
  }, []);

  useEffect(() => {
    const inactiveTimeOutsidePlatform = localStorage.getItem("inactiveTimeOutsidePlatform");
    //Check if the user's password is expired
    if ((location.pathname.startsWith("/app") || location.pathname.startsWith("/adminchoice")) && parseInt(passwordAge!) >= 90) {
      nav("/change-password-schedule");
    }
    //Log out the user if they've been outside the platform for more than 2 minutes
    else if ((location.pathname.startsWith("/app") || location.pathname.startsWith("/adminchoice")) && moment().diff(moment(inactiveTimeOutsidePlatform), 'milliseconds') > INACTIVE_TIME_LIMIT_OUTSIDE_PLATFORM) {
        localStorage.removeItem("userPayload");
        if (auth) {
          auth.setLoginInfo(undefined);
        }
        window.location.href = '/login';
    }
  }, [location.pathname])


  return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ApplyContext contexts={[
            GlobalCustomModal,
            LoginHelper,
            AuthenticationContextProvider,
            SuperAdminContextProvider,
            WebViewerContextProvider,
            LoadTransformsContextProvider,
            FilterFunctionContextProvider,
            SelectedMarkStyleContextProvider,
            SearchAcrossDocsContextProvider
          ]}>
            <Routes>
              <Route index element={<HomeScreen />} />
              <Route path='/login' element={<LoginPage />} />
              <Route path='/signup' element={<SignUpPage />} />
              <Route path='/two-factor-info' element={<TwoFactorAuthInfo/>}/>
              <Route path='/two-factor-auth' element={<TwoFactorAuthCode />} />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/forgot-password-confirmation' element={<ForgotPasswordConfirmation />} />
              <Route path='/reset-password' element={<ResetPassword />} />
              <Route path='/change-password-schedule' element={<div className='container'>
                  <AuthentificationModal><ChangeUserPassword isChangeScheduled /></AuthentificationModal>
                </div>}
              />
              <Route path='/inactivity-logout' element={<InactivityLogout />} />
              <Route path='/app/*' element={<AuthenticatedApp />} />
              <Route path="/adminchoice" element={<AdminChoices />} />
            </Routes>
          </ApplyContext>
          <CustomSnackbar />
        </ThemeProvider>
      </Provider>
  );
}

function AuthenticatedApp() {

  const { loginInfo, loadingLoginInfo } = useAuthService();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const controller = new AbortController();
    if (!loadingLoginInfo && loginInfo?.tenant?.user) {
      setTimeout(() => getApiNotificationsById(loginInfo?.tenant?.user?.id!, controller.signal)
          .then(notifications => dispatch(loadNotifications(notifications))), 500);
    }
    return () => controller.abort()
  }, [loginInfo, loadingLoginInfo]);

  return <>
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route path='/admin/*' element={<AdminAuthenticatedApp />} />
        <Route path='/user/*' element={<UserAuthenticatedApp />} />
      </Route>
    </Routes>
    <InactivityBar />
  </>
}

function AdminAuthenticatedApp() {

  const userSettingsLoaded = useAppSelector((state) => userSettingsLoadedSelector(state));
  if (!userSettingsLoaded) return null;

  return <Routes>
    <Route path="/" element={<CheckAuthenticationAdmin />}>
      <Route path="/account" element={<SingleAccountPage />} />
      <Route path="/accounts" element={<AccountsAdminPage />} />
      <Route path="/roles-overview" element={<RolesOverview />} />
      <Route path="/system-users" element={<SystemUsers />} />
      <Route path="/users" element={<UsersSuperAdmin />} />
    </Route>
  </Routes>
}

function UserAuthenticatedApp() {

  const userSettingsLoaded = useAppSelector((state) => userSettingsLoadedSelector(state));
  if (!userSettingsLoaded) return null;

  return <Routes>
    <Route path="/" element={<Outlet />}>
      <Route path="/landing-page" element={<LandingPageUser />} />
      <Route path="/workflow/my-tasks" element={<MyTasks />} />
      <Route path="/workflow/project-templates" element={<ProjectTemplates />} />
      <Route path="/workflow/search-pattern-generator" element={<SearchPatternGenerator/>}/>
      <Route path="/workflow/master-patterns-library" element={<MasterPatternsLibrary/>}/>
      <Route path="/workflow/master-patterns-library/:patternSetName" element={<SinglePatternSetFromMaster/>}/>
      <Route path="/workflow/projects/patterns/:patternSetName" element={<SinglePatternSetFromProject/>}/>
      <Route path="/workflow/master-patterns-library/:patternSetName/:patternName" element={<SinglePatternFromMaster/>}/>
      <Route path="/workflow/projects/patterns/:patternSetName/:patternName" element={<SinglePatternFromProject/>}/>
      <Route path="/workflow/project-templates" element={<ProjectTemplates/>}/>
      <Route path="/workflow/project-template/*" element={<SingleProjectTemplate/>}/>
      <Route path="/workflow/task-details/:taskId/:subtaskId" element={<TaskDetails/>}/>
      <Route path="/workflow/task-details/:taskId" element={<TaskDetails/>}/>
      <Route path="/workflow/projects" element={<Projects/>}/>
      <Route path="/workflow/projects/:projectId/task-list/:taskListId" element={<SingleProject/>}/>
      <Route path="/workflow/projects/:projectId" element={<SingleProject/>}/>
      <Route path="/workflow/projects/:projectId/file-management" element={<SingleProject/>}/>
      <Route path="/my-account" element={<MyAccount/>}/>
      <Route path="/admin" element={<SingleAccountPage/>}/>
      <Route path="/support-feedback" element={<SupportFeedback/>}/>
      <Route path="/notifications" element={<Notifications/>}/>
      <Route path="/workflow/projects/:projectId/viewer" element={<ProjectDocViewer />} />
      <Route path="/docs/:projectId/patientReport" element={<PatientReportViewer />} />
      <Route path="/docs/:projectId/batchReport" element={<BatchReportViewer />} />
      <Route path="/docs/:projectId/marksReport" element={<MarksReportViewer />} />
      <Route path="/docs/:projectId/transformsReport" element={<TransformsReportViewer />} />
      <Route path="/docs/:projectId/searchReport" element={<SearchReportViewer />} />
      <Route path="/docs/:documentName/comparisonReport" element={<ComparisonReportViewer />} />
      <Route path="/docs/:documentName/:projectId/logsFile" element={<LogsReportViewer />} />
      <Route path="/docs" element={<LeftPaneDocViewer/>} />
    </Route>
  </Routes>
}

function CheckAuthenticationAdmin() {
  const { verifyUserRole } = useAuthService();
  return !verifyUserRole("SUPER") ? <div>You are not a super admin!</div> : <Outlet />
}

export default App;
