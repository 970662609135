import Button from "@mui/material/Button";
import { useCustomModal } from "../custom-message-modal";
import { deleteApiTaskListsById } from "../../../services/task.list";
import { showSnackbar } from "../../../redux/snackbar";
import { useAppDispatch } from "../../../hooks/redux-hook";
import { useState } from "react";
import { ButtonSpinner } from "../../../components/button-spinner";
import { TaskList } from "../../../models/TaskList";
import { StyledCloseIcon } from "../../../components/close-button";

export function RemoveTemplateListModal(p: {taskList: TaskList}) {
    const { hideModal } = useCustomModal();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const onSubmit = async () => {
        setLoading(true);
        await deleteApiTaskListsById(p.taskList.id)
            .then(hideModal)
            .then(() => dispatch(showSnackbar({
                message: "List deleted successfully.",
                type: "info"
            })))
            .catch(() => {
                setLoading(false);
                dispatch(showSnackbar({
                    message: "Error removing list!",
                    type: "error"
                }));
            });
    }

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <StyledCloseIcon onClick={() => loading ? {} : hideModal()} />
            <span className="remove-entity-title" >
                Delete list {p.taskList.name} ?
            </span>
            <p className="remove-entity-disclaimer" >
                This list will be removed from the project template, along will all the tasks and subtasks created within it.
            </p>
            <div className="remove-entity-button-list" style={{ marginTop: 20 }} >
                <Button disabled={loading} color="secondary" variant="outlined" onClick={hideModal}>CANCEL</Button>
                <Button disabled={loading} onClick={onSubmit} color="secondary" variant="contained">
                    DELETE LIST {loading && <ButtonSpinner />}
                </Button>
            </div>
        </div>
    </div>
}
