import { Button, TextField } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorHelper } from "../../components/error-helper";
import { useAuthService } from "../../contexts/auth-context";
import { EmailErrors, useValidation } from "../../hooks/validation";
import { AuthentificationModal } from "../modals/auth-modal";
import './login.scss';
import './two-factor-code.scss';
import { useState } from "react";
import { ButtonSpinner } from "../../components/button-spinner";

type ForgotPasswordInfo = {
    email: string
}

export function ForgotPassword() {
    const { signInExpressions } = useValidation();
    const { register, handleSubmit, formState: { errors } } = useForm<ForgotPasswordInfo>();
    const auth = useAuthService();
    const [submitted, setSubmitted] = useState(false);

    const onSubmit: SubmitHandler<ForgotPasswordInfo> = (data) => {
        setSubmitted(true);
        auth.resetPassword(data.email).then(() => setSubmitted(false));
    }

    return <div className='box-container'>
        <AuthentificationModal>
            <div className="content-box">
                <span style={{ fontSize: 25, textAlign: "center" }}>Forgot your password?</span>
                <p>Enter your email address to recover your account.</p>
                <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                        error={!!errors.email}
                        variant="outlined"
                        id="outlined-error-helper-text"
                        label="Email"
                        {...register("email", { required: true, pattern: signInExpressions.emailExpression })}
                        inputProps={{ style: { fontSize: 17 } }}
                        style={{ marginTop: 25 }}
                    />
                    {errors.email && errors.email.type === "required" && <ErrorHelper helperText={EmailErrors.EMPTY} />}
                    {errors.email && errors.email.type === "pattern" && <ErrorHelper helperText={EmailErrors.INVALID} />}
                    <Button disabled={submitted} type="submit" color="secondary" variant="contained" style={{ marginTop: 40 }}>
                        SEND ME THE LINK {submitted && <ButtonSpinner />}
                    </Button>
                </form>
                <p style={{ textAlign: "center" }}>
                    <a className="hyperlink-text" href="/login">or log in</a>
                </p>
            </div>
        </AuthentificationModal>
    </div>
}

export function ForgotPasswordConfirmation() {

    return <div className="box-container">
        <AuthentificationModal>
            <div className="content-box">
                <span style={{ fontSize: 25, textAlign: "center" }}>Forgot your password?</span>
                <p style={{ fontSize: 17 }}>
                    If the account exists, an email will be send with further instructions.
                </p>
                <p style={{ fontSize: 17 }}>
                    Please follow the instructions in the email in order to recover your account.
                </p>
                <p style={{ textAlign: "center" }}>
                    <a className="hyperlink-text" href="/login">go back to login page</a>
                </p>
            </div>
        </AuthentificationModal>
    </div>
}
