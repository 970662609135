import { TaskList } from "../models/TaskList";
import { BaseTaskList } from "../models/BaseTaskList";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
export function getApiTaskLists({ projectId }: {
    projectId: number;
}, signal?: AbortSignal): Promise<TaskList[]> {
    const url = new URL(`/api/task-lists`, baseUrl);
    addQueryParameter(url, "projectId", projectId);
    return fetchAndParse<TaskList[]>(url.toString(), { method: "GET", headers, signal });
}
export function postApiTaskListsCreate(body?: BaseTaskList, signal?: AbortSignal): Promise<TaskList> {
    const url = new URL(`/api/task-lists/create`, baseUrl);
    return fetchAndParse<TaskList>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function putApiTaskListsById(id: number, body?: BaseTaskList, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/task-lists/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function getApiTaskListsById(id: number, signal?: AbortSignal): Promise<TaskList> {
    const url = new URL(`/api/task-lists/${id}`, baseUrl);
    return fetchAndParse<TaskList>(url.toString(), { method: "GET", headers, signal });
}
export function deleteApiTaskListsById(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/task-lists/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
