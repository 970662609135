import "./report-issue-form.scss"
import { Button, TextField } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useAppDispatch } from "../../hooks/redux-hook";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { ReportIssue } from "../../models/ReportIssue";
import { postApiFeedbackCreate } from "../../services/feedback";

type reportFormAttributes = "date" | "description" | "expectedVsOutcome" | "blocker"
    | "productVersion" | "previousVersion" | "logs";

function AttachmentRow(props: { attachment: File, removeFile: (attachment: File) => void }) {
    return <div style={{ display: "flex", justifyContent: "space-between"}}>
        <div className="report-issue-filename">
            <InsertDriveFileIcon className="report-issue-file-icon"/> {props.attachment.name}
        </div>
        <CloseIcon className="report-issue-file-delete-icon" onClick={() => props.removeFile(props.attachment)} />
    </div>
}

export function ReportIssueForm(props: { product: string, submitForm: () => void }) {

    const [reportIssueForm, setReportIssueForm] = useState<ReportIssue>({
        product: props.product,
        date: "",
        description: "",
        expectedVsOutcome: "",
        blocker: "",
        productVersion: "",
        previousVersion: "",
        logs: ""
    });
    const [attachments, setAttachments] = useState<File[]>([]);
    const uploadRef = useRef<HTMLInputElement | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (props.product !== reportIssueForm.product) {
            setReportIssueForm({ ...reportIssueForm, product: props.product });
        }
    }, [props]);

    const updateForm = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const form = { ...reportIssueForm };
        form[ev.target.id as reportFormAttributes] = ev.target.value;
        setReportIssueForm(form);
    }

    const addAttachment = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target!.files![0];
        setAttachments([ ...attachments, file])
    }

    const removeFile = (file: File) => {
        setAttachments(attachments.filter(a => a.name !== file.name));
    }

    const submitReportIssue = () => {
        dispatch(showProgressLine());
        let formData = new FormData();
        const formKeys = Object.keys(reportIssueForm).filter(k => k !== "files");
        formKeys.forEach(key => {
            formData.append(key, reportIssueForm[key as reportFormAttributes] as string);
        });
        if (attachments.length > 0) {
            attachments.forEach(attachment => {
                formData.append('files[]', attachment);
            });
        }
        const controller = new AbortController();
        postApiFeedbackCreate(formData, controller.signal)
            .then(() => {
                dispatch(hideProgressLine());
                props.submitForm();
            })
            .catch(() => {
                dispatch(hideProgressLine());
            })
    }

    return <div style={{ marginLeft: 32, marginTop: 24, display: "flex", flexDirection: "column", width: 401 }}>
        <span className="report-issue-form-label">What date / time did this occur?</span>
        <TextField placeholder="Your answer" fullWidth className="report-issue-form-field"
                   id="date"
                   value={reportIssueForm.date}
                   onChange={updateForm}
                   required
        />
        <span className="report-issue-form-label">
            Describe the exact steps to reproduce the issue <br />
            <span style={{ fontSize: 13, fontWeight: "normal" }}>(the more specific the better)</span>
        </span>
        <TextField placeholder="Your answer" fullWidth multiline rows={4} className="report-issue-form-field"
                   id="description"
                   value={reportIssueForm.description}
                   onChange={updateForm}
                   required
        />
        <span className="report-issue-form-label">Describe the expected vs actual outcome</span>
        <TextField placeholder="Your answer" fullWidth multiline rows={4} className="report-issue-form-field"
                   id="expectedVsOutcome"
                   value={reportIssueForm.expectedVsOutcome}
                   onChange={updateForm}
                   required
        />
        <span className="report-issue-form-label">
            Is this issue a blocker? <br />
            <span style={{ fontSize: 13, fontWeight: "normal" }}>(has no workaround and prevents production work)</span>
        </span>
        <TextField placeholder="Your answer" fullWidth className="report-issue-form-field"
                   id="blocker"
                   value={reportIssueForm.blocker}
                   onChange={updateForm}
                   required
        />
        {props.product !== "Workflow" && <div>
            <span className="report-issue-protect-title">
                RLS Protect Docs and Risk information<br />
                <div className="report-issue-protect-optional">These are optional fields.</div>
            </span>
            <div className="report-issue-form-label">What version of the software were you using?</div>
            <TextField placeholder="Your answer" fullWidth className="report-issue-form-field"
                       id="productVersion"
                       value={reportIssueForm.productVersion}
                       onChange={updateForm}
            />
            <div className="report-issue-form-label">
                Does this issue appear in a previous version? If so,<br /> which one?
            </div>
            <TextField placeholder="Your answer" fullWidth className="report-issue-form-field"
                       id="previousVersion"
                       value={reportIssueForm.previousVersion}
                       onChange={updateForm}
            />
            <div className="report-issue-form-label">
                Include application logs, test data (sample PDF), <br />
                and configuration. Link to WorkDocs is acceptable.
            </div>
            <TextField placeholder="Your answer" fullWidth className="report-issue-form-field"
                       id="logs"
                       value={reportIssueForm.logs}
                       onChange={updateForm}
            />
        </div>}
        <span className="report-issue-form-label">Please attach screenshots or videos if possible</span>
        {attachments.map(a => <AttachmentRow key={a.name} attachment={a} removeFile={removeFile}/>)}
        <div onClick={() => uploadRef.current?.click()} className="attach-link">
            <AttachmentIcon style={{ marginRight: 4 }} /> ATTACH FILES
        </div>
        <input type="file" ref={uploadRef} style={{ display: "none" }} accept="video/*, image/*"
               onChange={addAttachment}
        />
        <Button color="secondary" variant="contained" style={{ width: 166, marginTop: 24 }} onClick={submitReportIssue}>
            SUBMIT REQUEST
        </Button>
    </div>
}
