import React, {useEffect, useState, useReducer, useContext} from 'react';
import classNames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import './TransformPanel.scss'
import WebViewerContext from "../../../contexts/webviewer-context";
import AnnotationFilterHelpers from "../AnnotationFilterHelpers";
import AnnotationFilter from "../AnnotationFilter";
import {Button} from "@mui/material";
import LoadTransformsButton from "./LoadTransformsButton";
import {loadRedactionAnnotations} from "../RedactionPanel/RedactionPanelContainer";
import { Menu, MenuItem } from '@mui/material';
import {useCustomModal} from "../../../pages/modals/custom-message-modal";
import ChangeMarginsModal from "../ChangeMarginsModal";
import TransformSettingsModal from "../TransformSettingsModal";

export const TransformPanel = () => {
    const { setInstance,instance } = useContext(WebViewerContext);
    const annotationManager = instance.Core.annotationManager;
    const { showModal, hideModal } = useCustomModal();
    const [redactionAnnotations, setRedactionsAnnotations] = useState<any[]>([])
    const [currentRedactions, setCurrentRedactions] = useState<any[]>([]);
    const [checkedRedactions, setCheckedRedactions] = useState<any[]>([]);
    const [selectedRedactions, setSelectedRedactions] = useState<any[]>([]);
    const [pageRangeFilter, setPageRangeFilter] = useState<number[]|undefined>(undefined);
    const [searchPhraseFilter, setSearchPhraseFilter] = useState<string|undefined>(undefined);
    const [categoryFilter, setCategoryFilter] = useState<string|undefined>(undefined);
    const [regexInputFilter, setRegexInputFilter] = useState<string|undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<'Asc'|'Desc'>("Asc")
    const [position, setPosition] = useState({ left: 0, top: 0 });
    const [anchorEl, setAnchorEl] = useState<boolean>(false);
    const dispatch = useDispatch()
    const [, forceUpdate] = useReducer(x => x + 1, 0);


    function filterPageRangeOnChange(newPageRange: number[] | undefined) {
        //We need to clone the value so that React recognizes it as a change and re-renders.
        const verifiedPageRange = newPageRange === undefined ? undefined : [...newPageRange];
        setPageRangeFilter(verifiedPageRange);
    }

    function searchPhraseOnChange(newSearchPhrase: string) {
        setSearchPhraseFilter(newSearchPhrase)
    }


    function regexInputOnChange(newRegexInput: string) {
        setRegexInputFilter(newRegexInput)
    }

    function sortChangeCallBack(newSortOrder: 'Asc'|'Desc') {
        setSortOrder(newSortOrder)
    }

    function categoryOnChange(newCategory: string) {
        setCategoryFilter(newCategory)
    }

    function clearReplacementText() {
        currentRedactions.forEach(redaction => {
            redaction.setCustomData("Replacement", "");
            redaction.setCustomData("ManuallyEdited", "");
        })
        uncheckAllRedactions();
    }

    function checkRedactions(redactions: any[]) {
        redactions.forEach(redaction => redaction.transformChecked=true)
        setCheckedRedactions(redactions)
    }

    function uncheckAllRedactions() {
        redactionAnnotations.forEach(redaction => redaction.transformChecked=false)
        setCheckedRedactions([])
    }

    useEffect(() => {
        const onAnnotationChanged = () => {
            setRedactionsAnnotations(loadRedactionAnnotations(instance));
        }

        const onTransformLoaded = () => {
            onAnnotationChanged();
            forceUpdate()
        }

        const onAnnotationSelected = () => {
            setSelectedRedactions(instance.Core.annotationManager.getSelectedAnnotations())
        }

        if (instance) {
            const annotationManager = instance.Core.annotationManager;
            setRedactionsAnnotations(loadRedactionAnnotations(instance))
            annotationManager.addEventListener('annotationChanged', onAnnotationChanged)
            annotationManager.addEventListener('transformsLoaded', onTransformLoaded)
            annotationManager.addEventListener('annotationSelected', onAnnotationSelected);
        }

        return () => {
            if (instance) {
                instance.Core.annotationManager.removeEventListener('annotationChanged', onAnnotationChanged)
                instance.Core.annotationManager.removeEventListener('annotationChanged', onTransformLoaded)
                instance.Core.annotationManager.removeEventListener('annotationSelected', onAnnotationSelected);
            }
        }
    }, [instance]);

    useEffect(() => {
        //We need to map these fields to the ones that are used in the filter helper methods.
        redactionAnnotations.forEach((annotation: any) => {
            annotation.pageNum = annotation.PageNumber
        })
        AnnotationFilterHelpers.applyFiltersTransform(redactionAnnotations, searchPhraseFilter, pageRangeFilter, categoryFilter, regexInputFilter)

        const sortedRedactions = redactionAnnotations.sort((a: any, b: any) => {
            if (sortOrder==="Asc") {
                //sorting by location in document then location in page
                if (a.pageNum !== b.pageNum) {
                    return a.pageNum - b.pageNum
                } else if (a.getY() !== b.getY()) {
                    return a.getY() - b.getY()
                } else {
                    return a.getX() - b.getX()
                }
            } else {//desc
                if (b.pageNum !== a.pageNum) {
                    return b.pageNum - a.pageNum
                } else if (b.getY() !== a.getY()) {
                    return b.getY() - a.getY()
                } else {
                    return b.getX() - a.getX()
                }
            }
        })
        const filteredRedactions: any[] = [];
        sortedRedactions.forEach((annotation: any) => {

            if (annotation.shouldHide) {
                return
            }
            filteredRedactions.push(annotation);
        });

        setCurrentRedactions(filteredRedactions);
    }, [redactionAnnotations, pageRangeFilter, searchPhraseFilter, categoryFilter, regexInputFilter, sortOrder]);

    const onClick = (annotation: any) => {
        if (checkedRedactions.some(selectedRedaction => selectedRedaction===annotation)) {
            setCheckedRedactions(prevState => prevState.filter((el) => el!==annotation));
            annotation.transformChecked = false;
        } else {
            setCheckedRedactions([...checkedRedactions, annotation]);
            annotation.transformChecked = true;
        }
    }

    const onChangeComplete = (annotation: any) => {
        annotationManager.trigger('annotationChanged', [annotation], 'replacementChange', {imported: false})//This trigger will notify the webviewer
        //that there have been changes to the document and hence it will keep track of those changes if the user switched tabs in the webviewer
        forceUpdate()
    }

    const onReplacementChange = (annotation: any, event: any) => {
        const value = event.target.value;
        annotation.setCustomData("Replacement", event.target.value);
        annotation.setCustomData("ManuallyEdited", value ? "true" : "");
    }

    const annotationToolTip = (annotation: any) => {
        let toolTipText = 'type: ' + annotation.type + '\n';
        toolTipText+= 'page: ' + annotation.getPageNumber() + '\n';
        toolTipText+= 'status: ' + getStatus(annotation) + '\n';
        toolTipText+= 'patient Id: ' + annotation.getCustomData("Patient ID") + '\n';
        toolTipText+= 'start date: ' + annotation.getCustomData("Start Date") + '\n';
        toolTipText+= 'study ID: ' + annotation.getCustomData("Study ID") + '\n';
        toolTipText+= 'replacement method: Auto\n';//We don't have those yet
        toolTipText+= 'font type: \n';
        toolTipText+= 'font size: \n';
        return toolTipText;
    }

    function getStatus(annotation: any) {
        if (annotation.getStatus()===""){
            return "None"
        }
        return annotation.getStatus()
    }

    const handleCloseMenu = () => {
        setAnchorEl(false);
    };

    function onContextMenu(e: any, annotation: any) {
        e.preventDefault()
        selectAnnotation(annotation)
        setAnchorEl(true)
        let left = e.pageX
        let top = e.pageY - document.body.offsetHeight
        setPosition({left, top})
    }
    function onClickEditMark() {
        setAnchorEl(false)
        instance.Core.annotationManager.trigger('editMark')
    }

    function selectAnnotation(annotation: any) {
        instance.Core.annotationManager.selectAnnotation(annotation);
        instance.Core.annotationManager.jumpToAnnotation(annotation);
    }

    const renderRedactionPageGroups = () => {
        return (
            <div className={'table_container'}>
            <table className={'table_border'} >
                <thead  >
                <tr >
                    <td className={'column_header'} width="6%" ></td>
                    <td className={'column_header'} width="47%" >Before</td>
                    <td className={'column_header'} width="47%" >After</td>
                </tr>
                </thead>
                <tbody>
                <Menu
                    id="simple-menu"
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    style={{...position, zIndex: 99999}}
                >
                    <MenuItem onClick={onClickEditMark}>Edit</MenuItem>
                </Menu>
                {currentRedactions.map((annotation, index) => {
                    const {backgroundColor, textColor, displayReplacementText} = getTransformStyle(annotation, 'transform');
                    return (<tr key={annotation.Id} title={annotationToolTip(annotation)} style={{cursor: 'pointer' }}>
                        <td><input width={"inherit"} height={"inherit"} type={"checkbox"} readOnly onClick={() => onClick(annotation)}
                                   checked={checkedRedactions.some(checkedRedaction => checkedRedaction===annotation)}
                        /></td>
                        <td onClick={() => selectAnnotation(annotation)}  onContextMenu={(e) => onContextMenu(e, annotation)}
                            className={selectedRedactions.some(selectedRedaction => selectedRedaction.Id === annotation.Id) ? 'transform-item-selected' : ''}
                        >{annotation.resultStr || annotation.getCustomData('trn-annot-preview') || ''}</td>
                        <td><input key={`${annotation.Id}${displayReplacementText}`}  defaultValue={displayReplacementText} width={"inherit"} style={{ border: 'none', backgroundColor: backgroundColor, color: textColor }} onChange={event => onReplacementChange(annotation, event)} onBlur={() => onChangeComplete(annotation)} /></td>
                    </tr>);
                })}
                </tbody>
            </table>
            </div>
        );
    };

    const noRedactionAnnotations = (
        <div className="no-marked-redactions">
            <div>
                {/*<Icon className="empty-icon" glyph="icon-no-marked-redactions" />*/}
            </div>
            <div className="msg">Start redacting by marking text, regions, pages or making a search.</div>
        </div>
    );

    const redactAllButtonClassName = classNames('redact-all-marked', { disabled: redactionAnnotations.length === 0 });


    const applyCheckedRedactions = () => {
        checkedRedactions.forEach(redaction => redaction.pageNum = redaction.PageNumber)
        instance.Core.annotationManager.trigger('transformText');
    };

    return <div className={'panel'}>

        {currentRedactions.length > 0 ? <div className="redaction-panel-controls">
            <button className={"checkButton"}
                    onClick={() => checkRedactions(currentRedactions)}
                    disabled={currentRedactions.length === 0}
                    aria-label={'Check All'}
            >
                {'Check All'}
            </button>
            <button className={"checkButton"}
                    disabled={currentRedactions.length === 0}
                    onClick={() => uncheckAllRedactions()}
                    aria-label={'Uncheck All'}
            >
                {'Uncheck All'}
            </button>
            <button className={"checkButton"}
                    onClick={clearReplacementText}
            >
                {'Clear'}
            </button>
            <Button variant="contained" color="secondary"
                    className={'transform-button'}
                    onClick={() => {
                        showModal(TransformSettingsModal, {})
                    }}
            >
                Settings
            </Button>
            <LoadTransformsButton/>
        </div> : null}
        <button className={"checkButton"}
                disabled={checkedRedactions.length === 0}
                onClick={() => instance.Core.annotationManager.trigger('changeCategory', {annotations: checkedRedactions})}
                aria-label={'Uncheck All'}
        >
            {'Change Category'}
        </button>

        {redactionAnnotations.length > 0 ? <AnnotationFilter pageRangeChangeCallBack={filterPageRangeOnChange} searchPhraseChangeCallBack={searchPhraseOnChange} categoryChangeCallBack={categoryOnChange} categories={AnnotationFilterHelpers.getCategories(redactionAnnotations)} sortChangeCallBack={sortChangeCallBack} regexInputChangeCallBack={regexInputOnChange} limitedOptions={false} /> : null}
        {redactionAnnotations.length > 0 ? renderRedactionPageGroups() : noRedactionAnnotations}
        {redactionAnnotations.length > 0 ? <div className="redaction-panel-controls">
            <button
                disabled={checkedRedactions.length===0}
                className={redactAllButtonClassName}
                onClick={applyCheckedRedactions}
                aria-label={"Redact"}
            >
                Apply
            </button>
        </div> : null}
    </div>;
};


const mapStateToProps = (state: any) => ({
    style: state.viewer.activeToolStyles
});

export const formatCategoricalTransform = (category: string) => {
    category = category.replaceAll('_', ' ');
    return `(${category})`
}

function startsWithAndEndsWithParentheses(input: string): boolean {
    const regex = /^\(.*\)$/;
    return regex.test(input);
}

export const getTransformStyle = (annotation: any, panel: string) => {
    const replacementText = annotation.getCustomData('Replacement');
    const redact = replacementText === '%REDACT%' && panel === 'transform';
    const retain = replacementText === '%RETAIN%';
    const manuallyEdited = annotation.getCustomData("ManuallyEdited");
    const category = annotation.type
    const catTrans = category && (replacementText === '*' || startsWithAndEndsWithParentheses(replacementText));
    const backgroundColor = manuallyEdited ? 'yellow' : ((redact || catTrans) ? 'blue' : retain ? 'green' : 'inherit');
    const textColor = (redact || retain || catTrans && !manuallyEdited) ? 'white' : 'inherit';
    const displayReplacementText = redact || retain ? replacementText.substring(1, replacementText.length - 1) : replacementText;
    return { backgroundColor, textColor, displayReplacementText };
};