import React from "react";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";

export function updateOnChangeText<S>([state, setState]: [S, Dispatch<SetStateAction<S>>], key: keyof S, withValidation?: (value: string) => void) {
    return {
        value: state[key],
        onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
            setState({ ...state, [key]: event.target.value });
            if (withValidation) {
                withValidation(event.target.value);
            }
        }
    };
}

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
