import "./change-user-password.scss";
import { useCustomModal } from "../custom-message-modal";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff, Circle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { postApiUsersChangePassword } from "../../../services/user";
import { ChangePasswordRequest } from "../../../models/ChangePasswordRequest";
import { useValidation } from "../../../hooks/validation";
import { ButtonSpinner } from "../../../components/button-spinner";
import { StyledCloseIcon } from "../../../components/close-button";
import { useNavigate } from "react-router";

export function ChangeUserPassword(props: {isChangeScheduled: boolean}) {

    const { hideModal } = useCustomModal();
    const { passwordRequirements, requirementsValidation, createPasswordValidation } = useValidation();
    const [error, setError] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [currentPassword, setCurrentPassword] = useState<string>();
    const [newPassword, setNewPassword] = useState<string>();
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>();
    const [currentPasswordVisibility, setCurrentPasswordVisibility] = useState(false);
    const [newPasswordVisibility, setNewPasswordVisibility] = useState(false);
    const [confirmNewPasswordVisibility, setConfirmNewPasswordVisibility] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const nav = useNavigate();
    let passwordAge = localStorage.getItem("passwordAge");

    useEffect(() => {
        createPasswordValidation(newPassword || "")
    }, [newPassword]);

    useEffect(() => {
        setErrorMessage("");
        setError(undefined);
    }, [currentPassword, newPassword, confirmNewPassword]);

    const savePassword = () => {
        if (!currentPassword) {
            setErrorMessage("No password was filled!");
            setError('currentPassword');
            return
        }
        if (newPassword !== confirmNewPassword) {
            setErrorMessage("Passwords don't match!");
            setError('confirmNewPassword');
            return
        }
        if (!newPassword || requirementsValidation.length > 0) {
            setErrorMessage("Password doesn't meet criteria");
            setError('newPassword');
            return
        }

        const request: ChangePasswordRequest = {
            previousPassword: currentPassword,
            proposedPassword: newPassword
        }
        setSubmitted(true);
        const controller = new AbortController();
        postApiUsersChangePassword(request, controller.signal)
            .then(() => {
                if (!props.isChangeScheduled) {
                    hideModal();
                } else {
                    nav("/login");
                }
                localStorage.setItem("passwordAge", "0");
                passwordAge = "0";
            })
            .catch(err => {
                setErrorMessage(
                    err.message.includes('Attempt limit exceeded, please try after some time') ?
                        'Attempt limit exceeded, please try after some time' :
                        'Current password is incorrect. Please try again.'
                );
                setError('currentPassword');
                setSubmitted(false);
            });
    }

    const checkPasswordConfirmation = () => {
        if (newPassword !== confirmNewPassword) {
            setErrorMessage("Passwords don't match!");
            setError('confirmNewPassword');
        } else {
            setErrorMessage("");
            setError(undefined);
        }
    }

    if (props.isChangeScheduled && parseInt(passwordAge!) < 90) return <></>;
    return <div className="change-password-wrapper" style = {props.isChangeScheduled ? {height: 600} : {}}>
        <StyledCloseIcon onClick={() => !submitted ? hideModal() : null} />
        <h5 style = {{textAlign: !props.isChangeScheduled ? "initial" : "center"}}>Change Password</h5>
        <div style={{ fontSize: 15, marginBottom: 27 }}>{!props.isChangeScheduled ? 
            "Please confirm your current password and enter a new one for your account." :
            "For security reasons, we require you to change your password every 90 days.\n The password must be different from the previous 5 used passwords."}
        </div>
        <TextField fullWidth
                   autoComplete="off"
                   label="Current password"
                   className="password-text-field"
                   type={currentPasswordVisibility ? "text" : "password"}
                   value={currentPassword}
                   error={error === 'currentPassword'}
                   helperText={error === 'currentPassword' ? errorMessage : ''}
                   onChange={(ev) => setCurrentPassword(ev.target.value)}
                   inputProps={{ style: { fontFamily: !currentPasswordVisibility ? "initial" : "" } }}
                   InputProps={{
                       endAdornment: (
                           <InputAdornment position="end">
                               <IconButton
                                   aria-label="toggle password visibility"
                                   onClick={() => setCurrentPasswordVisibility(!currentPasswordVisibility)}
                               >
                                   {currentPasswordVisibility ? <VisibilityOff /> : <Visibility />}
                               </IconButton>
                           </InputAdornment>
                       )
                   }}
        />
        <TextField fullWidth
                   autoComplete="off"
                   label="New password"
                   className="password-text-field"
                   type={newPasswordVisibility ? "text" : "password"}
                   value={newPassword}
                   onChange={(ev) => setNewPassword(ev.target.value)}
                   error={error === 'newPassword'}
                   helperText={error === 'newPassword' ? errorMessage : ''}
                   inputProps={{ style: { fontFamily: !newPasswordVisibility ? "initial" : "" } }}
                   InputProps={{
                       endAdornment: (
                           <InputAdornment position="end">
                               <IconButton
                                   aria-label="toggle password visibility"
                                   onClick={() => setNewPasswordVisibility(!newPasswordVisibility)}
                               >
                                   {newPasswordVisibility ? <VisibilityOff /> : <Visibility />}
                               </IconButton>
                           </InputAdornment>
                       ),
                       style: {
                           height: 56,
                           fontSize: 17
                       }
                   }}
        />
        <TextField fullWidth
                   autoComplete="off"
                   label="Confirm new password"
                   className="password-text-field"
                   type={confirmNewPasswordVisibility ? "text" : "password"}
                   value={confirmNewPassword}
                   onChange={(ev) => setConfirmNewPassword(ev.target.value)}
                   onBlur={checkPasswordConfirmation}
                   error={error === 'confirmNewPassword'}
                   helperText={error === 'confirmNewPassword' ? errorMessage : ''}
                   inputProps={{ style: { fontFamily: !confirmNewPasswordVisibility ? "initial" : "" } }}
                   InputProps={{
                       endAdornment: (
                           <InputAdornment position="end">
                               <IconButton
                                   aria-label="toggle password visibility"
                                   onClick={() => setConfirmNewPasswordVisibility(!confirmNewPasswordVisibility)}
                               >
                                   {confirmNewPasswordVisibility ? <VisibilityOff /> : <Visibility />}
                               </IconButton>
                           </InputAdornment>
                       )
                   }}
        />
        <div className="legend-row">New password must be:</div>
        {passwordRequirements.map(requirement =>
            <div
                 style={{ color: requirementsValidation.includes(requirement) ? "" : "var(--darkGrayishBlue)",
                     fontSize: 13, letterSpacing: 0.4, display: "flex", alignItems: "center" }}>
                <Circle color="secondary" className="legend-bullet" sx={{ color: "var(--secondary)" }} />
                {requirement}
            </div>)}
        {!props.isChangeScheduled && <div className="legend-row"><Circle color="secondary" className="legend-bullet" />
            Different from the previous 5 used passwords
        </div>}
        {!props.isChangeScheduled ? <div className="buttons-wrapper">
            <Button disabled={submitted} color="secondary" variant="outlined" onClick={hideModal} style={{ width: 89, height: 39 }}>
                CANCEL
            </Button>
            <Button disabled={submitted} color="secondary" variant="contained" onClick={savePassword} style={{ width: 186, height: 39 }}>
                CHANGE PASSWORD {submitted && <ButtonSpinner />}
            </Button>
        </div> : <div style = {{display: "flex", justifyContent: "center", marginTop: 20 }}>
            <Button disabled={submitted} color="secondary" variant="contained" onClick={savePassword} 
                style={{ width: 328, height: 39 }}>
                CHANGE PASSWORD {submitted && <ButtonSpinner />}
            </Button></div>}
    </div>
}
