import AnnotationFilter from "./AnnotationFilter";

export const BasicAnnotationFilter = (props: {pageRangeChangeCallBack: any,
                                        searchPhraseChangeCallBack: any,
                                        categoryChangeCallBack: any,
                                        categories: string[]}) => {
    return <AnnotationFilter pageRangeChangeCallBack={props.pageRangeChangeCallBack}
                             searchPhraseChangeCallBack={props.searchPhraseChangeCallBack}
                             categoryChangeCallBack={props.categoryChangeCallBack}
                             sortChangeCallBack={undefined}
                             regexInputChangeCallBack={undefined}
                             categories={props.categories}
                             limitedOptions={true} />
}