import baseline_close from "../../../provisional_icons/baseline-close.png";
import { useCustomModal } from "../custom-message-modal";
import RLS_Protect_Logo from "../../../provisional_icons/RLS_Protect_logo.png";

export function RlsProtectUnavailableModal() {
    const { hideModal } = useCustomModal();

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img alt="close" src={baseline_close} className="remove-entity-close-button" style = {{marginRight: -16}} onClick={hideModal} />
            <div>
                <img alt="logo" src={RLS_Protect_Logo} />
            </div>
            <span style={{ fontSize: 18, fontWeight: "600", letterSpacing: 0.15, marginTop: 15 }}>
                This option is not currently available.
            </span>
        </div>
    </div>
}
