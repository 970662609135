import baseline_close from "../../../provisional_icons/baseline-close.png";
import Button from "@mui/material/Button";
import { useCustomModal } from "../../modals/custom-message-modal";
import { useEffect, useState } from "react";
import { ButtonSpinner } from "../../../components/button-spinner";
import { useAppDispatch } from "../../../hooks/redux-hook";
import { useTheme } from "@mui/material/styles";
import { TaskListSearch } from "../../../components/project/task-list-search";
import { Task } from "../../../models/Task";
import { TaskList } from "../../../models/TaskList";
import { SubTask } from "../../../models/SubTask";
import { postApiTasksBulkCreate } from "../../../services/task";
import { showSnackbar } from "../../../redux/snackbar";

export function DuplicateSubtaskListModal(p: { allLists: TaskList[], subtaskList: SubTask[], projectName: string }) {
    const { hideModal } = useCustomModal();
    const [submitted, setSubmitted] = useState(false);
    const [tabSelected, setTabSelected] = useState<"All tasks in a list" | "A single task">("All tasks in a list");
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedTaskLists, setSelectedTaskLists] = useState<TaskList[]>([]);
    const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
    const allTasksSelected = tabSelected === "All tasks in a list";
    const singleTaskSelected = tabSelected === "A single task";

    useEffect(() => {
        setSelectedTaskLists([]);
        setSelectedTasks([]);
    }, [tabSelected])

    const onSubmit = async () => {
        setSubmitted(true);
        const controller = new AbortController();
        const arr: Task[] = [];
        let formattedTaskLists = "";
        let formattedTasks = "";
        if (allTasksSelected) {
            selectedTaskLists.forEach(selectedList => {
                selectedList.tasks?.forEach(task => {
                    arr.push({
                        ...task,
                        subtasks: p.subtaskList.map(st => ({
                            ...st,
                            taskListId: task.taskListId,
                            status: "NOT_STARTED",
                            parentTaskId: task.id
                        }))
                    })
                })
            })
            formattedTaskLists = selectedTaskLists.map(stl => stl.name).join(", ");
        } else {
            selectedTasks.forEach(selectedTask => {
                arr.push({
                    ...selectedTask,
                    subtasks: p.subtaskList.map(st => ({
                        ...st,
                        taskListId: selectedTask.taskListId,
                        status: "NOT_STARTED",
                        parentTaskId: selectedTask.id
                    }))
                })
            })

            selectedTasks.forEach((selectedTask, i) => {
                const list = p.allLists.find(l => l.id === selectedTask.taskListId);
                formattedTasks = formattedTasks + `${selectedTask.name} in the ${list?.name || ""} list${i !== arr.length - 1 ? ", " : ""}`
            })
        }
        postApiTasksBulkCreate(arr, controller.signal)
            .then(() => {
                hideModal();
                setSubmitted(false);
            })
            .then(() => dispatch(showSnackbar({
                message: allTasksSelected ?
                    `Subtask list duplicated successfully to all tasks in the ${formattedTaskLists} list${selectedTaskLists.length > 1 ? "s" : ""}.` :
                    `Subtask list duplicated successfully to task ${formattedTasks}.`,
                type: "info"
            })))
            .catch(() => {
                setSubmitted(false);
                dispatch(showSnackbar({
                    message: "Error duplicating subtask list!",
                    type: "error"
                }))
            });
    }

    const onClickItem = (value: TaskList | Task) => {
        if (allTasksSelected) {
            const item: TaskList = p.allLists.filter(list => list.id === value.id).pop()!;
            const index = selectedTaskLists.indexOf(item);
            if (selectedTaskLists.includes(item)) selectedTaskLists.splice(index, 1)
            else selectedTaskLists.push(item)
        } else {
            p.allLists.forEach(list => {
                const item: Task = list.tasks?.filter(task => task.id === value.id).pop()!;
                if (item) {
                    const index = selectedTasks.indexOf(item);
                    if (selectedTasks.includes(item)) selectedTasks.splice(index!, 1)
                    else selectedTasks.push(item)
                }
            })
        }
        setSelectedTaskLists([...selectedTaskLists]);
        setSelectedTasks([...selectedTasks]);
    }

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img alt='close' src={baseline_close} className="remove-entity-close-button" onClick={hideModal} />
            <div className="wizard-title">
                <span>Duplicate Subtask List to</span>
            </div>
            <div style={{ marginTop: 24 }} />
            <div style={{ display: "flex", marginBottom: 24 }}>
                <div onClick={() => setTabSelected("All tasks in a list")} style={{
                    fontSize: 14, letterSpacing: 0.25, cursor: "pointer", borderRadius: 16, textAlign: "center", padding: "5px 0px",
                    backgroundColor: allTasksSelected ? "#217DA215" : "white",
                    border: allTasksSelected ? "1px solid #374C72" : "1px solid lightgray",
                }}>
                    <span style={{ margin: "6px 12px 7px 12px", color: allTasksSelected ? theme.palette.primary.main : "black" }}>All tasks in a list</span>
                </div>
                <div onClick={() => setTabSelected("A single task")} style={{
                    border: singleTaskSelected ? "1px solid #374C72" : "1px solid lightgray",
                    backgroundColor: singleTaskSelected ? "#217DA215" : "white",
                    marginLeft: 24, fontSize: 14, letterSpacing: 0.25, cursor: "pointer", borderRadius: 16, textAlign: "center", padding: "5px 0px"
                }}>
                    <span style={{ margin: "6px 12px 7px 12px", color: singleTaskSelected ? theme.palette.primary.main : "black" }}>A single task</span>
                </div>
            </div>
            <TaskListSearch anchorEl={anchorEl} setAnchorEl={setAnchorEl} type={allTasksSelected ? "LIST" : "TASK"}
                            taskListData={p.allLists} dependencyArray={p.allLists} selectedTaskLists={selectedTaskLists} selectedTasks={selectedTasks}
                            menuItemOnClick={onClickItem} showInPage projectName={p.projectName}
                            icon={<></>}
            />
            <div style={{ marginTop: 64, display: "flex", justifyContent: "center" }} >
                <Button disabled={submitted} onClick={onSubmit} color="secondary" variant="contained">DUPLICATE SUBTASKS {submitted && <ButtonSpinner />}</Button>
            </div>
        </div>
    </div >
}
