import {Accordion, AccordionDetails, AccordionSummary, MenuItem} from "@mui/material";
import React, {useEffect, useState} from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useEventHandler} from "../hooks/handle-events";
import {TaskList} from "../models/TaskList";
import {Task} from "../models/Task";

export function TaskListSelectMenuItem(p: { taskList: TaskList, selectedTasks: number[], onClick: (event: any) => void }) {
    const [expanded, setExpanded] = useState(false);
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const [allTasks, setAllTasks] = useState<Task[]>([]);

    useEffect(() => {
        p.taskList.tasks?.map(task => {
            allTasks.push(task)
            task.subtasks?.map(subTask => {
                allTasks.push(subTask)
            })
        })
        setAllTasks(allTasks)
    }, [])

    return <Accordion  onChange={() => setExpanded(!expanded)}>
        <AccordionSummary >
            <div style={{ display: "flex", alignItems: "center" }}>
                {expanded ? <ExpandLessIcon className="expand-icon" /> : <ExpandMoreIcon className="expand-icon" />}
                <div style={{ marginLeft: 4, fontSize: 17, letterSpacing: 0.49 }}>
                    {p.taskList.name}
                </div>
            </div>
        </AccordionSummary>
        <AccordionDetails>
            {allTasks.map(task => {
                return <MenuItem
                    key={'task ' +task.id}
                    value={task.id}
                    onClick={p.onClick}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler} style={{marginLeft: 10}}>
                    <input type={"checkbox"} readOnly={true} value={task.id}
                           checked={p.selectedTasks.some(selectedTask => selectedTask===task.id)} style={{marginRight:10}}/>
                    {task.parentTaskId ===undefined || task.parentTaskId ===null ? task.name : (task.name + ' (Subtask)')}
                </MenuItem>
            })}
        </AccordionDetails>
    </Accordion>
}