import React, {useEffect, useRef, useState} from 'react';
import {useCustomModal} from "./custom-message-modal";
import ModalIconContent from "./modal-content/modal-icon-content";

//This asks the user to choose a match category for the mark they just added.
const SanitizeSuccessModal = (props : { onSave: () => void,onRemoveSelected: ()=>void }) => {
    const { hideModal } = useCustomModal();
    const modalRef = useRef<HTMLDivElement>(null);
    const onSubmit = () => {
        props.onSave()
        hideModal()
    }


    return <div ref={modalRef} style={{position: "relative",
        width: 350, display: "flex", alignItems: 'center',
        flexDirection: "column", justifyContent: "center", padding: "20px 30px"
    }}>
        <h4 style={{fontWeight:"bold"}}>Sanitization is successful</h4>
        <ModalIconContent isDone/>
        <p style={{marginBottom:"0px",textAlign:"center"}}>
            Removed all the selected contents from the document successfully.
        </p>
        <p style={{textAlign:"center"}}>
            You can save the sanitized data or<br/>  continue editing.
        </p>
        <div style={{marginTop:"10px"}}>
            <button onClick={() => {hideModal();props.onRemoveSelected()}} className={"sanitize-first-modal"} style={{backgroundColor:"#e86122"}}>Continue</button>
            <button onClick={props.onSave} className={"sanitize-first-modal"} style={{margin:"0px 10px"}}>Save File</button>
        </div>

    </div>


};

export default SanitizeSuccessModal;
