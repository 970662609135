import React, {useEffect, useState, useReducer, useContext} from 'react';
import classNames from 'classnames';
import '../Panel.scss'
import { getTransformStyle } from '../TransformsPanel/transform-panel'
import WebViewerContext from "../../../contexts/webviewer-context";
import AnnotationFilterHelpers from "../AnnotationFilterHelpers";
import AnnotationFilter from "../AnnotationFilter";
import {loadRedactionAnnotations} from "../RedactionPanel/RedactionPanelContainer";

const ReplacedPanel = (props) => {
    const [highlightAnnotations, setHighlightAnnotations] = useState([])
    const [currentHighlights, setCurrentHighlights] = useState([]);
    const [selectedHighlights, setSelectedHighlights] = useState([]);
    const [pageRangeFilter, setPageRangeFilter] = useState(undefined);
    const [searchPhraseFilter, setSearchPhraseFilter] = useState(undefined);
    const [categoryFilter, setCategoryFilter] = useState(undefined);
    const [regexInputFilter, setRegexInputFilter] = useState(undefined);
    const [sortOrder, setSortOrder] = useState("Asc")
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const { setInstance,instance } = useContext(WebViewerContext);

    const loadHighlights = () => {
        return instance.Core.annotationManager.getAnnotationsList().filter((annotation) => annotation instanceof instance.Core.Annotations.TextHighlightAnnotation);
    }

    useEffect(() => {
        const onAnnotationChanged = () => {
            setHighlightAnnotations(loadHighlights())
        }

        if (instance) {
            setHighlightAnnotations(loadHighlights())
            instance.Core.annotationManager.addEventListener('annotationChanged', onAnnotationChanged)
        }
        return () => {
            instance.Core.annotationManager.removeEventListener('annotationChanged', onAnnotationChanged)
        }
    }, [instance]);

    function filterPageRangeOnChange(newPageRange) {
        //We need to clone the value so that React recognizes it as a change and re-renders.
        const verifiedPageRange = newPageRange === undefined ? undefined : [...newPageRange];
        console.log('setting page range');
        setPageRangeFilter(verifiedPageRange);
    }

    function searchPhraseOnChange(newSearchPhrase) {
        console.log('setting search phrase');
        setSearchPhraseFilter(newSearchPhrase)
    }


    function regexInputOnChange(newRegexInput) {
        console.log('setting regex input');
        setRegexInputFilter(newRegexInput)
    }

    function sortChangeCallBack(newSortOrder) {
        console.log('setting order');
        setSortOrder(newSortOrder)
    }

    function categoryOnChange(newCategory) {
        console.log('setting category');
        setCategoryFilter(newCategory)
    }

    useEffect(() => {
        //We need to map these fields to the ones that are used in the filter helper methods.
        highlightAnnotations.forEach(annotation => {
            annotation.pageNum = annotation.PageNumber
            //Marks added by selecting text have an annot-preview but contents is undefined.
            //Marks from Adobe have an empty string for the contents.
            //   annotation.resultStr = annotation.getCustomData('trn-annot-preview')
        })
        AnnotationFilterHelpers.applyFiltersTransform(highlightAnnotations, searchPhraseFilter, pageRangeFilter, categoryFilter, regexInputFilter)

        const sortedHighlights = highlightAnnotations.sort((a, b) => {
            if (sortOrder==="Asc") {
                //sorting by location in document then location in page
                if (a.pageNum !== b.pageNum) {
                    return a.pageNum - b.pageNum
                } else if (a.getY() !== b.getY()) {
                    return a.getY() - b.getY()
                } else {
                    return a.getX() - b.getX()
                }
            } else {//desc
                if (b.pageNum !== a.pageNum) {
                    return b.pageNum - a.pageNum
                } else if (b.getY() !== a.getY()) {
                    return b.getY() - a.getY()
                } else {
                    return b.getX() - a.getX()
                }
            }
        })
        const filteredHighlights = [];
        sortedHighlights.forEach((annotation) => {

            if (annotation.shouldHide) {
                return
            }
            filteredHighlights.push(annotation);
        });

        setCurrentHighlights(filteredHighlights);
    }, [highlightAnnotations, pageRangeFilter, searchPhraseFilter, categoryFilter, regexInputFilter, sortOrder]);

    const onClick = (annotation) => {
        if (selectedHighlights.some(selectedHighlight => selectedHighlight === annotation)) {
            setSelectedHighlights(prevState => prevState.filter((el) => el !== annotation));
        } else {
            setSelectedHighlights([...selectedHighlights, annotation]);
        }
    }

    const onReplacementChange = (annotation, event) => {
        const value = event.target.value;
        annotation.setCustomData("Replacement", event.target.value);
        annotation.setCustomData("ManuallyEdited", value ? "true" : "");
    }

    const annotationToolTip = (annotation) => {
        let toolTipText = 'type: ' + annotation.type + '\n';
        toolTipText+= 'page: ' + annotation.getPageNumber() + '\n';
        toolTipText+= 'status: ' + getStatus(annotation) + '\n';
        toolTipText+= 'patient Id: ' + annotation.getCustomData("Patient ID") + '\n';
        toolTipText+= 'start date: ' + annotation.getCustomData("Start Date") + '\n';
        toolTipText+= 'study ID: ' + annotation.getCustomData("Study ID") + '\n';
        toolTipText+= 'replacement method: Auto\n';//We don't have those yet
        toolTipText+= 'font type: \n';
        toolTipText+= 'font size: \n';
        return toolTipText;
    }

    function getStatus(annotation) {
        if (annotation.getStatus()==="") {
            return "None"
        }
        return annotation.getStatus()
    }

    function selectAnnotation(annotation) {
        instance.Core.annotationManager.deselectAllAnnotations();
        instance.Core.annotationManager.selectAnnotation(annotation);
        instance.Core.annotationManager.jumpToAnnotation(annotation);
    }

    const renderHighlightPageGroups = () => {
        return (
            <table width="100%" style={{ marginTop: 24 }} className={'table_border'} >
                <thead  >
                <tr >
                    <td className={'column_header'} width="6%" ></td>
                    <td className={'column_header'} width="47%" >Before</td>
                    <td className={'column_header'} width="47%" >After</td>
                </tr>
                </thead>
                <tbody>
                {currentHighlights.map((annotation, index) => {
                    const {backgroundColor, textColor, displayReplacementText} = getTransformStyle(annotation, 'replaced');
                    return (<tr key={annotation.Id} title={annotationToolTip(annotation)} style={{cursor: 'pointer' }}>
                        <td><input width={"inherit"} height={"inherit"} type={"checkbox"} readOnly onClick={() => onClick(annotation)}
                                   checked={selectedHighlights.some(selectedHighlights => selectedHighlights===annotation)}
                        /></td>
                        <td onClick={() => selectAnnotation(annotation)}>{annotation.resultStr || annotation.getContents() || ""}</td>
                        <td><input key={`${annotation.Id}${displayReplacementText}`} defaultValue={displayReplacementText} width={"inherit"} style={{ border: 'none', backgroundColor: backgroundColor, color: textColor }} onChange={event => onReplacementChange(annotation, event)} onBlur={() => forceUpdate()} /></td>
                    </tr>);
                })}
                </tbody>
            </table>
        );
    };

    const noHighlightAnnotations = (
        <div className="no-marked-redactions">
            <div className="msg">No Replaced Annotations</div>
        </div>
    );

    const redactAllButtonClassName = classNames('redact-all-marked', { disabled: highlightAnnotations.length === 0 });

    const revertCheckedHighlights = () => {
        currentHighlights.forEach(highlight => highlight.highlightChecked=false)
        selectedHighlights.forEach(highlight => highlight.highlightChecked=true)
        selectedHighlights.forEach(highlight => highlight.pageNum = highlight.PageNumber)
        selectedHighlights.forEach(highlight => highlight.setCustomData("RevertedReplacement", highlight.getCustomData("Replacement") === "%RETAIN%" ? "%RETAIN%" : highlight.getCustomData("Replacement")))
        selectedHighlights.forEach(highlight => highlight.setCustomData("Replacement", highlight.getCustomData("Replacement") === "%RETAIN%" ? "%RETAIN%" : highlight.getContents()))
        selectedHighlights.forEach(highlight => highlight.setCustomData('trn-annot-preview', highlight.getContents()))
        instance.Core.annotationManager.trigger('revertText');
    };

    const transformCheckedHighlights = () => {
        currentHighlights.forEach(highlight => highlight.highlightChecked=false)
        selectedHighlights.forEach(highlight => highlight.highlightChecked=true)
        selectedHighlights.forEach(highlight => highlight.pageNum = highlight.PageNumber)
        selectedHighlights.forEach(highlight => highlight.setCustomData('trn-annot-preview', highlight.getContents()))
        instance.Core.annotationManager.trigger('transformText');
    };

    const changeFontCheckedHighlights = () => {
        currentHighlights.forEach(highlight => highlight.highlightChecked=false)
        selectedHighlights.forEach(highlight => highlight.highlightChecked=true)
        selectedHighlights.forEach(highlight => highlight.pageNum = highlight.PageNumber)
        selectedHighlights.forEach(highlight => highlight.setCustomData('trn-annot-preview', highlight.getContents()))
        instance.Core.annotationManager.trigger('changeHighlightContentFont');
    };

    const changeCategoryCheckedHighlights = () => {
        currentHighlights.forEach(highlight => highlight.highlightChecked=false)
        selectedHighlights.forEach(highlight => highlight.highlightChecked=true)
        selectedHighlights.forEach(highlight => highlight.pageNum = highlight.PageNumber)
        selectedHighlights.forEach(highlight => highlight.setCustomData('trn-annot-preview', highlight.getContents()))
        instance.Core.annotationManager.trigger('replacedChangeCategory');
    };

    return (
        <>

            {currentHighlights.length > 0 ? <div>
                <button className={"checkButton"}
                        onClick={() => setSelectedHighlights(currentHighlights)}
                        disabled={currentHighlights.length === 0}
                        aria-label={'Check All'}
                >
                    {'Check All'}
                </button>
                <button className={"checkButton"}
                        disabled={currentHighlights.length === 0}
                        onClick={() => setSelectedHighlights([])}
                        aria-label={'Uncheck All'}
                >
                    {'Uncheck All'}
                </button>
                <button className={"checkButton"}
                        disabled={selectedHighlights.length === 0}
                        onClick={() => changeFontCheckedHighlights()}
                        aria-label={'Uncheck All'}
                >
                    {'Change Font'}
                </button>
                <button className={'checkButton'}
                        disabled={selectedHighlights.length === 0}
                        onClick={() => changeCategoryCheckedHighlights()}
                        aria-label={'Uncheck All'}
                >
                    {'Change Category'}
                </button>
            </div> : null}

            {highlightAnnotations.length > 0 ? <AnnotationFilter pageRangeChangeCallBack={filterPageRangeOnChange} searchPhraseChangeCallBack={searchPhraseOnChange} categoryChangeCallBack={categoryOnChange} categories={AnnotationFilterHelpers.getCategories(highlightAnnotations)} sortChangeCallBack={sortChangeCallBack} regexInputChangeCallBack={regexInputOnChange} limitedOptions={false} /> : null}
            {highlightAnnotations.length > 0 ? renderHighlightPageGroups() : noHighlightAnnotations}
            {highlightAnnotations.length > 0 ? <div className="redaction-panel-controls">
                <button
                    disabled={selectedHighlights.length===0}
                    className={redactAllButtonClassName}
                    onClick={revertCheckedHighlights}
                    style={{marginRight: 5}}
                    aria-label='revert checked'
                >
                    Revert
                </button>
                <button
                    disabled={selectedHighlights.length===0}
                    className={redactAllButtonClassName}
                    onClick={transformCheckedHighlights}
                    aria-label='redact checked'
                >
                    Apply
                </button>
            </div> : null}
        </>
    );
};


export default ReplacedPanel;
