import { NavBar } from "../../components/navBar";
import { Footer } from "../../components/footer";
import { Box } from "@mui/material";

export function InactivityLogout() {
    return <div>
        <NavBar />
        <div className='box-container' style={{ height: "calc(100vh - 120px)" }}>
            <Box boxShadow={3} style={{
                width: 424,
                backgroundColor: "white",
                borderRadius: 4,
                marginTop: 32,
                marginBottom: 32,
                overflowY: "auto",
                height: "fit-content"
            }}>
                <div className="content-box">
                    <span style={{ fontSize: 25, letterSpacing: 0 }}>Automatic Logout Due to Inactivity</span>
                    <p style={{ fontSize: 17, fontWeight: "600", letterSpacing: 0.49 }}>
                        You have been logged out for security reasons because you exceeded 15 minutes of inactivity.
                    </p>
                    <span style={{ fontSize: 15 }}>
                        The system automatically logged you out of your account for security reasons.<br />
                        Please go back to the login page to continue using the platform.
                    </span>
                    <br />
                    <span style={{ textAlign: "center", letterSpacing: 0.24 }}>
                        <a className="hyperlink-text" href="/login" style={{ fontWeight: 600, letterSpacing: 1.25 }}>go back to login page</a>
                    </span>
                </div>
            </Box>
        </div>
        <Footer />
    </div>
}
