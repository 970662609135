import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useCustomModal } from "../custom-message-modal";
import './remove-entity-modal.scss';
import { StyledBackArrow, StyledCloseIcon } from "../../../components/close-button";
import { getApiProjectsExistingCustomFields, putApiProjectsProjectDetailsById } from "../../../services/project";
import { ErrorHelper } from "../../../components/error-helper";
import { ProjectDetails } from "../../../models/ProjectDetails";
import { AddExistingCustomFieldProjectDetails } from "./add-existing-custom-field-project-details";
import { useEffect, useState } from "react";
import { Project } from "../../../models/Project";

export function AddCustomFieldProjectDetailsModal(props: {
    oldProjectDetails: ProjectDetails, project: Project,
    fetchProject: () => void
}) {
    const { register, handleSubmit, formState: { errors }, getValues } = useForm<{ name: string, value: string }>();
    const { hideModal, showModal } = useCustomModal();
    const [existingCustomFields, setExistingCustomFields] = useState<string[]>([]);

    useEffect(() => {
        const controller = new AbortController()
        getApiProjectsExistingCustomFields(controller.signal).then(res => setExistingCustomFields(res))
    }, [])

    const onSubmit = () => {
        const oldPd = JSON.parse(JSON.stringify(props.oldProjectDetails) ?? {});
        const newPd = { ...oldPd, [getValues("name")]: getValues("value") }
        putApiProjectsProjectDetailsById(props.project.id, newPd as ProjectDetails).then(() => {
            props.fetchProject();
            hideModal();
        })
    }

    return <div style={{
        width: 747, height: 388, display: "flex",
        flexDirection: "column", marginTop: 36
    }}>
        <div style={{ display: "flex", flexDirection: "column", marginRight: 45, marginLeft: 45 }}>
            <StyledBackArrow onClick={() => hideModal()} />
            <StyledCloseIcon onClick={() => hideModal()} />
            <div style={{ marginTop: 15, fontSize: 25, textAlign: "center" }}>
                Add Custom field to Project details
            </div>
            <label className="wizard-content-label" style={{ fontSize: 17, marginTop: 16, marginBottom: 18, letterSpacing: 0.49 }}>
                You can add up to 15 custom fields to the Project details.</label>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span>Field name</span>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 8 }}>
                    <div>
                        <TextField {...register("name", { required: true })} placeholder="Enter name" error={!!errors.name}
                            style={{ width: 316 }} />
                        {errors.name && errors.name.type === "required" && <ErrorHelper helperText="Please enter a name." />}
                    </div>
                    <div>
                        <TextField {...register("value", { required: true })} placeholder="Enter number or text"
                            error={!!errors.value} style={{ width: 316 }} />
                        {errors.value && errors.value.type === "required" && <ErrorHelper helperText="Please enter a value." />}
                    </div>
                </div>
            </div>
            <Button onClick={() => showModal(AddExistingCustomFieldProjectDetails, {
                project: props.project, oldProjectDetails: props.oldProjectDetails,
                fetchProject: props.fetchProject, existingCustomFields: existingCustomFields.filter(ecf => ecf !== "totalPages" &&
                    ecf !== "totalOutOfScopePages" && ecf !== "totalScopePages")
            })}
                color="secondary" variant="outlined" style={{ width: "fit-content", marginTop: 28, padding: "6px 32px" }}>
                OR ADD AN EXISTING FIELD
            </Button>
            <Button onClick={handleSubmit(onSubmit)} color="secondary" variant="contained" style={{
                width: "fit-content", alignSelf: "center", marginTop: 36, padding: "7px 32px"
            }}>
                SAVE CHANGES
            </Button>
        </div>
    </div>
}
